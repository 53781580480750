import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { connect } from "react-redux";
import { setFields } from "../../../../actions/selected_values";

export const SelectComponent = (props) => {
  const [page, setPage] = useState(
    props.fields[String(props.id)] === undefined
      ? "homepage"
      : props.fields[String(props.id)]
  );

  const handleChange = (event) => {
    props.setFields({ [String(props.id)]: event.target.value });
    setPage(event.target.value);
  };

  const options = [
    "homepage",
    "actors_new_actor",
    "actors_modify_actor",
    "actors_my_actors",
    "workspace_new_workspace",
    "workspace_my_workspaces",
    "workspace_modify_workspace",
    "actions_new_action",
    "actions_new_action",
    "actions_my_actions",
    "actions_modify_action",
    "actions_action_type",
    "contract_new_contract",
    "contract_my_contracts",
    "contract_modify_contract",
  ];
  const wrapper = document
    .getElementById("sub_wrapper")
    .getBoundingClientRect();
  return (
    <Box
      sx={{
        width:
          (wrapper.width - 35 * props.numWindows ** 1.1) / props.numWindows,
      }}
      id={"select"}
      className={"select_component"}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <FormControl>
        <Select
          value={page}
          onChange={handleChange}
          inputProps={{ IconComponent: () => null }}
        >
          {options.map((option) => {
            function capitalize(str) {
              var i,
                frags = str.split("_");
              for (i = 0; i < frags.length; i++) {
                if (i === 0 && str !== "homepage") {
                  frags[i] =
                    frags[i].charAt(0).toUpperCase() + frags[i].slice(1) + ":";
                } else {
                  frags[i] =
                    frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
              }
              return frags.join(" ");
            }
            return <MenuItem value={option}>{capitalize(option)}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { setFields })(SelectComponent);
