export const getUserType = (obj) => {
    var user_type = ""
    if (obj.is_project_admin === true) {
        user_type = "PROJECT ADMIN"
    }
    if (obj.is_project_user === true) {
        user_type = "PROJECT USER"
    }
    if (obj.is_actor === true) {
        user_type = "ACTOR"
    }
    if (obj.is_sub_actor === true) {
        user_type = "SUB ACTOR"
    }
    return user_type
}