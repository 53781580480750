import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card } from "reactstrap";
import { useDispatch } from "react-redux";
import { useWindowDimensions } from "../../../../../utils/useWindowDimensions";
import { FaSpinner } from "react-icons/fa"
import { AgGridReact } from "ag-grid-react";


function Table(props) {

    const [rowData, setRowData] = useState(null)
    const [columnDefs, setColumnDefs] = useState(null)
    const { width, height } = useWindowDimensions()


    const grid = useRef();
    useEffect(() => {
        var rows = []
        var cols = []
        if (props.output.value.data.length > 0) {
            rows = props.output.value.data
            cols = props.output.value.columns_config
        }
        setRowData(rows);
        setColumnDefs(cols);
    }, []);


    return (

        <div>
            <div style={{ fontFamily: "Montserrat" }}>
                {props.output.title}
            </div>
            <div
                style={{ fontFamily: "Montserrat", fontWeight: 500, fontSize: 12 }}
            >
                {props.output.description}
            </div>
            {rowData === null && columnDefs === null ?
                <FaSpinner
                    icon="spinner"
                    className="spinner"
                    style={{ fontSize: 25, color: "#5B5B5B", marginTop: 15 }}
                /> :
                <div className="ag-theme-balham" style={{
                    height: rowData.length < 15 ? "" : "400px",
                    marginTop: 5
                }}>
                    <AgGridReact
                        ref={grid}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={{
                            flex: 1,
                            sortable: true,
                            resizable: true,
                            filter: true,
                            filterParams: {
                                buttons: ["reset"],
                                newRowsAction: "keep",
                                caseSensitive: true
                            },
                        }}
                        domLayout={rowData.length < 15 ? "autoHeight" : ""}
                    >
                    </AgGridReact>
                </div>
            }
        </div>

    );
}

export default Table;
