import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "reactstrap";
import { InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { getHeightWithFooterWONavbar } from "../../../../utils/getHeightWithFooterWONavbar";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { connect } from "react-redux";
import { FaSpinner, FaCogs, FaRegFolderOpen } from "react-icons/fa";
import { API_URL } from "../../../../actions/types";
import axios from "axios";
import SingleParam from "./Parameters/SingleParam";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { GrPowerReset } from "react-icons/gr";
import ModalManualSpecify from "./ModalManualSpecify";

import ModalSubject from "./ModalSubject";

function Specify(props) {
  const [inventory, setInventory] = useState(null);
  const [what, setWhat] = useState("");
  const [where, setWhere] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [reset, setReset] = useState(0);
  const [hoverSubject1, setHoverSubject1] = useState(false);
  const [hoverSubject2, setHoverSubject2] = useState(false);
  const [subject1, setSubject1] = useState("");
  const [subject2, setSubject2] = useState("");
  const [isOpenSubject1, setIsOpenSubject1] = useState(false);
  const [isOpenSubject2, setIsOpenSubject2] = useState(false);
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();

  useEffect(async () => {
    const res = await axios.get(
      `${API_URL}/clicc/actions_inventory/`,
      tokenConfig()
    );
    setInventory(res.data);
  }, [reset]);

  return (
    <div
      style={{
        width: "100%",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        overflow: "auto",
      }}
    >
      {inventory === null ? (
        <Row style={{ margin: 0, marginTop: 15 }}>
          <Col xs="12">
            <FaSpinner className="spinner"></FaSpinner>
          </Col>
        </Row>
      ) : (
        <div style={{ position: "relative" }}>
          {/* <div
            onClick={() => setIsOpen(!isOpen)}
            style={{
              width: "fit-content",
              backgroundColor: props.layout.color_sidebar,
              color: "white",
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: 16,
              padding: "6px 15px 4px 15px",
              borderRadius: 10,
              cursor: "pointer",
              position: width > 990 ? "absolute" : "",
              marginLeft: 12,
              marginTop: 10,
              marginBottom: 5,
              right: 10,
              top: 0,
            }}
          >
            Add manual details
          </div> */}
          <Row style={{ margin: 0 }}>
            <Col lg="5" md="12" sm="12" style={{ marginTop: 15 }}>
              <div
                onMouseEnter={() => setHoverSubject1(true)}
                onMouseLeave={() => setHoverSubject1(false)}
                onClick={() => setIsOpenSubject1(true)}
                style={{
                  padding: "15px 15px",
                  border: "1px solid #CECECE",
                  color: "#707070",
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  fontSize: 16,
                  borderRadius: 5,
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderColor: hoverSubject1 === true ? "black" : "#CECECE",
                }}
              >
                {subject1 === ""
                  ? "Subject"
                  : subject1.substring(2).replace(/;;/g, " / ")}

                <FaRegFolderOpen style={{ fontSize: 20 }}></FaRegFolderOpen>
              </div>
            </Col>
          </Row>
          <Row style={{ margin: 0 }}>
            <Col lg="5" md="12" sm="12" style={{ marginTop: 15 }}>
              <div
                onMouseEnter={() => setHoverSubject2(true)}
                onMouseLeave={() => setHoverSubject2(false)}
                onClick={() => setIsOpenSubject2(true)}
                style={{
                  padding: "15px 15px",
                  border: "1px solid #CECECE",
                  color: "#707070",
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  fontSize: 16,
                  borderRadius: 5,
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderColor: hoverSubject2 === true ? "black" : "#CECECE",
                }}
              >
                {subject2 === ""
                  ? "Subject"
                  : subject2.substring(2).replace(/;;/g, " / ")}

                <FaRegFolderOpen style={{ fontSize: 20 }}></FaRegFolderOpen>
              </div>
            </Col>
          </Row>

          <Row style={{ margin: 0 }}>
            <Col lg="5" md="12" sm="12" style={{ marginTop: 15 }}>
              <TextField
                select
                label="Action type"
                value={
                  Object.keys(props.data.details).length === 0
                    ? ""
                    : props.data.details.id
                }
                style={{
                  width: "100%",
                }}
                InputProps={{
                  style: {
                    height: 56,
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                  },
                }}
                InputLabelProps={{
                  style: {
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                  },
                }}
                onChange={(e) => {
                  if (e.target.value === null) {
                    dispatch({
                      type: props.type,
                      payload: {
                        key: "details",
                        value: {},
                      },
                    });
                  } else {
                    dispatch({
                      type: props.type,
                      payload: {
                        key: "details",
                        value: inventory.find(
                          (obj) => obj.id === e.target.value
                        ),
                      },
                    });
                  }
                }}
              >
                <MenuItem
                  value={null}
                  disabled={
                    Object.keys(props.data.details).length <= 1 ? false : true
                  }
                  style={{ fontWeight: 500, fontFamily: "Montserrat" }}
                >
                  ...
                </MenuItem>
                {inventory
                  .filter((obj) =>
                    `;;${obj.subject_1.join(";;")}`.includes(subject1)
                  )
                  .filter((obj) =>
                    `;;${obj.subject_2.join(";;")}`.includes(subject2)
                  )
                  .map((inv) => {
                    return (
                      <MenuItem
                        value={inv.id}
                        style={{ fontWeight: 500, fontFamily: "Montserrat" }}
                      >
                        {!Object.keys(inv).includes("parameters") ||
                          inv.parameters === null ||
                          inv.parameters.length === 0 ?
                          `${inv.subActionName} [c]`
                          : inv.subActionName}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </Col>

            <Col
              lg="1"
              md="6"
              sm="6"
              style={{ display: "flex", alignItems: "center", marginTop: 15 }}
            >
              <GrPowerReset
                onClick={() => {
                  setSubject1("");
                  setSubject2("");
                  dispatch({
                    type: props.type,
                    payload: {
                      key: "details",
                      value: {},
                    },
                  });
                }}
                style={{ fontSize: 24, cursor: "pointer" }}
              ></GrPowerReset>
            </Col>
          </Row>

          {Object.keys(props.data.details).length > 0 &&
            props.data.details.parameters.length > 0 ? (
            <>
              <Row style={{ margin: 0 }}>
                <Col xs="12">
                  <div
                    style={{
                      fontFamily: "Montserrat",
                      marginTop: 30,
                      marginBottom: 5,
                      fontWeight: 500,
                      color: "#525252",
                      fontSize: 18,
                    }}
                  >
                    <FaCogs
                      style={{ marginTop: "-3px", marginRight: 5 }}
                    ></FaCogs>
                    Parameters
                  </div>
                </Col>
              </Row>
              <Row
                style={{
                  margin: 0,
                  marginTop: "-25px",
                  padding: 10,
                  paddingBottom: 180,
                }}
              >
                {props.data.details.parameters.map((param) => {
                  return (
                    <SingleParam
                      param={param}
                      data={props.data}
                      type={props.type}
                    ></SingleParam>
                  );
                })}
              </Row>
            </>
          ) : null}


          <ModalSubject
            isOpen={isOpenSubject1}
            setIsOpen={setIsOpenSubject1}
            treeData={inventory
              .filter((obj) =>
                `;;${obj.subject_2.join(";;")}`.includes(subject2)
              )
              .map((obj) => obj.subject_1.filter(element => element.trim() !== ''))
            }
            subject={subject1}
            setSubject={setSubject1}
          ></ModalSubject>
          <ModalSubject
            isOpen={isOpenSubject2}
            setIsOpen={setIsOpenSubject2}
            treeData={inventory
              .filter((obj) =>
                `;;${obj.subject_1.join(";;")}`.includes(subject1)
              )
              .map((obj) => obj.subject_2.filter(element => element.trim() !== ''))}
            subject={subject2}
            setSubject={setSubject2}
          ></ModalSubject>
        </div>
      )}

      {/* disabilitato inserimento manuale */}
      {/* <ModalManualSpecify
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        type={props.type}
        reset={reset}
        setReset={setReset}
      ></ModalManualSpecify> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Specify);
