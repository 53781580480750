import React, { useEffect, useState } from "react"
import {
    Form,
    FormGroup,
    Label,
    Input,
    Col,
    Button
} from "reactstrap";
import { FaEye, FaEyeSlash, FaSpinner } from "react-icons/fa";
import SuccessAlert from "../../../../components/Modals/SuccessAlert";
import { API_URL, GET_ERRORS, IS_LOADING } from "../../../../actions/types";
import { useDispatch } from "react-redux";
import axios from "axios";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { triggerRefresh } from "../../../../utils/triggerRefresh";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import moment from "moment"
import { getUserType } from "../../../../utils/getUserType";


const UserAnagrafica = (props) => {
    const [relatedAnagrafica, setRelatedAnagrafica] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [organization, setOrganization] = useState("")
    const [email, setEmail] = useState("")
    const [expirationDate, setExpirationDate] = useState(null)
    const [password, setPassword] = useState("                ")
    const [typePassword, setTypePassword] = useState("password")
    const dispatch = useDispatch()

    useEffect(() => {
        if (props.userAnagrafica !== null) {
            setRelatedAnagrafica(props.data.related_anagrafica)
            setFirstName(props.data.first_name)
            setLastName(props.data.last_name)
            setOrganization(props.data.organization)
            setEmail(props.data.email)
            setExpirationDate(props.data.expiration_date)
        }
    }, [props.userAnagrafica])

    const makeCall = async (str, value) => {
        if (str === "expiration_date") {
            if (value !== null) {
                if (value.isValid()) {
                    value = value.format("YYYY-MM-DD")
                } else {
                    value = null
                }
            }
        }
        try {
            await axios.put(
                `${API_URL}/users/${props.userAnagrafica.id}/`,
                { [str]: value },
                tokenConfig()
            );
            // refresh is done on toggle of ModalEditUser
        } catch (err) {
            const errors = {
                msg: err.response.data,
                status: err.response.status,
            };
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        }
    }
    return (
        <div>
            {props.userAnagrafica === null ?
                <div style={{ padding: 20 }}>
                    <FaSpinner
                        className="spinner"
                        style={{ marginTop: "-2px", marginRight: 5 }}
                    ></FaSpinner>
                    Loading...
                </div>
                :
                <Form style={{ padding: 20 }}>
                    <FormGroup row className="mb-4">
                        <Label for="form-username" sm={3}>
                            Username
                        </Label>
                        <Col sm={8}>
                            <Input
                                value={props.userAnagrafica.username}
                                disabled={true}
                                type="text"
                                name="username"
                                id="form-username"
                                placeholder=""
                                required
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row className="mb-4">
                        <Label for="form-user-type" sm={3}>
                            User type
                        </Label>
                        <Col sm={8}>
                            <Input
                                value={props.myUserType}
                                disabled={true}
                                type="text"
                                name="user-type"
                                id="form-user-type"
                                placeholder=""
                                required
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row className="mb-4">
                        <Label for="form-parent" sm={3}>
                            Parent
                        </Label>
                        <Col sm={8}>
                            <Input
                                value={props.userAnagrafica.parent_username}
                                disabled={true}
                                type="text"
                                name="parent"
                                id="form-parent"
                                placeholder=""
                                required
                            />
                        </Col>
                    </FormGroup>
                    {getUserType(props.userAnagrafica) === "ACTOR" || getUserType(props.userAnagrafica) === "SUB ACTOR" ?
                        <FormGroup row className="mb-4">
                            <Label for="form-related-anagrafica" sm={3}>
                                Related anagrafica
                            </Label>
                            <Col sm={8}>
                                <Input
                                    value={props.userAnagrafica.related_anagrafica}
                                    disabled={true}
                                    type="text"
                                    name="related_anagrafica"
                                    id="form-related_anagrafica"
                                    placeholder=""
                                    required
                                />
                            </Col>
                        </FormGroup>
                        : null}


                    <FormGroup row className="mb-4">
                        <Label for="form-firstname" sm={3}>
                            First name
                        </Label>
                        <Col sm={8}>
                            <Input
                                value={firstName}
                                onChange={e => setFirstName(e.target.value)}
                                onBlur={() => {
                                    makeCall("first_name", firstName)
                                }}
                                type="text"
                                name="first_name"
                                id="form-firstname"
                                placeholder=""
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row className="mb-4">
                        <Label for="form-lastname" sm={3}>
                            Last name
                        </Label>
                        <Col sm={8}>
                            <Input
                                value={lastName}
                                onChange={e => setLastName(e.target.value)}
                                onBlur={() => {
                                    makeCall("last_name", lastName)
                                }}
                                type="text"
                                name="last_name"
                                id="form-lastname"
                                placeholder=""
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row className="mb-4">
                        <Label for="form-organization" sm={3}>
                            Organization
                        </Label>
                        <Col sm={8}>
                            <Input
                                value={organization}
                                onChange={e => setOrganization(e.target.value)}
                                onBlur={() => {
                                    makeCall("organization", organization)
                                }}
                                type="text"
                                name="organization"
                                id="form-organization"
                                placeholder=""
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row className="mb-4">
                        <Label for="form-email" sm={3}>
                            Email
                        </Label>
                        <Col sm={8}>
                            <Input
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                onBlur={() => {
                                    makeCall("email", email)
                                }}
                                type="email"
                                name="email"
                                id="form-email"
                                placeholder=""
                                required
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row className="mb-4">
                        <Label for="form-expiration-date" sm={3}>
                            Expiration date
                        </Label>
                        <Col sm={8}>
                            <DesktopDatePicker
                                name="expiration_date"
                                inputFormat="DD-MM-YYYY"
                                value={expirationDate}
                                onChange={(newDate) => {
                                    setExpirationDate(newDate)
                                    makeCall("expiration_date", newDate)
                                }}
                                minDate={moment()}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        className="datePickerMontserrat"
                                    />
                                )}
                            />
                        </Col>
                    </FormGroup>
                </Form>}

        </div>
    )
};

export default UserAnagrafica;
