import React, { useState } from "react";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { connect, useDispatch } from "react-redux";
import { getHeightWithFooterWONavbar } from "../../../../utils/getHeightWithFooterWONavbar";
import { Row, Col } from "reactstrap";
import TitleCard from "../../../../components/TitleCard/TitleCard";
import { TextField } from "@mui/material";
import axios from "axios";
import {
  API_URL,
  IS_LOADING,
  GET_ERRORS,
  RESET_CREATE_NEW_CONTRACT,
  SET_OPENED_CONTRACT,
} from "../../../../actions/types";
import { tokenConfig } from "../../../../utils/tokenConfig";
import SuccessAlert from "../../../../components/Modals/SuccessAlert";
import InfoAlert from "../../../../components/Modals/InfoAlert";
import ErrorAlert from "../../../../components/Modals/ErrorAlert";
import { useHistory } from "react-router-dom";
import { triggerRefresh } from "../../../../utils/triggerRefresh";

function Content(props) {
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div
      style={{
        width: "100%",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        paddingLeft: 0,
        paddingBottom: props.layout.footer_height,
        overflow: "auto",
      }}
    >
      {/* workspace without padding */}
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 10,
        }}
      >
        {/* workspace */}
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          {props.data.workspace === null ? (
            <div>
              <span
                onClick={() => {
                  props.setActiveTab(1);
                }}
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecorationLine: "underline",
                }}
              >
                Open
              </span>{" "}
              a workspace to preview contract's details
            </div>
          ) : (
            <div>
              <Row style={{ margin: 0 }}>
                <Col
                  lg="4"
                  md="6"
                  sm="12"
                  style={{ height: "100%", marginTop: 10 }}
                >
                  <div
                    style={{
                      ...props.layout.generic_card,
                    }}
                  >
                    <TitleCard
                      title="Assign a name"
                      customBorderHeight={2}
                      customTitleSize={{ large: 19, small: 17 }}
                    ></TitleCard>
                    <div style={{ padding: "15px 15px 25px 15px" }}>
                      <TextField
                        variant="standard"
                        value={props.data.name}
                        inputProps={{
                          style: {
                            fontWeight: 500,
                            padding: 0,
                            fontFamily: "Montserrat",
                            marginTop: 10,
                          },
                        }}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          dispatch({
                            type: props.type,
                            payload: {
                              key: "name",
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row style={{ margin: 0 }}>
                <Col xs="12" style={{ height: "100%" }}>
                  <div
                    style={{
                      ...props.layout.generic_card,
                      marginTop: 20,
                    }}
                  >
                    <TitleCard
                      title="Description"
                      customBorderHeight={2}
                      customTitleSize={{ large: 19, small: 17 }}
                    ></TitleCard>
                    <div style={{ padding: "15px 15px 25px 15px" }}>
                      <TextField
                        variant="outlined"
                        placeholder="..."
                        multiline
                        rows={6}
                        value={props.data.description}
                        inputProps={{
                          style: {
                            fontWeight: 500,
                            padding: 0,
                            fontFamily: "Montserrat",
                          },
                        }}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          dispatch({
                            type: props.type,
                            payload: {
                              key: "description",
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <div style={{ display: "flex" }}>
                <div
                  onClick={async () => {
                    if (props.data.name === "") {
                      InfoAlert("Name cannot be empty");
                    } else if (props.data.description === "") {
                      InfoAlert("Description cannot be empty");
                    } else {
                      var params = {
                        ...props.data,
                      };
                      if (props.api_call === "post") {
                        try {
                          dispatch({
                            type: IS_LOADING,
                          });
                          const res = await axios.post(
                            `${API_URL}/clicc/contract_documents/`,
                            params,
                            tokenConfig()
                          );
                          dispatch({
                            type: IS_LOADING,
                          });
                          dispatch({
                            type: RESET_CREATE_NEW_CONTRACT,
                          });
                          SuccessAlert("Document saved successfully");
                          props.setActiveTab(1)
                          triggerRefresh()
                        } catch (err) {
                          dispatch({
                            type: IS_LOADING,
                          });
                          const errors = {
                            msg: err.response.data,
                            status: err.response.status,
                          };
                          dispatch({
                            type: GET_ERRORS,
                            payload: errors,
                          });
                        }
                      } else {
                        try {
                          dispatch({
                            type: IS_LOADING,
                          });
                          await axios.put(
                            `${API_URL}/clicc/contract_documents/${props.data.id}/`,
                            params,
                            tokenConfig()
                          );
                          dispatch({
                            type: IS_LOADING,
                          });
                          SuccessAlert("Document updated successfully");
                          triggerRefresh()
                        } catch (err) {
                          dispatch({
                            type: IS_LOADING,
                          });
                          const errors = {
                            msg: err.response.data,
                            status: err.response.status,
                          };
                          dispatch({
                            type: GET_ERRORS,
                            payload: errors,
                          });
                        }
                      }
                    }
                  }}
                  style={{
                    marginLeft: 10,
                    marginTop: 20,
                    width: "fit-content",
                    backgroundColor: props.layout.color_sidebar,
                    color: "white",
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                    fontSize: 16,
                    padding:
                      width < 770 ? "5px 8px 3px 8px" : "6px 20px 4px 20px",
                    borderRadius: 10,
                    cursor: "pointer",
                  }}
                >
                  {props.api_call === "post" ? "Save" : "Update"}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Content);
