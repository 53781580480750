import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setToolbarPage } from "../../../actions/toolbar";
import { closeMenuOC } from "../../../actions/handleOpenCloseActions";
import Sidebar from "../../../layout/CLICC/Sidebar/Sidebar";
import Footer from "../../../layout/CLICC/Footer/Footer";
import Navbar from "../../../layout/CLICC/Navbar/Navbar";
import SubNavbar from "../../../layout/CLICC/Navbar/SubNavbar";
import Content from "./components/Content";
import { UPDATE_CREATE } from "../../../actions/types";

export const NewWorkspace = (props) => {
  useEffect(() => {
    props.setToolbarPage("New workspace");
    props.closeMenuOC();
  }, []);

  const pages = [
    {
      name: "New workspace",
      link: "/clicc/dashboard/workspace_new_workspace",
    },
    {
      name: "Modify workspace",
      link: "/clicc/dashboard/workspace_modify_workspace",
    },
    {
      name: "My workspaces",
      link: "/clicc/dashboard/workspace_my_workspaces",
    },
  ];

  return (
    <div>
      <Sidebar></Sidebar>
      <Navbar></Navbar>
      <SubNavbar pages={pages}></SubNavbar>
      <Content
        pages={pages}
        data={props.create_workspace}
        type={UPDATE_CREATE}
        api_call="post"
      ></Content>
      <Footer></Footer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  create_workspace: state.create_workspace,
});

export default connect(mapStateToProps, { setToolbarPage, closeMenuOC })(
  NewWorkspace
);
