import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button } from "reactstrap";
import { MenuItem, Select, TextField } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { getHeightWithFooterWONavbar } from "../../../../utils/getHeightWithFooterWONavbar";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { FaUser, FaSpinner, FaPlus, FaClipboardList } from "react-icons/fa";
import Fields from "../../../Actors/NewActor/components/Fields";
import axios from "axios";
import { API_URL } from "../../../../actions/types";
import { tokenConfig } from "../../../../utils/tokenConfig";
import ModalCreateActor from "./ModalCreateActor";
import ModalAddStakeholder from "./ModalAddStakeholder";
import { AgGridReact } from "ag-grid-react";
import { applyTransactionFn } from "../../../../utils/applyTransactionFn";
import DeleteDetailStakeholders from "./DeleteDetailStakeholders";

function Actor(props) {
  const [rowData, setRowData] = useState(null);
  const [myActors, setMyActors] = useState(null);
  const [isOpenCreateActor, setIsOpenCreateActor] = useState(false);
  const [isOpenAddStakeholder, setIsOpenAddStakeholder] = useState(false);
  const dispatch = useDispatch();
  const { width, height } = useWindowDimensions();

  const getActors = async () => {
    const res = await axios.get(`${API_URL}/clicc/actors/`, tokenConfig());
    setMyActors(res.data);
  }

  // monitor refresh
  useEffect(() => {
    const channel = new BroadcastChannel("clicc-channel");
    channel.addEventListener("message", async e => {
      if (e.data === "refresh") {
        await getActors()
      }
    });
    return () => channel.close()
  }, [])

  const getData = async () => {
    return props.data.stakeholders;
  };

  useEffect(async () => {
    await getActors()
  }, []);

  const grid = useRef();
  const mounted = useRef();
  useEffect(async () => {
    if (!mounted.current) {
      setRowData(await getData());
      mounted.current = true;
    } else {
      const newRows = await getData();
      const arr_checks = ["id"];
      if (newRows.length >= 1) {
        applyTransactionFn(grid, newRows, arr_checks, true);
      }
    }
  }, [props.data.stakeholders]);



  const inputProps = {
    style: {
      fontWeight: 500,
      fontFamily: "Montserrat",
    },
  };

  const InputLabelProps = {
    style: {
      fontWeight: 500,
      fontFamily: "Montserrat",
    },
  };

  return (
    <div
      style={{
        width: "100%",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        overflow: "auto",
      }}
    >
      {myActors === null ? (
        <Row style={{ margin: 0 }}>
          <Col xs="12">
            <FaSpinner className="spinner"></FaSpinner>
          </Col>
        </Row>
      ) : (
        <div style={{ position: "relative" }}>
          <div
            onClick={() => setIsOpenCreateActor(!isOpenCreateActor)}
            style={{
              width: "fit-content",
              backgroundColor: props.layout.color_sidebar,
              color: "white",
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: 16,
              padding: "6px 15px 4px 15px",
              borderRadius: 10,
              cursor: "pointer",
              position: width > 990 ? "absolute" : "",
              marginLeft: 12,
              marginBottom: width > 990 ? 30 : 10,
              marginTop: width > 990 ? 0 : 10,
              right: 13,
              top: 10,
            }}
          >
            Create actor
          </div>

          <Row style={{ margin: 0 }}>
            <Col
              xxl="4"
              xl="5"
              lg="6"
              md="12"
              sm="12"
              style={{ marginTop: 15 }}
            >
              <TextField
                select
                label="Owner"
                value={props.data.actor ? props.data.actor : ""}
                style={{
                  width: "100%",
                }}
                InputLabelProps={InputLabelProps}
                InputProps={{
                  style: {
                    height: 56,
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                  },
                }}
                onChange={(e) => {
                  dispatch({
                    type: props.type,
                    payload: {
                      key: "actor",
                      value: e.target.value,
                    },
                  });
                }}
              >
                <MenuItem
                  value={null}
                  style={{ fontWeight: 500, fontFamily: "Montserrat" }}
                >
                  ...
                </MenuItem>
                {myActors.map((a, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={a.id}
                      style={{ fontWeight: 500, fontFamily: "Montserrat" }}
                    >
                      {a.actor_business_name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Col>
          </Row>
          <Row style={{ margin: 0, paddingBottom: 160 }}>
            <Col xs="12">
              <div
                style={{
                  fontFamily: "Montserrat",
                  marginTop: 20,
                  marginBottom: 5,
                  fontWeight: 500,
                  color: "#525252",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "end",
                }}
              >
                <div>
                  <FaClipboardList
                    style={{ marginTop: "-3px", marginRight: 5 }}
                  ></FaClipboardList>
                  Stakeholders involved
                </div>
                <div
                  onClick={() => setIsOpenAddStakeholder(true)}
                  style={{
                    width: "fit-content",
                    backgroundColor: props.layout.color_sidebar,
                    color: "white",
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                    fontSize: 16,
                    padding:
                      width < 770 ? "5px 6px 3px 6px" : "6px 15px 4px 15px",
                    borderRadius: 10,
                    cursor: "pointer",
                  }}
                >
                  {width < 770 ? (
                    <FaPlus
                      style={{
                        marginTop: "-3px",
                        marginRight: width < 770 ? 0 : 7,
                      }}
                    ></FaPlus>
                  ) : (
                    "Add detail"
                  )}
                </div>
              </div>
              <div
                className="excel-style ag-theme-balham"
                style={{ marginTop: 10 }}
              >
                <AgGridReact
                  ref={grid}
                  rowData={props.data.stakeholders}
                  domLayout="autoHeight"
                  frameworkComponents={{
                    deleteDetail: DeleteDetailStakeholders,
                  }}
                  popupParent={document.querySelector("body")}
                  columnDefs={[
                    {
                      field: "",
                      width: 28,
                      maxWidth: 28,
                      suppressMenu: true,
                      cellRenderer: "deleteDetail",
                      cellRendererParams: {
                        data_redux: props.data,
                        type: props.type,
                      },
                    },
                    {
                      field: "actor",
                      headerName: "Stakeholder",
                      flex: 1,
                      valueGetter: (params) => {
                        var actor = myActors.find(
                          (obj) => obj.id === params.data[params.colDef.field]
                        );
                        if (actor === undefined) {
                          return "";
                        } else {
                          return actor.actor_business_name;
                        }
                      },
                    },
                    {
                      field: "description",
                      headerName: "Description",
                      flex: 1,
                    },
                  ]}
                  defaultColDef={{
                    minWidth: 100,
                    resizable: true,
                    filter: true,
                    filterParams: { buttons: ["reset"], newRowsAction: "keep", caseSensitive: true },
                    cellStyle: (params) => {
                      var style = {
                        cursor: "default",
                      };
                      if (params.colDef.field !== "description") {
                        style["display"] = "flex";
                        style["alignItems"] = "center";
                        style["justifyContent"] = "center";
                      }
                      return style;
                    },
                  }}
                ></AgGridReact>
              </div>
            </Col>
          </Row>
        </div>
      )}

      {/* fake div to prevent overlay with footer */}
      <div style={{ height: 200 }}></div>

      <ModalCreateActor
        isOpen={isOpenCreateActor}
        setIsOpen={setIsOpenCreateActor}
      ></ModalCreateActor>
      <ModalAddStakeholder
        isOpen={isOpenAddStakeholder}
        setIsOpen={setIsOpenAddStakeholder}
        data={props.data}
        type={props.type}
        myActors={myActors}
      ></ModalAddStakeholder>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Actor);
