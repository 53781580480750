import React, { useState, useEffect, useRef } from "react"
import { Modal, ModalBody, Row, Col } from "reactstrap";
import { MdClose } from "react-icons/md";
import { FaSpinner } from "react-icons/fa";
import { cloneDeep } from "lodash"
import { useWindowDimensions } from "../../../../../../utils/useWindowDimensions";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import { roundAtMost2Digits } from "../../../../../../utils/roundAtMost2Digits";
import { roundNumberInCard } from "../../../../../../utils/roundNumberInCard";
import TitleCard from "../../../../../../components/TitleCard/TitleCard";
import { FaChartPie } from "react-icons/fa";
import { MdBarChart } from "react-icons/md";
import { usePlotlyConfig } from "../../../../../../utils/usePlotlyConfig";
import Plotly from "plotly.js-dist";


const ModalDashboard = (props) => {
    const [totalBaseline, setTotalBaseline] = useState(null)
    const [label, setLabel] = useState(null)
    const [macroArea, setMacroArea] = useState(null)
    const [sector, setSector] = useState(null)
    const { width, height } = useWindowDimensions()
    const plotlyConfig = usePlotlyConfig();



    useEffect(() => {
        var baseline
        var label_to_use
        if (props.chiave === "co2") {
            baseline = cloneDeep(props.data.baseline_ghg_co2)
            label_to_use = "CO2"
        } else if (props.chiave === "co2_equivalente") {
            baseline = cloneDeep(props.data.baseline_co2_equivalente)
            label_to_use = "CO2 eq."
        } else {
            baseline = cloneDeep(props.data[`baseline_ghg_${props.activeTab}`])
            label_to_use = props.activeTab.replace("_", "-").toUpperCase()
        }

        if (baseline.length > 0) {
            // compute total baseline
            var total = 0
            var total_macro_area = {}
            var total_sector = {}
            const keys_to_exclude = ['macro_area', 'settore', 'sottosettore']
            const list_keys = Object.keys(baseline[0]).filter(str => !keys_to_exclude.includes(str))
            baseline.forEach(obj => {
                if (!Object.keys(total_macro_area).includes(obj.macro_area)) {
                    total_macro_area[obj.macro_area] = 0
                }
                if (!Object.keys(total_sector).includes(obj.settore)) {
                    total_sector[obj.settore] = 0
                }
                list_keys.forEach(key => {
                    total += obj[key]
                    total_macro_area[obj.macro_area] += obj[key]
                    total_sector[obj.settore] += obj[key]
                })
            })

            // sort macro area by emissions and compute percentage
            var sorted_macro_area = Object.entries(total_macro_area)
                .map(([key, value]) => ({ description: key === "" ? "Generic" : key, emissions: value }))
                .sort((a, b) => b.emissions - a.emissions);
            sorted_macro_area.forEach(obj => {
                obj["percentage"] = obj.emissions / total
            })

            // sort sector by emissions and compute percentage
            var sorted_sector = Object.entries(total_sector)
                .map(([key, value]) => ({ description: key === "" ? "Generic" : key, emissions: value }))
                .sort((a, b) => b.emissions - a.emissions);
            sorted_sector.forEach(obj => {
                obj["percentage"] = obj.emissions / total
            })

            setMacroArea(sorted_macro_area)
            setSector(sorted_sector)
            setTotalBaseline(total)
            setLabel(label_to_use)
        } else {
            setMacroArea([])
            setSector([])
            setTotalBaseline(0)
            setLabel("--")
        }
    }, [props.data])

    useEffect(() => {
        if (sector !== null) {

            const fullLabels = sector.map(obj => obj.description)
            const shortLabels = fullLabels.map(label => label.length > 9 ? label.substring(0, 9) + '.' : label);
            var dictLabels = {}
            shortLabels.forEach((lab, index) => {
                dictLabels[lab] = `${label} ${fullLabels[index]} emissions: ${roundAtMost2Digits(sector[index].emissions)} [t/y]`
            })


            var data = [
                {
                    x: Object.keys(dictLabels),
                    y: sector.map(obj => obj.emissions),
                    type: "bar",
                    customdata: shortLabels.map(label => dictLabels[label]),
                    hovertemplate: '%{customdata}<extra></extra>'
                },
            ];

            var layout = {
                margin: {
                    t: 20,
                    b: 40,
                    l: 60,
                    r: 60,
                },
                showlegend: false,
            };

            Plotly.newPlot("sectorChart", data, layout, { ...plotlyConfig });
        }
    }, [sector])


    return (
        <Modal
            isOpen={props.isOpen}
            style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
            }}
            size="xl"
            toggle={() => { props.toggle() }}
        >
            <ModalBody>
                <div style={{ position: "absolute", right: 10, top: 10 }}>
                    <MdClose onClick={() => { props.toggle() }}
                        style={{ fontSize: 24, color: "gray", cursor: "pointer" }}></MdClose>
                </div>
                {macroArea === null || sector === null || totalBaseline === null || label === null ?
                    <>
                        <FaSpinner
                            className="spinner"
                            style={{ marginTop: "-3px", marginRight: 5 }}
                        ></FaSpinner>
                        Loading...</>
                    : <div>
                        <Row>
                            <Col lg={width > 1200 ? "6" : "8"} md="12" >
                                <div
                                    style={{
                                        ...props.layout.generic_card,
                                        display: "flex",
                                        flexFlow: "column",
                                        height: "100%",
                                    }}
                                >
                                    <TitleCard
                                        title={
                                            <div>
                                                {label} baseline
                                                emissions
                                            </div>
                                        }
                                    ></TitleCard>
                                    <div style={{
                                        height: "100%",
                                        display: "flex", justifyContent: "end",
                                        alignItems: "center",
                                        fontSize: 28,
                                        fontWeight: "bold",
                                        marginTop: "-25px",
                                        marginRight: 20,
                                        marginBottom: 10
                                    }}>
                                        {roundNumberInCard(totalBaseline, "[t/y]")}
                                    </div>
                                </div>

                            </Col>
                        </Row>
                        <div style={{
                            fontFamily: "Montserrat", fontSize: 18, marginTop: 30,
                            display: "flex", alignItems: "center"
                        }}>
                            <FaChartPie style={{ fontSize: 16, marginRight: 5, color: "#3A3A3A" }} />Emissions by most relevant macro area [t/y]
                        </div>
                        <Row className={width > 1200 ? "flex-nowrap" : ""}>
                            {macroArea.map((macro_area, index) => {
                                if (index < 5) {
                                    return <Col style={{
                                        flex: width > 1200 ? "0 0 20%" : "",
                                        marginTop: 10,
                                    }} md="12">
                                        <div
                                            style={{
                                                ...props.layout.generic_card,
                                                display: "flex",
                                                flexFlow: "column",
                                                height: "100%",
                                            }}
                                        >
                                            <TitleCard
                                                title={
                                                    <div>{macro_area.description.length > 17 ? macro_area.description.substring(0, 17) + '.' : macro_area.description}
                                                    </div>
                                                }
                                                customTitleSize={
                                                    {
                                                        large: 16,
                                                        small: 16
                                                    }
                                                }
                                            ></TitleCard>
                                            <div style={{
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "end",
                                                alignItems: "end",
                                                fontSize: 20,
                                                fontWeight: "bold",
                                                marginRight: 20,
                                                marginBottom: 10,
                                                marginTop: 10,
                                            }}>
                                                <div>
                                                    {roundNumberInCard(macro_area.emissions, "")}
                                                </div>
                                                <div style={{ fontWeight: 500, marginLeft: 5, fontSize: 16, marginBottom: 2 }}>
                                                    ({Math.round((macro_area.percentage * 100 + Number.EPSILON) * 10) / 10}%)
                                                </div>
                                            </div>
                                        </div>

                                    </Col>
                                }
                            })}
                        </Row>
                        <div style={{
                            fontFamily: "Montserrat", fontSize: 18, marginTop: 30,
                            display: "flex", alignItems: "center"
                        }}>
                            <MdBarChart style={{ fontSize: 24, marginRight: 5, color: "#3A3A3A" }} />Emissions by most relevant sectors [t/y]
                        </div>
                        <Row className={width > 1200 ? "flex-nowrap" : ""}>
                            {sector.map((sector_inner, index) => {
                                if (index < 5) {
                                    return <Col style={{
                                        flex: width > 1200 ? "0 0 20%" : "",
                                        marginTop: 10,
                                    }} md="12">
                                        <div
                                            style={{
                                                ...props.layout.generic_card,
                                                display: "flex",
                                                flexFlow: "column",
                                                height: "100%",
                                            }}
                                        >
                                            <TitleCard
                                                title={
                                                    <div>{sector_inner.description.length > 17 ? sector_inner.description.substring(0, 17) + '.' : sector_inner.description}
                                                    </div>
                                                }
                                                customTitleSize={
                                                    {
                                                        large: 16,
                                                        small: 16
                                                    }
                                                }
                                            ></TitleCard>
                                            <div style={{
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "end",
                                                alignItems: "end",
                                                fontSize: 20,
                                                fontWeight: "bold",
                                                marginRight: 20,
                                                marginBottom: 10,
                                                marginTop: 10,
                                            }}>
                                                <div>
                                                    {roundNumberInCard(sector_inner.emissions, "")}
                                                </div>
                                                <div style={{ fontWeight: 500, marginLeft: 5, fontSize: 16, marginBottom: 2 }}>
                                                    ({Math.round((sector_inner.percentage * 100 + Number.EPSILON) * 10) / 10}%)
                                                </div>
                                            </div>
                                        </div>

                                    </Col>
                                }

                            })}
                        </Row>

                        <div
                            style={{
                                ...props.layout.generic_card,
                                display: "flex",
                                flexFlow: "column",
                                height: "100%",
                                marginTop: 30
                            }}
                        >
                            <TitleCard
                                title={
                                    <div> Emissions by sector [t/y]
                                    </div>
                                }
                                customTitleSize={
                                    {
                                        large: 18,
                                        small: 18
                                    }
                                }
                            ></TitleCard>
                            <div
                                style={{
                                    flex: "0.92 1 auto",
                                    borderBottomLeftRadius:
                                        props.layout.generic_card.borderRadius,
                                    borderBottomRightRadius:
                                        props.layout.generic_card.borderRadius,
                                }}
                            >
                                <div
                                    style={{
                                        width: "100%",
                                        height: "300px",
                                    }}
                                    id="sectorChart"
                                ></div>
                            </div>
                        </div>


                    </div>}
            </ModalBody>
        </Modal>
    )
};

export default ModalDashboard;
