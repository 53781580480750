import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
} from "reactstrap";
import { FaSpinner } from "react-icons/fa";
import InfoAlert from "../../../components/Modals/InfoAlert";
import SuccessAlert from "../../../components/Modals/SuccessAlert";
import ErrorAlert from "../../../components/Modals/ErrorAlert";
import { API_URL, GET_ERRORS } from "../../../actions/types";
import { tokenConfig } from "../../../utils/tokenConfig";
import axios from "axios";
import { connect, useDispatch } from "react-redux";

function ModalUsers(props) {
  const [loading, setLoading] = useState(false);
  const [actorList, setActorList] = useState([]);
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();

  const [registerForm, setRegisterForm] = useState({
    username: "",
    first_name: "",
    last_name: "",
    organization: "",
    email: "",
    password: "",
    password_confirmation: "",
    user_type: "",
    actor_id: null,
    parent_actor: null,
  });
  const toggle = () => props.setIsOpen(!props.isOpen);

  useEffect(async () => {
    if (props.isOpen === true) {
      if (props.auth.is_superuser === true || props.auth.is_staff === true) {
        const res = await axios.get(`${API_URL}/clicc/actors/`, tokenConfig());
        setActorList(res.data);
        const resUsers = await axios.get(`${API_URL}/users/`, tokenConfig());
        setUsers(resUsers.data);
      } else if (props.auth.is_actor === true) {
        const res = await axios.get(`${API_URL}/clicc/actors/`, tokenConfig());
        setActorList(res.data);
      }
    }
  }, [props.isOpen, props.auth]);

  const submitForm = (e) => {
    e.preventDefault();
    if (registerForm.password.length < 8) {
      InfoAlert("The length of the password cannot be less than 8 characters");
    } else {
      if (loading === false) {
        if (registerForm.user_type === "") {
          InfoAlert("Select a user type from the list");
        } else if (
          registerForm.password !== registerForm.password_confirmation
        ) {
          InfoAlert("Passwords are different");
        } else if (
          registerForm.user_type === "SUB-ACTOR" &&
          registerForm.parent_actor === null &&
          props.auth.is_actor === false
        ) {
          InfoAlert("Select parent account to proceed");
        } else {
          setLoading(true);
          axios
            .post(`${API_URL}/autenticazione/`, registerForm, tokenConfig())
            .then((res) => {
              toggle();
              setRegisterForm({
                username: "",
                first_name: "",
                last_name: "",
                organization: "",
                email: "",
                password: "",
                password_confirmation: "",
                user_type: "",
                actor_id: null,
                parent_actor: null,
              });
              setLoading(false);
              SuccessAlert("Account created");
            })
            .catch((err) => {
              setLoading(false);
              const errors = {
                msg: err.response.data,
                status: err.response.status,
              };
              dispatch({
                type: GET_ERRORS,
                payload: errors,
              });
            });
        }
      }
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      size="lg"
      style={{ fontFamily: "Montserrat", fontWeight: 500 }}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Create new user</ModalHeader>
      <ModalBody>
        <Form id="registration-form" onSubmit={submitForm}>
          <FormGroup row className="mb-4">
            <Label for="form-username" sm={4}>
              Username*
            </Label>
            <Col sm={8}>
              <Input
                value={registerForm.username}
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    [e.target.name]: e.target.value,
                  })
                }
                type="text"
                name="username"
                id="form-username"
                placeholder=""
                required
              />
            </Col>
          </FormGroup>
          <FormGroup row className="mb-4">
            <Label for="form-user-type" sm={4}>
              User type*
            </Label>
            <Col sm={8}>
              <Input
                value={registerForm.user_type}
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    [e.target.name]: e.target.value,
                  })
                }
                type="select"
                name="user_type"
                id="form-user-type"
                placeholder=""
                required
              >
                <option value="">...</option>
                {props.auth.is_superuser === true ||
                props.auth.is_staff === true ? (
                  <>
                    {props.auth.is_superuser === true ? (
                      <option value="ADMIN">OWNER</option>
                    ) : null}
                    <option value="ACTOR">ACTOR</option>
                    <option value="SUB-ACTOR">SUB-ACTOR</option>
                    <option value="CITIZEN">CITIZEN</option>
                  </>
                ) : props.auth.is_actor === true ? (
                  <option value="SUB-ACTOR">SUB-ACTOR</option>
                ) : null}
              </Input>
            </Col>
          </FormGroup>
          {(props.auth.is_superuser === true || props.auth.is_staff === true) &&
          registerForm.user_type === "ACTOR" ? (
            <FormGroup row className="mb-4">
              <Label for="form-user-type" sm={4}>
                Select existing actor (optional)
              </Label>
              <Col sm={8}>
                <Input
                  value={registerForm.actor_id}
                  onChange={(e) => {
                    const actorSelected = actorList.find(
                      (act) => String(act.id) === String(e.target.value)
                    );
                    setRegisterForm({
                      ...registerForm,
                      [e.target.name]: e.target.value,
                      first_name: actorSelected.actor_reference_person_name,
                      last_name: actorSelected.actor_reference_person_surname,
                      organization: actorSelected.actor_business_name,
                      email: actorSelected.actor_reference_person_email,
                    });
                  }}
                  type="select"
                  name="actor_id"
                  id="form-user-type"
                  placeholder=""
                >
                  <option value={null}>...</option>
                  {actorList.map((act) => {
                    return (
                      <option key={act.id} value={act.id}>
                        {act.actor_business_name}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            </FormGroup>
          ) : null}
          {(props.auth.is_superuser === true || props.auth.is_staff === true) &&
          registerForm.user_type === "SUB-ACTOR" ? (
            <>
              <FormGroup row className="mb-4">
                <Label for="form-user-type" sm={4}>
                  Parent account*
                </Label>
                <Col sm={8}>
                  <Input
                    value={registerForm.parent_actor}
                    onChange={(e) =>
                      setRegisterForm({
                        ...registerForm,
                        [e.target.name]: e.target.value,
                      })
                    }
                    type="select"
                    name="parent_actor"
                    id="form-user-type"
                    placeholder=""
                    required
                  >
                    <option value={null}>...</option>
                    {users
                      .filter((user) => user.parent_actor === null)
                      .map((user) => {
                        return (
                          <option key={user} value={user.id}>
                            {user.username}
                          </option>
                        );
                      })}
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup row className="mb-4">
                <Label for="form-user-type" sm={4}>
                  Select sub-actor (optional)
                </Label>
                <Col sm={8}>
                  <Input
                    value={registerForm.actor_id}
                    onChange={(e) => {
                      const actorSelected = actorList.find(
                        (act) => String(act.id) === String(e.target.value)
                      );
                      setRegisterForm({
                        ...registerForm,
                        [e.target.name]: e.target.value,
                        first_name: actorSelected.actor_reference_person_name,
                        last_name: actorSelected.actor_reference_person_surname,
                        organization: actorSelected.actor_business_name,
                        email: actorSelected.actor_reference_person_email,
                      });
                    }}
                    type="select"
                    name="actor_id"
                    id="form-user-type"
                    placeholder=""
                  >
                    <option value={null}>...</option>
                    {actorList.map((act) => {
                      return (
                        <option key={act.id} value={act.id}>
                          {act.actor_business_name}
                        </option>
                      );
                    })}
                  </Input>
                </Col>
              </FormGroup>
            </>
          ) : null}
          {props.auth.is_actor === true &&
          registerForm.user_type === "SUB-ACTOR" ? (
            <>
              <FormGroup row className="mb-4">
                <Label for="form-user-type" sm={4}>
                  Select sub-actor (optional)
                </Label>
                <Col sm={8}>
                  <Input
                    value={registerForm.actor_id}
                    onChange={(e) => {
                      const actorSelected = actorList.find(
                        (act) => String(act.id) === String(e.target.value)
                      );
                      setRegisterForm({
                        ...registerForm,
                        [e.target.name]: e.target.value,
                        first_name: actorSelected.actor_reference_person_name,
                        last_name: actorSelected.actor_reference_person_surname,
                        organization: actorSelected.actor_business_name,
                        email: actorSelected.actor_reference_person_email,
                      });
                    }}
                    type="select"
                    name="actor_id"
                    id="form-user-type"
                    placeholder=""
                  >
                    <option value={null}>...</option>
                    {actorList.map((act) => {
                      return (
                        <option key={act.id} value={act.id}>
                          {act.actor_business_name}
                        </option>
                      );
                    })}
                  </Input>
                </Col>
              </FormGroup>
            </>
          ) : null}

          <FormGroup row className="mb-4">
            <Label for="form-firstname" sm={4}>
              First name
            </Label>
            <Col sm={8}>
              <Input
                value={registerForm.first_name}
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    [e.target.name]: e.target.value,
                  })
                }
                type="text"
                name="first_name"
                id="form-firstname"
                placeholder=""
              />
            </Col>
          </FormGroup>
          <FormGroup row className="mb-4">
            <Label for="form-lastname" sm={4}>
              Last name
            </Label>
            <Col sm={8}>
              <Input
                value={registerForm.last_name}
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    [e.target.name]: e.target.value,
                  })
                }
                type="text"
                name="last_name"
                id="form-lastname"
                placeholder=""
              />
            </Col>
          </FormGroup>
          <FormGroup row className="mb-4">
            <Label for="form-organization" sm={4}>
              Organization
            </Label>
            <Col sm={8}>
              <Input
                value={registerForm.organization}
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    [e.target.name]: e.target.value,
                  })
                }
                type="text"
                name="organization"
                id="form-organization"
                placeholder=""
              />
            </Col>
          </FormGroup>
          <FormGroup row className="mb-4">
            <Label for="form-email" sm={4}>
              Email*
            </Label>
            <Col sm={8}>
              <Input
                value={registerForm.email}
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    [e.target.name]: e.target.value,
                  })
                }
                type="email"
                name="email"
                id="form-email"
                placeholder=""
                required
              />
            </Col>
          </FormGroup>
          <FormGroup row className="mb-4">
            <Label for="form-password" sm={4}>
              Password*
            </Label>
            <Col sm={8}>
              <Input
                value={registerForm.password}
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    [e.target.name]: e.target.value,
                  })
                }
                type="password"
                name="password"
                id="form-password"
                placeholder=""
                required
              />
            </Col>
          </FormGroup>
          <FormGroup row className="mb-4">
            <Label for="form-passowrd-confirmation" sm={4}>
              Password Confirmation*
            </Label>
            <Col sm={8}>
              <Input
                value={registerForm.password_confirmation}
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    [e.target.name]: e.target.value,
                  })
                }
                type="password"
                name="password_confirmation"
                id="form-passowrd-confirmation"
                placeholder=""
                required
              />
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        {loading === true ? (
          <Button
            color="primary"
            type="submit"
            form="registration-form"
            style={{ minWidth: 130 }}
          >
            <FaSpinner className="spinner"> </FaSpinner>
          </Button>
        ) : (
          <Button
            color="primary"
            type="submit"
            form="registration-form"
            style={{ minWidth: 130 }}
          >
            Register
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ModalUsers);
