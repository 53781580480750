import React, { useState } from "react";
import { FaTimes, FaPencilAlt } from "react-icons/fa";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  API_URL,
  IS_LOADING,
  RESET_OPENED_ACTOR,
  SET_OPENED_ACTOR,
} from "../../../../actions/types";
import { useDispatch } from "react-redux";
import SuccessAlert from "../../../../components/Modals/SuccessAlert";
import axios from "axios";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { useHistory } from "react-router-dom";
import { triggerRefresh } from "../../../../utils/triggerRefresh";
import { checkPermission } from "../../../../utils/checkPermission";

function ActionActors(props) {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const toggle = () => setIsOpen(!isOpen);
  return (
    <div>
      {checkPermission(props.auth.user_permissions, "Can delete actor") === true ?
        <FaTimes
          onClick={toggle}
          style={{
            color: "#FC2727",
            cursor: "pointer",
          }}
        ></FaTimes>
        : null}

      {checkPermission(props.auth.user_permissions, "Can change actor") === true ?
        <FaPencilAlt
          onClick={async (e) => {
            if (e.ctrlKey) {
              window.open(
                window.location.hostname === "localhost" ? `http://localhost:3009/clicc/dashboard/actors_modify_actor/?id=${props.data.id}` :
                  API_URL.replace(
                    "/api",
                    `/clicc/dashboard/actors_modify_actor/?id=${props.data.id}`
                  ),
                '_blank',
              );
              SuccessAlert("Actor opened in another tab")
            } else if (e.shiftKey) {
              localStorage.setItem("opened_actor", props.data.id);
              triggerRefresh()
              SuccessAlert("Actor available in tab already opened")
            } else {
              dispatch({
                type: RESET_OPENED_ACTOR,
              });
              history.push(`/clicc/dashboard/actors_modify_actor/?id=${props.data.id}`);
            }
          }}
          style={{
            color: "#EE9B1B",
            cursor: "pointer",
            marginLeft: checkPermission(props.auth.user_permissions, "Can delete actor") === true ? 10 : 0,
          }}
        ></FaPencilAlt>
        : null
      }


      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        style={{
          fontFamily: "Montserrat",
          fontWeight: 500,
        }}
      >
        <ModalHeader toggle={toggle}>Attention</ModalHeader>
        <ModalBody>Do you really want to delete the selected actor?</ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={async () => {
              dispatch({
                type: IS_LOADING,
              });
              await axios.delete(
                `${API_URL}/clicc/actors/${props.data.id}/?name=${props.data.actor_business_name}`,
                tokenConfig()
              );
              if (
                props.opened_actor.id !== undefined &&
                props.opened_actor.id === props.data.id
              ) {
                dispatch({
                  type: RESET_OPENED_ACTOR,
                });
              }
              dispatch({
                type: IS_LOADING,
              });
              toggle();
              SuccessAlert("Actor deleted successfully");
              triggerRefresh()
            }}
          >
            Delete
          </Button>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ActionActors;
