import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Switch, FormControlLabel } from "@mui/material";
import { AgGridColumn } from "ag-grid-react";
import { AgGridReact } from "ag-grid-react";
import { applyTransactionFn } from "../../../../../../../../utils/applyTransactionFn";
import axios from "axios";
import { API_URL } from "../../../../../../../../actions/types";
import { tokenConfig } from "../../../../../../../../utils/tokenConfig";
import InfoAlert from "../../../../../../../../components/Modals/InfoAlert";
function Gwp(props) {
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState(null);
  const getData = async () => {
    return props.data.gwps;
  };

  const grid = useRef();
  const mounted = useRef();

  useEffect(async () => {
    if (!mounted.current) {
      setRowData(await getData());
      mounted.current = true;
    } else {
      const newRows = await getData();
      const arr_checks = ["gas"];
      if (newRows.length >= 1) {
        applyTransactionFn(grid, newRows, arr_checks, false);
      }
    }
  }, [props.data.gwps]);

  var isDragging = false;
  var listChanges = [];
  const fnCellChanged = async (myList) => {
    var raise_error = false;
    var regExp = /^-?(\d*[.,]?\d+)$/;
    var listFiltered = [];
    myList.forEach((params) => {
      // check if there are no letters or commas --> if yes show error and set newValue = oldValue
      if (
        params.newValue === "" ||
        params.newValue === undefined ||
        params.newValue === null
      ) {
        params.data[params.colDef.field] = parseFloat(params.oldValue);

        params.newValue = parseFloat(params.oldValue);
      } else if (
        String(params.newValue).match(regExp) === null ||
        String(params.newValue).includes(" ")
      ) {
        raise_error = true;
        params.data[params.colDef.field] = params.oldValue;
        params.newValue = params.oldValue;
      }
      listFiltered.push(params);
    });

    var final = [];
    var updateRows = {};
    listFiltered.forEach((updatedRow) => {
      updateRows[updatedRow.data["gas"]] = updatedRow.data;
    });
    props.data.gwps.forEach((s) => {
      if (s.gas in updateRows) {
        final.push(updateRows[s.gas]);
      } else {
        final.push(s);
      }
    });
    if (raise_error === false) {
      const co2_equivalent = await axios.post(
        `${API_URL}/clicc/workspace/co2_equivalent/`,
        {
          co2: props.data.baseline_ghg_co2,
          f_gas: props.data.baseline_ghg_f_gas,
          nf3: props.data.baseline_ghg_nf3,
          n2o: props.data.baseline_ghg_n2o,
          sf6: props.data.baseline_ghg_sf6,
          ch4: props.data.baseline_ghg_ch4,
          gwps: final,
        },
        tokenConfig()
      );
      dispatch({
        type: props.type,
        payload: {
          key: "baseline_co2_equivalente",
          value: co2_equivalent.data.co2_equivalent,
        },
      });
    }
    // if there are errors (i.e. letters) show error --> in any case continue (there is no an ELSE)
    if (raise_error === true) {
      InfoAlert("Invalid format! Only numbers are allowed");
    }

    // reset list
    listChanges = [];
  };
  return (
    <div>
      <div
        style={{
          fontFamily: "Montserrat",
          fontWeight: 500,
          fontSize: 16,
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={props.data.insert_manual_values}
              onChange={async (e) => {
                dispatch({
                  type: props.type,
                  payload: {
                    key: "insert_manual_values",
                    value: e.target.checked,
                  },
                });
                if (props.data.insert_manual_values === true) {
                  const co2_equivalent = await axios.post(
                    `${API_URL}/clicc/workspace/co2_equivalent/`,
                    {
                      co2: props.data.baseline_ghg_co2,
                      f_gas: props.data.baseline_ghg_f_gas,
                      nf3: props.data.baseline_ghg_nf3,
                      n2o: props.data.baseline_ghg_n2o,
                      sf6: props.data.baseline_ghg_sf6,
                      ch4: props.data.baseline_ghg_ch4,
                      gwps: rowData,
                    },
                    tokenConfig()
                  );
                  dispatch({
                    type: props.type,
                    payload: {
                      key: "baseline_co2_equivalente",
                      value: co2_equivalent.data.co2_equivalent,
                    },
                  });
                }
              }}
              inputProps={{ "aria-label": "controlled" }}
              style={{ fontFamily: "Montserrat" }}
            />
          }
          label={
            <div style={{ fontFamily: "Montserrat", fontWeight: 500 }}>
              Insert manual values
            </div>
          }
        />
      </div>
      <div className="excel-style ag-theme-balham" style={{ marginTop: 5 }}>
        <AgGridReact
          ref={grid}
          domLayout="autoHeight"
          enableFillHandle={true}
          enableRangeSelection={true}
          stopEditingWhenCellsLoseFocus={true}
          processDataFromClipboard={(params) => {
            var data = [];
            for (var i = 0; i < params.data.length; i++) {
              if (
                params.data[i].length !== 1 ||
                (params.data[i][0] !== "" && params.data[i][0] !== " ")
              ) {
                data.push(params.data[i]);
              }
            }
            return data;
          }}
          rowData={rowData}
          defaultColDef={{
            resizable: true,
            filter: true,
            filterParams: {
              buttons: ["reset"],
              newRowsAction: "keep",
              caseSensitive: true
            },
            flex: 1,
            editable: (params) => {
              return false;
            },
          }}
          onCellValueChanged={(params) => {
            params.newValue = parseFloat(params.newValue);
            params.value = parseFloat(params.value);
            params.data[params.colDef.field] = parseFloat(
              params.data[params.colDef.field]
            );
            listChanges.push(params);
            if (params.source !== "paste" && isDragging === false) {
              fnCellChanged(listChanges);
            }
          }}
          onPasteEnd={(params) => {
            fnCellChanged(listChanges);
          }}
          onDragStarted={(params) => {
            isDragging = true;
          }}
          onDragStopped={(params) => {
            isDragging = false;
            // check if there are no changes because isDragging becomes true even if you simply drag without using the fill operations
            if (listChanges.length > 0) {
              fnCellChanged(listChanges);
            }
          }}
        >
          <AgGridColumn
            field={"gas"}
            valueGetter={(params) => {
              return params.data.gas.replace("_", " ").toUpperCase();
            }}
          ></AgGridColumn>

          <AgGridColumn
            field={"gwp"}
            headerName="GWP"
            editable={(params) => {
              if (props.data.insert_manual_values) {
                return false;
              } else return true;
            }}
            cellClass={(params) => {
              if (props.data.insert_manual_values) {
                return "non-editable";
              } else return "";
            }}
          ></AgGridColumn>
        </AgGridReact>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Gwp);
