import React, { useState } from "react";
import { useWindowDimensions } from "../../../utils/useWindowDimensions";
import { connect } from "react-redux";
import LogoTorino from "../../../../images/torino_logo_full.png";
import LogoPolitecnico from "../../../../images/logo_est_polito_EC_noBackgroud.png";
import LogoSanPaolo from "../../../../images/logo_compagnia_san_paolo.png";
// import DefaultPerson from "../../../../images/default_person.png";
import LogoCLICC from "../../../../images/logo_clicc_senza_scritta.png";
import { FaSearch, FaGlobe, FaMapMarkerAlt } from "react-icons/fa";
import { Input } from "reactstrap";
import { FaRegFolderOpen } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import { API_URL, PRODUCTION_API_URL } from "../../../actions/types";
import { Link } from "@mui/material";

function Navbar(props) {
  const [hover, setHover] = useState(false);
  const [hoverCE, setHoverCE] = useState(false);
  const [hoverBaseline, setHoverBaseline] = useState(false);
  const { width, height } = useWindowDimensions();
  const history = useHistory();
  const location = useLocation();

  return (
    <div
      style={{
        width: "100%",
        zIndex: 1,
        backgroundColor: props.layout.color_navbar,
        height: height * 0.08,
        paddingLeft: props.layout.width_sidebar,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          padding: 10,
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ height: "100%" }}>
            {width > 1100 ? (
              <>
                <img
                  src={LogoTorino}
                  style={{
                    height: "100%",
                    width: "auto",
                  }}
                ></img>
                <img
                  src={LogoPolitecnico}
                  style={{
                    height: "100%",
                    marginLeft: 20,
                  }}
                ></img>
                <img
                  src={LogoSanPaolo}
                  style={{
                    height: "100%",
                    marginLeft: 20,
                  }}
                ></img>
              </>
            ) : null}
          </div>
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <a
              onMouseEnter={() => setHoverCE(true)}
              onMouseLeave={() => setHoverCE(false)}
              style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
                marginRight: 20,
                border: `1px solid ${hoverCE === true ? "#417afe" : "#D8D8D8"}`,
                transition: "0.5s",
                padding: "0px 15px",
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                textDecoration: "none",
                color: hoverCE === true ? "#417afe" : "#212529",
              }}
              // if localhost use local version, else always in production  
              href={
                window.location.hostname.includes("localhost")
                  ? `http://localhost:3008/ctp/dashboard/city_explorer/?token=${localStorage.getItem(
                    "token"
                  )}`
                  : PRODUCTION_API_URL.replace("clicc.est", "citta.est").replace(
                    "/api",
                    `/ctp/dashboard/city_explorer/?token=${localStorage.getItem(
                      "city_explorer_tk"
                    )}`
                  )
              }
              target="_blank"
            >
              City Explorer
              <FaMapMarkerAlt
                style={{
                  marginTop: "-2px",
                  marginLeft: 5,
                  color: hoverCE === true ? "#417afe" : "#4A4A4A",
                  transition: "0.5s",
                }}
              ></FaMapMarkerAlt>
            </a>
            <a
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
                marginRight: 20,
                border: `1px solid ${hover === true ? "#417afe" : "#D8D8D8"}`,
                transition: "0.5s",
                padding: "0px 15px",
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                textDecoration: "none",
                color: hover === true ? "#417afe" : "#212529",
              }}
              // if localhost use local version, else always in production  
              href={
                window.location.hostname.includes("localhost")
                  ? `http://localhost:3008/ctp/dashboard/homepage/?token=${localStorage.getItem(
                    "token"
                  )}`
                  : PRODUCTION_API_URL.replace("clicc.est", "citta.est").replace(
                    "/api",
                    `/ctp/dashboard/homepage/?token=${localStorage.getItem(
                      "city_explorer_tk"
                    )}`
                  )
              }
              target="_blank"
            >
              Access CITTA
              <FaGlobe
                style={{
                  marginTop: "-2px",
                  marginLeft: 5,
                  color: hover === true ? "#417afe" : "#4A4A4A",
                  transition: "0.5s",
                }}
              ></FaGlobe>
            </a>

            <img
              src={LogoCLICC}
              alt="pic"
              style={{
                borderRadius: "10%",
                height: "55%",
                width: "auto",
                imageRendering: "-webkit-optimize-contrast",
              }}
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Navbar);
