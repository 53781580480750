import { getEmissionsByYear } from "./getEmissionsByYear";
import { intersectDatesWithWorkspace } from "./intersectDatesWithWorkspace";
import moment from "moment"

export const getYearlyEmissionGroupedAction = (actions_list, gas, emission_type, start_date_wp, end_date_wp) => {
    var result = {}
    actions_list.forEach((obj) => {

        var emissions_gas = obj.emissions.find(inner_obj => inner_obj.gas === gas)[emission_type]

        var start_date_moment
        var datesToUse
        if (start_date_wp === null) {
            start_date_moment = moment(obj.start_date)
        } else {
            datesToUse = intersectDatesWithWorkspace(start_date_wp, end_date_wp, obj.start_date, obj.end_date)
            start_date_moment = datesToUse[0]
        }
        var end_date_moment
        if (end_date_wp === null) {
            end_date_moment = obj.end_date === null ? moment("2100-12-31") : moment(obj.end_date)
        } else {
            datesToUse = intersectDatesWithWorkspace(start_date_wp, end_date_wp, obj.start_date, obj.end_date)
            end_date_moment = datesToUse[1]
        }

        var getEmissions = getEmissionsByYear(start_date_moment, end_date_moment, emissions_gas);

        Object.keys(getEmissions).forEach(k => {
            if (!Object.keys(result).includes(k)) {
                result[k] = getEmissions[k]
            } else {
                result[k] += getEmissions[k]
            }
        })
    });
    return result
}