import { roundAtMost2Digits } from "./roundAtMost2Digits";

export const valueFormatterTotalActions = (params, uom) => {
  var total = 0;
  params.api.forEachNode((rowNode, index) => {
    if (rowNode.data[params.colDef.field] !== undefined) {
      total += rowNode.data[params.colDef.field];
    }
  });
  if (String(params.value / total) === "NaN") {
    return `${params.value.toLocaleString("eng", {
      maximumFractionDigits: 0,
    })}${uom} (0%)`;
  }
  else {
    return `${params.value.toLocaleString("eng", {
      maximumFractionDigits: 0,
    })}${uom} (${roundAtMost2Digits(
      (params.value / total) * 100
    )}%)`;
  }
};
