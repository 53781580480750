import React from "react";
import { Row, Col } from "reactstrap";
import { getHeightWithFooterWONavbar } from "../../../../utils/getHeightWithFooterWONavbar";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { connect, useDispatch } from "react-redux";
import { MenuItem, Select, TextField } from "@mui/material";

function Notes(props) {
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();

  const inputProps = {
    style: {
      fontWeight: 500,
      fontFamily: "Montserrat",
    },
  };

  const InputLabelProps = {
    style: {
      fontWeight: 500,
      fontFamily: "Montserrat",
    },
  };

  return (
    <div
      style={{
        width: "100%",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        overflow: "auto",
      }}
    >
      <Row style={{ margin: 0, marginTop: 15 }}>
        <Col xs="12">
          <TextField
            label={<div>Notes</div>}
            multiline
            rows={
              height > 800 ? Math.round(height / 35) : Math.round(height / 40)
            }
            value={props.data.notes ? props.data.notes : ""}
            inputProps={inputProps}
            InputLabelProps={InputLabelProps}
            style={{ width: "100%" }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: { key: "notes", value: e.target.value },
              });
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Notes);
