import React from "react";
import { connect } from "react-redux";
import { useWindowDimensions } from "../../utils/useWindowDimensions";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function TargetTitleCard(props) {
  const { width, height } = useWindowDimensions();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <div
        style={{
          borderBottom: `${props.customBorderHeight === undefined
            ? 3
            : props.customBorderHeight
            }px solid ${props.color_bar === undefined
              ? props.layout.yellow_detail
              : props.color_bar
            }`,
          width:
            props.length_border_bottom === undefined
              ? "fit-content"
              : props.length_border_bottom,
          display: "flex",
        }}
      >
        <div
          style={{
            padding:
              props.customPadding === undefined
                ? "11px 15px 2px 15px"
                : props.customPadding,
            fontFamily: "Montserrat",
            fontWeight: "bold",
            color: props.layout.color_footer,
            fontSize:
              props.customTitleSize === undefined
                ? height < 780
                  ? 18
                  : width > 1450
                    ? 21
                    : 18
                : width > 1450
                  ? props.customTitleSize.large
                  : props.customTitleSize.small,
          }}
        >
          Target per
        </div>
        <Box sx={{ minWidth: 160 }}>
          <FormControl
            fullWidth
            style={{
              marginTop: 10,
            }}
          >
            <Select
              variant="standard"
              style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
              value={props.chiave}
              onChange={(e) => {
                props.setChiave(e.target.value);
              }}
            >
              <MenuItem
                value={"co2"}
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                }}
              >
                Co2
              </MenuItem>
              <MenuItem
                value={"co2_equivalente"}
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                }}
              >
                Co2 Equivalent
              </MenuItem>
              <MenuItem
                value={"ghg"}
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                }}
              >
                GHG
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(TargetTitleCard);
