import React from "react";
import "./404page.css";

export default () => {
  return (
    <div id="main">
      <div class="fof">
        <h1>Error 404</h1>
        <h3>The page you are looking for it doesn't exist!</h3>
      </div>
    </div>
  );
};
