import React from "react";
import { getHeightWithFooterWONavbar } from "../../../../utils/getHeightWithFooterWONavbar";
import {
  API_URL,
  GET_ERRORS,
  RESET_CREATE_NEW_ACTOR,
  IS_LOADING,
  SET_OPENED_ACTOR,
} from "../../../../actions/types";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { tokenConfig } from "../../../../utils/tokenConfig";
import axios from "axios";
import SuccessAlert from "../../../../components/Modals/SuccessAlert";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Fields from "./Fields";
import { triggerRefresh } from "../../../../utils/triggerRefresh";

function Content(props) {
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#E4E6E8",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        paddingLeft: props.layout.width_sidebar,
        paddingBottom: props.layout.footer_height,
        overflow: "auto",
      }}
    >
      {/* workspace without padding */}
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 20,
        }}
      >
        {/* workspace */}
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <div
            style={{ fontFamily: "Montserrat", fontSize: 20, marginBottom: 10 }}
          >
            Create a new actor
          </div>
          <div
            style={{
              backgroundColor: "white",
              padding: "25px 10px",
              borderRadius: 10,
            }}
          >
            <Fields data={props.data} type={props.type}></Fields>
            <div
              style={{
                width: "fit-content",
                backgroundColor: props.layout.color_sidebar,
                color: "white",
                fontFamily: "Montserrat",
                fontWeight: 500,
                fontSize: 16,
                padding: "6px 15px 4px 15px",
                borderRadius: 10,
                cursor: "pointer",
                marginTop: 20,
                marginLeft: 12,
              }}
              onClick={async () => {
                try {
                  await axios.post(
                    `${API_URL}/clicc/actors/`,
                    props.data,
                    tokenConfig()
                  );
                  dispatch({
                    type: RESET_CREATE_NEW_ACTOR,
                  });
                  SuccessAlert("Actor created successfully!");
                  triggerRefresh()
                } catch (err) {
                  dispatch({
                    type: IS_LOADING,
                  });
                  const errors = {
                    msg: err.response.data,
                    status: err.response.status,
                  };
                  dispatch({
                    type: GET_ERRORS,
                    payload: errors,
                  });
                }
              }}
            >
              {props.type === "UPDATE_CREATE_NEW_ACTOR" ? "CREATE" : "UPDATE"}
            </div>
          </div>
          {/* fake div to prevent overlay with footer */}
          <div style={{ height: 50 }}></div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Content);
