import React, { useState, useEffect, useRef } from "react";
import { useWindowDimensions } from "../../../../../utils/useWindowDimensions";
import { connect, useDispatch } from "react-redux";
import { getHeightWithFooterWONavbar } from "../../../../../utils/getHeightWithFooterWONavbar";
import {
  Row,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap";
import TargetTitleCard from "../../../../../components/TargetTitleCard/TargetTitleCard";
import Data from "./components/Data";
import ModalDashboard from "./components/ModalDashboard";
import { FaChartPie } from "react-icons/fa"

function Content(props) {
  const [chiave, setChiave] = useState("co2");
  const [activeTab, setActiveTab] = useState("co2");
  const [isOpenDashboard, setIsOpenDashboard] = useState(false);
  const [totalCards, setTotalCards] = useState(null);
  const [tableModal, setTableModal] = useState(null);
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();
  const toggleDashboard = () => setIsOpenDashboard(!isOpenDashboard);

  const styleTabPane = {
    height: height < 760 ? "88%" : "95%",
    overflowY: "hidden",
    border: "1px solid #C6C6C6",
    marginTop: "-1px",
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
    // paddingBottom: 80,
    background: "white",
  };

  return (
    <div
      style={{
        ...props.layout.generic_card,
        width: "100%",
        backgroundColor: "#E4E6E8",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        paddingLeft: 0,
        paddingBottom: props.layout.footer_height,
        overflow: "auto",
      }}
    >
      {/* workspace without padding */}
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 10,
        }}
      >
        {/* workspace */}
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <Row style={{ margin: 0 }}>
            <Col xs="12" style={{ padding: 0 }}>
              <div
                style={{
                  width: "100%",
                  padding: 10,
                  height: height < 850 ? height * 0.69 : height * 0.74,
                }}
              >
                <div
                  style={{
                    ...props.layout.generic_card,
                    width: "100%",
                    position: "relative",
                    display: "flex",
                    flexFlow: "column",
                    height: "100%",
                  }}
                >
                  <TargetTitleCard
                    chiave={chiave}
                    setChiave={setChiave}
                  ></TargetTitleCard>
                  <div
                    onClick={toggleDashboard}
                    style={{
                      width: "fit-content",
                      color: "#1976d2",
                      border: "1px solid #1976d2",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      fontSize: 16,
                      padding: width < 770 ? "5px 8px 3px 8px" : "6px 20px 4px 20px",
                      borderRadius: 10,
                      cursor: "pointer",
                      position: "absolute",
                      top: 15,
                      right: 20,
                    }}
                  >
                    <FaChartPie
                      style={{ marginTop: "-3px", marginRight: width < 770 ? 0 : 10 }}
                    ></FaChartPie >
                    {width < 770 ? null : "Dashboard"}
                  </div>
                  {chiave === "co2" ? (
                    <Data
                      myActions={props.myActions}
                      data={props.data}
                      type={props.type}
                      chiave={"ghg_" + chiave}
                      setTotalCards={setTotalCards}
                      setTableModal={setTableModal}
                    ></Data>
                  ) : chiave === "co2_equivalente" ? (
                    <Data
                      myActions={props.myActions}
                      data={props.data}
                      type={props.type}
                      chiave={chiave}
                      setTotalCards={setTotalCards}
                      setTableModal={setTableModal}
                    ></Data>
                  ) : chiave === "ghg" ? (
                    <Container
                      style={{ height: "85%", marginTop: 15, maxWidth: "99%" }}
                    >
                      <Nav tabs style={{ width: "97%" }}>
                        {props.data.gases.map((gas) => {
                          return (
                            <NavItem>
                              <NavLink
                                className={activeTab === gas ? "active" : ""}
                                onClick={() => setActiveTab(gas)}
                                href="#"
                              >
                                {gas.toUpperCase().replace("_", "-")}
                              </NavLink>
                            </NavItem>
                          );
                        })}
                      </Nav>
                      <TabContent
                        activeTab={activeTab}
                        style={{ height: "100%" }}
                      >
                        {props.data.gases.map((gas) => {
                          if (activeTab === gas) {
                            return (
                              <TabPane
                                tabId={gas}
                                style={{ height: "100%", ...styleTabPane }}
                              >
                                <Data
                                  myActions={props.myActions}
                                  data={props.data}
                                  type={props.type}
                                  chiave={"ghg_" + gas}
                                  setTotalCards={setTotalCards}
                                  setTableModal={setTableModal}
                                ></Data>
                              </TabPane>
                            );
                          }
                        })}
                      </TabContent>
                    </Container>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
          {/* modal dashboard */}
          {isOpenDashboard === true ?
            <ModalDashboard
              isOpen={isOpenDashboard}
              toggle={toggleDashboard}
              activeTab={activeTab}
              chiave={chiave}
              totalCards={totalCards}
              tableModal={tableModal}
              {...props}
            >
            </ModalDashboard> : null}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Content);
