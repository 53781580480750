// remove below cell if top value is the same
// note that if arr_cols.length > 0 look at cols by considering also the previous one
// for example if arr_cols=["col1", "col2"] then when looking at "col2" look also at "col1" (i.e. value=col1&col2)
export const removeBelowEqualCell = (list, arr_cols) => {
  var nested_cols = [];
  arr_cols.forEach((col, index) => {
    if (index > 0) {
      nested_cols.push([...nested_cols[index - 1], col]);
    } else {
      nested_cols.push([col]);
    }
  });

  //   given a row and list of keys, return the merged string
  const getValue = (obj, list_keys) => {
    return list_keys.map((k) => obj[k]).join(", ");
  };

  nested_cols.forEach((inner_arr_col) => {
    var latest_value;
    list.forEach((obj, index) => {
      if (index === 0) {
        latest_value = getValue(list[index], inner_arr_col);
      } else if (getValue(list[index], inner_arr_col) === latest_value) {
        list[index][inner_arr_col[inner_arr_col.length - 1]] = "";
      } else {
        latest_value = getValue(list[index], inner_arr_col);
      }
    });
  });
  return list;
};
