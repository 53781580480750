import React, { useState, useEffect } from "react";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { connect, useDispatch } from "react-redux";
import { getHeightWithFooterWONavbar } from "../../../../utils/getHeightWithFooterWONavbar";
import classnames from "classnames";
import {
  Card,
  Modal,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import WorkspaceUpdate from "./WorkspaceUpdate";
import Commitments from "../../NewContract/components/Commitments";
import ActionPlan from "../../NewContract/components/ActionPlan";
import InvestmentPlan from "../../NewContract/components/InvestmentPlan";
import Download from "../../NewContract/components/Download";
import CreateOrUpdate from "../../NewContract/components/CreateOrUpdate";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { API_URL, SET_OPENED_CONTRACT, RESET_OPENED_CONTRACT } from "../../../../actions/types";
import { FaSpinner } from "react-icons/fa"

function Content(props) {
  const [reset, setReset] = useState(1);
  const [activeTab, setActiveTab] = useState(1);
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch()
  const history = useHistory()

  const key_name = `opened_${window.location.href.split("/?id=")[0].split("_modify_")[1]}`

  // define function to get data
  const getData = async () => {
    // if there is something in local storage remove it at this point
    localStorage.removeItem(key_name)
    // if there is an id in the url, open it
    if (window.location.href.includes("?id=")) {
      // if there is an actor in another tab and you delete him, the call will crash
      try {
        const res = await axios.get(
          `${API_URL}/clicc/contract_documents/${window.location.href.split("?id=")[1]}/`,
          tokenConfig()
        );
        dispatch({
          type: SET_OPENED_CONTRACT,
          payload: res.data,
        });
      } catch {
        // see above
        history.push(`/clicc/dashboard/contract_modify_contract`);
        dispatch({
          type: RESET_OPENED_CONTRACT,
        });
      }
    }
  }

  // on did mount, get data
  useEffect(async () => {
    await getData()
  }, [])

  // monitor refresh
  useEffect(() => {
    const channel = new BroadcastChannel("clicc-channel");
    channel.addEventListener("message", async e => {
      if (e.data === "refresh") {
        if (localStorage.getItem(key_name) !== null) {
          history.push(`/clicc/dashboard/contract_modify_contract/?id=${localStorage.getItem(key_name)}`);
          await getData();
          setReset(reset + 1) // need to refresh because UI is linked to redux only on first render
        } else {
          await getData();
          setReset(reset + 1) // need to refresh because UI is linked to redux only on first render
        }
      }
    });
    return () => channel.close()
  }, [reset])

  const styleTabPane = {
    ...props.layout.generic_card,
    height: "95%",
    overflowY: "hidden",
    backgroundColor: "white",
    marginTop: "-1px",
    borderTopRightRadius: 15,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
    padding: 10,
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#E4E6E8",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        paddingLeft: props.layout.width_sidebar,
        paddingBottom: props.layout.footer_height,
        overflow: "auto",
      }}
    >
      {/* workspace without padding */}
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 10,
        }}
      >
        {/* workspace */}
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          {!window.location.href.includes("/?id=") ? (
            <div style={{ padding: 10 }}>
              <Card style={{ padding: "10px 20px" }}>
                <div>
                  <Link to="/clicc/dashboard/contract_my_contracts">Open</Link> a
                  contract to see the details
                </div>
              </Card>
            </div>
          ) : props.opened_contract.name === undefined ? (
            <>
              <FaSpinner
                className="spinner"
                style={{ marginTop: "-2px", marginRight: 5 }}
              ></FaSpinner>
              Loading...
            </>
          ) : (
            <div style={{ padding: 10, height: "100%", position: "relative" }}>
              <Nav tabs style={{ width: "90%" }}>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 1,
                      activeWhite: activeTab === 1,
                    })}
                    onClick={() => {
                      setActiveTab(1);
                    }}
                    style={{
                      fontSize: 14,
                      backgroundColor:
                        activeTab === 1 ? "white" : "transparent",
                    }}
                  >
                    WORKSPACE
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 2,
                      activeWhite: activeTab === 2,
                    })}
                    onClick={() => {
                      setActiveTab(2);
                    }}
                    style={{
                      fontSize: 14,
                      backgroundColor:
                        activeTab === 2 ? "white" : "transparent",
                    }}
                  >
                    COMMITMENTS
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 3,
                      activeWhite: activeTab === 3,
                    })}
                    onClick={() => {
                      setActiveTab(3);
                    }}
                    style={{
                      fontSize: 14,
                      backgroundColor:
                        activeTab === 3 ? "white" : "transparent",
                    }}
                  >
                    ACTION PLAN
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 4,
                      activeWhite: activeTab === 4,
                    })}
                    onClick={() => {
                      setActiveTab(4);
                    }}
                    style={{
                      fontSize: 14,
                      backgroundColor:
                        activeTab === 4 ? "white" : "transparent",
                    }}
                  >
                    INVESTMENT PLAN
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 5,
                      activeWhite: activeTab === 5,
                    })}
                    onClick={() => {
                      setActiveTab(5);
                    }}
                    style={{
                      fontSize: 14,
                      backgroundColor:
                        activeTab === 5 ? "white" : "transparent",
                    }}
                  >
                    DOWNLOAD
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 6,
                      activeWhite: activeTab === 6,
                    })}
                    onClick={() => {
                      setActiveTab(6);
                    }}
                    style={{
                      fontSize: 14,
                      backgroundColor:
                        activeTab === 6 ? "white" : "transparent",
                    }}
                  >
                    {props.type === "UPDATE_CREATE_NEW_CONTRACT"
                      ? "SAVE"
                      : "UPDATE"}
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab} style={{ height: "100%" }}>
                <TabPane tabId={1} style={styleTabPane}>
                  <WorkspaceUpdate
                    data={props.data}
                    reset={reset}
                    setReset={setReset}
                  ></WorkspaceUpdate>
                </TabPane>
                <TabPane tabId={2} style={styleTabPane}>
                  <Commitments
                    data={props.data}
                    type={props.type}
                    setActiveTab={setActiveTab}
                    reset={reset}
                    setReset={setReset}
                  ></Commitments>
                </TabPane>
                <TabPane tabId={3} style={styleTabPane}>
                  <ActionPlan
                    data={props.data}
                    type={props.type}
                    setActiveTab={setActiveTab}
                    reset={reset}
                    setReset={setReset}
                  ></ActionPlan>
                </TabPane>
                <TabPane tabId={4} style={styleTabPane}>
                  <InvestmentPlan
                    data={props.data}
                    type={props.type}
                    setActiveTab={setActiveTab}
                    reset={reset}
                    setReset={setReset}
                  ></InvestmentPlan>
                </TabPane>
                <TabPane tabId={5} style={styleTabPane}>
                  <Download
                    data={props.data}
                    type={props.type}
                    setActiveTab={setActiveTab}
                  ></Download>
                </TabPane>

                <TabPane tabId={6} style={styleTabPane}>
                  <CreateOrUpdate
                    data={props.data}
                    type={props.type}
                    setActiveTab={setActiveTab}
                    api_call="put"
                  ></CreateOrUpdate>
                </TabPane>
              </TabContent>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
  opened_contract: state.opened_contract,
});

export default connect(mapStateToProps)(Content);
