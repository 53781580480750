import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { getHeightWithFooterWONavbar } from "../../../../utils/getHeightWithFooterWONavbar";
import { IS_LOADING, GET_ERRORS, API_URL } from "../../../../actions/types";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { InputLabel, Select, MenuItem } from "@mui/material";
import { useDispatch } from "react-redux";
import axios from "axios";
import { saveAs } from "file-saver";
import SuccessAlert from "../../../../components/Modals/SuccessAlert";

function Download(props) {
  const [document, setDocument] = useState("commitments");
  const [format, setFormat] = useState("Word");
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();

  return (
    <div
      style={{
        width: "100%",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        overflow: "auto",
      }}
    >
      <Row style={{ margin: 0, marginTop: 15, height: 100 }}>
        <Col xs="12">
          {props.data.commitments !== null ? (
            <div>
              <InputLabel
                id="document"
                style={{ fontFamily: "Montserrat", fontWeight: 500 }}
              >
                Document
              </InputLabel>
              <Select
                labelId="document"
                value={document}
                style={{
                  width: 200,
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                }}
                onChange={(e) => {
                  setDocument(e.target.value);
                }}
              >
                {/* <MenuItem
                  value={"all"}
                  style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                >
                  All
                </MenuItem> */}
                <MenuItem
                  value={"commitments"}
                  style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                >
                  Commitments
                </MenuItem>
                <MenuItem
                  value={"action_plan"}
                  style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                >
                  Action plan
                </MenuItem>
                <MenuItem
                  value={"investment_plan"}
                  style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                >
                  Investment plan
                </MenuItem>
              </Select>
              <InputLabel
                id="format"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  marginTop: 20,
                }}
              >
                Format
              </InputLabel>
              <Select
                labelId="format"
                value={format}
                style={{
                  width: 200,
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                }}
                onChange={(e) => {
                  setFormat(e.target.value);
                }}
              >
                <MenuItem
                  value={"Word"}
                  style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                >
                  Word
                </MenuItem>
                <MenuItem
                  value={"PDF"}
                  style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                >
                  PDF
                </MenuItem>
                <MenuItem
                  value={"LibreOffice"}
                  style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                >
                  LibreOffice
                </MenuItem>
              </Select>
              <div
                style={{
                  width: "fit-content",
                  backgroundColor: props.layout.color_sidebar,
                  color: "white",
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  fontSize: 16,
                  padding: "6px 15px 4px 15px",
                  borderRadius: 10,
                  cursor: "pointer",
                  marginTop: 30,
                }}
                onClick={async () => {
                  dispatch({
                    type: IS_LOADING,
                  });
                  const xhr = new XMLHttpRequest();
                  xhr.responseType = "arraybuffer";
                  xhr.open(
                    "POST",
                    `${API_URL}/clicc/contract_documents/download/`,
                    true
                  );
                  xhr.setRequestHeader(
                    "Authorization",
                    `Token ${localStorage.getItem("token")}`
                  );
                  xhr.setRequestHeader("Content-type", "application/json");
                  xhr.onload = function () {
                    if (this.status === 200) {
                      var type = xhr.getResponseHeader("Content-Type");

                      var blob = new Blob([this.response], { type: type });
                      var filename = "CLICC - ";
                      if (document === "all") {
                        filename += "full contract";
                      } else {
                        filename += document;
                      }
                      saveAs(blob, filename);
                    }
                    dispatch({
                      type: IS_LOADING,
                    });
                  };

                  var body = {
                    document: document,
                    format: format,
                    data: props.data,
                  };

                  xhr.send(JSON.stringify(body));
                }}
              >
                Download
              </div>
            </div>
          ) : (
            <div>
              <span
                onClick={() => {
                  props.setActiveTab(1);
                }}
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecorationLine: "underline",
                }}
              >
                Open
              </span>{" "}
              a workspace to preview contract's details
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Download);
