import moment from "moment"

export const checkActionPeriod = (aggrid_cell_params, redux_props) => {
    var error_level = 0
    var start_date_action_moment = moment(aggrid_cell_params.data.start_date)
    var end_date_action_moment = aggrid_cell_params.data.end_date === null ? moment("2100-12-31") : moment(aggrid_cell_params.data.end_date)
    var start_date_wp_moment = moment(redux_props.data.start_date)
    var end_date_wp_moment = moment(redux_props.data.end_date)


    if (
        (start_date_action_moment < start_date_wp_moment && end_date_action_moment < start_date_wp_moment) ||
        (start_date_action_moment > end_date_wp_moment && end_date_action_moment > end_date_wp_moment)) {
        error_level = 2
    } else if (
        (start_date_action_moment < start_date_wp_moment && aggrid_cell_params.colDef.field === "start_date") ||
        (end_date_action_moment > end_date_wp_moment && aggrid_cell_params.colDef.field === "end_date")
    ) {
        error_level = 1
    }

    return error_level
} 