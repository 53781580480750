import {
  index,
  netZero,
  actor,
  financials,
  specify,
} from "./initializeNewAction";

export const getProgressBar = (data, field) => {
  const tabMapping = {
    index_completion: index(),
    netZero_completion: netZero(),
    actor_completion: actor(),
    financials_completion: financials(),
    specify_completion: specify(),
  };

  if (
    data.actions === undefined &&
    Object.keys(tabMapping).includes(field) &&
    data.id !== "total"
  ) {
    const fullArray = Object.keys(tabMapping[field]);
    var filledFields = [];
    fullArray.forEach((k) => {
      // first manage particular cases like [] and {}
      if (k === "stakeholders" || k === "financials_investment_detail") {
        if (data[k].length > 0) {
          filledFields.push(k);
        }
      } else if (k === "details") {
        if (Object.keys(data[k]).length > 0) {
          filledFields.push(k);
        }
      } else if (
        Object.keys(data).includes(k) &&
        data[k] !== null &&
        data[k] !== "" &&
        data[k] !== 0
      ) {
        filledFields.push(k);
      }
    });
    const percentage = Math.round(
      100 * (filledFields.length / fullArray.length)
    );
    return percentage;
  } else {
    return null;
  }
};
