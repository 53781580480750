import React, { useState, useRef, useEffect } from "react";
import { useWindowDimensions } from "../../../../../utils/useWindowDimensions";
import { connect, useDispatch } from "react-redux";
import TitleCard from "../../../../../components/TitleCard/TitleCard";
import { getHeightWithFooterWONavbar } from "../../../../../utils/getHeightWithFooterWONavbar";
import { TextField, MenuItem } from "@mui/material";
import moment from "moment";
import { Row, Col, Card } from "reactstrap";
import { FaMarker, FaPlus } from "react-icons/fa";
import { AgGridReact } from "ag-grid-react";
import { applyTransactionFn } from "../../../../../utils/applyTransactionFn";
import axios from "axios";
import { API_URL } from "../../../../../actions/types";
import { tokenConfig } from "../../../../../utils/tokenConfig";
import ModalSystemDetail from "./ModalSystemDetail";
import DeleteDetail from "./DeleteDetail";

function Content(props) {
  const [rowData, setRowData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [listActors, setListActors] = useState([]);
  const [reset, setReset] = useState([]);
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();

  const getData = async () => {
    return props.data.barriers_system_and_stakeholder;
  };

  const grid = useRef();
  const mounted = useRef();
  useEffect(async () => {
    if (!mounted.current) {
      setRowData(await getData());
      mounted.current = true;
    } else {
      const newRows = await getData();
      const arr_checks = ["id"];
      if (newRows.length >= 1) {
        applyTransactionFn(grid, newRows, arr_checks, true);
      }
    }
  }, [props.data.barriers_system_and_stakeholder]);

  useEffect(async () => {
    const res = await axios.get(`${API_URL}/clicc/actors/`, tokenConfig());
    setListActors(res.data);
  }, [reset]);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#E4E6E8",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        paddingLeft: 0,
        paddingBottom: props.layout.footer_height,
        overflow: "auto",
      }}
    >
      {/* workspace without padding */}
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 10,
        }}
      >
        {/* workspace */}
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              padding: 10,
              height: height < 850 ? height * 0.69 : height * 0.74,
            }}
          >
            <div
              style={{
                ...props.layout.generic_card,
                display: "flex",
                flexFlow: "column",
              }}
            >
              <TitleCard title={"Barriers"}></TitleCard>
              <div
                style={{
                  width: "100%",
                  padding: "15px 10px 25px 10px",
                  borderRadius: props.layout.generic_card.borderRadius,
                  height: "100%",
                }}
              >
                <Row style={{ margin: 0 }}>
                  <Col xs="12">
                    <div
                      style={{
                        fontFamily: "Montserrat",
                        marginBottom: 5,
                        fontWeight: 500,
                        color: "#525252",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                      }}
                    >
                      <div>
                        <FaMarker
                          style={{ marginTop: "-3px", marginRight: 7 }}
                        ></FaMarker>
                        Systems & stakeholder mapping
                      </div>
                      <div
                        onClick={() => setIsOpen(true)}
                        style={{
                          width: "fit-content",
                          backgroundColor: props.layout.color_sidebar,
                          color: "white",
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                          fontSize: 16,
                          padding:
                            width < 770
                              ? "5px 6px 3px 6px"
                              : "6px 15px 4px 15px",
                          borderRadius: 10,
                          cursor: "pointer",
                        }}
                      >
                        <FaPlus
                          style={{
                            marginTop: "-3px",
                            marginRight: width < 770 ? 0 : 7,
                          }}
                        ></FaPlus>
                        {width < 770 ? null : "Add row"}
                      </div>
                    </div>
                    <div
                      className="excel-style ag-theme-balham"
                      style={{ marginTop: 10 }}
                    >
                      <AgGridReact
                        ref={grid}
                        rowData={props.data.barriers_system_and_stakeholder}
                        domLayout="autoHeight"
                        frameworkComponents={{
                          deleteDetail: DeleteDetail,
                        }}
                        defaultColDef={{
                          minWidth: 100,
                          resizable: true,
                          filter: true,
                          filterParams: {
                            buttons: ["reset"],
                            newRowsAction: "keep",
                            caseSensitive: true
                          },
                          cellStyle: (params) => {
                            var style = {
                              cursor: "default",
                            };

                            return style;
                          },
                        }}
                        columnDefs={[
                          {
                            field: "",
                            width: 28,
                            maxWidth: 28,
                            suppressMenu: true,
                            cellRenderer: "deleteDetail",
                            cellRendererParams: {
                              data_redux: props.data,
                              type: props.type,
                            },
                          },
                          {
                            field: "description",
                            headerName: "System description",
                            flex: 1,
                          },
                          {
                            field: "stakeholder",
                            headerName: "Stakeholders involved",
                            flex: 1,
                            valueGetter: (params) => {
                              var actor = listActors.find(
                                (obj) =>
                                  obj.id === params.data[params.colDef.field]
                              );
                              if (actor === undefined) {
                                return "";
                              } else {
                                return actor.actor_business_name;
                              }
                            },
                          },
                          { field: "network", headerName: "Network", flex: 1 },
                          {
                            field: "influence",
                            headerName: "Influence",
                            flex: 1,
                          },
                          {
                            field: "interest",
                            headerName: "Interest",
                            flex: 1,
                          },
                        ]}
                      ></AgGridReact>
                    </div>
                  </Col>
                </Row>
                <Row style={{ margin: 0 }}>
                  <Col xs="12" style={{ marginTop: 30 }}>
                    <div
                      style={{
                        fontFamily: "Montserrat",
                        marginBottom: 5,
                        fontWeight: 500,
                        color: "#525252",
                      }}
                    >
                      <FaMarker
                        style={{ marginTop: "-3px", marginRight: 7 }}
                      ></FaMarker>
                      Description of systemic barriers and opportunities
                    </div>
                    <TextField
                      multiline
                      rows={3}
                      label={<div>Text&nbsp;&nbsp;</div>}
                      value={props.data.barriers_description_systemic_barriers}
                      inputProps={{
                        style: {
                          fontWeight: 500,
                          fontFamily: "Montserrat",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          fontWeight: 500,
                          fontFamily: "Montserrat",
                        },
                      }}
                      style={{ width: "100%", marginTop: 5 }}
                      onChange={(e) => {
                        dispatch({
                          type: props.type,
                          payload: {
                            key: "barriers_description_systemic_barriers",
                            value: e.target.value,
                          },
                        });
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ margin: 0, paddingBottom: 160 }}>
                  <Col xs="12" style={{ marginTop: 30 }}>
                    <div
                      style={{
                        fontFamily: "Montserrat",
                        marginBottom: 5,
                        fontWeight: 500,
                        color: "#525252",
                      }}
                    >
                      <FaMarker
                        style={{ marginTop: "-3px", marginRight: 7 }}
                      ></FaMarker>
                      Description of participatory model for the city climate
                      neutrality
                    </div>
                    <TextField
                      multiline
                      rows={3}
                      label={<div>Text&nbsp;&nbsp;</div>}
                      value={
                        props.data.barriers_description_participatory_model
                      }
                      inputProps={{
                        style: {
                          fontWeight: 500,
                          fontFamily: "Montserrat",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          fontWeight: 500,
                          fontFamily: "Montserrat",
                        },
                      }}
                      style={{ width: "100%", marginTop: 5 }}
                      onChange={(e) => {
                        dispatch({
                          type: props.type,
                          payload: {
                            key: "barriers_description_participatory_model",
                            value: e.target.value,
                          },
                        });
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalSystemDetail
        data={props.data}
        type={props.type}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        listActors={listActors}
      ></ModalSystemDetail>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Content);
