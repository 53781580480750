import React, { useState, useEffect } from "react";
import {
  InputAdornment,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { FaEuroSign } from "react-icons/fa";
import InfoAlert from "../../../../../components/Modals/InfoAlert";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { useDispatch } from "react-redux";
import axios from "axios";
import { API_URL } from "../../../../../actions/types";
import { tokenConfig } from "../../../../../utils/tokenConfig";
import { fieldsOfAction } from "../../../../../utils/fieldsOfAction";
import { useWindowDimensions } from "../../../../../utils/useWindowDimensions";

function ModalSystemDetail(props) {
  const [description, setDescription] = useState("");
  const [stakeholder, setStakeholder] = useState("");
  const [network, setNetwork] = useState("");
  const [influence, setInfluence] = useState("");
  const [interest, setInterest] = useState("");
  const { width, height } = useWindowDimensions();

  const dispatch = useDispatch();

  const toggle = () => props.setIsOpen(!props.isOpen);

  return (
    <Modal isOpen={props.isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} style={{ fontFamily: "Montserrat" }}>
        Add systems detail
      </ModalHeader>
      <ModalBody style={{ fontFamily: "Montserrat", fontWeight: 500 }}>
        <Row style={{ margin: 0 }}>
          <Col xs="12" style={{ marginTop: 10 }}>
            <TextField
              select
              label={<div>System description*&nbsp;&nbsp;</div>}
              value={description}
              SelectProps={{
                style: {
                  height: 55,
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                  paddingLeft: 5,
                },
              }}
              InputLabelProps={{
                style: {
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                },
              }}
              style={{ width: "100%" }}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            >
              {fieldsOfAction.map((action) => {
                return (
                  <MenuItem
                    value={action}
                    style={{
                      fontWeight: 500,
                      fontFamily: "Montserrat",
                    }}
                  >
                    {action}
                  </MenuItem>
                );
              })}
            </TextField>
          </Col>
        </Row>
        <Row style={{ margin: 0 }}>
          <Col lg="6" md="12" style={{ marginTop: 30 }}>
            <TextField
              select
              label={<div>Stakeholder*&nbsp;&nbsp;</div>}
              value={stakeholder}
              SelectProps={{
                style: {
                  height: 55,
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                  paddingLeft: 5,
                },
              }}
              InputLabelProps={{
                style: {
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                },
              }}
              style={{ width: "100%" }}
              onChange={(e) => {
                setStakeholder(e.target.value);
              }}
            >
              {props.listActors.map((actor) => {
                return (
                  <MenuItem
                    value={actor.id}
                    style={{
                      fontWeight: 500,
                      fontFamily: "Montserrat",
                    }}
                  >
                    {actor.actor_business_name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Col>
          <Col lg="6" md="12" style={{ marginTop: 30 }}>
            <TextField
              label={<div>Network&nbsp;&nbsp;</div>}
              value={network}
              inputProps={{
                style: {
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                },
              }}
              InputLabelProps={{
                style: {
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                },
              }}
              style={{ width: "100%" }}
              onChange={(e) => {
                setNetwork(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row style={{ margin: 0 }}>
          <Col lg="6" md="12" style={{ marginTop: 30 }}>
            <TextField
              label={<div>Influence&nbsp;&nbsp;</div>}
              value={influence}
              inputProps={{
                style: {
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                },
              }}
              InputLabelProps={{
                style: {
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                },
              }}
              style={{ width: "100%" }}
              onChange={(e) => {
                setInfluence(e.target.value);
              }}
            />
          </Col>
          <Col lg="6" md="12" style={{ marginTop: 30 }}>
            <TextField
              label={<div>Interest&nbsp;&nbsp;</div>}
              value={interest}
              inputProps={{
                style: {
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                },
              }}
              InputLabelProps={{
                style: {
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                },
              }}
              style={{ width: "100%" }}
              onChange={(e) => {
                setInterest(e.target.value);
              }}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          onClick={() => {
            if (description === "") {
              InfoAlert("Description cannot be empty");
            } else if (stakeholder === "") {
              InfoAlert("Stakeholder cannot be empty");
            } else {
              dispatch({
                type: props.type,
                payload: {
                  key: "barriers_system_and_stakeholder",
                  value: [
                    ...props.data.barriers_system_and_stakeholder,
                    {
                      id: new Date().getTime(),
                      description: description,
                      stakeholder: stakeholder,
                      network: network,
                      influence: influence,
                      interest: interest,
                    },
                  ],
                },
              });
              // reset modal values
              setTimeout(() => {
                setDescription("");
                setStakeholder("");
                setNetwork("");
                setInfluence("");
                setInterest("");
              }, 300);
              toggle();
            }
          }}
        >
          Add
        </Button>
        <Button
          color="secondary"
          style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          onClick={toggle}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalSystemDetail;
