import React, { useState } from "react";
import { TextField } from "@mui/material";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import TitleCard from "../../../../../components/TitleCard/TitleCard";
import { connect, useDispatch } from "react-redux";
import SuccessAlert from "../../../../../components/Modals/SuccessAlert";
import { generateRowPathway } from "../../../../../utils/generateRowPathway";
import InfoAlert from "../../../../../components/Modals/InfoAlert";

function ModalAddPathway(props) {
  const [pathwayName, setPathwayName] = useState("");
  const [pathwayDescription, setPathwayDescription] = useState("");
  const dispatch = useDispatch();
  const toggle = () => props.setIsOpen(!props.isOpen);
  return (
    <Modal isOpen={props.isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Create a new pathway</ModalHeader>
      <ModalBody>
        <Row style={{ margin: 0 }}>
          <Col lg="4" md="6" sm="12" style={{ height: "100%" }}>
            <div
              style={{
                ...props.layout.generic_card,
              }}
            >
              <TitleCard
                title="Assign a name"
                customBorderHeight={2}
                customTitleSize={{ large: 19, small: 17 }}
              ></TitleCard>
              <div style={{ padding: "15px 15px 25px 15px" }}>
                <TextField
                  variant="standard"
                  value={pathwayName}
                  inputProps={{
                    style: {
                      fontWeight: 500,
                      padding: 0,
                      fontFamily: "Montserrat",
                    },
                  }}
                  style={{ width: "100%" }}
                  onChange={(e) => setPathwayName(e.target.value)}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ margin: 0 }}>
          <Col xs="12" style={{ height: "100%" }}>
            <div
              style={{
                ...props.layout.generic_card,
                marginTop: 20,
              }}
            >
              <TitleCard
                title="Description"
                customBorderHeight={2}
                customTitleSize={{ large: 19, small: 17 }}
              ></TitleCard>
              <div style={{ padding: "15px 15px 25px 15px" }}>
                <TextField
                  variant="outlined"
                  placeholder="..."
                  multiline
                  rows={6}
                  value={pathwayDescription}
                  inputProps={{
                    style: {
                      fontWeight: 500,
                      padding: 0,
                      fontFamily: "Montserrat",
                    },
                  }}
                  style={{ width: "100%" }}
                  onChange={(e) => setPathwayDescription(e.target.value)}
                />
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          onClick={() => {
            if (
              props.data.pathways.map((obj) => obj.name).includes(pathwayName)
            ) {
              InfoAlert("There is already a pathway with the same name");
            } else {
              var idPathway = new Date().getTime();
              dispatch({
                type: props.type,
                payload: { key: "displayed_pathway", value: idPathway },
              });
              dispatch({
                type: props.type,
                payload: {
                  key: "pathways",
                  value: [
                    ...props.data.pathways,
                    {
                      id: idPathway,
                      name: pathwayName,
                      description: pathwayDescription,
                      actions: [],
                    },
                  ],
                },
              });
              // create objects for the following keys
              ["fields_of_action", "systemic_lever", "co_benefit"].forEach(
                (k) => {
                  dispatch({
                    type: props.type,
                    payload: {
                      key: k,
                      value: [...props.data[k], { id: idPathway, value: "" }],
                    },
                  });
                }
              );

              setPathwayName("");
              setPathwayDescription("");
              SuccessAlert("Pathway added successfully");
              toggle();
            }
          }}
        >
          Save
        </Button>
        <Button color="secondary" onClick={() => toggle()} style={{ fontFamily: "Montserrat", fontWeight: 500 }}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(ModalAddPathway);
