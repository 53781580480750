import React, { useEffect, useState } from "react"
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Collapse from '@mui/material/Collapse';
import { FaSpinner, FaPlus } from "react-icons/fa"
import { API_URL, GET_ERRORS } from "../../../../actions/types";
import { triggerRefresh } from "../../../../utils/triggerRefresh";
import axios from "axios";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { useDispatch, connect } from "react-redux";
import { Card } from "reactstrap"
import { checkPermission } from "../../../../utils/checkPermission";
import SelectActionInventory from "./SelectActionInventory";

const UserPermissions = (props) => {
    const [userPermissions, setUserPermissions] = useState(null)
    const dispatch = useDispatch()
    const prefixes = ["Can add", "Can change", "Can delete", "Can view", "Can view ALL", "Can manage all"]

    useEffect(() => {
        if (props.userAnagrafica !== null && props.permissions !== null) {
            var user_permissions = []
            props.permissions.forEach(obj => {
                var bool = props.userAnagrafica.user_permissions.map(inner_obj => inner_obj.id).includes(obj.id) ? true : false
                user_permissions.push({ ...obj, active: bool })
            })

            // get categories ['user', 'actions', 'actions inventory',...]
            var categories = []
            user_permissions.forEach(permission => {
                var category = ""
                prefixes.forEach(prefix => {
                    if (permission.name.includes(prefix)) {
                        category = permission.name.split(prefix)[1].trim()
                    }
                })
                if (!categories.includes(category)) {
                    categories.push(category)
                }
            })

            // append list of permission per category but remove the final "s" (for example we want "actor" and "actors" to be in the same group)
            var grouped_permissions = []
            categories.forEach(category => {
                var objToAppend = {
                    name: category.endsWith("s") ? category.substring(0, category.length - 1) : category,
                    children: []
                }
                user_permissions.forEach(permission => {
                    var permission_cat = ""
                    prefixes.forEach(prefix => {
                        if (permission.name.includes(prefix)) {
                            permission_cat = permission.name.split(prefix)[1].trim()
                        }
                    })
                    if (permission_cat === category) {
                        objToAppend.children.push(permission)
                    }
                })
                grouped_permissions.push(objToAppend)
            })

            // since the "s" has been removed group together (before the loop you have 2 "actor", ...)
            var grouped_permissions2 = []
            grouped_permissions.forEach(group => {
                var names = grouped_permissions2.map(obj => obj.name)
                if (!names.includes(group.name)) {
                    grouped_permissions2.push(group)
                } else {
                    grouped_permissions2.forEach(inner_obj => {
                        if (inner_obj.name === group.name) {
                            inner_obj.children = [...inner_obj.children, ...group.children]
                        }
                    })
                }
            })

            // add checked = false
            grouped_permissions2.forEach(obj => {
                obj["checked"] = false
            })

            setUserPermissions(grouped_permissions2)
        }
    }, [props.userAnagrafica, props.permissions])

    const makeCall = async (id, bool) => {
        try {
            if (bool === true) {
                await axios.put(
                    `${API_URL}/permissions/${id}/?user_id=${props.userAnagrafica.id}`,
                    null,
                    tokenConfig()
                );
            } else {
                await axios.delete(
                    `${API_URL}/permissions/${id}/?user_id=${props.userAnagrafica.id}`,
                    tokenConfig()
                );
            }

            // update state
            setUserPermissions(
                userPermissions.map(obj => {
                    return {
                        ...obj,
                        children: obj.children.map(inner_obj => {
                            if (inner_obj.id !== id) {
                                return inner_obj
                            } else {
                                return {
                                    ...inner_obj,
                                    active: bool
                                }
                            }
                        })
                    }
                })
            )
        } catch (err) {
            const errors = {
                msg: err.response.data,
                status: err.response.status,
            };
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        }
    }

    return (
        <div>
            {props.data.user_type === "PROJECT ADMIN" ?
                <div style={{ padding: 20 }}>
                    User of type "Project admin" has all permissions
                </div>
                :
                props.data.id === props.auth.id ?
                    <div style={{ padding: 20 }}>
                        You cannot change your own permissions
                    </div>
                    :
                    userPermissions === null ?
                        <div style={{ padding: 20 }}>
                            <FaSpinner
                                className="spinner"
                                style={{ marginTop: "-2px", marginRight: 5 }}
                            ></FaSpinner>
                            Loading...
                        </div>
                        :
                        <FormGroup style={{ padding: 20 }}>
                            {userPermissions.map((group, idx) => {
                                return (<Card body style={{ marginTop: idx > 0 ? 20 : 0 }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <FaPlus
                                            onClick={() => setUserPermissions(
                                                userPermissions.map(obj => {
                                                    if (obj.name === group.name) {
                                                        return {
                                                            ...group,
                                                            checked: !group.checked
                                                        }
                                                    } else {
                                                        return obj
                                                    }
                                                })
                                            )}
                                            style={{
                                                color: props.layout.color_sidebar,
                                                marginRight: 10,
                                                cursor: "pointer"
                                            }}></FaPlus> {group.name.toUpperCase()}
                                    </div>
                                    <Collapse in={group.checked}>
                                        <div style={{ marginTop: 10 }}>
                                            {group.children.map(permission => {
                                                return <div>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                onChange={(e) => makeCall(permission.id, e.target.checked)}
                                                                checked={permission.active}
                                                            />
                                                        }
                                                        label={permission.name}
                                                        style={{ width: "fit-content" }}
                                                    />
                                                    {group.name === "actions inventory" && permission.name === "Can view ALL actions inventory" && permission.active === false ?
                                                        <SelectActionInventory actionsInventory={props.actionsInventory} userAnagrafica={props.userAnagrafica}></SelectActionInventory>
                                                        : null}
                                                </div>
                                            })}
                                        </div>
                                    </Collapse>
                                </Card>
                                )

                            })}

                        </FormGroup>}

        </div>
    )
};

const mapStateToProps = (state) => ({
    layout: state.layout,
    auth: state.auth
})

export default connect(mapStateToProps)(UserPermissions);
