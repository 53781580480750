import React, { useState, useEffect } from "react"
import { Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { API_URL } from "../../../../actions/types";
import axios from "axios";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { connect } from "react-redux";
import classnames from "classnames";
import UserAnagrafica from "./UserAnagrafica";
import UserPermissions from "./UserPermissions";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { triggerRefresh } from "../../../../utils/triggerRefresh";


const ModalDeleteUser = (props) => {
    const [activeTab, setActiveTab] = useState(1);
    const [myUserType, setMyUserType] = useState("");
    const [userAnagrafica, setUserAnagrafica] = useState(null);
    const [permissions, setPermissions] = useState(null);
    const [actors, setActors] = useState(null);
    const [actionsInventory, setActionsInventory] = useState(null);
    const { width, height } = useWindowDimensions();
    const toggle = () => {
        if (props.isOpen === true) {
            triggerRefresh()
        }
        props.setIsOpen(!props.isOpen)
    };

    useEffect(async () => {
        const resUserAnagrafica = await axios.get(
            `${API_URL}/users/${props.data.id}/`,
            tokenConfig()
        );
        const resActors = await axios.get(
            `${API_URL}/clicc/actors/`,
            tokenConfig()
        );

        try {
            const resActionsInventory = await axios.get(
                `${API_URL}/clicc/actions_inventory/`,
                tokenConfig()
            );
            setActionsInventory(resActionsInventory.data)
        } catch {
            setActionsInventory([])
        }


        var my_user_type = ""
        if (resUserAnagrafica.data.is_project_admin === true) {
            my_user_type = "PROJECT ADMIN"
        }
        if (resUserAnagrafica.data.is_project_user === true) {
            my_user_type = "PROJECT USER"
        }
        if (resUserAnagrafica.data.is_actor === true) {
            my_user_type = "ACTOR"
        }
        if (resUserAnagrafica.data.is_sub_actor === true) {
            my_user_type = "SUB ACTOR"
        }
        const resPermissions = await axios.get(
            `${API_URL}/permissions/`,
            { ...tokenConfig(), params: { user_type: my_user_type } }

        );
        setMyUserType(my_user_type)
        setUserAnagrafica(resUserAnagrafica.data)
        setPermissions(resPermissions.data)
        setActors(resActors.data)
    }, [])

    const styleTabPane = {
        height: "95%",
        // overflow: "auto",
        border: "1px solid #666666",
        marginTop: "-1px",
        borderTopRightRadius: 15,
        borderBottomRightRadius: 15,
        borderBottomLeftRadius: 15,
    };


    return (
        <Modal
            isOpen={props.isOpen}
            toggle={toggle}
            size="lg"
            style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
            }}
        >
            <ModalHeader toggle={toggle}>Edit user</ModalHeader>
            <ModalBody style={{
                maxHeight: height * 0.8,
                overflow: "auto"
            }}>
                <Nav tabs style={{ width: "90%" }}>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === 1 })}
                            onClick={() => {
                                setActiveTab(1);
                            }}
                            style={{ fontSize: 14, backgroundColor: "transparent" }}
                        >
                            USER ANAGRAFICA
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 2 })}
                                onClick={() => {
                                    setActiveTab(2);
                                }}
                                style={{ fontSize: 14, backgroundColor: "transparent" }}
                            >
                                PERMISSIONS
                            </NavLink>
                        </NavItem>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} style={{ height: "100%" }}>
                    <TabPane tabId={1} style={styleTabPane}>
                        <UserAnagrafica myUserType={myUserType} userAnagrafica={userAnagrafica} actors={actors} {...props}></UserAnagrafica>
                    </TabPane>
                    <TabPane tabId={2} style={styleTabPane}>
                        <UserPermissions userAnagrafica={userAnagrafica} permissions={permissions} actionsInventory={actionsInventory} {...props}></UserPermissions>
                    </TabPane>
                </TabContent>

            </ModalBody>
        </Modal>
    )
};

const mapStateToProps = (state) => ({
    layout: state.layout,
});

export default connect(mapStateToProps)(ModalDeleteUser);
