import React from "react";
import { connect } from "react-redux";
import Areas from "./components/Areas/Areas";
import Commodities from "./components/Commodities/Commodities";

function BaselineDimensions(props) {
  return (
    <div>
      <Areas data={props.data} type={props.type}></Areas>
      <Commodities data={props.data} type={props.type}></Commodities>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(BaselineDimensions);
