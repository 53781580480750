import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Card, Button } from "reactstrap";
import { InputAdornment, Switch, FormControlLabel, TextField } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { getHeightWithFooterWONavbar } from "../../../../utils/getHeightWithFooterWONavbar";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { FaSpinner, FaExclamationTriangle, FaEuroSign, FaPercentage } from "react-icons/fa";
import { API_URL, GET_ERRORS } from "../../../../actions/types";
import axios from "axios";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { FaBurn, FaBolt, FaCoins, FaChartArea } from "react-icons/fa";
import { applyTransactionFn } from "../../../../utils/applyTransactionFn";
import InfoAlert from "../../../../components/Modals/InfoAlert";
import { AgGridColumn } from "ag-grid-react";
import { AgGridReact } from "ag-grid-react";
import { NumericFormat } from "react-number-format";
import Tooltip from "@mui/material/Tooltip";
import { roundAtMost2Digits } from "../../../../utils/roundAtMost2Digits";
import moment from "moment/moment";
import { getRangeNumbers } from "../../../../utils/getRangeNumbers";
import { checkArraysEqual } from "../../../../utils/checkArraysEqual";
import { triggerRefresh } from "../../../../utils/triggerRefresh";
import { cleanRatio } from "../../../../utils/cleanRatio";
import { checkObjectsEqual } from "../../../../utils/checkObjectsEqual";
import SingleOutput from "./output/SingleOutput";



function CO2EQAvoided(props) {
  const [isLoading, setIsLoading] = useState(true);
  const { width, height } = useWindowDimensions();
  const [showWarning, setShowWarning] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [emissionKeys, setEmissionKeys] = useState([]);
  const [discountRate, setDiscountRate] = useState(null);

  const dispatch = useDispatch();

  const keysComputations = [
    "total_co2_equivalent_avoided",
    "emissions",
    "electricity_impact",
    "financials_capex",
    "financials_opex",
    "financials_total_investment",
    "optional_params"
  ]
  const financials_keys = {
    financials_capex: "CAPEX",
    financials_opex: "OPEX",
    financials_total_investment: "Total cost",
  }
  const elec_keys = {
    Green_Certified_elec_Purchase: "Green certified purchase",
    RESs_Generation: "RES generation",
    Variation_Electricity_consumption: "Variation elec. consumption",
    electricity_self_consumption: "Self consumption",
  }
  const inputProps = {
    style: {
      fontWeight: 500,
      fontFamily: "Montserrat",
      cursor: props.data.manual_values === false ? "not-allowed" : null
    },
  };

  const InputLabelProps = {
    style: {
      fontWeight: 500,
      fontFamily: "Montserrat",
    },
  };

  useEffect(async () => {
    let res;
    try {
      res = await axios.post(
        `${API_URL}/clicc/computations/`,
        {
          start_date: props.data.start_date,
          end_date: props.data.end_date,
          details: props.data.details,
          manual_values: props.data.manual_values,
          emissions: props.data.emissions,
          electricity_impact: props.data.electricity_impact,
          financials_capex: props.data.financials_capex,
          financials_opex: props.data.financials_opex,
          financials_total_investment: props.data.financials_total_investment,
          optional_params: props.data.optional_params,
        },
        tokenConfig()
      );
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    }

    // check if details contains discount rate (to show read only)
    var discount_rate_found = null
    if (Object.keys(props.data.details).length > 0 && props.data.details.parameters !== undefined
      && props.data.details.parameters.length > 0) {
      var discount_obj = props.data.details.parameters.find(obj => obj.id === "discount_rate")
      if (discount_obj !== undefined) {
        discount_rate_found = discount_obj.default
      }
    }
    setDiscountRate(discount_rate_found)

    // policy
    if (props.data.id === undefined) {
      keysComputations.forEach(k => {
        dispatch({
          type: props.type,
          payload: {
            key: k,
            value: res.data[k],
          },
        });
      })
    } else {
      if (
        props.data.manual_values === false &&
        (Math.round(props.data.total_co2_equivalent_avoided) !==
          Math.round(res.data.total_co2_equivalent_avoided) ||
          !checkArraysEqual(props.data.financials_capex, res.data.financials_capex) ||
          !checkArraysEqual(props.data.financials_opex, res.data.financials_opex) ||
          Math.round(props.data.financials_total_investment) !==
          Math.round(res.data.financials_total_investment) ||
          !checkObjectsEqual(props.data.electricity_impact, res.data.electricity_impact)
        )
      ) {
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
    }
  }, [props.data.id]);

  const getData = async () => {
    var emissions = [];
    props.data.emissions.forEach((row) => {
      if (row.emissions_avoided !== 0) {
        if (!emissions.includes(row.gas)) {
          emissions.push(row.gas);
        }
      }
    });
    setEmissionKeys(emissions);
    return props.data.emissions;
  };

  const grid = useRef();
  const mounted = useRef();

  useEffect(async () => {
    if (!mounted.current) {
      setRowData(await getData());
      mounted.current = true;
    } else {
      const newRows = await getData();
      const arr_checks = ["gas"];
      if (newRows.length >= 1) {
        applyTransactionFn(grid, newRows, arr_checks, false);
      }
    }
  }, [props.data.emissions]);

  var isDragging = false;
  var listChanges = [];
  const fnCellChanged = async (myList) => {
    var raise_error = false;
    var regExp = /^-?(\d*[.,]?\d+)$/;
    var listFiltered = [];
    myList.forEach((params) => {
      // check if there are no letters or commas --> if yes show error and set newValue = oldValue
      if (
        params.newValue === "" ||
        params.newValue === undefined ||
        params.newValue === null
      ) {
        params.data[params.colDef.field] = parseFloat(params.oldValue);

        params.newValue = parseFloat(params.oldValue);
      } else if (
        String(params.newValue).match(regExp) === null ||
        String(params.newValue).includes(" ")
      ) {
        raise_error = true;
        params.data[params.colDef.field] = params.oldValue;
        params.newValue = params.oldValue;
      }
      listFiltered.push(params);
    });

    var final = [];
    var updateRows = {};
    listFiltered.forEach((updatedRow) => {
      updateRows[updatedRow.data["gas"]] = updatedRow.data;
    });
    props.data.emissions.forEach((s) => {
      if (s.gas in updateRows) {
        final.push(updateRows[s.gas]);
      } else {
        final.push(s);
      }
    });
    const res = await axios.post(
      `${API_URL}/clicc/computations/`,
      {
        start_date: props.data.start_date,
        end_date: props.data.end_date,
        details: props.data.details,
        manual_values: props.data.manual_values,
        emissions: final,
        electricity_impact: props.data.electricity_impact,
        financials_capex: props.data.financials_capex,
        financials_opex: props.data.financials_opex,
        financials_total_investment: props.data.financials_total_investment,
        optional_params: props.data.optional_params,
      },
      tokenConfig()
    );
    keysComputations.forEach(k => {
      dispatch({
        type: props.type,
        payload: {
          key: k,
          value: res.data[k],
        },
      });
    })
    // if there are errors (i.e. letters) show error --> in any case continue (there is no an ELSE)
    if (raise_error === true) {
      InfoAlert("Invalid format! Only numbers are allowed");
    }

    // reset list
    listChanges = [];
  };


  const showFinancialValue = (elem) => {
    if (typeof elem === "number") {
      return roundAtMost2Digits(elem)
    } else if (elem.length === 0) {
      return 0
    } else {
      var v = elem.find(element => element !== 0)
      if (v !== undefined) {
        return roundAtMost2Digits(v)
      } else {
        return 0
      }
    }
  }


  const updateFinancialValue = (num, k) => {
    if (typeof props.data[k] === "number") {
      return num
    } else {
      var start_date_to_use = "2019-01-01"
      if (props.data.start_date !== null) {
        start_date_to_use = props.data.start_date
      }
      var end_date_to_use = "2030-12-31"
      if (props.data.end_date !== null) {
        end_date_to_use = props.data.end_date
      }
      var start_year = moment(start_date_to_use).year()
      var end_year = moment(end_date_to_use).year()
      var list_years = getRangeNumbers(start_year, end_year)
      var array = []
      if (k === "financials_capex") {
        list_years.forEach((y, idx) => {
          if (idx === 0) {
            array.push(num)
          } else {
            array.push(0)
          }
        })
      } else {
        list_years.forEach((y, idx) => {
          if (idx === 0) {
            array.push(0)
          } else {
            array.push(num)
          }
        })
      }
      return array
    }
  }

  const endYear = () => {
    if (props.data.end_date !== null && moment(props.data.end_date).isValid()) {
      return `in ${moment(props.data.end_date).year()}`
    } else {
      return ""
    }
  }



  return (
    <div
      style={{
        width: "100%",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        overflow: "auto",
      }}
    >
      {isLoading === true ? (
        <Row style={{ margin: 0, marginTop: 15 }}>
          <Col xs="12">
            <FaSpinner className="spinner"></FaSpinner>
          </Col>
        </Row>
      ) : (
        <div style={{ position: "relative" }}>
          <div
            style={{
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: 16,
              position: width > 990 ? "absolute" : "",
              marginLeft: 12,
              marginTop: 10,
              marginBottom: 15,
              right: 20,
              top: 15,
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={props.data.manual_values}
                  onChange={async (e) => {
                    dispatch({
                      type: props.type,
                      payload: {
                        key: "manual_values",
                        value: e.target.checked,
                      },
                    });
                    if (e.target.checked === false) {
                      const res = await axios.post(
                        `${API_URL}/clicc/computations/`,
                        {
                          start_date: props.data.start_date,
                          end_date: props.data.end_date,
                          details: props.data.details,
                          manual_values: false,
                          emissions: props.data.emissions,
                          electricity_impact: props.data.electricity_impact,
                          financials_capex: props.data.financials_capex,
                          financials_opex: props.data.financials_opex,
                          financials_total_investment: props.data.financials_total_investment,
                          optional_params: props.data.optional_params,
                        },
                        tokenConfig()
                      );
                      keysComputations.forEach(k => {
                        dispatch({
                          type: props.type,
                          payload: {
                            key: k,
                            value: res.data[k],
                          },
                        });
                      })
                    } else {
                      setShowWarning(false);
                    }
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                  style={{ fontFamily: "Montserrat" }}
                />
              }
              label={
                <div style={{ fontFamily: "Montserrat", fontWeight: 500 }}>
                  Insert manual values
                </div>
              }
            />
          </div>
          <Row style={{ margin: 0 }}>
            <Col lg="4" md="6" sm="12" style={{ marginTop: 10 }}>
              <Card body>
                <Row>
                  <Col sm="8">
                    <div style={{ fontFamily: "Montserrat", fontWeight: 500, }}>
                      {emissionKeys.length > 1 ? (
                        <div>
                          CO
                          <span style={{ fontSize: 8, marginTop: 5 }}>2</span>{" "}
                          eq. avoided {endYear()} [t/y]
                        </div>
                      ) : emissionKeys.length === 1 ? (
                        <div>
                          {emissionKeys[0].replace("_", "-").toUpperCase()}{" "}
                          avoided {endYear()} [t/y]
                        </div>
                      ) : emissionKeys.length === 0 ? (
                        <div>
                          No emissions detected
                        </div>
                      ) : null}
                      <div style={{ marginTop: 5, fontStyle: "italic" }}>
                        {props.data.manual_values === false
                          ? "Automatic computation"
                          : "Value fixed manually"}
                      </div>
                    </div>
                  </Col>
                  <Col
                    sm="4"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Montserrat",
                      fontSize: 25,
                    }}
                  >
                    {emissionKeys.length === 0
                      ? 0
                      : emissionKeys.length === 1
                        ?
                        props.data.emissions.find(
                          (g) => g.gas === emissionKeys[0]
                        ).emissions_avoided.toLocaleString("eng", {
                          maximumFractionDigits: 0,
                        })
                        : props.data.total_co2_equivalent_avoided.toLocaleString("eng", {
                          maximumFractionDigits: 0,
                        })}
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col lg="4" md="6" sm="12" style={{ marginTop: 10 }}>
              <Card body>
                <Row>
                  <Col sm="8">
                    <div style={{
                      fontFamily: "Montserrat", fontWeight: 500
                    }}>
                      {emissionKeys.length > 1 ? (
                        <div>€<sub style={{ fontSize: 8 }}>CAPEX</sub> / CO2 eq. [€/(t/y)]</div>
                      ) : emissionKeys.length === 1 ? (
                        <div>
                          €<sub style={{ fontSize: 8 }}>CAPEX</sub> / {emissionKeys[0].replace("_", "-").toUpperCase()}{" "}
                          av. [€/(t/y)]
                        </div>
                      ) : emissionKeys.length === 0 ? (
                        <div>No emissions detected</div>
                      ) : null}
                      <div style={{
                        marginTop: 5, fontStyle: "italic",
                        fontWeight: 500,
                      }}>
                        {props.data.manual_values === false
                          ? "Automatic computation"
                          : "Value fixed manually"}
                      </div>

                    </div>
                  </Col>
                  <Col
                    sm="4"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Montserrat",
                      fontSize: 25,
                    }}
                  >
                    {emissionKeys.length === 0
                      ? 0
                      : emissionKeys.length === 1
                        ? cleanRatio(showFinancialValue(props.data.financials_capex),
                          props.data.emissions.find(
                            (g) => g.gas === emissionKeys[0]
                          ).emissions_avoided).toLocaleString("eng", {
                            maximumFractionDigits: 0,
                          })
                        : cleanRatio(showFinancialValue(props.data.financials_capex), props.data.total_co2_equivalent_avoided).toLocaleString("eng", {
                          maximumFractionDigits: 0,
                        })
                    }
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          {/* Financials */}
          <Row
            style={{
              margin: 0,
              marginTop: 20,
              fontFamily: "Montserrat",
              fontWeight: 500,
            }}
          >
            <Col
              sm="12"
              style={{ marginTop: 10 }}
            >
              <FaCoins style={{ marginTop: "-3px", marginRight: 5 }}></FaCoins >
              Financial costs
              <Card style={{ marginTop: 5, padding: "0px 20px 20px 20px" }}>
                <Row>
                  {Object.keys(financials_keys).map(k => {
                    return (
                      <Col lg="3" md="12" style={{ marginTop: 30 }}>
                        <NumericFormat
                          thousandSeparator=","
                          decimalSeparator="."
                          value={
                            showFinancialValue(props.data[k])}
                          customInput={TextField}
                          // prevent user from typing when manual_values === false
                          onKeyDown={(e) => {
                            if (props.data.manual_values === false) {
                              e.preventDefault();
                            }
                          }}
                          onValueChange={(values) => {
                            const { formattedValue, value, floatValue } = values;
                            const res_update = updateFinancialValue(floatValue, k)

                            dispatch({
                              type: props.type,
                              payload: {
                                key: k,
                                value: res_update,
                              },
                            });
                          }}
                          label={<div style={{ display: "flex" }}>{financials_keys[k]}{k === "financials_total_investment" && props.data.manual_values === false && (props.data.start_date === null || props.data.end_date === null) && (props.data.financials_capex > 0 || props.data.financials_opex > 0) ? <Tooltip title='Make sure to add a "Start date" and an "End date" to automatically compute the Total investment'><div style={{ marginLeft: 2 }}>❌</div></Tooltip> : props.data.manual_values === false ? <Tooltip title="Value computed automatically"><div style={{ marginTop: "-2px" }}>🔒</div></Tooltip> : null}&nbsp;&nbsp;</div>}
                          inputProps={inputProps}
                          InputLabelProps={InputLabelProps}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <div>
                                  <FaEuroSign style={{ marginTop: "-2px" }}></FaEuroSign>
                                </div>
                              </InputAdornment>
                            ),
                          }}
                          style={{ width: "100%" }}
                        />
                      </Col>
                    )
                  })
                  }

                  {discountRate !== null ?
                    <Col lg="3" md="12" style={{ marginTop: 30 }}>
                      <NumericFormat
                        thousandSeparator=","
                        decimalSeparator="."
                        value={discountRate}
                        customInput={TextField}
                        // prevent user from typing (this field is readOnly)
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        label={<div style={{ display: "flex" }}>Discount rate <Tooltip title="This value can be changed from 'Specify'"><div style={{ marginTop: "-2px" }}>🔒</div></Tooltip>&nbsp;&nbsp;</div>}
                        inputProps={inputProps}
                        InputLabelProps={InputLabelProps}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <div>
                                <FaPercentage style={{ marginTop: "-2px" }}></FaPercentage >
                              </div>
                            </InputAdornment>
                          ),
                        }}
                        style={{ width: "100%" }}
                      />
                    </Col>
                    : null}

                </Row>
              </Card>
            </Col>
          </Row>

          {/* Electricity sources */}
          <Row
            style={{
              margin: 0,
              marginTop: 20,
              fontFamily: "Montserrat",
              fontWeight: 500,
            }}
          >
            <Col
              sm="12"
              style={{ marginTop: 10 }}
            >
              <FaBolt style={{ marginTop: "-3px", marginRight: 5 }}></FaBolt>
              Electricity sources
              <Card style={{ marginTop: 5, padding: "0px 20px 20px 20px" }}>
                <Row>
                  {Object.keys(elec_keys).map(k => {
                    return (
                      <Col lg="3" md="6" sm="12" style={{ marginTop: 20 }}>
                        <NumericFormat
                          thousandSeparator=","
                          decimalSeparator="."
                          value={props.data.electricity_impact !== undefined &&
                            props.data.electricity_impact !== null && props.data.electricity_impact[k] !== undefined ? roundAtMost2Digits(props.data.electricity_impact[k]) : 0}
                          customInput={TextField}
                          // prevent user from typing when manual_values === false
                          onKeyDown={(e) => {
                            if (props.data.manual_values === false) {
                              e.preventDefault();
                            }
                          }}
                          onValueChange={(values) => {
                            const { formattedValue, value, floatValue } = values;
                            dispatch({
                              type: props.type,
                              payload: {
                                key: "electricity_impact",
                                value: {
                                  ...props.data.electricity_impact,
                                  [k]: floatValue
                                },
                              },
                            });
                          }}
                          label={<div style={{ display: "flex" }}>{elec_keys[k]}{props.data.manual_values === false ? <Tooltip title="Value computed automatically"><div style={{ marginTop: "-2px" }}>🔒</div></Tooltip> : null}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>}
                          inputProps={inputProps}
                          InputLabelProps={InputLabelProps}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <div style={{
                                  fontFamily: "Montserrat",
                                  fontSize: 14
                                }}>
                                  MWh
                                </div>
                              </InputAdornment>
                            ),
                          }}
                          style={{
                            width: "100%",
                          }}
                        />
                      </Col>
                    )
                  })}

                </Row>
              </Card>
            </Col>
          </Row>


          {/* Other results */}
          {props.data.optional_params !== undefined && props.data.optional_params.other_results !== undefined && props.data.optional_params.other_results.length > 0 ?
            <Row
              style={{
                margin: 0,
                marginTop: 20,
                marginBottom: 180,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >

              <Col
                sm="12"
                style={{ marginTop: 10 }}
              >
                <FaChartArea style={{ marginTop: "-3px", marginRight: 5 }}></FaChartArea >
                Other results
                <Card style={{ marginTop: 5, padding: "10px 20px" }}>
                  <Row>
                    {props.data.optional_params.other_results.map((output, index) => {
                      return <div
                        style={{ marginTop: index > 0 ? 40 : 0 }}
                      >
                        <SingleOutput
                          key={output.id}
                          output={output}
                        ></SingleOutput>
                      </div>
                    })}
                  </Row>
                </Card>
              </Col>
            </Row>
            : null}


          {showWarning === true ? (
            <Row style={{ margin: 0, marginTop: 15 }}>
              <Col xs="12">
                <Card
                  body
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                    display: "flex",
                    borderColor: "#FFC107",
                  }}
                >
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ marginRight: 10 }}>
                      <div>
                        <FaExclamationTriangle
                          style={{
                            color: "#FFC107",
                            marginTop: "-2px",
                            marginRight: 3,
                          }}
                        ></FaExclamationTriangle>
                        New parameters detected
                      </div>
                      <div style={{ fontStyle: "italic", marginTop: 5 }}>
                        Rerun the function to update computations
                      </div>
                    </div>

                    <Button
                      color="warning"
                      style={{ marginLeft: 15 }}
                      onClick={async () => {
                        try {
                          const resUpdate = await axios.put(
                            `${API_URL}/clicc/actions/${props.data.id}/`,
                            {
                              recompute: true,
                              start_date: props.data.start_date,
                              end_date: props.data.end_date,
                              details: props.data.details,
                              manual_values: props.data.manual_values,
                              emissions: props.data.emissions,
                              electricity_impact: props.data.electricity_impact,
                              financials_capex: props.data.financials_capex,
                              financials_opex: props.data.financials_opex,
                              financials_total_investment: props.data.financials_total_investment,
                              optional_params: props.data.optional_params,
                            },
                            tokenConfig()
                          );
                          keysComputations.forEach(k => {
                            dispatch({
                              type: props.type,
                              payload: {
                                key: k,
                                value: resUpdate.data[k],
                              },
                            });
                          })
                          setShowWarning(false);
                          triggerRefresh()
                        } catch (err) {
                          const errors = {
                            msg: err.response.data,
                            status: err.response.status,
                          };
                          dispatch({
                            type: GET_ERRORS,
                            payload: errors,
                          });
                        }
                      }}
                    >
                      Click to refresh
                    </Button>
                  </div>
                </Card>
              </Col>
            </Row>
          ) : null}
          <Row
            style={{
              margin: 0,
              marginTop: 20,
              fontFamily: "Montserrat",
              fontWeight: 500,
            }}
          >
            <Col
              sm="12"
              style={{ marginTop: 10 }}
              hidden={
                (emissionKeys.length < 2 &&
                  props.data.manual_values === false) ||
                props.data.manual_values === false
              }
            >
              <FaBurn style={{ marginTop: "-3px", marginRight: 5 }}></FaBurn>
              Emission details
              <div
                className="excel-style ag-theme-balham"
                style={{ marginTop: 5 }}
              >
                <AgGridReact
                  ref={grid}
                  domLayout="autoHeight"
                  enableFillHandle={true}
                  enableRangeSelection={true}
                  stopEditingWhenCellsLoseFocus={true}
                  processDataFromClipboard={(params) => {
                    var data = [];
                    for (var i = 0; i < params.data.length; i++) {
                      if (
                        params.data[i].length !== 1 ||
                        (params.data[i][0] !== "" && params.data[i][0] !== " ")
                      ) {
                        data.push(params.data[i]);
                      }
                    }
                    return data;
                  }}
                  rowData={rowData}
                  defaultColDef={{
                    resizable: true,
                    filter: true,
                    filterParams: {
                      buttons: ["reset"],
                      newRowsAction: "keep",
                      caseSensitive: true
                    },
                    flex: 1,
                    editable: (params) => {
                      return false;
                    },
                  }}
                  onCellValueChanged={(params) => {
                    params.newValue = parseFloat(params.newValue);
                    params.value = parseFloat(params.value);
                    params.data[params.colDef.field] = parseFloat(
                      params.data[params.colDef.field]
                    );
                    listChanges.push(params);
                    if (params.source !== "paste" && isDragging === false) {
                      fnCellChanged(listChanges);
                    }
                  }}
                  onPasteEnd={(params) => {
                    fnCellChanged(listChanges);
                  }}
                  onDragStarted={(params) => {
                    isDragging = true;
                  }}
                  onDragStopped={(params) => {
                    isDragging = false;
                    // check if there are no changes because isDragging becomes true even if you simply drag without using the fill operations
                    if (listChanges.length > 0) {
                      fnCellChanged(listChanges);
                    }
                  }}
                >
                  <AgGridColumn
                    field={"gas"}
                    valueGetter={(params) => {
                      return params.data.gas.replace("_", " ").toUpperCase();
                    }}
                  ></AgGridColumn>
                  <AgGridColumn
                    field={"emissions_avoided"}
                    headerName={"Emissions Avoided"}
                    editable={() => {
                      if (props.data.manual_values) {
                        return true;
                      } else return false;
                    }}
                    cellClass={(params) => {
                      if (!props.data.manual_values) {
                        return "non-editable";
                      } else return "";
                    }}
                  ></AgGridColumn>
                  <AgGridColumn
                    field={"gwp"}
                    headerName="GWP"
                    editable={(params) => {
                      if (
                        (!props.data.manual_values &&
                          params.data.gas !== "f_gas") ||
                        params.data.gas === "co2"
                      ) {
                        return false;
                      } else return true;
                    }}
                    cellClass={(params) => {
                      if (
                        (!props.data.manual_values &&
                          params.data.gas !== "f_gas") ||
                        params.data.gas === "co2"
                      ) {
                        return "non-editable";
                      } else return "";
                    }}
                  ></AgGridColumn>
                  <AgGridColumn
                    field={"co2_equivalent_avoided"}
                    headerName={"CO₂ Equivalent Avoided"}
                    cellClass={(params) => {
                      return "non-editable";
                    }}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(CO2EQAvoided);
