import React, { useState, useEffect } from "react";
import { FaTimes, FaPencilAlt, FaSpinner } from "react-icons/fa";
import {
  Card,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  API_URL,
  IS_LOADING,
  RESET_OPENED_ACTION,
} from "../../../../actions/types";
import { useDispatch } from "react-redux";
import SuccessAlert from "../../../../components/Modals/SuccessAlert";
import axios from "axios";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { triggerRefresh } from "../../../../utils/triggerRefresh";
import { checkPermission } from "../../../../utils/checkPermission";

function ActionColumn(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenGroupedAction, setIsOpenGroupedAction] = useState(false);
  const [listGroupedAction, setListGroupedAction] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (isOpenGroupedAction === true) {
      const list_promises = props.data.actions.map((id) => {
        return axios.get(`${API_URL}/clicc/actions/${id}/`, tokenConfig());
      });

      Promise.all(list_promises).then(function (values) {
        setListGroupedAction(values.map((obj) => obj.data));
      });
    }
  }, [isOpenGroupedAction]);

  const toggle = () => setIsOpen(!isOpen);
  const toggleGroupedAction = () => {
    if (isOpenGroupedAction === false) {
      setListGroupedAction(null);
    }
    setIsOpenGroupedAction(!isOpenGroupedAction);
  };

  const openAction = async (e, id, actor) => {
    if (e.ctrlKey) {
      window.open(
        window.location.hostname === "localhost" ? `http://localhost:3009/clicc/dashboard/actions_modify_action/?id=${id}` :
          API_URL.replace(
            "/api",
            `/clicc/dashboard/actions_modify_action/?id=${id}`
          ),
        '_blank',
      );
      SuccessAlert("Action opened in another tab")
    } else if (e.shiftKey) {
      localStorage.setItem("opened_action", id);
      triggerRefresh()
      SuccessAlert("Action available in tab already opened")
    } else {
      dispatch({
        type: RESET_OPENED_ACTION,
      });
      history.push(`/clicc/dashboard/actions_modify_action/?id=${id}`);
    }

  };
  return (
    <>
      {props.data.id !== "total" ? (
        <div>

          <div style={{ display: "flex", alignItems: "center" }}>
            {checkPermission(props.auth.user_permissions, "Can delete actions") === true ?
              <FaTimes
                onClick={toggle}
                style={{
                  color: "#FC2727",
                  cursor: "pointer",
                }}
              ></FaTimes>
              : null
            }
            {checkPermission(props.auth.user_permissions, "Can change actions") === true ?
              <FaPencilAlt
                onClick={async (e) => {
                  if (props.data.actions !== undefined) {
                    toggleGroupedAction();
                  } else {
                    openAction(e, props.data.id, props.data.actor);
                  }
                }}
                style={{
                  color: "#EE9B1B",
                  cursor: "pointer",
                  marginLeft: checkPermission(props.auth.user_permissions, "Can delete actions") === true ? 10 : 0,
                }}
              ></FaPencilAlt> : null
            }

          </div>
          <Modal
            isOpen={isOpen}
            toggle={toggle}
            size="lg"
            style={{ fontFamily: "Montserrat" }}
          >
            <ModalHeader toggle={toggle}>Attention</ModalHeader>
            <ModalBody style={{ fontWeight: 500 }}>
              Do you really want to delete the selected action?
            </ModalBody>
            <ModalFooter>
              <Button
                style={{ fontWeight: 500 }}
                color="danger"
                onClick={async () => {
                  toggle();
                  dispatch({
                    type: IS_LOADING,
                  });
                  // check if deleting a normal action or a grouped action
                  if (props.data.actions === undefined) {
                    await axios.delete(
                      `${API_URL}/clicc/actions/${props.data.id}/?name=${props.data.name}`,
                      tokenConfig()
                    );
                    if (
                      props.opened_action.id !== undefined &&
                      props.opened_action.id === props.data.id
                    ) {
                      dispatch({
                        type: RESET_OPENED_ACTION,
                      });
                    }
                    triggerRefresh()
                  } else {
                    await axios.delete(
                      `${API_URL}/clicc/grouped_actions/${props.data.id}/`,
                      tokenConfig()
                    );
                    triggerRefresh()
                  }
                  dispatch({
                    type: IS_LOADING,
                  });
                  SuccessAlert("Action deleted successfully");
                }}
              >
                Delete
              </Button>
              <Button
                color="secondary"
                onClick={toggle}
                style={{ fontWeight: 500 }}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={isOpenGroupedAction}
            toggle={toggleGroupedAction}
            size="lg"
            style={{ fontFamily: "Montserrat" }}
          >
            <ModalHeader toggle={toggleGroupedAction}>
              Grouped action
            </ModalHeader>
            <ModalBody>
              {listGroupedAction === null ? (
                <FaSpinner className="spinner"></FaSpinner>
              ) : listGroupedAction.length === 0 ? (
                <div style={{ fontWeight: 500 }}>
                  All actions have been deleted
                </div>
              ) : (
                <div>
                  {listGroupedAction.map((card, index) => {
                    return (
                      <Card
                        body
                        style={{
                          marginTop: index === 0 ? 0 : 15,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ fontWeight: 500 }}>{card.name}</div>
                          <div
                            onClick={(e) => openAction(e, card.id, card.actor)}
                            style={{
                              width: "fit-content",
                              backgroundColor: props.layout.color_sidebar,
                              color: "white",
                              fontFamily: "Montserrat",
                              fontWeight: 500,
                              fontSize: 16,
                              padding: "6px 15px 4px 15px",
                              borderRadius: 10,
                              cursor: "pointer",
                            }}
                          >
                            Open
                          </div>
                        </div>
                      </Card>
                    );
                  })}
                </div>
              )}
            </ModalBody>
          </Modal>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(ActionColumn);
