export function getCheckedNames(obj) {
    // manage old actions
    if (obj === null || obj === undefined) {
        return ""
    } else {
        // Helper function to check if all elements in an array are checked
        function allChecked(arr) {
            return arr.every(item => item.checked);
        }

        // Helper function to concatenate names of checked elements
        function concatenateChecked(arr) {
            return arr.filter(item => item.checked).map(item => item.nome).join(", ");
        }

        let result = [];

        // Traverse the object
        for (const [parent, children] of Object.entries(obj)) {
            let parentResult = [];
            let allParentsChecked = true;

            for (const [key, childArray] of Object.entries(children)) {
                if (allChecked(childArray)) {
                    parentResult.push("Circoscrizione " + key);
                } else {
                    allParentsChecked = false;
                    let checkedNames = concatenateChecked(childArray);
                    if (checkedNames) {
                        parentResult.push(checkedNames);
                    }
                }
            }

            if (allParentsChecked) {
                result.push(parent);
            } else {
                result.push(parentResult.join(", "));
            }
        }

        return result.join(", ");
    }
}