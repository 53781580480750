import React, { useEffect } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { Row, Col, Card } from "reactstrap";
import { FaEuroSign } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import Plotly from "plotly.js-dist";
import { usePlotlyConfig } from "../../../../../utils/usePlotlyConfig";
import { nanoid } from "nanoid";
import { recurrentUpdate } from "../../../../../utils/recurrentUpdate";

function NumericWithPie(props) {
  const dispatch = useDispatch();
  const plotlyConfig = usePlotlyConfig();

  const idPie = nanoid();

  useEffect(() => {
    var data = [
      {
        values: [
          props.param.default > 100
            ? 100
            : props.param.default < 0
            ? 0
            : props.param.default,
          props.param.default > 100
            ? 0
            : props.param.default < 0
            ? 100
            : 100 - props.param.default,
        ],
        labels: ["Increase", "diff"],
        // domain: { column: 1 },
        marker: {
          colors: [props.layout.color_footer, "#E4E4E4"],
        },
        name: "GHG Emissions",
        hoverinfo: "none",
        hole: 0.7,
        type: "pie",
        textinfo: "none",
      },
    ];

    var layout = {
      annotations: [
        {
          font: {
            size: 25,
            family: "Montserrat",
          },
          showarrow: false,
          text: `${props.param.default}%`,
          x: 0.5,
          y: 0.5,
        },
      ],
      showlegend: false,
      margin: {
        l: 5,
        r: 5,
        b: 5,
        t: 5,
      },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
    };

    Plotly.newPlot(idPie, data, layout, {
      ...plotlyConfig,
      displayModeBar: false,
    });
  }, [props.layout, props.param.default]);

  const updateRedux = (newValue) => {
    newValue = Number(newValue);
    props.data.details.parameters.forEach((obj) =>
      recurrentUpdate(obj, props.param, newValue)
    );
    dispatch({
      type: props.type,
      payload: {
        key: "details",
        value: props.data.details,
      },
    });
  };

  return (
    <Card body style={{ marginTop: 20 }}>
      <Row style={{ margin: 0 }}>
        <Col lg="4" md="12" style={{ padding: 0 }}>
          <div style={{ fontFamily: "Montserrat" }}>
            {props.param.paramName}
          </div>
          <div
            style={{ fontFamily: "Montserrat", fontWeight: 500, fontSize: 12 }}
          >
            {props.param.paramDescription}
          </div>
          <TextField
            type="number"
            label={<div>Value&nbsp;</div>}
            value={props.param.default}
            inputProps={{
              min: props.param.min,
              max: props.param.max,
              style: {
                fontWeight: 500,
                fontFamily: "Montserrat",
              },
            }}
            InputLabelProps={{
              style: {
                fontWeight: 500,
                fontFamily: "Montserrat",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div>{props.param.uom}</div>
                </InputAdornment>
              ),
            }}
            style={{ width: "100%", marginTop: 15 }}
            onChange={(e) => updateRedux(e.target.value)}
            onBlur={(e) => {
              if (e.target.value > props.param.max) {
                e.target.value = props.param.max;
              } else if (e.target.value < props.param.min) {
                e.target.value = props.param.min;
              }
              updateRedux(e.target.value);
            }}
          />
        </Col>
        <Col
          lg="8"
          md="12"
          style={{ padding: 0, display: "flex", justifyContent: "center" }}
        >
          <div
            id={idPie}
            style={{
              width: "auto",
              height: "120px",
              display: "flex",
              justifyContent: "center",
            }}
          ></div>
        </Col>
      </Row>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(NumericWithPie);
