import { roundAtMost2Digits } from "./roundAtMost2Digits";
import moment from "moment";
import { cloneDeep } from "lodash"
import { getYearlyEmissionGroupedAction } from "./getYearlyEmissionGroupedAction";
import { getRangeNumbers } from "./getRangeNumbers";
import { getCheckedNames } from "./getCheckedNames";

export const extendGroupedAction = (grouped_action, actors, start_date_wp = null, end_date_wp = null) => {
  // initialize list of actors
  grouped_action["actors"] = [];
  grouped_action["actors_business_names"] = [];
  // initialize list of action_types
  grouped_action["action_types"] = [];
  // initialize emissions
  grouped_action["emissions"] = [];
  // initialize macro_area and sector
  grouped_action["macro_area_sector"] = [];
  // initialize action_scale_detail
  grouped_action["action_scale_detail"] = [];

  grouped_action["actions_list"].forEach((obj) => {
    // append actor
    if (obj.actor !== null) {
      var currentActor = actors.find((act) => act.id === obj.actor);
      if (
        currentActor !== undefined &&
        !grouped_action["actors"].includes(currentActor.id)
      ) {
        grouped_action["actors"].push(currentActor.id);
        grouped_action["actors_business_names"].push(currentActor.actor_business_name);
      }
    }
    // append macro_area and sector with separator ___
    grouped_action["macro_area_sector"].push(`${obj.macro_area}___${obj.sector}`)
    // append action_scale_detail
    grouped_action["action_scale_detail"].push(getCheckedNames(obj.action_scale_detail))
  });

  // assign macro_area and sector based on max count occurrences
  var occurrences = {};
  grouped_action["macro_area_sector"].forEach((s) => {
    occurrences[s] = (occurrences[s] || 0) + 1;
  });
  var maxItem = Object.keys(occurrences).reduce((a, b) => (occurrences[a] > occurrences[b] ? a : b));
  grouped_action["macro_area"] = maxItem.split("___")[0]
  grouped_action["sector"] = maxItem.split("___")[1]


  // append action types
  grouped_action["actions_list"].forEach((obj) => {
    if (
      obj.details !== undefined &&
      obj.details.subActionName !== undefined &&
      !grouped_action["action_types"].includes(obj.details.subActionName)
    ) {
      grouped_action["action_types"].push(obj.details.subActionName);
    }
  });
  // get min start_date
  var start_date = grouped_action["actions_list"]
    .map((obj) => (obj.start_date === null ? null : moment(obj.start_date)))
    .filter((d) => d !== null);

  if (start_date.length > 0) {
    grouped_action["start_date"] = moment
      .min(start_date)
      .format("YYYY-MM-DD");
  } else {
    grouped_action["start_date"] = null;
  }
  // get max end_date
  var end_date = grouped_action["actions_list"]
    .map((obj) => (obj.end_date === null ? null : moment(obj.end_date)))
  if (end_date.includes(null) || grouped_action["actions_list"].length === 0) {
    grouped_action["end_date"] = null;
  } else {
    grouped_action["end_date"] = moment.max(end_date).format("YYYY-MM-DD");
  }
  // append details, compute sum of financials and append financial details
  grouped_action["financials_total_investment"] = 0;
  grouped_action["actions_list"].forEach((obj) => {
    grouped_action["financials_total_investment"] +=
      obj.financials_total_investment;
  });

  // compute financial capex as array
  var dict_years_capex = {}
  grouped_action["actions_list"].forEach((obj) => {
    var start_year = moment(obj.start_date).year()
    var end_year = obj.end_date === null ? 2019 : moment(obj.end_date).year()
    var list_years = getRangeNumbers(start_year, end_year)

    list_years.forEach((y, idx) => {
      if (!Object.keys(dict_years_capex).includes(String(y))) {
        dict_years_capex[y] = 0
      }
      if (obj.financials_capex[idx] !== undefined) {
        dict_years_capex[y] += obj.financials_capex[idx]
      }
    })
  });
  var ordered_years_capex = Object.keys(dict_years_capex).sort()
  grouped_action["financials_capex"] = []
  if (ordered_years_capex.length > 0) {
    ordered_years_capex.forEach((y, idx) => {
      grouped_action["financials_capex"][idx] = dict_years_capex[y]
    })
  }

  // compute financial opex as array
  var dict_years_opex = {}
  grouped_action["actions_list"].forEach((obj) => {
    var start_year = moment(obj.start_date).year()
    var end_year = obj.end_date === null ? 2019 : moment(obj.end_date).year()
    var list_years = getRangeNumbers(start_year, end_year)

    list_years.forEach((y, idx) => {
      if (!Object.keys(dict_years_opex).includes(String(y))) {
        dict_years_opex[y] = 0
      }
      if (obj.financials_opex[idx] !== undefined) {
        dict_years_opex[y] += obj.financials_opex[idx]
      }
    })
  });
  var ordered_years_opex = Object.keys(dict_years_opex).sort()
  grouped_action["financials_opex"] = []
  if (ordered_years_opex.length > 0) {
    ordered_years_opex.forEach((y, idx) => {
      grouped_action["financials_opex"][idx] = dict_years_opex[y]
    })
  }


  grouped_action["financials_investment_detail"] = [];
  grouped_action["actions_list"].forEach((obj) => {
    grouped_action["financials_investment_detail"].push(
      ...obj.financials_investment_detail
    );
  });
  grouped_action["total_co2_equivalent_avoided"] = 0;
  grouped_action["actions_list"].forEach((obj) => {
    grouped_action["total_co2_equivalent_avoided"] = roundAtMost2Digits(
      grouped_action["total_co2_equivalent_avoided"] + obj.total_co2_equivalent_avoided
    );
  });
  // append emissions
  grouped_action["actions_list"].forEach((obj) => {
    // check for old actions
    if (obj.emissions !== null) {
      if (grouped_action["emissions"].length === 0) {
        grouped_action["emissions"] = cloneDeep(obj.emissions)
      } else {
        grouped_action["emissions"].forEach(em_obj => {
          obj.emissions.forEach(int_em => {
            if (em_obj.gas === int_em.gas) {
              em_obj["emissions_avoided"] += int_em["emissions_avoided"]
              em_obj["co2_equivalent_avoided"] += int_em["co2_equivalent_avoided"]
            }
          })
        })
      }
    }
  })

  // compute average yearly emission avoided: this computation is done daily. Then take sum for each year, and at the end take the mean
  const gases = grouped_action["emissions"].map(obj => obj.gas)
  gases.forEach(gas => {

    var result = getYearlyEmissionGroupedAction(grouped_action["actions_list"], gas, "emissions_avoided", start_date_wp, end_date_wp)
    var result_in_co2_eq = getYearlyEmissionGroupedAction(grouped_action["actions_list"], gas, "co2_equivalent_avoided", start_date_wp, end_date_wp)

    var final_average = Object.values(result).reduce((a, b) => a + b, 0) / Object.keys(result).length
    var final_average_in_co2_equivalent = Object.values(result_in_co2_eq).reduce((a, b) => a + b, 0) / Object.keys(result_in_co2_eq).length

    //  final_average can be NaN when len(unique years) = 0 (this happens when the grouped action has more actions inside)
    if (String(final_average) !== "NaN") {
      grouped_action[`average_yearly_emission_avoided_${gas}`] = final_average
    } else {
      grouped_action[`average_yearly_emission_avoided_${gas}`] = 0
    }
    if (String(final_average_in_co2_equivalent) !== "NaN") {
      grouped_action[`average_yearly_emission_avoided_${gas}_in_co2_equivalent`] = final_average_in_co2_equivalent
    } else {
      grouped_action[`average_yearly_emission_avoided_${gas}_in_co2_equivalent`] = 0
    }
  })

  // compute the sum of all the above (shown in My Actions)
  var sum_co2_equivalent = 0
  gases.forEach(gas => {
    sum_co2_equivalent += grouped_action[`average_yearly_emission_avoided_${gas}_in_co2_equivalent`]
  })
  if (String(sum_co2_equivalent) !== "NaN") {
    grouped_action["average_yearly_emission_avoided"] = sum_co2_equivalent
  } else {
    grouped_action["average_yearly_emission_avoided"] = 0
  }

  return grouped_action;
};
