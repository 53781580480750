import React, { useState } from "react";
import { FaPencilAlt } from "react-icons/fa";

function UpdateDetail(props) {
  return (
    <div>
      <FaPencilAlt
        style={{
          color: "#EE9B1B",
          cursor: "pointer",
        }}
        onClick={(e) => {
          props.setIsOpen(true);
          props.setUpdate(true);
          props.setMacroArea(props.data.macro_area);
          props.setSettore(props.data.settore);
          props.setSottosettore(props.data.sottosettore);
          props.setData(props.data);
        }}
      ></FaPencilAlt>
    </div>
  );
}

export default UpdateDetail;
