import React, { useEffect, useRef, useState } from "react";
import { useWindowDimensions } from "../../../../../utils/useWindowDimensions";
import { connect } from "react-redux";
import TitleCard from "../../../../../components/TitleCard/TitleCard";
import { getHeightWithFooterWONavbar } from "../../../../../utils/getHeightWithFooterWONavbar";
import { roundAtMost2Digits } from "../../../../../utils/roundAtMost2Digits";
import { AgGridReact } from "ag-grid-react";
import { Row, Col } from "reactstrap";
import { cloneDeep } from "lodash";
import DateRenderer from "./../Pathways/DateRenderer";
import { getYearlyEmissionGroupedAction } from "../../../../../utils/getYearlyEmissionGroupedAction";
import { getYearlyEmissionAction } from "../../../../../utils/getYearlyEmissionAction";
import { cleanRatio } from "../../../../../utils/cleanRatio";

function Content(props) {
  const { width, height } = useWindowDimensions();
  const [rowData, setRowData] = useState(null);

  const grid = useRef();

  const headerHeightGetter = () => {
    var columnHeaderTexts = [
      ...document.querySelectorAll(".ag-header-cell-text"),
    ];
    var clientHeights = columnHeaderTexts.map(
      (headerText) => headerText.clientHeight
    );
    var tallestHeaderTextHeight = Math.max(...clientHeights);
    return tallestHeaderTextHeight;
  };

  const headerHeightSetter = (myGrid) => {
    var padding = 20;
    var height = headerHeightGetter() + padding;
    myGrid.api.setHeaderHeight(height);
  };


  useEffect(() => {
    if (
      props.myActions !== null &&
      props.data.pathways
        .map((p) => p.actions.length)
        .reduce((partialSum, a) => partialSum + a, 0) > 0
    ) {


      var emission_types = ["co2_equivalent_avoided"]
      // initialize yearly emissions (used later for totals)
      var yearly_emissions = {}
      var yearly_emissions_by_id = {}
      const generate_key = (gas, emission_type) => {
        var key_name = `average_yearly_emission_avoided_${gas}`
        if (emission_type === "co2_equivalent_avoided") {
          key_name += "_in_co2_equivalent"
        }
        return key_name
      }
      props.data.gases.forEach(gas => {
        emission_types.forEach(emission_type => {
          yearly_emissions[generate_key(gas, emission_type)] = {}
          yearly_emissions_by_id[generate_key(gas, emission_type)] = {}
        })
      })

      // compute emissions: this is used to later compute € / CO2 avoided
      var yearly_values
      props.data.pathways.forEach((pathway) => {
        pathway.actions.forEach((action_redux, index) => {
          var row = cloneDeep(
            props.myActions.find((obj) => obj.id === action_redux.action_id)
          );
          if (row !== undefined) {
            // compute yearly emissions and add values to yearly_emissions
            props.data.gases.forEach(gas => {
              emission_types.forEach(emission_type => {
                if (row.actions !== undefined) {
                  yearly_values = getYearlyEmissionGroupedAction(
                    row["actions_list"],
                    gas,
                    emission_type,
                    props.data.start_date,
                    props.data.end_date
                  )
                } else {
                  yearly_values = getYearlyEmissionAction(
                    row,
                    gas,
                    emission_type,
                    props.data.start_date,
                    props.data.end_date
                  )
                }
                yearly_emissions_by_id[generate_key(gas, emission_type)][action_redux.id] = yearly_values
                Object.keys(yearly_values).forEach(year => {
                  if (yearly_emissions[generate_key(gas, emission_type)][year] === undefined) {
                    yearly_emissions[generate_key(gas, emission_type)][year] = yearly_values[year]
                  } else {
                    yearly_emissions[generate_key(gas, emission_type)][year] += yearly_values[year]
                  }
                })
              })
            })
          }
        });
      })

      const findValue = (arr) => {
        var v = arr.find(element => element !== 0)
        if (v !== undefined) {
          return v
        } else {
          return 0
        }
      }

      // compute the sum of total investment, capex and opex (used later to get the %)
      var sum_total_investment = 0;
      var sum_total_capex = 0;
      var sum_total_opex = 0;
      props.data.pathways.forEach((pathway) => {
        pathway.actions.forEach((action_redux, index) => {
          var row = cloneDeep(
            props.myActions.find((obj) => obj.id === action_redux.action_id)
          );
          if (row !== undefined) {
            sum_total_investment += row.financials_total_investment;
            sum_total_capex += findValue(row.financials_capex);
            sum_total_opex += findValue(row.financials_opex);
          }
        });
      });

      // create rows
      var rows = [];
      props.data.pathways.forEach((pathway) => {
        pathway.actions.forEach((action_redux, index) => {
          var row = cloneDeep(
            props.myActions.find((obj) => obj.id === action_redux.action_id)
          );
          if (row !== undefined) {
            var id_row = row.actions === undefined ? `action_${row.id}` : `grouped_action_${row.id}`
            var emissions_row = 0
            Object.keys(yearly_emissions_by_id).forEach(k => {
              if (Object.keys(yearly_emissions_by_id[k]).includes(id_row)) {
                var sum_key = Object.values(yearly_emissions_by_id[k][id_row]).reduce((a, b) => a + b, 0)
                var num_years = Object.keys(yearly_emissions_by_id[k][id_row]).length
                emissions_row += sum_key / num_years
              }
            })

            rows.push({
              ...row,
              id: id_row,
              financials_capex: findValue(row.financials_capex),
              financials_opex: findValue(row.financials_opex),
              percentage_total_investment:
                cleanRatio(row.financials_total_investment, sum_total_investment),
              percentage_capex: cleanRatio(findValue(row.financials_capex), sum_total_capex),
              percentage_opex: cleanRatio(findValue(row.financials_opex), sum_total_opex),
              eur_capex: cleanRatio(findValue(row.financials_capex), emissions_row),
              eur_opex: cleanRatio(findValue(row.financials_opex), emissions_row),
              eur_total: cleanRatio(row.financials_total_investment, emissions_row),
            });
          }
        });
      });
      // add total
      if (rows.length > 0) {
        var emissions_total = 0
        Object.keys(yearly_emissions).forEach(k => {
          var sum_key = Object.values(yearly_emissions[k]).reduce((a, b) => a + b, 0)
          var num_years = Object.keys(yearly_emissions[k]).length
          emissions_total += sum_key / num_years

        })
        var total = {
          id: "total",
          name: "Total",
          financials_capex: sum_total_capex,
          financials_opex: sum_total_opex,
          financials_total_investment: sum_total_investment,
          percentage_total_investment: 1,
          percentage_capex: 1,
          percentage_opex: 1,
          eur_capex: cleanRatio(sum_total_capex, emissions_total),
          eur_opex: cleanRatio(sum_total_opex, emissions_total),
          eur_total: cleanRatio(sum_total_investment, emissions_total),
        };
        rows.push(total);
      }
      setRowData(rows);
    } else {
      setRowData(null);
    }
  }, [props.data.pathways, props.myActions]);

  const valuePercentage = (value) => {
    if (value === undefined) {
      return null;
    } else if (String(value) === "NaN") {
      return "0%";
    } else if (String(value) === "Infinity") {
      return "100%";
    } else {
      return `${roundAtMost2Digits(value * 100)}%`;
    }
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#E4E6E8",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        paddingLeft: 0,
        paddingBottom: props.layout.footer_height,
        overflow: "auto",
      }}
    >
      {/* workspace without padding */}
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 10,
        }}
      >
        {/* workspace */}
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <Row style={{ margin: 0 }}>
            <Col xs="12" style={{ padding: 0 }}>
              <div
                style={{
                  width: "100%",
                  padding: 10,
                  height: height < 850 ? height * 0.69 : height * 0.74,
                }}
              >
                <div
                  style={{
                    ...props.layout.generic_card,
                    width: "100%",
                    position: "relative",
                    display: "flex",
                    flexFlow: "column",
                    height: "100%",
                  }}
                >
                  <TitleCard title={"Investment plan"}></TitleCard>
                  {rowData === null ? (
                    <div
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                        padding: 15,
                      }}
                    >
                      Insert at least one action from Pathways
                    </div>
                  ) : (
                    <div
                      className="excel-style ag-theme-balham"
                      style={{
                        height: "90%",
                        padding: "15px 15px 0px 15px",
                      }}
                    >
                      <AgGridReact
                        onFirstDataRendered={(params) => {
                          headerHeightSetter(params)
                        }
                        }
                        onColumnResized={(params) => headerHeightSetter(params)}
                        rowData={rowData}
                        ref={grid}
                        frameworkComponents={{
                          dateRenderer: DateRenderer,
                        }}
                        enableRangeSelection={true}
                        suppressMovableColumns={true}
                        defaultColDef={{
                          flex: 1,
                          resizable: true,
                          filter: true,
                          filterParams: {
                            buttons: ["reset"],
                            newRowsAction: "keep",
                            caseSensitive: true
                          },
                          suppressMenu: true,
                          // wrap up header
                          headerComponentParams: {
                            template:
                              '<div class="ag-cell-label-container" role="presentation">' +
                              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation" style="justify-content: center; text-align: center;">' +
                              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                              "  </div>" +
                              "</div>",
                          },
                          cellStyle: (params) => {
                            var style = {
                              cursor: "default",
                              display: "flex",
                              alignItems: "center",
                            };
                            if (params.column.colId !== "name") {
                              style["justifyContent"] = "center";
                            }
                            if (params.data.id === "total") {
                              style["fontWeight"] = "bold";
                              style["backgroundColor"] =
                                "rgba(217, 217, 217, 0.5)";
                            }

                            return style;
                          },
                        }}
                        columnDefs={[
                          {
                            field: "name",
                            headerName: "Actions",
                            cellClass: "darker-border",
                            headerClass: "darker-border",
                            minWidth: 250,
                          },
                          // {
                          //   headerName: "Date",
                          //   children: [
                          //     {
                          //       field: "start_date",
                          //       headerName: "start",
                          //       minWidth: 120,
                          //     },
                          //     {
                          //       field: "end_date",
                          //       headerName: "end",
                          //       cellClass: "darker-border",
                          //       headerClass: "darker-border",
                          //       minWidth: 120,
                          //     },
                          //   ],
                          // },
                          {
                            headerName: "CAPEX",
                            children: [
                              {
                                field: "financials_capex",
                                headerName: "€",
                                cellClass: "darker-border",
                                headerClass: "darker-border",
                                minWidth: 120,
                                valueFormatter: (params) => {
                                  if (params.value !== undefined) {
                                    return `${params.value.toLocaleString("eng", {
                                      maximumFractionDigits: 0,
                                    })}€`;
                                  } else {
                                    return null;
                                  }
                                },
                              },
                              {
                                field: "percentage_capex",
                                headerName: "%",
                                cellClass: "darker-border",
                                headerClass: "darker-border",
                                minWidth: 80,
                                valueFormatter: (params) => {
                                  return params.value.toLocaleString("eng", {
                                    style: 'percent',
                                    maximumFractionDigits: 2,
                                  })
                                },
                              },
                              {
                                field: "eur_capex",
                                headerName: "€/CO2 eq. [€/(t/y)]",
                                cellClass: "darker-border",
                                headerClass: "darker-border",
                                minWidth: 100,
                                valueFormatter: (params) => {
                                  if (params.value !== undefined) {
                                    return params.value.toLocaleString("eng", {
                                      maximumFractionDigits: 2,
                                    });
                                  } else {
                                    return null;
                                  }
                                },
                              },
                            ],
                          },
                          {
                            headerName: "OPEX",
                            children: [
                              {
                                field: "financials_opex",
                                headerName: "€",
                                cellClass: "darker-border",
                                headerClass: "darker-border",
                                minWidth: 120,
                                valueFormatter: (params) => {
                                  if (params.value !== undefined) {
                                    return `${params.value.toLocaleString("eng", {
                                      maximumFractionDigits: 0,
                                    })}€`;
                                  } else {
                                    return null;
                                  }
                                },
                              },
                              {
                                field: "percentage_opex",
                                headerName: "%",
                                cellClass: "darker-border",
                                headerClass: "darker-border",
                                minWidth: 80,
                                valueFormatter: (params) => {
                                  return params.value.toLocaleString("eng", {
                                    style: 'percent',
                                    maximumFractionDigits: 2,
                                  })
                                },
                              },
                              {
                                field: "eur_opex",
                                headerName: "€/CO2 eq. [€/(t/y)]",
                                cellClass: "darker-border",
                                headerClass: "darker-border",
                                minWidth: 100,
                                valueFormatter: (params) => {
                                  if (params.value !== undefined) {
                                    return params.value.toLocaleString("eng", {
                                      maximumFractionDigits: 2,
                                    });
                                  } else {
                                    return null;
                                  }
                                },
                              },
                            ],
                          },
                          {
                            headerName: "TOTAL COST",
                            children: [
                              {
                                field: "financials_total_investment",
                                headerName: "€",
                                cellClass: "darker-border",
                                headerClass: "darker-border",
                                minWidth: 120,
                                valueFormatter: (params) => {
                                  if (params.value !== undefined) {
                                    return `${params.value.toLocaleString("eng", {
                                      maximumFractionDigits: 0,
                                    })}€`;
                                  } else {
                                    return null;
                                  }
                                },
                              },
                              {
                                field: "percentage_total_investment",
                                headerName: "%",
                                cellClass: "darker-border",
                                headerClass: "darker-border",
                                minWidth: 80,
                                valueFormatter: (params) => {
                                  return params.value.toLocaleString("eng", {
                                    style: 'percent',
                                    maximumFractionDigits: 2,
                                  })
                                },
                              },
                              {
                                field: "eur_total",
                                headerName: "€/CO2 eq. [€/(t/y)]",
                                cellClass: "darker-border",
                                headerClass: "darker-border",
                                minWidth: 100,
                                valueFormatter: (params) => {
                                  if (params.value !== undefined) {
                                    return params.value.toLocaleString("eng", {
                                      maximumFractionDigits: 2,
                                    });
                                  } else {
                                    return null;
                                  }
                                },
                              },
                            ],
                          },
                        ]}
                      ></AgGridReact>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Content);
