import { GET_ERRORS, IS_LOADING } from "./types";

export const initialCalls = () => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
    });
    // console.log("initial calls here")
    dispatch({
      type: IS_LOADING,
    });
  } catch (err) {
    dispatch({
      type: IS_LOADING,
    });
    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors,
    });
  }
};
