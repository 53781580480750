import Swal from "sweetalert2";

export default function ErrorAlert(msg) {
  Swal.fire({
    title: msg,
    icon: "error",
    confirmButtonText: "OK",
    customClass: {
      confirmButton: "btn btn-info",
      title: "MuiTypography-h6"
    },
    buttonsStyling: false
  });
}
