import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { MenuItem, Select, TextField } from "@mui/material";
import { getHeightWithFooterWONavbar } from "../../../../utils/getHeightWithFooterWONavbar";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import Editor from "../../../../components/Editor/Editor";
import { connect } from "react-redux";

function Commitments(props) {
  const { width, height } = useWindowDimensions();
  const [editorData, setEditorData] = useState(null);


  useEffect(() => {
    if (editorData === null) {
      setEditorData(props.data.commitments);
    }
  }, [props.data.commitments]);

  useEffect(() => {
    setEditorData(props.data.commitments);
  }, [props.reset]);

  return (
    <div
      style={{
        width: "100%",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        overflow: "auto",
      }}
    >
      <Row style={{ margin: 0, marginTop: 15, height: 100 }}>
        <Col xs="12">
          {editorData !== null ? (
            <Editor
              DEFAULT_INITIAL_DATA={editorData}
              type={props.type}
              name={"commitments"}
            ></Editor>
          ) : (
            <div>
              <span
                onClick={() => {
                  props.setActiveTab(1);
                }}
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecorationLine: "underline",
                }}
              >
                Open
              </span>{" "}
              a workspace to preview contract's details
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Commitments);
