import React, { useState, useEffect } from "react";
import NumericWithPie from "./NumericWithPie";
import Numeric from "./Numeric";
import Dropdown from "./Dropdown";
// import SelectBuilding from "./SelectBuildingOLD";
import SelectBuilding from "./SelectBuilding";
import SelectRoads from "./SelectRoads";
import TableWithAPI from "./TableWithAPI/TableWithAPI";
import TableEditable from "./TableEditable/TableEditable";

function SingleParam(props) {
  const [dataTableWithAPI, setDataTableWithAPI] = useState(null)
  const [dataSelectBuilding, setDataSelectBuilding] = useState(null)

  // inside TableWithAPI the information inside redux is passed only at the beginning
  // inside the component there is a ref which is used to display the UI. onclick, redux is changed but there is no rerender
  useEffect(() => {
    if (props.param.input_type === "table_with_api") {
      setDataTableWithAPI(props.data)
    } else {
      setDataTableWithAPI(null)
    }
  }, [props.param])




  useEffect(() => {
    if (props.param.input_type === "select_building") {
      setDataSelectBuilding(props.data)
    } else {
      setDataSelectBuilding(null)
    }
  }, [props.param])

  return (
    <>
      {props.param.input_type === "numeric" && props.param.show_pie === true ? (
        <NumericWithPie
          data={props.data}
          param={props.param}
          type={props.type}
        ></NumericWithPie>
      ) : props.param.input_type === "numeric" &&
        props.param.show_pie === false ? (
        <Numeric
          data={props.data}
          param={props.param}
          type={props.type}
        ></Numeric>
      ) : props.param.input_type === "dropdown" ? (
        <Dropdown
          data={props.data}
          param={props.param}
          type={props.type}
        ></Dropdown>
      )
        : props.param.input_type === "select_building" && dataSelectBuilding !== null ? (
          <SelectBuilding
            data={dataSelectBuilding}
            param={props.param}
            type={props.type}
          ></SelectBuilding>
        )
          : props.param.input_type === "select_roads" ? (
            <SelectRoads
              data={props.data}
              param={props.param}
              type={props.type}
            ></SelectRoads>
          ) : props.param.input_type === "table_with_api" && dataTableWithAPI !== null ? (
            <TableWithAPI
              data={dataTableWithAPI}
              param={props.param}
              type={props.type}
            ></TableWithAPI>
          ) : props.param.input_type === "table_editable" ? (
            <TableEditable
              data={props.data}
              param={props.param}
              type={props.type}
            ></TableEditable>
          ) : null}
    </>
  );
}

export default SingleParam;
