import React from "react"
import Chart from "./Chart";
import Table from "./Table";

const SingleOutput = (props) => {
    return (
        <>
            {props.output.output_type === "chart" ?
                <Chart output={props.output}></Chart>
                : props.output.output_type === "table" ?
                    <Table output={props.output}></Table>
                    : null}
        </>
    )
};

export default SingleOutput;
