import React, { useState, useEffect, useRef } from "react"
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import { FaSpinner } from "react-icons/fa";
import ActionSelect from "./ActionSelect"

const SelectActionInventory = (props) => {
    const [rowData, setRowData] = useState(null)

    useEffect(() => {
        setRowData(props.actionsInventory)
    }, [props.actionsInventory])

    const grid = useRef()
    return (
        <div>
            {rowData === null ?
                <FaSpinner className="spinner"></FaSpinner>
                :

                <div
                    className="excel-style ag-theme-balham"
                    style={{
                        height: 200,
                        padding: "15px 15px 0px 15px",
                    }}
                >
                    <AgGridReact
                        ref={grid}
                        rowData={rowData}
                        frameworkComponents={{
                            actionSelect: ActionSelect,
                        }}
                        defaultColDef={{
                            flex: 1,
                            filter: true,
                            filterParams: {
                                buttons: ["reset"],
                                newRowsAction: "keep",
                                caseSensitive: true,
                            },
                        }}
                    >

                        <AgGridColumn
                            maxWidth={35}
                            minWidth={35}
                            cellRenderer='actionSelect'
                            cellRendererParams={{
                                userAnagrafica: props.userAnagrafica
                            }}
                        ></AgGridColumn>
                        <AgGridColumn field="id" sort="asc"></AgGridColumn>
                    </AgGridReact>
                </div>}


        </div>
    )
};

export default SelectActionInventory;
