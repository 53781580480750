import React, { useState, useEffect, useRef } from "react";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { connect, useDispatch } from "react-redux";
import { getHeightWithFooterWONavbar } from "../../../../utils/getHeightWithFooterWONavbar";
import classnames from "classnames";
import {
  Card,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Scenario from "../../../Workspace/NewWorkspace/components/Scenario/Content";
import Pathways from "../../../Workspace/NewWorkspace/components/Pathways/Content";
import InvestmentPlan from "../../../Workspace/NewWorkspace/components/InvestmentPlan/Content";
import Barriers from "../../../Workspace/NewWorkspace/components/Barriers/Content";
import Baseline from "../../../Workspace/NewWorkspace/components/Baseline/Content";
import Target from "../../../Workspace/NewWorkspace/components/Target/Content";
import Others from "../../../Workspace/NewWorkspace/components/Others/Content";
import Update from "../../../Workspace/NewWorkspace/components/Save/Content";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import { tokenConfig } from "../../../../utils/tokenConfig";
import {
  API_URL,
  UPDATE_OPENED,
  SET_OPENED,
  RESET_OPENED,
} from "../../../../actions/types";
import { extendGroupedAction } from "../../../../utils/extendGroupedAction";
import { FaSpinner } from "react-icons/fa";
import { computeAvgEmission } from "../../../../utils/computeAvgEmission";
import store from "../../../../../store"
import { cloneDeep } from "lodash"
import { generateCCDecarbonizationAction } from "../../../../utils/generateCCDecarbonizationAction";

function Content(props) {
  const [activeTab, setActiveTab] = useState("pathways");
  const [myActions, setMyActions] = useState(null);
  const { width, height } = useWindowDimensions();

  const dispatch = useDispatch();
  const history = useHistory();
  const actorsRef = useRef()
  const actionsRef = useRef()
  const groupedActionsRef = useRef()

  const key_name = `opened_${window.location.href.split("/?id=")[0].split("_modify_")[1]
    }`;

  // define function to get the workspace
  const getWorkspace = async () => {
    // if there is something in local storage remove it at this point
    localStorage.removeItem(key_name);
    // if there is an id in the url, open it
    if (window.location.href.includes("?id=")) {
      // if there is an actor in another tab and you delete him, the call will crash
      try {
        const res = await axios.get(
          `${API_URL}/clicc/workspace/${window.location.href.split("?id=")[1]
          }/`,
          tokenConfig()
        );
        dispatch({
          type: SET_OPENED,
          payload: res.data,
        });
      } catch {
        // see above
        history.push(`/clicc/dashboard/workspace_modify_workspace`);
        dispatch({
          type: RESET_OPENED,
        });
      }
    }
  };

  // define function to get data
  const getData = async (start_date_wp, end_date_wp, get_actions) => {
    // make calls all together
    const list_promises = [
      axios.post(
        `${API_URL}/clicc/workspace/updated_cc_functions/`,
        {
          pathways: store.getState()[`${props.type.split("_")[1].toLowerCase()}_workspace`].pathways,
          use_motherfunctions_ccs: store.getState()[`${props.type.split("_")[1].toLowerCase()}_workspace`].use_motherfunctions_ccs
        },
        tokenConfig()
      )
    ]

    // to speed up, get actions only on did mount and when changes are detected from other tabs
    if (get_actions === true) {
      list_promises.push(
        ...[
          axios.get(
            `${API_URL}/clicc/actions/`,
            tokenConfig()
          ),
          axios.get(
            `${API_URL}/clicc/grouped_actions/`,
            tokenConfig()
          ),
          axios.get(
            `${API_URL}/clicc/actors/`,
            tokenConfig()
          )
        ]
      )
    }

    Promise.all(list_promises).then(function (values) {
      const resMotherFn = values[0]
      dispatch({
        type: props.type,
        payload: {
          key: "CCs",
          value: resMotherFn.data.CCs,
        },
      });

      let resActions
      if (get_actions === true) {
        resActions = values[1].data
        actionsRef.current = cloneDeep(resActions)
      } else {
        resActions = cloneDeep(actionsRef.current)
      }
      const accountableActions = resActions.filter(a => a.is_accountable === true)
      var accountableActionsExtended = []
      accountableActions.forEach(a => {
        if (resMotherFn.data.actions.map(a_mf => a_mf.id).includes(a.id)) {
          accountableActionsExtended.push(computeAvgEmission(resMotherFn.data.actions.find(a_mf => a_mf.id === a.id), start_date_wp, end_date_wp))
        } else {
          accountableActionsExtended.push(computeAvgEmission(a, start_date_wp, end_date_wp))
        }
      })

      let resGroupedActions
      let resActors
      if (get_actions === true) {
        resGroupedActions = values[2].data
        groupedActionsRef.current = cloneDeep(resGroupedActions)
        resActors = values[3].data
        actorsRef.current = cloneDeep(resActors)
      } else {
        resGroupedActions = cloneDeep(groupedActionsRef.current)
        resActors = cloneDeep(actorsRef.current)
      }
      var groupedActionsExtended = [];
      for (const grouped_action of resGroupedActions) {
        var copy_action = cloneDeep(grouped_action)
        copy_action.actions_list.forEach((a, index) => {
          if (resMotherFn.data.actions.map(a_mf => a_mf.id).includes(a.id)) {
            copy_action.actions_list[index] = resMotherFn.data.actions.find(a_mf => a_mf.id === a.id)
          }
        })
        groupedActionsExtended.push(
          extendGroupedAction(copy_action, resActors, start_date_wp, end_date_wp)
        );
      }
      var actionsList = [...accountableActionsExtended, ...groupedActionsExtended]

      // check cc_decarbonization and add as a fake action
      if (resMotherFn.data.cc_decarbonization !== undefined && resMotherFn.data.cc_decarbonization !== 0) {
        var cc_decarbonization_action = generateCCDecarbonizationAction(resMotherFn.data, start_date_wp, end_date_wp, props.data.gases)
        actionsList.push(computeAvgEmission(cc_decarbonization_action, start_date_wp, end_date_wp))
      }

      setMyActions(actionsList);
    });
  };

  // on did mount, get the workspace
  useEffect(async () => {
    await getWorkspace();
  }, []);

  // once the workspace is in redux, get data
  const mounted = useRef();
  useEffect(async () => {
    if (props.data.id !== undefined) {
      if (!mounted.current) {
        mounted.current = true;
        // get also actions
        await getData(props.data.start_date, props.data.end_date, true);
      } else {
        // don't get actions again
        await getData(props.data.start_date, props.data.end_date, false);
      }
    }
  }, [props.data.id, props.data.start_date, props.data.end_date, props.data.pathways, props.data.use_motherfunctions_ccs]);


  // monitor refresh
  useEffect(() => {
    const channel = new BroadcastChannel("clicc-channel");
    channel.addEventListener("message", async (e) => {
      if (e.data === "refresh") {
        if (localStorage.getItem(key_name) !== null) {
          history.push(
            `/clicc/dashboard/workspace_modify_workspace/?id=${localStorage.getItem(
              key_name
            )}`
          );
          await getData(props.data.start_date, props.data.end_date, true);
        } else {
          await getData(props.data.start_date, props.data.end_date, true);
        }
      }
    });
    return () => channel.close();
  }, [props.data.start_date, props.data.end_date]);


  const styleTabPane = {
    height: "95%",
    overflowY: "hidden",
    border: "1px solid #666666",
    marginTop: "-1px",
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
    paddingBottom: 80,
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#E4E6E8",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        paddingLeft: props.layout.width_sidebar,
        paddingBottom: props.layout.footer_height,
        overflow: "auto",
      }}
    >
      {/* workspace without padding */}
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 10,
        }}
      >
        {/* workspace */}
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          {!window.location.href.includes("/?id=") ? (
            <div style={{ padding: 10 }}>
              <Card style={{ padding: "10px 20px" }}>
                <div>
                  <Link to="/clicc/dashboard/workspace_my_workspaces">
                    Open
                  </Link>{" "}
                  a workspace to see the details
                </div>
              </Card>
            </div>
          ) : props.opened_workspace.name === undefined ? (
            <>
              <FaSpinner
                className="spinner"
                style={{ marginTop: "-2px", marginRight: 5 }}
              ></FaSpinner>
              Loading...
            </>
          ) : (
            <div style={{ padding: 10, height: "100%", position: "relative" }}>
              <Nav tabs style={{ width: "98%" }}>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 1 })}
                    onClick={() => {
                      setActiveTab(1);
                    }}
                    style={{ fontSize: 14, backgroundColor: "transparent" }}
                  >
                    SCENARIO
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "pathways" })}
                    onClick={() => {
                      setActiveTab("pathways");
                    }}
                    style={{ fontSize: 14, backgroundColor: "transparent" }}
                  >
                    PATHWAYS
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "investment_plan" })}
                    onClick={() => {
                      setActiveTab("investment_plan");
                    }}
                    style={{ fontSize: 14, backgroundColor: "transparent" }}
                  >
                    INVESTMENT PLAN
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "baseline" })}
                    onClick={() => {
                      setActiveTab("baseline");
                    }}
                    style={{ fontSize: 14, backgroundColor: "transparent" }}
                  >
                    BASELINE
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "target" })}
                    onClick={() => {
                      setActiveTab("target");
                    }}
                    style={{ fontSize: 14, backgroundColor: "transparent" }}
                  >
                    TARGET
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "barriers" })}
                    onClick={() => {
                      setActiveTab("barriers");
                    }}
                    style={{ fontSize: 14, backgroundColor: "transparent" }}
                  >
                    BARRIERS
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "others" })}
                    onClick={() => {
                      setActiveTab("others");
                    }}
                    style={{ fontSize: 14, backgroundColor: "transparent" }}
                  >
                    OTHERS
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "update" })}
                    onClick={() => {
                      setActiveTab("update");
                    }}
                    style={{ fontSize: 14, backgroundColor: "transparent" }}
                  >
                    UPDATE
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab} style={{ height: "100%" }}>
                {/* <TabPane tabId={1} style={styleTabPane}>
                  <Scenario
                    pages={props.pages}
                    data={props.opened_workspace}
                    type={UPDATE_OPENED}
                  ></Scenario>
                </TabPane> */}
                <TabPane tabId={"pathways"} style={styleTabPane}>
                  <Pathways
                    pages={props.pages}
                    data={props.opened_workspace}
                    type={UPDATE_OPENED}
                    myActions={myActions}
                  ></Pathways>
                </TabPane>
                <TabPane tabId={"investment_plan"} style={styleTabPane}>
                  <InvestmentPlan
                    pages={props.pages}
                    data={props.opened_workspace}
                    type={UPDATE_OPENED}
                    myActions={myActions}
                  ></InvestmentPlan>
                </TabPane>
                <TabPane tabId={"baseline"} style={styleTabPane}>
                  <Baseline
                    pages={props.pages}
                    data={props.opened_workspace}
                    type={UPDATE_OPENED}
                  ></Baseline>
                </TabPane>
                <TabPane tabId={"target"} style={styleTabPane}>
                  <Target
                    pages={props.pages}
                    data={props.opened_workspace}
                    type={UPDATE_OPENED}
                    myActions={myActions}
                  ></Target>
                </TabPane>
                <TabPane tabId={"barriers"} style={styleTabPane}>
                  <Barriers
                    pages={props.pages}
                    data={props.opened_workspace}
                    type={UPDATE_OPENED}
                  ></Barriers>
                </TabPane>
                <TabPane tabId={"others"} style={styleTabPane}>
                  <Others
                    pages={props.pages}
                    data={props.opened_workspace}
                    type={UPDATE_OPENED}
                  ></Others>
                </TabPane>
                <TabPane tabId={"update"} style={styleTabPane}>
                  <Update
                    pages={props.pages}
                    data={props.opened_workspace}
                    type={UPDATE_OPENED}
                    api_call="put"
                  ></Update>
                </TabPane>
              </TabContent>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
  opened_workspace: state.opened_workspace,
});

export default connect(mapStateToProps)(Content);
