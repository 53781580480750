import React from "react"
import { connect } from "react-redux";
import TitleCard from "../../../../components/TitleCard/TitleCard";


const CardModalLog = (props) => {
  return (
    <div
      style={{
        ...props.layout.generic_card,
        display: "flex",
        flexFlow: "column",
        height: "100%",
      }}
    >
      <TitleCard
        title={
          <div>
            {props.title}
          </div>
        }
      ></TitleCard>
      <div style={{
        height: "100%",
        display: "flex", justifyContent: "end",
        alignItems: "center",
        fontSize: 25,
        fontWeight: "bold",
        marginTop: "10px",
        marginRight: 20,
        marginBottom: 10
      }}>
        {props.text}
      </div>
    </div>
  )
};

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(CardModalLog);
