import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import SuccessAlert from "../../../../../../../../components/Modals/SuccessAlert";

function DeleteDetail(props) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <FaTimes
        style={{
          color: "#FC2727",
          cursor: "pointer",
        }}
        onClick={(e) => {
          setIsOpen(true);
        }}
      ></FaTimes>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        style={{
          fontFamily: "Montserrat",
          fontWeight: 500,
        }}
      >
        <ModalHeader toggle={toggle}>Delete row</ModalHeader>
        <ModalBody>Are you sure you want to delete this element?</ModalBody>
        <ModalFooter>
          <Button
            color={"danger"}
            onClick={async () => {
              dispatch({
                type: props.type,
                payload: {
                  key: "baseline_areas",
                  value: props.data_redux.baseline_areas.filter(
                    (obj) =>
                      props.data.macro_area !== obj.macro_area ||
                      props.data.settore !== obj.settore ||
                      props.data.sottosettore !== obj.sottosettore
                  ),
                },
              });
              dispatch({
                type: props.type,
                payload: {
                  key: "baseline_data",
                  value: props.data_redux.baseline_data.filter(
                    (obj) =>
                      props.data.macro_area !== obj.macro_area ||
                      props.data.settore !== obj.settore ||
                      props.data.sottosettore !== obj.sottosettore
                  ),
                },
              });
              toggle();
              SuccessAlert("Done!");
            }}
          >
            Delete
          </Button>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default DeleteDetail;
