export const replaceBlocks = (empty, filled) => {
  empty.forEach((tab, indexTab) => {
    tab.forEach((block, indexBlock) => {
      const checkItem = filled[indexTab].find((obj) => obj.id === block.id);
      if (checkItem !== undefined) {
        tab.splice(indexBlock, 1, checkItem);
      }
    });
  });
  return empty;
};
