import { useWindowDimensions } from "./useWindowDimensions";

// custom hook to define generical plotly config
export const usePlotlyConfig = () => {
  const windowDimension = useWindowDimensions();
  return {
    toImageButtonOptions: {
      format: "png",
      filename: "custom_image",
      height: windowDimension.height,
      width: windowDimension.width,
      scale: 4,
    },
    displaylogo: false,
    responsive: true,
  };
};