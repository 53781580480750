import React from "react";
import { FaTimes } from "react-icons/fa";
import { useDispatch } from "react-redux";

function DeleteDetail(props) {
  const dispatch = useDispatch();
  return (
    <div>
      <FaTimes
        style={{
          color: "#FC2727",
          cursor: "pointer",
          marginLeft: 1,
        }}
        onClick={() => {
          dispatch({
            type: props.type,
            payload: {
              key: "barriers_system_and_stakeholder",
              value: props.data_redux.barriers_system_and_stakeholder.filter(
                (obj) => obj.id !== props.data.id
              ),
            },
          });
        }}
      ></FaTimes>
    </div>
  );
}

export default DeleteDetail;
