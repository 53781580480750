import React, { useState, useEffect } from "react"
import { Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { API_URL, } from "../../../../actions/types";
import axios from "axios";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { connect } from "react-redux";
import { FaSpinner } from "react-icons/fa"
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import TableLogs from "../ModalLogUser/TableLogs";


const ModalAllLogs = (props) => {
    const [logUser, setLogUser] = useState(null)
    const { width, height } = useWindowDimensions()

    const toggle = () => props.setIsOpen(!props.isOpen);

    useEffect(async () => {
        const log_user = await axios.get(
            `${API_URL}/clicc/logs/`,
            tokenConfig()
        );
        setLogUser(log_user.data)
    }, [])

    return (
        <Modal
            isOpen={props.isOpen}
            toggle={toggle}
            size="xl"
            style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
            }}
        >
            <ModalHeader toggle={toggle}>All logs</ModalHeader>

            <ModalBody>
                {
                    logUser === null ?
                        <div>
                            <FaSpinner
                                className="spinner"
                                style={{ marginTop: "-2px", marginRight: 5 }}
                            ></FaSpinner>
                            Loading...
                        </div>
                        : <div>
                            <TableLogs logs={logUser} height={height * 0.8}></TableLogs>
                        </div>
                }</ModalBody>
        </Modal>
    )
};

const mapStateToProps = (state) => ({
    layout: state.layout,
});

export default connect(mapStateToProps)(ModalAllLogs);



