export const getHeightWithFooterWONavbar = (
  pages,
  height,
  subnavbar_height_empty,
  subnavbar_height_not_empty
) => {
  var heightWeight = 0.92;
  if (height < 780) {
    heightWeight = 0.9;
  }
  if (pages === undefined) {
    return height * heightWeight - subnavbar_height_empty;
  } else {
    return height * heightWeight - subnavbar_height_not_empty;
  }
};
