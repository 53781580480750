import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";

export const Box = ({ id, left, top, width, children, numWindows }) => {
  const localStyle = {
    position: "absolute",
    border: "1px solid gray",
    backgroundColor: "white",
    padding: "0.5rem 1rem",
    cursor: "move",
  };

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "draggable-box",
      item: { id, left, top, width },
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging(),
        };
      },
    }),
    [id, left, top, width]
  );

  if (isDragging) {
    return <div ref={drag} id={id} />;
  }
  return (
    <div
      className="draggable-box"
      onMouseUp={(e) => {
        const element = e.target.getBoundingClientRect();

        const wrapper = document
          .getElementById("sub_wrapper")
          .getBoundingClientRect();
        if (
          e.target.getAttribute("aria-hidden") !== "true" &&
          e.target.getAttribute("aria-selected") === null
        ) {
          const el = document.getElementById(id);
          if (element.left < wrapper.left) {
            el.style.left = `${
              Number(id.charAt()) === 0
                ? 5
                : (wrapper.width / numWindows) * Number(id.charAt())
            }px`;
          }
          // if (
          //   element.right > wrapper.right ||
          //   element.top < wrapper.top ||
          //   element.bottom > wrapper.bottom
          // ) {
          //   el.style.left = `${
          //     Number(id.charAt()) === 0
          //       ? 5
          //       : (wrapper.width / numWindows) * Number(id.charAt())
          //   }px`;
          //   el.style.width = `${wrapper.width / numWindows}px`;
          //   el.style.height = wrapper.height;
          //   el.style.top = "5px";
          //   e.preventDefault();
          // }
        }
      }}
      ref={drag}
      style={{
        ...localStyle,
        zIndex: 999,
        left,
        top,
        overflow: "hidden",
      }}
      id={id}
    >
      {children}
    </div>
  );
};
