import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { triggerRefresh } from "../../../../utils/triggerRefresh";
import SuccessAlert from "../../../../components/Modals/SuccessAlert";
import { API_URL, GET_ERRORS, IS_LOADING } from "../../../../actions/types";
import axios from "axios";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { useDispatch } from "react-redux";

const ModalDeleteUser = (props) => {
    const toggle = () => props.setIsOpen(!props.isOpen);
    const dispatch = useDispatch();

    return (
        <Modal
            isOpen={props.isOpen}
            toggle={toggle}
            size="lg"
            style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
            }}
        >
            <ModalHeader toggle={toggle}>Attention</ModalHeader>
            <ModalBody>Do you really want to delete the selected user?</ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    onClick={async () => {
                        try {
                            dispatch({
                                type: IS_LOADING,
                            });
                            await axios.delete(
                                `${API_URL}/users/${props.data.id}/`,
                                tokenConfig()
                            );
                            dispatch({
                                type: IS_LOADING,
                            });
                            toggle();
                            SuccessAlert("User deleted successfully");
                            triggerRefresh()
                        } catch (err) {
                            dispatch({
                                type: IS_LOADING,
                            });
                            const errors = {
                                msg: err.response.data,
                                status: err.response.status,
                            };
                            dispatch({
                                type: GET_ERRORS,
                                payload: errors,
                            });
                        }
                    }}
                >
                    Delete
                </Button>
                <Button color="secondary" onClick={toggle}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    )
};

export default ModalDeleteUser;
