import axios from "axios";
import { API_URL } from "../actions/types";
import { tokenConfig } from "./tokenConfig";
import moment from "moment";
import { removeBelowEqualCell } from "./removeBelowEqualCell";
import { roundAtMost2Digits } from "./roundAtMost2Digits";

export const getFilledBlocks = async (workspace) => {
  const resActors = await axios.get(`${API_URL}/clicc/actors/`, tokenConfig());

  // for all pathways open all actions and save data
  var impact_pathways = [];
  var individual_action_outline = [];
  for await (const pt of workspace.pathways) {
    var curr_fields_of_action = workspace.fields_of_action.find(
      (obj) => obj.id === pt.id
    ).value;
    var curr_systemic_lever = workspace.systemic_lever.find(
      (obj) => obj.id === pt.id
    ).value;
    var curr_co_benefit = workspace.co_benefit.find(
      (obj) => obj.id === pt.id
    ).value;
    var row = {
      fields_of_action: curr_fields_of_action,
      systemic_lever: curr_systemic_lever,
      early_change: [],
      late_outcome: [],
      total_co2_equivalent_avoided: 0,
      co_benefit: curr_co_benefit,
      actions_description: [],
    };
    // try to open an action, try is necessary because it can be deleted but here there is no a foreign key, so inside catch do nothing
    var actions_in_pathway = [];
    for await (const action of pt.actions) {
      if (action.is_grouped_action === true) {
        try {
          const resGrouped = await axios.get(
            `${API_URL}/clicc/grouped_actions/${action.action_id}/`,
            tokenConfig()
          );
          for await (const inner_action_id of resGrouped.data.actions) {
            try {
              const resInnerAction = await axios.get(
                `${API_URL}/clicc/actions/${inner_action_id}/`,
                tokenConfig()
              );
              actions_in_pathway.push(resInnerAction.data);
            } catch {
              // do nothing!
            }
          }
        } catch {
          // do nothing!
        }
      } else {
        try {
          const resAction = await axios.get(
            `${API_URL}/clicc/actions/${action.action_id}/`,
            tokenConfig()
          );
          actions_in_pathway.push(resAction.data);
        } catch {
          // do nothing!
        }
      }
    }
    actions_in_pathway.forEach((obj) => {
      var is_early_change = false;
      if (
        moment(obj.end_date).isValid() &&
        moment(workspace.start_date).isValid() &&
        moment(obj.end_date).diff(
          moment(workspace.start_date),
          "years",
          true
        ) <= 2
      ) {
        is_early_change = true;
      }
      individual_action_outline.push({
        ...obj,
        fields_of_action: curr_fields_of_action,
        systemic_lever: curr_systemic_lever,
        co_benefit: curr_co_benefit,
        is_early_change: is_early_change,
      });
      // push description
      row["actions_description"].push(obj.description);
      // for each action check if early change (within first 2 years) or late outcome
      if (is_early_change === true) {
        row["early_change"].push(obj.name);
      } else {
        row["late_outcome"].push(obj.name);
      }
      //   increase co2 avoided
      row["total_co2_equivalent_avoided"] += obj.total_co2_equivalent_avoided;
    });
    row["early_change"] = row["early_change"].join(", ");
    row["late_outcome"] = row["late_outcome"].join(", ");
    row["actions_description"] = row["actions_description"]
      .filter((inner) => inner !== "")
      .join(", ");
    impact_pathways.push(row);
  }
  // sort alphabetically
  impact_pathways.sort((a, b) =>
    a.fields_of_action > b.fields_of_action ? 1 : -1
  );

  //   get data for action portfolio table
  const uniqueFields = [
    ...new Set(impact_pathways.map((obj) => obj.fields_of_action)),
  ];
  var action_portfolio = uniqueFields.map((field) => {
    return {
      fields_of_action: field,
      actions_list: [],
      actions_description: [],
    };
  });
  impact_pathways.forEach((obj) => {
    action_portfolio.forEach((inner_obj) => {
      if (obj.fields_of_action === inner_obj.fields_of_action) {
        inner_obj["actions_list"].push(obj.early_change);
        inner_obj["actions_list"].push(obj.late_outcome);
        inner_obj["actions_description"].push(obj.actions_description);
      }
    });
  });
  action_portfolio.forEach((obj) => {
    obj["actions_list"] = obj["actions_list"]
      .filter((inner) => inner !== "")
      .join(", ");
    obj["actions_description"] = obj["actions_description"]
      .filter((inner) => inner !== "")
      .join(", ");
  });

  //   individual_action_outline row data
  var individual_action_outline_raw_data = [];
  individual_action_outline.forEach((obj, index) => {
    var curr_actor = resActors.data.find((actor) => actor.id === obj.actor);
    var involved_stakeholders = [];
    // if (curr_actor !== undefined) {
    //   involved_stakeholders.push(`${curr_actor.actor_business_name} (owner)`);
    // }
    obj.stakeholders.forEach((st) => {
      var currStakeholder = resActors.data.find(
        (actor) => actor.id === st.actor
      );
      if (currStakeholder !== undefined) {
        involved_stakeholders.push(`${currStakeholder.actor_business_name}`);
      }
    });

    individual_action_outline_raw_data.push(
      ["Action outline", "Action name", obj.name],
      [
        "",
        "Action type",
        Object.keys(obj.details).length === 0 ? "" : obj.details.subActionName,
      ],
      ["", "Action description", obj.description],
      ["Reference to impact pathway", "Field of action", obj.fields_of_action],
      ["", "Systemic lever", obj.systemic_lever],
      [
        "",
        "Outcome",
        obj.is_early_change === true ? "Early change" : "Late outcome",
      ],
      [
        "Implementation",
        "Responsible bodies/person for implementation",
        curr_actor === undefined
          ? ""
          : `${curr_actor.actor_reference_person_surname} ${curr_actor.actor_reference_person_name}`,
      ],
      ["", "Action scale & addressed entities", obj.scale],
      ["", "Involved stakeholders", involved_stakeholders.join(", ")],
      ["", "Comments on implementation", ""],
      [
        "Impact & cost",
        "Generated renewable energy (if applicable)",
        obj.net_zero_generated_renewable,
      ],
      [
        "",
        "Removed/substituted energy, volume or fuel type",
        obj.net_zero_removed_energy,
      ],
      [
        "",
        "GHG emissions reduction estimate (total) per emission source sector",
        obj.total_co2_equivalent_avoided,
      ],
      [
        "",
        "Total costs and costs by CO2e unit",
        `${obj.financials_total_investment}€ - ${obj.total_co2_equivalent_avoided === 0
          ? 0
          : roundAtMost2Digits(
            obj.financials_total_investment / obj.total_co2_equivalent_avoided
          )
        } CO2e unit`,
      ]
    );
    if (index < individual_action_outline.length - 1) {
      individual_action_outline_raw_data.push(["---", "---", "---"]);
    }
  });

  //   investment detail
  var capex_opex = individual_action_outline.map((obj) => {
    return {
      fields_of_action: obj.fields_of_action,
      name: obj.name,
      financials_capex: obj.financials_capex,
      financials_opex: obj.financials_opex,
      total_co2_equivalent_avoided: obj.total_co2_equivalent_avoided,
      co_benefit: obj.co_benefit,
    };
  });

  // remove below cell if values are the same
  impact_pathways = removeBelowEqualCell(impact_pathways, [
    "fields_of_action",
    "systemic_lever",
  ]);
  // sort alphabetically
  capex_opex.sort((a, b) => (a.fields_of_action > b.fields_of_action ? 1 : -1));
  capex_opex = removeBelowEqualCell(capex_opex, ["fields_of_action"]);

  const commitments = [
    resActors.data.filter((actor) => actor.actor_clicc_signatory === true)
      .length === 0
      ? {}
      : {
        id: "c_p5_2",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            [
              "Name of the institution",
              "Sector/Area",
              "Legal form",
              "Name of the responsible person",
              "Position of the responsible person",
            ],
            ...resActors.data
              .filter((actor) => actor.actor_clicc_signatory === true)
              .map((actor) => {
                return [
                  actor.actor_business_name,
                  actor.actor_service_category,
                  actor.actor_type,
                  `${actor.actor_reference_person_name} ${actor.actor_reference_person_surname}`,
                  actor.actor_reference_person_job_role,
                ];
              }),
          ],
        },
      },
  ];

  const action_plan = [
    workspace.barriers_system_and_stakeholder.length === 0
      ? {}
      : {
        id: "a_t7_1_6",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            [
              "System description",
              "Stakeholders involved",
              "Network",
              "Influence",
              "Interest",
            ],
            ...workspace.barriers_system_and_stakeholder.map((obj) => {
              return [
                obj.description,
                resActors.data.find((actor) => actor.id === obj.stakeholder)
                  .actor_business_name,
                obj.network,
                obj.influence,
                obj.interest,
              ];
            }),
          ],
        },
      },
    workspace.barriers_description_systemic_barriers === ""
      ? {}
      : {
        id: "a_p7_2_15",
        type: "paragraph",
        data: {
          text: workspace.barriers_description_systemic_barriers,
          level: 2,
        },
      },
    workspace.barriers_description_participatory_model === ""
      ? {}
      : {
        id: "a_p7_2_17",
        type: "paragraph",
        data: {
          text: workspace.barriers_description_participatory_model,
          level: 2,
        },
      },
    impact_pathways.length === 0
      ? {}
      : {
        id: "a_t7_1_7",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            [
              "Fields of action",
              "Systemic levers",
              "Early changes (1-2 years)",
              "Late outcomes (3-4 years)",
              "Direct impacts (Emission reductions t/y)",
              "Indirect impacts (co-benefits)",
            ],
            ...impact_pathways.map((obj) => {
              return [
                obj.fields_of_action,
                obj.systemic_lever,
                obj.early_change,
                obj.late_outcome,
                obj.total_co2_equivalent_avoided,
                obj.co_benefit,
              ];
            }),
          ],
        },
      },
    action_portfolio.length === 0
      ? {}
      : {
        id: "a_t7_1_8",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            ["Fields of action", "List of actions", "Descriptions"],
            ...action_portfolio.map((obj) => {
              return [
                obj.fields_of_action,
                obj.actions_list,
                obj.actions_description,
              ];
            }),
          ],
        },
      },
    individual_action_outline_raw_data.length === 0
      ? {}
      : {
        id: "a_t7_1_9",
        type: "table",
        data: {
          withHeadings: false,
          content: individual_action_outline_raw_data,
        },
      },
    individual_action_outline.length === 0
      ? {}
      : {
        id: "a_t7_1_17",
        type: "table",
        data: {
          withHeadings: false,
          content: [
            [
              "Action/ intervention name",
              "Responsible entity and person",
              "Start/end date",
              "Field of action",
              "Impact",
              "Total cost estimated",
            ],
            ...individual_action_outline.map((obj) => {
              return [
                obj.name,
                resActors.data.find((actor) => actor.id === obj.actor) ===
                  undefined
                  ? ""
                  : `${resActors.data.find((actor) => actor.id === obj.actor)
                    .actor_business_name
                  } - ${resActors.data.find((actor) => actor.id === obj.actor)
                    .actor_reference_person_surname
                  }`,
                `${obj.start_date === null ? "//" : obj.start_date} - ${obj.end_date === null ? "//" : obj.end_date
                }`,
                obj.fields_of_action,
                `CO2 eq. reduction: ${obj.total_co2_equivalent_avoided} [t/y]`,
                `${obj.financials_total_investment}€`,
              ];
            }),
          ],
        },
      },
  ];
  const investment_plan = [
    capex_opex.length === 0
      ? {}
      : {
        id: "i_p5_2_6",
        type: "table",
        data: {
          withHeadings: true,
          content: [
            [
              "Fields of Action",
              "Action / Indicator",
              "Implementation Costs/Capex",
              "Operational Costs",
              "Direct impacts (Emission reductions)*",
              "Indirect impacts (co-benefits)*",
            ],
            ...capex_opex.map((obj) => {
              return [
                obj.fields_of_action,
                obj.name,
                `${obj.financials_capex}€`,
                `${obj.financials_opex}€`,
                `${obj.total_co2_equivalent_avoided} [t/y]`,
                obj.co_benefit,
              ];
            }),
          ],
        },
      },
  ];

  return [commitments, action_plan, investment_plan];
};
