import React, { useEffect, useRef, useState, useCallback } from "react";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { connect } from "react-redux";
import { getHeightWithFooterWONavbar } from "../../../../utils/getHeightWithFooterWONavbar";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import { FaSpinner } from "react-icons/fa";
import ActionColumn from "./ActionColumn";
import ProgressColumn from "./ProgressColumn";
import IsAccountableColumn from "./IsAccountableColumn";
import moment from "moment";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import { valueFormatterTotalActions } from "../../../../utils/valueFormatterTotalActions";
import { applyTransactionFn } from "../../../../utils/applyTransactionFn";
import { contextMenuWithJSON } from "../../../../utils/contextMenuWithJSON";
import { cloneDeep } from "lodash"
import { checkActionColumnWidth } from "../../../../utils/checkActionColumnWidth";
import { getCheckedNames } from "../../../../utils/getCheckedNames";


function AccountableTab(props) {
  const { width, height } = useWindowDimensions();
  const [rowData, setRowData] = useState(null)

  const completionArray = [
    "index_completion",
    "netZero_completion",
    "actor_completion",
    "financials_completion",
    "specify_completion",
  ];

  const listTotals = [
    "financials_capex",
    "average_yearly_emission_avoided",
    "eur_co2_avoided"
  ];

  // grid ref
  const myGrid = useRef();

  // find capex
  const findValue = (arr) => {
    if (arr === null) {
      return 0
    } else {
      var v = arr.find(element => element !== 0)
      if (v !== undefined) {
        return v
      } else {
        return 0
      }
    }
  }

  // define function to get data
  const getData = () => {
    var rows = []
    props.rowData.forEach(obj => {
      const objClone = cloneDeep(obj)
      var financials_capex_found = findValue(objClone.financials_capex)
      var eur_co2_avoided = financials_capex_found / objClone.average_yearly_emission_avoided
      if (String(eur_co2_avoided) === "NaN" ||
        String(eur_co2_avoided) === "Infinity") {
        eur_co2_avoided = 0
      }
      rows.push({
        ...objClone,
        financials_capex: financials_capex_found,
        eur_co2_avoided: eur_co2_avoided
      })
    })
    return rows
  }

  const mounted = useRef();
  useEffect(async () => {
    if (!mounted.current && props.rowData !== null) {
      setRowData(getData());
      mounted.current = true;
    } else {
      // get data and apply changes
      const newRows = getData();
      const arr_checks = ["id"];
      if (newRows !== undefined && newRows.length >= 0) {
        applyTransactionFn(myGrid, newRows, arr_checks, true);
      }
      // update totals at the bottom
      if (myGrid.current !== undefined) {
        setTimeout(() => {
          var newTotalRow = {
            id: "total",
            name: "Total",
          };
          listTotals.forEach((totalKey) => {
            var total = 0;
            myGrid.current.api.forEachNode((rowNode, index) => {
              if (rowNode.data[totalKey] !== undefined) {
                total += rowNode.data[totalKey];
              }
            });
            newTotalRow[totalKey] = total;
          });
          myGrid.current.api.setPinnedBottomRowData([newTotalRow]);
        }, 200);
      }
    }
  }, [props.rowData]);

  return (
    <div
      style={{
        width: "100%",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        overflow: "auto",
      }}
    >
      {rowData === null || rowData === undefined ? (
        <div style={{ fontFamily: "Montserrat", fontWeight: 500, padding: 10 }}>
          <FaSpinner
            className="spinner"
            style={{ marginTop: "-2px", marginRight: 8 }}
          ></FaSpinner>
          Loading...
        </div>
      ) : (
        <>
          <div
            className="excel-style ag-theme-balham"
            style={{
              height: height - 240,
              padding: 10,
            }}
          >
            <AgGridReact
              ref={myGrid}
              rowData={rowData}
              getContextMenuItems={(params) => contextMenuWithJSON(params, "Export", "accountable")}
              onGridReady={(params) => {
                // set total pinned bottom row
                var pinnedRow = {
                  id: "total",
                  name: "Total",
                };
                listTotals.forEach((totalKey) => {
                  var total = 0;
                  params.api.forEachNode((rowNode, index) => {
                    if (rowNode.data[totalKey] !== undefined) {
                      total += rowNode.data[totalKey];
                    }
                  });
                  pinnedRow[totalKey] = total;
                });
                params.api.setPinnedBottomRowData([pinnedRow]);
              }}
              frameworkComponents={{
                actionColumn: ActionColumn,
                progressColumn: ProgressColumn,
                isAccountableColumn: IsAccountableColumn,
              }}
              defaultColDef={{
                flex: 1,
                resizable: true,
                filter: true,
                sortable: true,
                minWidth: 150,
                filterParams: { buttons: ["reset"], newRowsAction: "keep", caseSensitive: true },
                cellStyle: (params) => {
                  var style = {};
                  style["cursor"] = "default";
                  style["display"] = "flex";
                  style["alignItems"] = "center";
                  if (params.column.colId.includes("completion")) {
                    style["justifyContent"] = "center";
                  }

                  if (params.data.id === "total") {
                    style["fontWeight"] = "bold";
                    style["backgroundColor"] = "rgba(217, 217, 217, 0.5)";
                  }

                  return style;
                },
              }}
            >
              <AgGridColumn
                field=""
                maxWidth={checkActionColumnWidth(props.auth.user_permissions, "actions")}
                minWidth={checkActionColumnWidth(props.auth.user_permissions, "actions")}
                suppressMenu={true}
                cellRenderer="actionColumn"
                cellRendererParams={{
                  opened_action: props.opened_action,
                  auth: props.auth
                }}
              ></AgGridColumn>
              <AgGridColumn
                field="id"
                headerName="ID"
                hide={true}
                maxWidth={80}
              ></AgGridColumn>
              {completionArray.map((col) => {
                return (
                  <AgGridColumn
                    field={col}
                    headerName={capitalizeFirstLetter(col.split("_")[0])}
                    suppressMenu={true}
                    sortable={false}
                    hide={props.showCompletion === true ? false : true}
                    maxWidth={100}
                    cellRenderer="progressColumn"
                  ></AgGridColumn>
                );
              })}
              <AgGridColumn
                field="count"
                headerName="#"
                sort="desc"
                sortable={false}
                suppressMenu={true}
                maxWidth={rowData.length > 99 ? 35 : 30}
              ></AgGridColumn>
              <AgGridColumn
                field="name"
                headerName="Action name"
                flex={1.3}
              ></AgGridColumn>
              <AgGridColumn
                field=""
                headerName="Period"
                flex={0.8}
                valueGetter={(params) => {
                  var str = "";
                  if (params.data.id !== "total") {
                    if (
                      params.data.start_date === null &&
                      params.data.end_date === null
                    ) {
                      str = "NA";
                    } else {
                      if (params.data.start_date === null) {
                        str += "//";
                      } else {
                        str += params.data.start_date;
                      }
                      if (params.data.end_date === null) {
                        str += " - //";
                      } else {
                        str += ` - ${params.data.end_date}`;
                      }
                    }
                  }
                  return str;
                }}
              ></AgGridColumn>
              <AgGridColumn
                field={"macro_area"}
                headerName={"Macro Area"}
              ></AgGridColumn>
              <AgGridColumn
                field={"sector"}
                headerName={"Sector"}
              ></AgGridColumn>
              <AgGridColumn
                field="actor_business_name"
                headerName="Actor name"
              ></AgGridColumn>
              <AgGridColumn
                field="action_type"
                headerName="Action type"
                valueGetter={(params) => {
                  if (
                    params.data.details !== undefined &&
                    params.data.details.subActionName !== undefined
                  ) {
                    return params.data.details.subActionName;
                  } else {
                    return "";
                  }
                }}
              ></AgGridColumn>
              <AgGridColumn
                field="action_scale_detail"
                headerName="Scale detail"
                hide={true}
                valueGetter={(params) => {
                  return getCheckedNames(params.data.action_scale_detail)
                }}
              ></AgGridColumn>
              <AgGridColumn
                hide={true}
                field="address"
              ></AgGridColumn>
              <AgGridColumn
                hide={true}
                field="torino_cambia"
                headerName="TorinoCambia"
                valueGetter={(params) => {

                  if (params.data.id === "total") {
                    return ""
                  } else if (params.data.torino_cambia === false) {
                    return "NO"
                  } else {
                    return "YES"
                  }
                }}
              ></AgGridColumn>
              <AgGridColumn
                hide={true}
                field="cup"
                headerName="CUP"
              ></AgGridColumn>
              <AgGridColumn
                hide={true}
                field="rup"
                headerName="RUP"
              ></AgGridColumn>
              <AgGridColumn
                hide={true}
                field="notes"
              ></AgGridColumn>
              <AgGridColumn
                field="financials_capex"
                headerName="CAPEX"
                flex={0.8}
                valueFormatter={(params) => {
                  return valueFormatterTotalActions(params, "€");
                }}
              ></AgGridColumn>
              <AgGridColumn
                field="average_yearly_emission_avoided"
                headerName="CO2 eq. avoided"
                flex={1}
                valueFormatter={(params) => {
                  return valueFormatterTotalActions(params, " [t/y]");
                }}
              ></AgGridColumn>
              <AgGridColumn
                hide={true}
                field="eur_co2_avoided"
                headerName="€/CO2 eq. avoided"
                flex={1}
                valueFormatter={(params) => {
                  return valueFormatterTotalActions(params, " [€/(t/y)]");
                }}
              ></AgGridColumn>
              <AgGridColumn
                field="creation_date"
                headerName="Creation date"
                hide={true}
                valueFormatter={(params) => {
                  return moment(params.value).format("DD-MM-YYYY HH:mm:ss");
                }}
              ></AgGridColumn>
            </AgGridReact>
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layout: state.layout,
  opened_action: state.opened_action,
});

export default connect(mapStateToProps)(AccountableTab);
