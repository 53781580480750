import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useWindowDimensions } from "../Dashboard/utils/useWindowDimensions";
import Logo from "../images/logo_clicc_con_scritta.png";
import { API_URL, GET_ERRORS } from "../Dashboard/actions/types";
import { tokenConfig } from "../Dashboard/utils/tokenConfig";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import SuccessAlert from "../Dashboard/components/Modals/SuccessAlert";
import ErrorAlert from "../Dashboard/components/Modals/ErrorAlert";

export const SendEmail = (props) => {
    const { width, height } = useWindowDimensions();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const onChangePassword = (e) => setPassword(e.target.value);
    const onChangeConfirmPassword = (e) => setConfirmPassword(e.target.value);
    const dispatch = useDispatch()
    let location = useLocation();

    const onSubmit = async (e) => {
        e.preventDefault();
        var token = null
        if (location.search.includes("token=")) {
            var find_token = location.search.split("token=")
            token = find_token[find_token.length - 1]
        }
        // const token = searchParams.get("token")
        if (token === null) {
            ErrorAlert("User not identified")
        }
        else if (password !== confirmPassword) {
            ErrorAlert("Password are different")
        } else {
            try {
                await axios.post(
                    API_URL + "/password_reset/confirm/",
                    {
                        password: password,
                        token: token,
                    },
                    tokenConfig()
                );
                SuccessAlert("Password reset successfully!")
            } catch (err) {
                const errors = {
                    msg: err.response.data,
                    status: err.response.status,
                };
                dispatch({
                    type: GET_ERRORS,
                    payload: errors,
                });
            }
        }

    };

    return (
        <div>
            <div
                style={{
                    fontFamily: 'Montserrat',
                    fontWeight: "300",
                    height: height,
                    display: "flex",
                    background: "#f9fafc",
                }}
            >
                <div style={{ margin: "auto auto" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            background: "#ffffff",
                            borderRadius: "4px",
                            boxShadow: "0px 2px 6px -1px rgb(0 0 0 / 12%)",
                        }}
                    >
                        <form
                            onSubmit={onSubmit}
                            style={{
                                width: width > 721 ? "400px" : null,
                                padding: "35px",
                                background: "#fefefe",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                    marginBottom: 10,
                                }}
                            >
                                <img
                                    src={Logo}
                                    style={{ width: "auto", height: 50, marginRight: 5 }}
                                ></img>
                            </div>

                            <p
                                style={{
                                    width: "100%",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    textAlign: "center",
                                    marginBottom: 20,
                                }}
                            >
                                Reset password
                            </p>
                            <div
                                style={{
                                    position: "relative",
                                    marginBottom: "20px",
                                    width: "100%",
                                }}
                            >
                                <TextField
                                    type="password"
                                    label={"Password"}
                                    placeholder="Insert new password"
                                    onChange={onChangePassword}
                                    value={password}
                                    InputLabelProps={{
                                        shrink: true,
                                        fontFamily: "Montserrat"
                                    }}
                                    sx={{
                                        width: "100%",
                                        "& input": {
                                            fontFamily: "Montserrat"
                                        },
                                        "& label": {
                                            fontFamily: "Montserrat"
                                        }
                                    }}
                                ></TextField>
                            </div>
                            <div
                                style={{
                                    position: "relative",
                                    marginBottom: "30px",
                                    width: "100%",
                                }}
                            >
                                <TextField
                                    type="password"
                                    label={"Confirm password"}
                                    placeholder="Confirm password"
                                    onChange={onChangeConfirmPassword}
                                    value={confirmPassword}
                                    InputLabelProps={{
                                        shrink: true,
                                        fontFamily: "Montserrat"
                                    }}
                                    sx={{
                                        width: "100%",
                                        "& input": {
                                            fontFamily: "Montserrat"
                                        },
                                        "& label": {
                                            fontFamily: "Montserrat"
                                        }
                                    }}
                                ></TextField>
                            </div>
                            <Button
                                onClick={onSubmit}
                                sx={{
                                    placeSelf: "end",
                                    margin: "20px 0px 20px 0px",
                                    width: "100%",
                                }}
                                type="submit"
                                variant="contained"
                                style={{
                                    backgroundColor: "rgb(0, 43, 73)", marginTop: 0, fontFamily: "Montserrat"
                                }}
                            >
                                RESET
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, {})(SendEmail)
