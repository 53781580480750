export const initializeNewActor = () => {
  var toReturn = {
    actor_business_name: "",
    actor_address: "",
    actor_service_category: "",
    actor_website: "",
    actor_type: null,
    actor_sector: null,
    actor_area: null,
    actor_legal_representative: "",
    actor_clicc_signatory: false,
    actor_reference_person_name: "",
    actor_reference_person_surname: "",
    actor_reference_person_job_role: "",
    actor_reference_person_email: "",
    actor_reference_person_phone_number: "",
    actor_reference_person_address: "",
    actor_reference_person_post_code: "",
    actor_reference_person_municipality: "",
  };
  return toReturn;
};
