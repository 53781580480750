import axios from "axios";
import { tokenConfig } from "./tokenConfig";
import { API_URL, IS_LOADING } from "../actions/types";
import { initializeNewActor } from "./initializeNewActor";
import { saveAs } from "file-saver";

export const generateExcelAction = async (props) => {
  const resActors = await axios.get(`${API_URL}/clicc/actors/`, tokenConfig());

  var toDownload = [];
  // index tab
  var indexTab = {
    name: "Index",
    data: [
      {
        Description: "Action name",
        Value: props.data.name,
      },
      {
        Description: "Field of action",
        Value: props.data.field_of_action,
      },
      {
        Description: "Systemic lever",
        Value: props.data.systemic_lever,
      },
      {
        Description: "Action status",
        Value: props.data.status,
      },
      {
        Description: "Start date",
        Value: props.data.start_date,
      },
      {
        Description: "End date",
        Value: props.data.end_date,
      },
      {
        Description: "Action scale",
        Value: props.data.scale,
      },
      {
        Description: "Description",
        Value: props.data.description,
      },
    ],
  };
  toDownload.push(indexTab);

  // net-zero tab
  var netZeroTab = {
    name: "Net-zero",
    data: [
      {
        Description: "Scope",
        Value: props.data.net_zero_scope,
      },
      {
        Description: "Source sector",
        Value: props.data.net_zero_source_sector,
      },
      {
        Description: "Generated renewable energy (if applicable)",
        Value: props.data.net_zero_generated_renewable,
      },
      {
        Description: "Removed/substituted energy, volume or fuel type",
        Value: props.data.net_zero_removed_energy,
      },
    ],
  };
  toDownload.push(netZeroTab);

  // actor owner
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const actorFields = Object.keys(initializeNewActor());
  var actOwner = resActors.data.find((a) => a.id === props.data.actor);
  var actorTab = {
    name: "Actor owner",
    data: actorFields.map((k) => {
      return {
        Description: capitalizeFirstLetter(k).replace(/_/g, " "),
        Value: actOwner !== undefined ? actOwner[k] : "",
      };
    }),
  };
  toDownload.push(actorTab);

  // stakeholder involved
  var stakeholdersInvolved = {
    name: "Stakeholders involved",
    data:
      props.data.stakeholders.length === 0
        ? [
          {
            Name: "",
            Description: "",
          },
        ]
        : props.data.stakeholders.map((st) => {
          return {
            Name:
              resActors.data.find((a) => a.id === st.actor) !== undefined
                ? resActors.data.find((a) => a.id === st.actor)
                  .actor_business_name
                : "",
            Description: st.description,
          };
        }),
  };
  toDownload.push(stakeholdersInvolved);

  // financials tab
  var financialsTab = {
    name: "Financials",
    data: [
      {
        Description: "Funding programme",
        Value: props.data.financials_funding_programme,
      },
      {
        Description: "Total investment",
        Value: props.data.financials_total_investment,
      },
      {
        Description: "Capex",
        Value: props.data.financials_capex,
      },
      {
        Description: "Opex",
        Value: props.data.financials_opex,
      },
    ],
  };
  toDownload.push(financialsTab);

  // financials detail tab
  var financialsDetailsTab = {
    name: "Financials details",
    data:
      props.data.financials_investment_detail.length === 0
        ? [
          {
            Description: "",
            "Start date": "",
            "End date": "",
            Funder: "",
            Amount: "",
          },
        ]
        : props.data.financials_investment_detail.map((obj) => {
          var currFunder = null;
          if (obj.funder !== null) {
            var currFunderObj = resActors.data.find(
              (a) => a.id === obj.funder
            );
            if (currFunderObj !== undefined) {
              currFunder = currFunderObj.actor_business_name;
            }
          }
          return {
            Description: obj.description,
            "Start date": obj.start_date,
            "End date": obj.end_date,
            Funder: currFunder,
            Amount: obj.amount,
          };
        }),
  };
  toDownload.push(financialsDetailsTab);

  // Action details
  var actionDetails = {
    name: "Action details",
    data: [],
  };
  if (Object.keys(props.data.details).length > 0) {
    actionDetails.data.push(
      ...[
        {
          Description: "Action type",
          Value: props.data.details.subActionName,
        },
        {
          Description: "Subject",
          Value: props.data.details.subject_1.join(" / "),
        },
        {
          Description: "Subject",
          Value: props.data.details.subject_2.join(" / "),
        },
      ]
    );
  } else {
    actionDetails.data.push({
      Description: "Action type",
      Value: "No details specified",
    });
  }
  actionDetails.data.push({
    Description: "CO2 equivalent avoided [t/y]",
    Value: props.data.total_co2_equivalent_avoided,
  });
  toDownload.push(actionDetails);

  // notes tab
  var notesTab = {
    name: "Notes",
    data: [
      {
        Description: "Notes",
        Value: props.data.notes,
      },
    ],
  };
  toDownload.push(notesTab);

  const xhr = new XMLHttpRequest();
  xhr.responseType = "arraybuffer";
  xhr.open(
    "POST",
    `${API_URL}/clicc/download_excel/?name=Action: ${props.data.name === "" ? "No_name" : props.data.name
    }`,
    true
  );
  xhr.setRequestHeader(
    "Authorization",
    `Token ${localStorage.getItem("token")}`
  );
  xhr.setRequestHeader("Content-type", "application/json");
  xhr.onload = function () {
    if (this.status === 200) {
      var type = xhr.getResponseHeader("Content-Type");

      var blob = new Blob([this.response], { type: type });
      var filename = `CLICC download${props.data.name !== "" ? ` - ${props.data.name}` : ""
        }.xlsx`;
      saveAs(blob, filename);
    }
  };
  xhr.send(JSON.stringify(toDownload));
  return "";
};
