import { SET_FIELDS } from "../actions/types";

const initialState = {
  fields: {},
};

export const selected_values = (state = initialState, action) => {
  switch (action.type) {
    case SET_FIELDS:
      return {
        ...state,
        fields: { ...state.fields, ...action.payload },
      };
    default:
      return state;
  }
};
