import {
  HANDLE_MENU_OC,
  HANDLE_INFOBOTTOM_OC,
  OPEN_MENU_OC,
  CLOSE_MENU_OC
} from "./types";

export const handleMenuOC = () => {
  return {
    type: HANDLE_MENU_OC
  };
};

export const openMenuOC = () => {
  return {
    type: OPEN_MENU_OC
  };
};

export const closeMenuOC = () => {
  return {
    type: CLOSE_MENU_OC
  };
};

export const handleOCInfoBottom = () => {
  return {
    type: HANDLE_INFOBOTTOM_OC
  };
};
