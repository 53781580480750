import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Box, Checkbox, FormControlLabel, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { getHeightWithFooterWONavbar } from "../../../../utils/getHeightWithFooterWONavbar";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { connect } from "react-redux";
import axios from "axios";
import { API_URL } from "../../../../actions/types";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { FaInfoCircle } from "react-icons/fa"
import Tooltip from "@mui/material/Tooltip";
import { FaExclamationTriangle } from "react-icons/fa";
import moment from "moment"
import { getRangeNumbers } from "../../../../utils/getRangeNumbers";
import { getCheckedNames } from "../../../../utils/getCheckedNames";
import Autocomplete from '@mui/material/Autocomplete';
import { MdLocationOn } from "react-icons/md";



function Index(props) {
  const dispatch = useDispatch();
  const { width, height } = useWindowDimensions();
  const [myActions, setMyActions] = useState(null);
  const [adressesOptions, setAdressesOptions] = useState([]);

  const getActions = async () => {
    const res = await axios.get(`${API_URL}/clicc/actions/`, tokenConfig());
    setMyActions(res.data);
  };

  // monitor refresh
  useEffect(() => {
    const channel = new BroadcastChannel("clicc-channel");
    channel.addEventListener("message", async (e) => {
      if (e.data === "refresh") {
        await getActions();
      }
    });
    return () => channel.close();
  }, []);

  useEffect(async () => {
    await getActions();
  }, []);

  // get quartieri
  useEffect(async () => {
    // if running on create, append data
    if (props.data.action_scale_detail === undefined) {
      const res = await axios.get(`${API_URL}/data/quartieri_torino/`, tokenConfig());
      const result = res.data.reduce((acc, item) => {
        const { circoscrizione, nome } = item;
        if (!acc[circoscrizione]) {
          acc[circoscrizione] = [];
        }
        acc[circoscrizione].push({ nome: nome, checked: true });
        return acc;
      }, {});

      dispatch({
        type: props.type,
        payload: {
          key: "action_scale_detail",
          value: {
            "Whole city": result
          },
        },
      });

    }
  }, []);



  const handleParentChange = (cityKey, circoscrizione) => {
    const allChecked = props.data.action_scale_detail[cityKey][circoscrizione].every(item => item.checked);
    const newCheckedState = {
      ...props.data.action_scale_detail,
      [cityKey]: {
        ...props.data.action_scale_detail[cityKey],
        [circoscrizione]: props.data.action_scale_detail[cityKey][circoscrizione].map(item => ({
          ...item,
          checked: !allChecked
        }))
      }
    };
    dispatch({
      type: props.type,
      payload: {
        key: "action_scale_detail",
        value: newCheckedState,
      },
    });
  };

  const handleChildChange = (cityKey, circoscrizione, index) => {
    const newCheckedState = {
      ...props.data.action_scale_detail,
      [cityKey]: {
        ...props.data.action_scale_detail[cityKey],
        [circoscrizione]: props.data.action_scale_detail[cityKey][circoscrizione].map((item, idx) => (
          idx === index ? { ...item, checked: !item.checked } : item
        ))
      }
    };
    dispatch({
      type: props.type,
      payload: {
        key: "action_scale_detail",
        value: newCheckedState,
      },
    });
  };

  const field_of_action = [
    "Energy systems",
    "Mobility & transport",
    "Waste & circular economy",
    "Green infrastructure & nature based solutions",
    "Built environment",
    "Cross-cutting",
  ];
  const systemic_lever = [
    "Technology/infrastucture",
    "Governance & policy",
    "Social innovation",
    "Democracy & partecipation",
    "Finance & founding",
    "Learning & capabilities",
  ];
  const action_status = [
    "Technical-economic feasibility",
    "Executive design",
    "Definitive design",
    "Not yet programmed",
    "Programmed",
    "Planned",
    "Ongoing",
    "Executed",
  ];
  const macro_area_sector = {
    // "": [""],
    "AFOLU": ["Urban"],
    "IPPU": ["--"],
    "Stationary energy": ["Incinerator", "Industrial", "Municipal", "Residential", "Tertiary"],
    "Transport": ["Municipal vehicles", "Private transportation", "Public transportation"],
    "Waste": ["Sorted waste treatment", "Wastewater treatment", "Landfill"]
  }

  const inputProps = {
    style: {
      fontWeight: 500,
      fontFamily: "Montserrat",
    },
  };

  const InputLabelProps = {
    style: {
      fontWeight: 500,
      fontFamily: "Montserrat",
    },
  };


  const changeFinancialsArray = (start, end) => {
    var start_year = start === null ? 2019 : moment(start).year()
    var end_year = end === null ? 2030 : moment(end).year()
    var list_years = getRangeNumbers(start_year, end_year)
    if (list_years.length === 0) {
      dispatch({
        type: props.type,
        payload: {
          key: "financials_capex",
          value: [],
        },
      });
      dispatch({
        type: props.type,
        payload: {
          key: "financials_opex",
          value: [],
        },
      });
    } else {
      var capex_value = 0
      if (props.data.financials_capex.length > 0) {
        capex_value = props.data.financials_capex[0]
      }
      var financials_capex = []
      list_years.forEach((y, idx) => {
        if (idx === 0) {
          financials_capex.push(capex_value)
        } else {
          financials_capex.push(0)
        }
      })
      var opex_value = 0
      if (props.data.financials_opex.length > 1) {
        opex_value = props.data.financials_opex[1]
      }
      var financials_opex = []
      list_years.forEach((y, idx) => {
        if (idx === 0) {
          financials_opex.push(0)
        } else {
          financials_opex.push(opex_value)
        }
      })
      dispatch({
        type: props.type,
        payload: {
          key: "financials_capex",
          value: financials_capex,
        },
      });
      dispatch({
        type: props.type,
        payload: {
          key: "financials_opex",
          value: financials_opex,
        },
      });
    }
  }


  return (
    <div
      style={{
        width: "100%",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        overflow: "auto",
      }}
    >
      <Row style={{ margin: 0, marginTop: 15 }}>
        <Col lg="8" md="12">
          <TextField
            label={<div>Action name*&nbsp;&nbsp;&nbsp;</div>}
            value={props.data.name ? props.data.name : ""}
            inputProps={inputProps}
            InputLabelProps={InputLabelProps}
            style={{ width: "100%" }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: { key: "name", value: e.target.value },
              });
            }}
          />
        </Col>
      </Row>
      <Row style={{ margin: 0 }}>
        <Col lg="4" md="12" style={{ marginTop: 30 }}>
          <div
            style={{
              display: "flex",
              paddingTop: "15px",
            }}
          >
            <Checkbox
              onChange={(e) => {
                dispatch({
                  type: props.type,
                  payload: {
                    key: "is_accountable",
                    value: e.target.checked,
                  },
                });
                if (e.target.checked === true) {
                  dispatch({
                    type: props.type,
                    payload: {
                      key: "parent_action",
                      value: null,
                    },
                  });
                } else {
                  dispatch({
                    type: props.type,
                    payload: {
                      key: "macro_area",
                      value: "",
                    },
                  });
                  dispatch({
                    type: props.type,
                    payload: {
                      key: "sector",
                      value: "",
                    },
                  });
                }
              }}
              checked={props.data.is_accountable}
              style={{ marginLeft: "-3px" }}
            />
            <span
              style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
                color: "#6D6D6D",
                marginLeft: 3,
                display: "flex"
              }}
            >
              Set as accountable action
              <Tooltip title={"Only accountable actions are considered for emissions computations and investments in workspaces"}>
                <div>
                  <FaInfoCircle style={{
                    marginTop: "-7px", fontSize: 12, color: "#2072DE",
                    marginLeft: 2
                  }}></FaInfoCircle>
                </div>
              </Tooltip>

            </span>
          </div>
        </Col>
        <Col lg="4" md="12" hidden={props.data.is_accountable === true} style={{ marginTop: 30 }}>
          {myActions !== null ? (
            <TextField
              select
              label="Parent Action"
              value={props.data.parent_action ? props.data.parent_action : ""}
              style={{
                width: "100%",
              }}
              InputLabelProps={InputLabelProps}
              InputProps={{
                style: {
                  height: 56,
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                },
              }}
              onChange={(e) => {
                dispatch({
                  type: props.type,
                  payload: {
                    key: "parent_action",
                    value: e.target.value,
                  },
                });
              }}
            >
              <MenuItem
                value=""
                style={{ fontWeight: 500, fontFamily: "Montserrat" }}
              >
                ...
              </MenuItem>
              {myActions.filter(a => a.is_accountable === true).map((s) => {
                return (
                  <MenuItem
                    value={s.id}
                    style={{ fontWeight: 500, fontFamily: "Montserrat" }}
                  >
                    {s.name}
                  </MenuItem>
                );
              })}
            </TextField>
          ) : null}
        </Col>



        <Col lg="4" md="12" hidden={props.data.is_accountable === false} style={{ marginTop: 30 }}>
          <TextField
            select
            label="Macro area"
            value={props.data.macro_area ? props.data.macro_area : ""}
            style={{
              width: "100%",
            }}
            InputLabelProps={InputLabelProps}
            InputProps={{
              style: {
                height: 56,
                fontWeight: 500,
                fontFamily: "Montserrat",
              },
            }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: {
                  key: "macro_area",
                  value: e.target.value,
                },
              });
              dispatch({
                type: props.type,
                payload: {
                  key: "sector",
                  value: "",
                },
              });
            }}
          >
            <MenuItem
              value=""
              style={{ fontWeight: 500, fontFamily: "Montserrat" }}
            >
              ...
            </MenuItem>
            {Object.keys(macro_area_sector).map((s) => {
              return (
                <MenuItem
                  value={s}
                  style={{ fontWeight: 500, fontFamily: "Montserrat" }}
                >
                  {s}
                </MenuItem>
              );
            })}
          </TextField>
        </Col>
        <Col lg="4" md="12" hidden={props.data.is_accountable === false} style={{ marginTop: 30 }}>
          <TextField
            select
            label={<div style={{ display: "flex" }}>
              {Object.keys(macro_area_sector).includes(props.data.macro_area) &&
                !macro_area_sector[props.data.macro_area].includes(props.data.sector) &&
                props.data.sector !== "" ?
                <Tooltip title={`Current sector is "${props.data.sector}" but it's not in list`}>
                  <div>
                    <FaExclamationTriangle style={{ color: "#FFA600", marginTop: "-3px", marginRight: 5 }} />
                  </div>
                </Tooltip>
                : null
              }
              Sector
            </div>}
            value={props.data.sector ? props.data.sector : ""}
            style={{
              width: "100%",

            }}
            InputLabelProps={InputLabelProps}
            InputProps={{
              style: {
                height: 56,
                fontWeight: 500,
                fontFamily: "Montserrat",
              },
            }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: {
                  key: "sector",
                  value: e.target.value,
                },
              });
            }}
          >
            <MenuItem
              value=""
              style={{ fontWeight: 500, fontFamily: "Montserrat" }}
            >
              ...
            </MenuItem>
            {props.data.macro_area !== "" && macro_area_sector[props.data.macro_area] !== undefined && macro_area_sector[props.data.macro_area].map((s) => {
              return (
                <MenuItem
                  value={s}
                  style={{ fontWeight: 500, fontFamily: "Montserrat" }}
                >
                  {s}
                </MenuItem>
              );
            })}
          </TextField>
        </Col>
      </Row>

      <Row style={{ margin: 0 }}>
        <Col lg="4" md="6" style={{ marginTop: 30 }}>
          <DesktopDatePicker
            inputFormat="DD-MM-YYYY"
            value={props.data.execution_start_date}
            maxDate={props.data.execution_end_date}
            onChange={(newDate) => {
              dispatch({
                type: props.type,
                payload: {
                  key: "execution_start_date",
                  value: newDate === null ? null : newDate.format("YYYY-MM-DD"),
                },
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <Tooltip title="Period where the action is executed">
                    <div>Execution start date&nbsp;&nbsp;&nbsp;&nbsp;</div>
                  </Tooltip>
                }
                className="datePickerMontserrat"
                InputLabelProps={InputLabelProps}
                style={{ width: "100%" }}
              />
            )}
          />
        </Col>
        <Col lg="4" md="6" style={{ marginTop: 30 }}>
          <DesktopDatePicker
            inputFormat="DD-MM-YYYY"
            value={props.data.execution_end_date}
            minDate={props.data.execution_start_date}
            onChange={(newDate) => {
              dispatch({
                type: props.type,
                payload: {
                  key: "execution_end_date",
                  value: newDate === null ? null : newDate.format("YYYY-MM-DD"),
                },
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <Tooltip title="Period where the action is executed">
                    <div>Execution end date&nbsp;&nbsp;&nbsp;&nbsp;</div>
                  </Tooltip>
                }
                className="datePickerMontserrat"
                InputLabelProps={InputLabelProps}
                style={{ width: "100%" }}
              />
            )}
          />
        </Col>
      </Row>

      <Row style={{ margin: 0 }}>
        <Col lg="4" md="6" style={{ marginTop: 30 }}>
          <DesktopDatePicker
            inputFormat="DD-MM-YYYY"
            value={props.data.start_date}
            maxDate={props.data.end_date}
            onChange={(newDate) => {
              if ((moment(newDate).isValid() || newDate === null) && props.data.manual_values === true) {
                changeFinancialsArray(newDate, props.data.end_date)
              }
              dispatch({
                type: props.type,
                payload: {
                  key: "start_date",
                  value: newDate === null ? null : newDate.format("YYYY-MM-DD"),
                },
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <Tooltip title="Period in which the environmental effects of the action is active">
                    <div>Effect start date*&nbsp;&nbsp;&nbsp;&nbsp;</div>
                  </Tooltip>
                }
                className="datePickerMontserrat"
                InputLabelProps={InputLabelProps}
                style={{ width: "100%" }}
              />
            )}
          />
        </Col>
        <Col lg="4" md="6" style={{ marginTop: 30 }}>
          <DesktopDatePicker
            inputFormat="DD-MM-YYYY"
            value={props.data.end_date}
            minDate={props.data.start_date}
            onChange={(newDate) => {
              if ((moment(newDate).isValid() || newDate === null) && props.data.manual_values === true) {
                changeFinancialsArray(props.data.start_date, newDate)
              }
              dispatch({
                type: props.type,
                payload: {
                  key: "end_date",
                  value: newDate === null ? null : newDate.format("YYYY-MM-DD"),
                },
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <Tooltip title="Period in which the environmental effects of the action is active">
                    <div>Effect end date*&nbsp;&nbsp;&nbsp;&nbsp;</div>
                  </Tooltip>
                }
                className="datePickerMontserrat"
                InputLabelProps={InputLabelProps}
                style={{ width: "100%" }}
              />
            )}
          />
        </Col>
      </Row>

      <Row style={{ margin: 0 }}>
        <Col lg="4" md="12" style={{ marginTop: 30 }}>
          <TextField
            select
            label="Field of action"
            value={props.data.field_of_action ? props.data.field_of_action : ""}
            style={{
              width: "100%",
            }}
            InputLabelProps={InputLabelProps}
            InputProps={{
              style: {
                height: 56,
                fontWeight: 500,
                fontFamily: "Montserrat",
              },
            }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: {
                  key: "field_of_action",
                  value: e.target.value,
                },
              });
            }}
          >
            <MenuItem
              value=""
              style={{ fontWeight: 500, fontFamily: "Montserrat" }}
            >
              ...
            </MenuItem>
            {field_of_action.map((s) => {
              return (
                <MenuItem
                  value={s}
                  style={{ fontWeight: 500, fontFamily: "Montserrat" }}
                >
                  {s}
                </MenuItem>
              );
            })}
          </TextField>
        </Col>
        <Col lg="4" md="12" style={{ marginTop: 30 }}>
          <TextField
            select
            label="Systemic lever"
            value={props.data.systemic_lever ? props.data.systemic_lever : ""}
            style={{
              width: "100%",
            }}
            InputLabelProps={InputLabelProps}
            InputProps={{
              style: {
                height: 56,
                fontWeight: 500,
                fontFamily: "Montserrat",
              },
            }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: {
                  key: "systemic_lever",
                  value: e.target.value,
                },
              });
            }}
          >
            <MenuItem
              value=""
              style={{ fontWeight: 500, fontFamily: "Montserrat" }}
            >
              ...
            </MenuItem>
            {systemic_lever.map((s) => {
              return (
                <MenuItem
                  value={s}
                  style={{ fontWeight: 500, fontFamily: "Montserrat" }}
                >
                  {s}
                </MenuItem>
              );
            })}
          </TextField>
        </Col>
      </Row>

      <Row style={{ margin: 0 }}>
        {props.data.action_scale_detail !== undefined ?
          <Col lg="4" md="12" style={{ marginTop: 30 }}>
            <TextField
              select
              value={getCheckedNames(props.data.action_scale_detail)}
              label="Action scale details"
              style={{
                width: "100%",
              }}
              InputLabelProps={InputLabelProps}
              InputProps={{
                style: {
                  height: 56,
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                },
              }}
            >
              <MenuItem
                value={getCheckedNames(props.data.action_scale_detail)}
                style={{
                  display: "none"
                }}
              >
                {getCheckedNames(props.data.action_scale_detail)}
              </MenuItem>
              <div style={{ padding: "0px 23px", maxHeight: "300px" }}>
                <Box>
                  {Object.keys(props.data.action_scale_detail).map(cityKey => (
                    <Box key={cityKey} sx={{ mb: 2 }}>
                      <FormControlLabel
                        label={<Typography style={{ fontFamily: "Montserrat", fontWeight: 500 }}>{cityKey}</Typography>}
                        control={
                          <Checkbox
                            checked={Object.keys(props.data.action_scale_detail[cityKey]).every(circoscrizione =>
                              props.data.action_scale_detail[cityKey][circoscrizione].every(item => item.checked)
                            )}
                            indeterminate={Object.keys(props.data.action_scale_detail[cityKey]).some(circoscrizione =>
                              props.data.action_scale_detail[cityKey][circoscrizione].some(item => item.checked) &&
                              !props.data.action_scale_detail[cityKey][circoscrizione].every(item => item.checked)
                            )}
                            onChange={() => {
                              const allChecked = Object.keys(props.data.action_scale_detail[cityKey]).every(circoscrizione =>
                                props.data.action_scale_detail[cityKey][circoscrizione].every(item => item.checked)
                              );
                              const newCheckedState = {
                                ...props.data.action_scale_detail,
                                [cityKey]: Object.keys(props.data.action_scale_detail[cityKey]).reduce((acc, circoscrizione) => {
                                  acc[circoscrizione] = props.data.action_scale_detail[cityKey][circoscrizione].map(item => ({
                                    ...item,
                                    checked: !allChecked
                                  }));
                                  return acc;
                                }, {})
                              };

                              dispatch({
                                type: props.type,
                                payload: {
                                  key: "action_scale_detail",
                                  value: newCheckedState
                                },
                              });

                            }}
                          />
                        }
                      />
                      <Box sx={{ ml: 3 }}>
                        {Object.keys(props.data.action_scale_detail[cityKey]).map(circoscrizione => (
                          <Box key={circoscrizione} sx={{ mb: 2 }}>
                            <FormControlLabel
                              label={<Typography style={{ fontFamily: "Montserrat", fontWeight: 500 }}>{`Circoscrizione ${circoscrizione}`}</Typography>}
                              control={
                                <Checkbox
                                  checked={props.data.action_scale_detail[cityKey][circoscrizione].every(item => item.checked)}
                                  indeterminate={
                                    props.data.action_scale_detail[cityKey][circoscrizione].some(item => item.checked) &&
                                    !props.data.action_scale_detail[cityKey][circoscrizione].every(item => item.checked)
                                  }
                                  onChange={() => handleParentChange(cityKey, circoscrizione)}
                                />
                              }
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                              {props.data.action_scale_detail[cityKey][circoscrizione].map((item, index) => (
                                <FormControlLabel
                                  key={index}
                                  label={<Typography style={{ fontFamily: "Montserrat", fontWeight: 500 }}>{item.nome}</Typography>}
                                  control={
                                    <Checkbox
                                      checked={item.checked}
                                      onChange={() => handleChildChange(cityKey, circoscrizione, index)}
                                    />
                                  }
                                />
                              ))}
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </div>
            </TextField>
          </Col>
          : null}

        <Col lg="4" md="12" style={{ marginTop: 30 }}>
          <Autocomplete
            disablePortal
            options={adressesOptions}
            sx={{ width: '100%', fontFamily: "Montserrat" }}
            onInputChange={(e, v) => {
              axios.get(API_URL + "/data/address/", { params: { address: v } }).then(response => {
                setAdressesOptions(response.data)
              })
            }}
            value={props.data.address}
            renderInput={(params) => <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                style: {
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                },
                autoComplete: 'new-password',
              }}
              label="Address"
              InputLabelProps={InputLabelProps}
            />}
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option.name
            }
            filterOptions={(x) => x}
            includeInputInList
            filterSelectedOptions
            onChange={(e, v) => {
              dispatch({
                type: props.type,
                payload: {
                  key: "address",
                  value: v !== null ? v.name : null
                },
              });
              dispatch({
                type: props.type,
                payload: {
                  key: "long",
                  value: v !== null ? v.long : null
                },
              });
              dispatch({
                type: props.type,
                payload: {
                  key: "lat",
                  value: v !== null ? v.lat : null
                },
              });
            }}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              const primary = option.name.split(", ")[0]
              const secondary = option.name.split(", ").slice(1).join(', ')
              return (
                <li key={key} {...optionProps}>
                  <Grid container sx={{ alignItems: 'center' }}>
                    <Grid item sx={{ display: 'flex', width: 44 }}>
                      <MdLocationOn sx={{ color: 'text.secondary' }} size="2em" />
                    </Grid>
                    <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }} style={{ fontWeight: 500, fontFamily: "Montserrat" }}>
                      {primary}
                      <Typography variant="body2" color="text.secondary" style={{ fontWeight: 500, fontFamily: "Montserrat" }}>
                        {secondary}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              );
            }}

          />
        </Col>
        <Col lg="4" md="12" style={{ marginTop: 30 }}>
          <div
            style={{
              display: "flex",
              paddingTop: "15px",
            }}
          >
            <Checkbox
              onChange={(e) => {
                dispatch({
                  type: props.type,
                  payload: {
                    key: "torino_cambia",
                    value: e.target.checked,
                  },
                });
              }}
              checked={props.data.torino_cambia}
              style={{ marginLeft: "-3px" }}
            />
            <span
              style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
                color: "#6D6D6D",
                marginLeft: 3,
                display: "flex"
              }}
            >
              Is part of TorinoCambia?

            </span>
          </div>
        </Col>
      </Row>

      <Row style={{ margin: 0 }}>
        <Col lg="4" md="12" style={{ marginTop: 30 }}>
          <TextField
            label={<div>CUP</div>}
            value={props.data.cup ? props.data.cup : ""}
            inputProps={inputProps}
            InputLabelProps={InputLabelProps}
            style={{ width: "100%" }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: { key: "cup", value: e.target.value },
              });
            }}
          />
        </Col>
        <Col lg="4" md="12" style={{ marginTop: 30 }}>
          <TextField
            label={<div>RUP</div>}
            value={props.data.rup ? props.data.rup : ""}
            inputProps={inputProps}
            InputLabelProps={InputLabelProps}
            style={{ width: "100%" }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: { key: "rup", value: e.target.value },
              });
            }}
          />
        </Col>
        <Col lg="4" md="12" style={{ marginTop: 30 }}>
          <TextField
            select
            label="Action status"
            value={props.data.status ? props.data.status : ""}
            style={{
              width: "100%",
            }}
            InputLabelProps={InputLabelProps}
            InputProps={{
              style: {
                height: 56,
                fontWeight: 500,
                fontFamily: "Montserrat",
              },
            }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: {
                  key: "status",
                  value: e.target.value,
                },
              });
            }}
          >
            <MenuItem
              value=""
              style={{ fontWeight: 500, fontFamily: "Montserrat" }}
            >
              ...
            </MenuItem>
            {action_status.map((s) => {
              return (
                <MenuItem
                  value={s}
                  style={{ fontWeight: 500, fontFamily: "Montserrat" }}
                >
                  {s}
                </MenuItem>
              );
            })}
          </TextField>
        </Col>


      </Row>

      <Row style={{ margin: 0, marginTop: 30 }}>
        <Col xs="12">
          <TextField
            label={<div>Action scale&nbsp;&nbsp;</div>}
            multiline
            rows={2}
            value={props.data.scale ? props.data.scale : ""}
            inputProps={inputProps}
            InputLabelProps={InputLabelProps}
            style={{ width: "100%" }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: { key: "scale", value: e.target.value },
              });
            }}
          />
        </Col>
      </Row>

      <Row style={{ margin: 0, marginTop: 30 }}>
        <Col xs="12">
          <TextField
            label={
              <div>Addressed entities&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
            }
            multiline
            rows={2}
            value={
              props.data.addressed_entities ? props.data.addressed_entities : ""
            }
            inputProps={inputProps}
            InputLabelProps={InputLabelProps}
            style={{ width: "100%" }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: { key: "addressed_entities", value: e.target.value },
              });
            }}
          />
        </Col>
      </Row>

      <Row style={{ margin: 0, marginTop: 30, paddingBottom: 180 }}>
        <Col xs="12">
          <TextField
            label={<div>Description&nbsp;&nbsp;</div>}
            multiline
            rows={4}
            value={props.data.description ? props.data.description : ""}
            inputProps={inputProps}
            InputLabelProps={InputLabelProps}
            style={{ width: "100%" }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: { key: "description", value: e.target.value },
              });
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Index);
