import React, { useState } from "react";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { connect, useDispatch } from "react-redux";
import { getHeightWithFooterWONavbar } from "../../../../utils/getHeightWithFooterWONavbar";
import classnames from "classnames";
import {
  Modal,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Commitments from "./Commitments";
import ActionPlan from "./ActionPlan";
import InvestmentPlan from "./InvestmentPlan";
import Workspace from "./Workspace";
import Download from "./Download";
import CreateOrUpdate from "./CreateOrUpdate";

function Content(props) {
  const [activeTab, setActiveTab] = useState(1);
  const [reset, setReset] = useState(1);
  const { width, height } = useWindowDimensions();

  const styleTabPane = {
    ...props.layout.generic_card,
    height: "95%",
    overflowY: "hidden",
    backgroundColor: "white",
    marginTop: "-1px",
    borderTopRightRadius: 15,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
    padding: 10,
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#E4E6E8",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        paddingLeft: props.layout.width_sidebar,
        paddingBottom: props.layout.footer_height,
        overflow: "auto",
      }}
    >
      {/* workspace without padding */}
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 10,
        }}
      >
        {/* workspace */}
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <div style={{ padding: 10, height: "100%", position: "relative" }}>
            <div style={{ position: "absolute", right: 15, top: 15 }}></div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Nav tabs style={{ width: "90%" }}>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 1,
                      activeWhite: activeTab === 1,
                    })}
                    onClick={() => {
                      setActiveTab(1);
                    }}
                    style={{
                      fontSize: 14,
                      backgroundColor:
                        activeTab === 1 ? "white" : "transparent",
                    }}
                  >
                    IMPORT
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 2,
                      activeWhite: activeTab === 2,
                    })}
                    onClick={() => {
                      setActiveTab(2);
                    }}
                    style={{
                      fontSize: 14,
                      backgroundColor:
                        activeTab === 2 ? "white" : "transparent",
                    }}
                  >
                    COMMITMENTS
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 3,
                      activeWhite: activeTab === 3,
                    })}
                    onClick={() => {
                      setActiveTab(3);
                    }}
                    style={{
                      fontSize: 14,
                      backgroundColor:
                        activeTab === 3 ? "white" : "transparent",
                    }}
                  >
                    ACTION PLAN
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 4,
                      activeWhite: activeTab === 4,
                    })}
                    onClick={() => {
                      setActiveTab(4);
                    }}
                    style={{
                      fontSize: 14,
                      backgroundColor:
                        activeTab === 4 ? "white" : "transparent",
                    }}
                  >
                    INVESTMENT PLAN
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 5,
                      activeWhite: activeTab === 5,
                    })}
                    onClick={() => {
                      setActiveTab(5);
                    }}
                    style={{
                      fontSize: 14,
                      backgroundColor:
                        activeTab === 5 ? "white" : "transparent",
                    }}
                  >
                    DOWNLOAD
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 6,
                      activeWhite: activeTab === 6,
                    })}
                    onClick={() => {
                      setActiveTab(6);
                    }}
                    style={{
                      fontSize: 14,
                      backgroundColor:
                        activeTab === 6 ? "white" : "transparent",
                    }}
                  >
                    {props.type === "UPDATE_CREATE_NEW_CONTRACT"
                      ? "SAVE"
                      : "UPDATE"}
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            <TabContent activeTab={activeTab} style={{ height: "100%" }}>
              <TabPane tabId={1} style={styleTabPane}>
                <Workspace reset={reset} setReset={setReset}></Workspace>
              </TabPane>
              <TabPane tabId={2} style={styleTabPane}>
                <Commitments
                  data={props.data}
                  type={props.type}
                  setActiveTab={setActiveTab}
                  reset={reset}
                  setReset={setReset}
                ></Commitments>
              </TabPane>
              <TabPane tabId={3} style={styleTabPane}>
                <ActionPlan
                  data={props.data}
                  type={props.type}
                  setActiveTab={setActiveTab}
                  reset={reset}
                  setReset={setReset}
                ></ActionPlan>
              </TabPane>
              <TabPane tabId={4} style={styleTabPane}>
                <InvestmentPlan
                  data={props.data}
                  type={props.type}
                  setActiveTab={setActiveTab}
                  reset={reset}
                  setReset={setReset}
                ></InvestmentPlan>
              </TabPane>
              <TabPane tabId={5} style={styleTabPane}>
                <Download
                  data={props.data}
                  type={props.type}
                  setActiveTab={setActiveTab}
                ></Download>
              </TabPane>

              <TabPane tabId={6} style={styleTabPane}>
                <CreateOrUpdate
                  data={props.data}
                  type={props.type}
                  setActiveTab={setActiveTab}
                  api_call="post"
                ></CreateOrUpdate>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Content);
