import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import { Row, Col, Card } from "reactstrap";
import { FaEuroSign } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { recurrentUpdate } from "../../../../../utils/recurrentUpdate";

function Numeric(props) {
  const dispatch = useDispatch();

  const updateRedux = (newValue) => {
    newValue = Number(newValue);
    props.data.details.parameters.forEach((obj) =>
      recurrentUpdate(obj, props.param, newValue)
    );
    dispatch({
      type: props.type,
      payload: {
        key: "details",
        value: props.data.details,
      },
    });
  };

  return (
    <Card body style={{ marginTop: 20 }}>
      <Row style={{ margin: 0 }}>
        <Col lg="4" md="12" style={{ padding: 0 }}>
          <div style={{ fontFamily: "Montserrat" }}>
            {props.param.paramName}
          </div>
          <div
            style={{ fontFamily: "Montserrat", fontWeight: 500, fontSize: 12 }}
          >
            {props.param.paramDescription}
          </div>
          <TextField
            type="number"
            label={<div>Value&nbsp;</div>}
            value={props.param.default}
            inputProps={{
              min: props.param.min,
              max: props.param.max,
              style: {
                fontWeight: 500,
                fontFamily: "Montserrat",
              },
            }}
            InputLabelProps={{
              style: {
                fontWeight: 500,
                fontFamily: "Montserrat",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div>{props.param.uom}</div>
                </InputAdornment>
              ),
            }}
            style={{ width: "100%", marginTop: 15 }}
            onChange={(e) => updateRedux(e.target.value)}
            onBlur={(e) => {
              if (e.target.value > props.param.max) {
                e.target.value = props.param.max;
              } else if (e.target.value < props.param.min) {
                e.target.value = props.param.min;
              }
              updateRedux(e.target.value);
            }}
          />
        </Col>
      </Row>
    </Card>
  );
}

export default Numeric;
