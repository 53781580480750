import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
} from "reactstrap";
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import SuccessAlert from "../../../../../../../../components/Modals/SuccessAlert";
function AddUpdateModal(props) {
  const dispatch = useDispatch();
  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        size="lg"
        style={{
          fontFamily: "Montserrat",
          fontWeight: 500,
        }}
      >
        <ModalHeader toggle={props.toggle}>Add new row</ModalHeader>
        <ModalBody>
          <Row style={{ margin: 0 }}>
            <Col lg="4" md="12">
              <TextField
                variant="standard"
                inputProps={{
                  style: {
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                  },
                }}
                label={<div style={{
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                }}>Commodity</div>}
                value={props.commodity}
                onChange={(e) => {
                  props.setCommodity(e.target.value);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={
              props.data_redux.baseline_commodities.filter(
                (obj) => props.commodity === obj.commodity
              ).length !== 0 || props.commodity === ""
            }
            color={props.id !== null ? "primary" : "success"}
            onClick={async () => {
              if (props.update === true) {
                dispatch({
                  type: props.type,
                  payload: {
                    key: "baseline_commodities",
                    value: props.data_redux.baseline_commodities.map((obj) => {
                      if (obj.commodity === props.data.commodity) {
                        return {
                          commodity: props.commodity,
                        };
                      } else return obj;
                    }),
                  },
                });
                props.data_redux.gases.forEach((gas) => {
                  dispatch({
                    type: props.type,
                    payload: {
                      key: "baseline_ghg_" + gas,
                      value: props.data_redux["baseline_ghg_" + gas].map(
                        (obj) => {
                          const commodityValue = obj[props.data.commodity];
                          delete obj[props.data.commodity];

                          return {
                            ...obj,
                            [props.commodity]: commodityValue,
                          };
                        }
                      ),
                    },
                  });
                });
                dispatch({
                  type: props.type,
                  payload: {
                    key: "baseline_co2_equivalente",
                    value: props.data_redux.baseline_co2_equivalente.map(
                      (obj) => {
                        const commodityValue = obj[props.data.commodity];
                        delete obj[props.data.commodity];

                        return {
                          ...obj,
                          [props.commodity]: commodityValue,
                        };
                      }
                    ),
                  },
                });
                props.toggle();
                SuccessAlert("Done!");
              } else {
                dispatch({
                  type: props.type,
                  payload: {
                    key: "baseline_commodities",
                    value: [
                      ...props.data_redux.baseline_commodities,
                      {
                        commodity: props.commodity,
                      },
                    ],
                  },
                });
                props.data_redux.gases.forEach((gas) => {
                  dispatch({
                    type: props.type,
                    payload: {
                      key: "baseline_ghg_" + gas,
                      value: props.data_redux["baseline_ghg_" + gas].map(
                        (row) => {
                          return {
                            ...row,
                            [props.commodity]: 0,
                          };
                        }
                      ),
                    },
                  });
                });
                dispatch({
                  type: props.type,
                  payload: {
                    key: "baseline_co2_equivalente",
                    value: props.data_redux.baseline_co2_equivalente.map(
                      (row) => {
                        return {
                          ...row,
                          [props.commodity]: 0,
                        };
                      }
                    ),
                  },
                });
              }
              props.toggle();
              SuccessAlert("Done!");
            }}
          >
            {props.id !== null ? "Update" : "Add"}
          </Button>
          <Button color="secondary" onClick={props.toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AddUpdateModal;
