import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import InfoAlert from "../../../../../../components/Modals/InfoAlert";
import { applyTransactionFn } from "../../../../../../utils/applyTransactionFn";
import { getYearlyEmissionGroupedAction } from "../../../../../../utils/getYearlyEmissionGroupedAction";
import { getYearlyEmissionAction } from "../../../../../../utils/getYearlyEmissionAction";
import { roundAtMost2Digits } from "../../../../../../utils/roundAtMost2Digits";
import DateColumns from "../../Pathways/DateColumns";
import { checkActionPeriod } from "../../../../../../utils/checkActionPeriod";
import { cleanRatio } from "../../../../../../utils/cleanRatio";


function Data(props) {
  const [rowData, setRowData] = useState(null);
  const dispatch = useDispatch();

  const getReduction = (emissionsDict, emissions_avoided) => {
    var total = 0;
    if (emissionsDict !== undefined) {
      Object.keys(emissionsDict).forEach((emission) => {
        if (typeof emissionsDict[emission] === "number") {
          total += emissionsDict[emission];
        } else {
          Object.keys(emissionsDict[emission]).forEach((macro_area) => {
            total += emissionsDict[emission][macro_area];
          });
        }
      });
    }
    var ratio = cleanRatio(emissions_avoided, total)
    return ratio
  };

  const getDescription = (id) => {
    const gasEmissions = props.data["target_" + props.chiave];
    const findRow = gasEmissions.find((e) => e.id === id);
    if (findRow !== undefined) {
      var newValue = findRow.descrizione;
      return newValue;
    } else return "";
  };

  const setId = (str, id) => {
    if (id === "global_impact") {
      return "global_impact"
    }
    return `${str}_${id}`
  }

  const findValue = (arr) => {
    if (arr === null) {
      return 0
    } else {
      var v = arr.find(element => element !== 0)
      if (v !== undefined) {
        return v
      } else {
        return 0
      }
    }
  }

  // in getData don't use async because it's needed also in pinnedBottomRowData
  const getData = (only_total = false) => {
    const accountableActions = props.myActions.filter(
      (action) => action.actions === undefined
    );
    const groupedActions = props.myActions.filter(
      (action) => action.actions !== undefined
    );

    // save the list of actions (both, single and grouped) in pathways
    var actionsWorkspace = [];
    props.data.pathways.forEach((pathway) => {
      if (pathway.actions.length > 0) {
        pathway.actions.forEach((action) => {
          if (action.is_grouped_action === false) {
            if (
              accountableActions.map((obj) => obj.id).includes(action.action_id)
            ) {
              var action_found = accountableActions.find((obj) => obj.id === action.action_id)
              actionsWorkspace.push(
                { ...action_found, id: setId("single", action_found.id) }
              );
            }
          } else {
            if (
              groupedActions.map((obj) => obj.id).includes(action.action_id)
            ) {
              var action_found = groupedActions.find((obj) => obj.id === action.action_id)
              actionsWorkspace.push(
                { ...action_found, id: setId("grouped", action_found.id) }
              );
            }
          }
        });
      }
    });

    // check if cc_decarbonization is available in props.myActions
    var cc_decarbonization_row = props.myActions.find(obj => obj.id === "global_impact")
    if (cc_decarbonization_row !== undefined) {
      actionsWorkspace.push(cc_decarbonization_row)
    }

    // initialize some dict to keep track of numbers later on
    // notice that here we use only accountableActions since groupedActions cannot have different macro_area/sector
    var macroAreaSectorDistinct = {};
    var macroAreaDict = {};
    var sectorDict = {};

    accountableActions.forEach((row) => {
      macroAreaSectorDistinct[row.macro_area] = macroAreaSectorDistinct[row.macro_area] || {};
      macroAreaSectorDistinct[row.macro_area][row.sector] = macroAreaSectorDistinct[row.macro_area][row.sector] || 0;
      macroAreaDict[row.macro_area] = macroAreaDict[row.macro_area] || {
        id_list: [],
        latest_id: null,
        capex: 0,
        emissions_avoided: {},
        emissions_target: {},
      };
      sectorDict[row.macro_area + row.sector] = sectorDict[row.macro_area + row.sector] || {
        id_list: [],
        latest_id: null,
        capex: 0,
        emissions_avoided: {},
        emissions_target: {}
      };

    })

    var rowsDict = {};
    // inside rowsDict save the values in baseline
    props.data["baseline_" + props.chiave].forEach(row => {
      if (!Object.keys(rowsDict).includes(row.macro_area)) {
        rowsDict[row.macro_area] = {}
      }

      if (!Object.keys(rowsDict[row.macro_area]).includes(row.settore)) {
        rowsDict[row.macro_area][row.settore] = 0
      }

      Object.keys(row).forEach((key) => {
        if (
          key !== "macro_area" &&
          key !== "settore" &&
          key !== "sottosettore"
        ) {
          rowsDict[row.macro_area][row.settore] += row[key];
        }
      });
    })


    // for each macro_area and macro_area+sector save the list of action id and the latest id
    accountableActions.forEach((action) => {
      macroAreaDict[action.macro_area]["latest_id"] = "";
      macroAreaDict[action.macro_area]["id_list"].push(setId("single", action.id))
      sectorDict[action.macro_area + action.sector]["latest_id"] = setId("single", action.id);
      sectorDict[action.macro_area + action.sector]["id_list"].push(setId("single", action.id))
    });
    groupedActions.forEach((action) => {
      macroAreaDict[action.macro_area]["latest_id"] = "";
      macroAreaDict[action.macro_area]["id_list"].push(setId("grouped", action.id))
      sectorDict[action.macro_area + action.sector]["latest_id"] = setId("grouped", action.id);
      sectorDict[action.macro_area + action.sector]["id_list"].push(setId("grouped", action.id))
    });


    // create a function to be used later on to get emissions
    const getEmissions = (fn, action_or_list, gas, emission_type, macro_area, sector) => {
      var result = fn(action_or_list, gas, emission_type, props.data.start_date, props.data.end_date)

      Object.keys(result).forEach((k) => {
        if (!Object.keys(sectorDict[macro_area + sector]["emissions_avoided"]).includes(k)) {
          sectorDict[macro_area + sector]["emissions_avoided"][k] = result[k];
        } else {
          sectorDict[macro_area + sector]["emissions_avoided"][k] += result[k];
        }

        if (!Object.keys(macroAreaDict[macro_area]["emissions_avoided"]).includes(k)) {
          macroAreaDict[macro_area]["emissions_avoided"][k] = result[k];
        } else {
          macroAreaDict[macro_area]["emissions_avoided"][k] += result[k];
        }
      });

      return Object.values(result).reduce((a, b) => a + b, 0) / Object.keys(result).length
    }

    // generate rows for aggrid: start the loop for each macro_area and for each sector
    var rows = [];
    Object.keys(macroAreaSectorDistinct).forEach((macro_area) => {
      Object.keys(macroAreaSectorDistinct[macro_area]).forEach((sector, index) => {
        const filtered_single_actions = accountableActions.filter(
          (e) => e.macro_area === macro_area && e.sector === sector
        );
        const filtered_grouped_actions = groupedActions.filter(
          (e) => e.macro_area === macro_area && e.sector === sector
        );
        const actions = [...filtered_single_actions, ...filtered_grouped_actions]


        // if we are doing the last sector of a macro area, save the the id
        if (index === Object.keys(macroAreaSectorDistinct[macro_area]).length - 1) {
          var latest_action_in_macro_area = actions[actions.length - 1]
          if (latest_action_in_macro_area.actions === undefined) {
            macroAreaDict[macro_area]["latest_id"] = setId("single", latest_action_in_macro_area.id);
          } else {
            macroAreaDict[macro_area]["latest_id"] = setId("grouped", latest_action_in_macro_area.id);
          }
        }


        // for each action there will be a row in aggrid + some other extra rows on the totals
        actions.forEach((action) => {
          var id
          if (action.actions !== undefined) {
            id = setId("grouped", action.id)
          } else {
            id = setId("single", action.id)
          }

          // get list gases
          var gases = action["emissions"].map(obj => obj.gas)


          // get computed emissions and capex
          var emissions_avoided = 0;
          var capex = 0;
          const workspaces = actionsWorkspace.filter(
            (aws) => aws.id === id
          );

          var emission_type
          if (props.chiave === "co2_equivalente") {
            emission_type = "co2_equivalent_avoided"
          } else {
            emission_type = "emissions_avoided"
          }

          var final_average
          workspaces.forEach((action_ws) => {
            capex += findValue(action_ws.financials_capex)
            // do emission computations
            if (action_ws.actions !== undefined) {
              if (props.chiave === "co2_equivalente") {
                gases.forEach(gas => {
                  final_average = getEmissions(getYearlyEmissionGroupedAction, action_ws["actions_list"], gas, emission_type, macro_area, sector)
                  if (String(final_average) !== "NaN") {
                    emissions_avoided += final_average
                  }
                })
              } else {
                final_average = getEmissions(getYearlyEmissionGroupedAction, action_ws["actions_list"], props.chiave.replace("ghg_", ""), emission_type, macro_area, sector)
                if (String(final_average) !== "NaN") {
                  emissions_avoided += final_average
                }
              }
            } else {
              if (props.chiave === "co2_equivalente") {
                gases.forEach(gas => {
                  final_average = getEmissions(getYearlyEmissionAction, action_ws, gas, emission_type, macro_area, sector)
                  if (String(final_average) !== "NaN") {
                    emissions_avoided += final_average
                  }
                })

              } else {
                final_average = getEmissions(getYearlyEmissionAction, action_ws, props.chiave.replace("ghg_", ""), emission_type, macro_area, sector)
                if (String(final_average) !== "NaN") {
                  emissions_avoided += final_average
                }
              }
            }
          })

          // update capex
          macroAreaDict[action.macro_area]["capex"] += capex;
          sectorDict[action.macro_area + action.sector]["capex"] +=
            capex;


          // append row
          rows.push({
            id: id,
            macro_area: macro_area,
            sector: sector,
            emission_baseline: rowsDict[macro_area] === undefined ? 0 : Object.values(rowsDict[macro_area]).reduce((a, b) => a + b, 0),
            action: action.name,
            start_date: action.start_date,
            end_date: action.end_date,
            emissions_avoided: emissions_avoided,
            capex: capex,
            percentage_reduction_macro_area: getReduction(
              rowsDict[macro_area],
              emissions_avoided
            ),
            percentage_reduction_total_emissions: getReduction(
              rowsDict,
              emissions_avoided
            ),
            descrizione: getDescription(id),
          });

          // compute baseline macro area and total (used later to compute the % reduction)
          var emission_baseline_macro_area = 0;
          if (rowsDict[macro_area] !== undefined) {
            Object.keys(rowsDict[macro_area]).forEach((key) => {
              emission_baseline_macro_area += rowsDict[macro_area][key];
            });
          }
          var emission_baseline_total = Object.values(rowsDict)
            .flatMap(innerObject => Object.values(innerObject))
            .reduce((sum, value) => sum + value, 0);

          // generate Total row per Sector
          if (sectorDict[macro_area + sector]["latest_id"] === id) {
            var emissions_avoided_sector = Object.keys(sectorDict[action.macro_area + action.sector]["emissions_avoided"]).length === 0 ? 0 : Object.values(sectorDict[action.macro_area + action.sector]["emissions_avoided"]).reduce((a, b) => a + b, 0) / Object.keys(sectorDict[action.macro_area + action.sector]["emissions_avoided"]).length
            rows.push({
              id:
                "TOTAL" + macro_area === ""
                  ? "macro_area"
                  : macro_area + sector === ""
                    ? "sector"
                    : sector,
              action: "TOTAL SECTOR",
              macro_area: macro_area,
              sector: sector,
              emission_baseline: 0,
              emissions_avoided: emissions_avoided_sector,
              percentage_reduction_macro_area: cleanRatio(emissions_avoided_sector, emission_baseline_macro_area),
              percentage_reduction_total_emissions: cleanRatio(emissions_avoided_sector, emission_baseline_total),
              capex:
                sectorDict[action.macro_area + action.sector]["capex"],
            });
          }

          // generate Total row per Macro Area
          if (macroAreaDict[macro_area]["latest_id"] === id) {
            var emissions_avoided_macro_area = Object.keys(macroAreaDict[action.macro_area]["emissions_avoided"]).length === 0 ? 0 : Object.values(macroAreaDict[action.macro_area]["emissions_avoided"]).reduce((a, b) => a + b, 0) / Object.keys(macroAreaDict[action.macro_area]["emissions_avoided"]).length
            var macro_area_baseline_emissions = 0;
            if (rowsDict[macro_area] !== undefined) {
              Object.keys(rowsDict[macro_area]).forEach((sector) => {
                macro_area_baseline_emissions += rowsDict[macro_area][sector];
              });
            }
            rows.push({
              id: "TOTAL" + macro_area,
              action: "TOTAL MACRO AREA",
              macro_area: macro_area,
              emission_baseline: macro_area_baseline_emissions,
              emissions_avoided: emissions_avoided_macro_area,
              percentage_reduction_macro_area: cleanRatio(emissions_avoided_macro_area, emission_baseline_macro_area),
              percentage_reduction_total_emissions: cleanRatio(emissions_avoided_macro_area, emission_baseline_total),
              capex: macroAreaDict[action.macro_area]["capex"],
            });
          }
        });
      });
    });


    // if you are not doing pinnedBottomRowData it's finished else do the computations for the latest row
    if (only_total === false) {
      props.setTableModal(rows) // used in dashboard
      return rows
    } else {
      // compute total baseline
      var emission_baseline_total = Object.values(rowsDict)
        .flatMap(innerObject => Object.values(innerObject))
        .reduce((sum, value) => sum + value, 0);

      // compute total computed emission avoided
      var emissions_avoided_computed_total_obj = {}
      Object.keys(macroAreaDict).forEach(k => {
        Object.keys(macroAreaDict[k].emissions_avoided).forEach(y => {
          if (emissions_avoided_computed_total_obj[y] === undefined) {
            emissions_avoided_computed_total_obj[y] = macroAreaDict[k].emissions_avoided[y]
          } else {
            emissions_avoided_computed_total_obj[y] += macroAreaDict[k].emissions_avoided[y]
          }
        })
      })
      var emissions_avoided_computed_total = Object.keys(emissions_avoided_computed_total_obj).length === 0 ? 0 : Object.values(emissions_avoided_computed_total_obj).reduce((a, b) => a + b, 0) / Object.keys(emissions_avoided_computed_total_obj).length

      // compute total computed capex
      var capex_computed_total = 0
      Object.keys(macroAreaDict).forEach(k => {
        capex_computed_total += macroAreaDict[k].capex
      })

      var totals_final_row = {
        id: "TOTAL",
        sector: "Total",
        emission_baseline: emission_baseline_total,
        emissions_avoided: emissions_avoided_computed_total,
        percentage_reduction_total_emissions: cleanRatio(emissions_avoided_computed_total, emission_baseline_total),
        capex: capex_computed_total,
      }

      props.setTotalCards(totals_final_row) // used in dashboard
      return [totals_final_row]
    }
  };

  const grid = useRef();
  const mounted = useRef();
  useEffect(async () => {
    mounted.current = false;
    setRowData(null);
  }, [props.chiave]);

  useEffect(async () => {
    if (!mounted.current) {
      setRowData(getData());
      mounted.current = true;
    } else {
      const newRows = getData();
      const arr_checks = ["id"];
      if (newRows !== undefined && newRows.length >= 1) {
        applyTransactionFn(grid, newRows, arr_checks, true);
      }
    }
  }, [
    props.myActions,
    props.data.pathways,
    ...props.data.gases.map((g) => props.data[`baseline_ghg_${g}`]),
    props.data.baseline_co2_equivalente,
    ...props.data.gases.map((g) => props.data[`target_ghg_${g}`]),
    props.data.target_co2_equivalente,
    props.chiave,
  ]);

  var isDragging = false;
  var listChanges = [];
  const fnCellChanged = async (myList) => {
    var raise_error = false;
    var regExp = /^\d*(?:[.,]\d+)?$/;
    var listFiltered = [];
    myList.forEach((params) => {
      // don't make any check since here we can only change the column "descrizione" and it's an open field
      // if (params.column.colId !== "descrizione") {
      //   if (
      //     params.newValue === "" ||
      //     params.newValue === undefined ||
      //     params.newValue === null
      //   ) {
      //     params.data[params.colDef.field] = parseFloat(params.oldValue);

      //     params.newValue = parseFloat(params.oldValue);
      //   } else if (
      //     String(params.newValue).match(regExp) === null ||
      //     String(params.newValue).includes(" ")
      //   ) {
      //     raise_error = true;
      //     params.data[params.colDef.field] = params.oldValue;
      //     params.newValue = params.oldValue;
      //   }
      // }
      listFiltered.push(params);
    });

    var final = [];
    const ids = listFiltered.map((obj) => {
      return obj.data.id;
    });
    props.data["target_" + props.chiave].forEach((row) => {
      if (!ids.includes(row.id)) {
        final.push(row);
      }
    });
    var dictFinalCapexDescription = {};
    var localFinal = [];
    listFiltered.forEach((obj) => {
      var row = obj.data;

      var id = obj.data.id;

      ["co2_equivalente", ...props.data.gases].forEach((gas) => {
        if (gas !== props.chiave.replace("ghg_", "")) {
          var key = "ghg_" + gas;
          if (gas === "co2_equivalente") {
            key = "co2_equivalente";
          }
          const match = props.data["target_" + key].find((e) => e.id === id);
          if (localFinal.find((e) => e.id === id) === undefined) {
            if (match === undefined) {
              localFinal.push({
                id: id,
                descrizione: obj.data["descrizione"],
              });
            } else {
              localFinal.push({
                ...match,
                descrizione: obj.data["descrizione"],
              });
            }
          }

          dictFinalCapexDescription["target_" + key] = [
            ...localFinal,
            ...props.data["target_" + key].filter((e) => e.id !== id),
          ];
        }
      });

      const sameObj = final.find((e) => e.id === row.id);
      if (sameObj === undefined) {
        final.push({
          id: row.id,
          descrizione: row.descrizione,
        });
      }
    });
    Object.keys(dictFinalCapexDescription).forEach((key) => {
      dispatch({
        type: props.type,
        payload: {
          key: key,
          value: dictFinalCapexDescription[key],
        },
      });
    });
    //dispatch
    dispatch({
      type: props.type,
      payload: {
        key: "target_" + props.chiave,
        value: final,
      },
    });

    // if there are errors (i.e. letters) show error --> in any case continue (there is no an ELSE)
    if (raise_error === true) {
      InfoAlert("Invalid format! Only positive numbers are allowed");
    }

    // reset list
    listChanges = [];
  };

  const displayPercentage = (params) => {
    if (params.colDef.field === "percentage_reduction_macro_area" && params.data.id === "TOTAL") {
      return ""
    } else {
      return (params.value).toLocaleString("eng", {
        style: "percent",
        minimumFractionDigits: 2,
      });
    }
  }

  return (
    <div style={{ height: "100.00%" }}>
      {rowData !== null ? (
        <div
          className="excel-style ag-theme-balham"
          style={{
            height: "95%",
            padding: "20px 20px 0px 20px",
          }}
        >
          <AgGridReact
            ref={grid}
            pinnedBottomRowData={getData(true)}
            enableFillHandle={true}
            enableRangeSelection={true}
            stopEditingWhenCellsLoseFocus={true}
            processDataFromClipboard={(params) => {
              var data = [];
              for (var i = 0; i < params.data.length; i++) {
                if (
                  params.data[i].length !== 1 ||
                  (params.data[i][0] !== "" && params.data[i][0] !== " ")
                ) {
                  data.push(params.data[i]);
                }
              }
              return data;
            }}
            rowData={rowData}
            suppressFieldDotNotation={true}
            getRowStyle={(params) => {
              var style = {};
              style["border"] = "0px";
              return style;
            }}
            defaultColDef={{
              minWidth: 100,
              resizable: true,
              filter: true,
              filterParams: {
                buttons: ["reset"],
                newRowsAction: "keep",
                caseSensitive: true,
              },
              editable: (params) => {
                if (
                  params.colDef.field === "macro_area" ||
                  params.colDef.field === "sector" ||
                  params.colDef.field === "emission_baseline" ||
                  params.colDef.field === "action" ||
                  params.colDef.field === "emissions_avoided" ||
                  params.colDef.field === "percentage_reduction_macro_area" ||
                  params.colDef.field ===
                  "percentage_reduction_total_emissions" ||
                  params.colDef.field === "capex" ||
                  params.data.action.includes("TOTAL") ||
                  params.data.id === "TOTAL"
                ) {
                  return false;
                } else return true;
              },
              cellStyle: (params) => {
                var style = {
                  cursor: "default",
                };
                const grayColor = "rgba(180, 180, 180, 0.5)";
                // gray columns not editable
                if (
                  params.colDef.field === "emission_baseline" ||
                  params.colDef.field === "emissions_avoided" ||
                  params.colDef.field === "percentage_reduction_macro_area" ||
                  params.colDef.field ===
                  "percentage_reduction_total_emissions" ||
                  params.colDef.field === "capex"
                ) {
                  style["background-color"] = grayColor;
                }
                // manage Total sector rows
                if (params.data.action === "TOTAL SECTOR") {
                  style["fontWeight"] = "bold";
                  if (params.colDef.field !== "macro_area") {
                    style["background-color"] = grayColor;
                  }
                }
                // manage Total macro area rows
                if (params.data.action === "TOTAL MACRO AREA") {
                  style["fontWeight"] = "bold";
                  if (params.colDef.field === "macro_area") {
                    style["border-top"] = "1px solid gray";
                    style["border-left"] = "1px solid gray";
                    style["border-bottom"] = "1px solid gray";
                  } else {
                    style["border-top"] = "1px solid gray";
                    style["border-bottom"] = "1px solid gray";
                  }
                }
                if (params.data.id === "TOTAL") {
                  style = { background: grayColor };
                  if (params.data.id === "TOTAL") {
                    style["fontWeight"] = "bold";
                  }
                }
                // manage start date and end date
                if (params.colDef.field === "start_date" || params.colDef.field === "end_date") {
                  style["display"] = "flex"
                  style["alignItems"] = "center"
                  style["justifyContent"] = "center"
                }
                // manage emissions_avoided_target when start_date and end_date are outside the period of the workspace
                var error_level = checkActionPeriod(params, props)
                if (
                  (
                    params.colDef.field === "percentage_reduction_macro_area" ||
                    params.colDef.field === "percentage_reduction_total_emissions"
                  ) && error_level === 2 && params.data.descrizione !== undefined) {
                  style["color"] = "red"
                }
                return style;
              },
            }}
            onCellValueChanged={(params) => {
              listChanges.push(params);
              if (params.source !== "paste" && isDragging === false) {
                fnCellChanged(listChanges);
              }
            }}
            onPasteEnd={(params) => {
              fnCellChanged(listChanges);
            }}
            onDragStarted={(params) => {
              isDragging = true;
            }}
            onDragStopped={(params) => {
              isDragging = false;
              // check if there are no changes because isDragging becomes true even if you simply drag without using the fill operations
              if (listChanges.length > 0) {
                fnCellChanged(listChanges);
              }
            }}
            frameworkComponents={{
              dateColumns: DateColumns
            }}
          >
            <AgGridColumn
              field={"macro_area"}
              pinned="left"
              headerName={"Macro Area"}
              valueFormatter={(params) => {
                if (params.data.action === "TOTAL MACRO AREA") {
                  return "Total";
                } else if (params.data.id === "TOTAL") {
                } else {
                  const prevRow = params.api.getDisplayedRowAtIndex(
                    params.node.rowIndex - 1
                  );
                  if (
                    prevRow !== undefined &&
                    prevRow.data.macro_area === params.data.macro_area
                  ) {
                    return "";
                  }
                  return params.data["macro_area"];
                }
              }}
            ></AgGridColumn>
            <AgGridColumn
              field={"emission_baseline"}
              headerName={"Emissions Baseline [t/y]"}
              pinned="left"
              width={220}
              valueFormatter={(params) => {
                if (params.data.id === "TOTAL") {
                } else {
                  const prevRow = params.api.getDisplayedRowAtIndex(
                    params.node.rowIndex - 1
                  );
                  const nextRow = params.api.getDisplayedRowAtIndex(
                    params.node.rowIndex - 1
                  );
                  if (
                    // params.data.action !== "TOTAL SECTOR" &&
                    // params.data.action !== "TOTAL MACRO AREA"
                    prevRow !== undefined &&
                    params.data.sector !== undefined &&
                    (prevRow.data.sector === params.data.sector ||
                      prevRow.data.macro_area === params.data.macro_area)
                  ) {
                    return "";
                  }
                }
                return params.data["emission_baseline"].toLocaleString("eng", {
                  maximumFractionDigits: 0,
                });
              }}
            ></AgGridColumn>
            <AgGridColumn
              field={"sector"}
              headerName={"Sector"}
              valueFormatter={(params) => {
                if (params.data.action === "TOTAL SECTOR") {
                  return "Total";
                } else if (params.data.id === "TOTAL") {
                } else {
                  const prevRow = params.api.getDisplayedRowAtIndex(
                    params.node.rowIndex - 1
                  );
                  if (
                    prevRow !== undefined &&
                    prevRow.data.sector === params.data.sector
                  ) {
                    return "";
                  }
                  return params.data["sector"];
                }
              }}
            ></AgGridColumn>
            <AgGridColumn
              field={"action"}
              headerName={"Action"}
              width={280}
              valueFormatter={(params) => {
                if (
                  params.data.action === "TOTAL MACRO AREA" ||
                  params.data.action === "TOTAL SECTOR"
                ) {
                  return "";
                } else {
                  return params.data["action"];
                }
              }}
            ></AgGridColumn>
            <AgGridColumn headerName="Date">
              <AgGridColumn
                field={"start_date"}
                headerName={"start"}
                width={110}
                cellRenderer='dateColumns'
                cellRendererParams={{
                  allProps: props,
                }}
              ></AgGridColumn>
              <AgGridColumn
                field={"end_date"}
                headerName={"end"}
                width={110}
                cellRenderer='dateColumns'
                cellRendererParams={{
                  allProps: props,
                }}
              ></AgGridColumn>
            </AgGridColumn>
            <AgGridColumn headerName="Emissions Avoided [t/y]">
              <AgGridColumn
                field={"emissions_avoided"}
                headerName={"Computed"}
                valueFormatter={params => {
                  if (String(params.value) !== "NaN") {
                    return params.value.toLocaleString("eng", {
                      maximumFractionDigits: 0,
                    })
                  }
                }}
                width={230}
              ></AgGridColumn>
            </AgGridColumn>
            <AgGridColumn headerName="% of Baseline reduction">
              <AgGridColumn
                field={"percentage_reduction_macro_area"}
                headerName={"over Macro Area"}
                width={230}
                valueFormatter={displayPercentage}
              ></AgGridColumn>
              <AgGridColumn
                field={"percentage_reduction_total_emissions"}
                headerName={"over Total Emissions"}
                width={250}
                valueFormatter={displayPercentage}
              ></AgGridColumn>
            </AgGridColumn>
            <AgGridColumn headerName="CAPEX">
              <AgGridColumn
                field={"capex"}
                headerName={"Computed"}
                valueFormatter={params => {
                  if (String(params.value) !== "NaN") {
                    return params.value.toLocaleString("eng", {
                      maximumFractionDigits: 0,
                    })
                  }
                }}
                width={230}
              ></AgGridColumn>
            </AgGridColumn>
            <AgGridColumn
              field={"descrizione"}
              headerName={"Description"}
              width={460}
            ></AgGridColumn>
          </AgGridReact>
        </div>
      ) : null
      }
    </div >
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Data);
