import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setToolbarPage } from "../../actions/toolbar";
import { closeMenuOC } from "../../actions/handleOpenCloseActions";
import Sidebar from "../../layout/CLICC/Sidebar/Sidebar";
import Footer from "../../layout/CLICC/Footer/Footer";
import Navbar from "../../layout/CLICC/Navbar/Navbar";
import SubNavbar from "../../layout/CLICC/Navbar/SubNavbar";
import Content from "./components/Content";

export const ActionType = (props) => {
    useEffect(() => {
        props.setToolbarPage("Users");
        props.closeMenuOC();
    }, []);

    const pages = [
        {
            name: "Users",
            link: "/clicc/dashboard/users",
        },
    ];
    return (
        <div>
            <Sidebar></Sidebar>
            <Navbar></Navbar>
            <SubNavbar pages={pages}></SubNavbar>
            <Content pages={pages}></Content>
            <Footer></Footer>
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { setToolbarPage, closeMenuOC })(
    ActionType
);
