import React, { useState } from "react";
import { useWindowDimensions } from "../../../../../utils/useWindowDimensions";
import { connect, useDispatch } from "react-redux";
import { getHeightWithFooterWONavbar } from "../../../../../utils/getHeightWithFooterWONavbar";
import { Row, Col } from "reactstrap";
import TitleCard from "../../../../../components/TitleCard/TitleCard";
import { Checkbox, TextField } from "@mui/material";
import axios from "axios";
import {
  API_URL,
  SET_OPENED,
  RESET_CREATE,
} from "../../../../../actions/types";
import { tokenConfig } from "../../../../../utils/tokenConfig";
import SuccessAlert from "../../../../../components/Modals/SuccessAlert";
import InfoAlert from "../../../../../components/Modals/InfoAlert";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { triggerRefresh } from "../../../../../utils/triggerRefresh";

function Content(props) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();
  const history = useHistory();

  const makeCall = async (status) => {
    if (props.data.name === "") {
      InfoAlert("Name cannot be empty");
    } else if (props.data.description === "") {
      InfoAlert("Description cannot be empty");
    } else if (!moment(props.data.start_date).isValid()) {
      InfoAlert("Start date is not valid");
    } else if (!moment(props.data.end_date).isValid()) {
      InfoAlert("End date is not valid");
    } else {
      if (props.api_call === "post") {
        const res = await axios.post(
          `${API_URL}/clicc/workspace/`,
          { ...props.data, status: status },
          tokenConfig()
        );
        dispatch({
          type: RESET_CREATE,
        });
        SuccessAlert("Workspace created successfully");
        props.setActiveTab("pathways");
        triggerRefresh();
      } else if (props.api_call === "put") {
        await axios.put(
          `${API_URL}/clicc/workspace/${props.data.id}/`,
          { ...props.data, status: status },
          tokenConfig()
        );
        SuccessAlert("Workspace updated successfully");
        triggerRefresh();
      }
    }
  };
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#E4E6E8",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        paddingLeft: 0,
        paddingBottom: props.layout.footer_height,
        overflow: "auto",
      }}
    >
      {/* workspace without padding */}
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 10,
        }}
      >
        {/* workspace */}
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <Row style={{ margin: 0 }}>
            <Col
              lg="4"
              md="6"
              sm="12"
              style={{ height: "100%", marginTop: 10 }}
            >
              <div
                style={{
                  ...props.layout.generic_card,
                }}
              >
                <TitleCard
                  title="Assign a name"
                  customBorderHeight={2}
                  customTitleSize={{ large: 19, small: 17 }}
                ></TitleCard>
                <div style={{ padding: "15px 15px 25px 15px" }}>
                  <TextField
                    variant="standard"
                    value={props.data.name}
                    inputProps={{
                      style: {
                        fontWeight: 500,
                        padding: 0,
                        fontFamily: "Montserrat",
                        marginTop: 10,
                      },
                    }}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      dispatch({
                        type: props.type,
                        payload: {
                          key: "name",
                          value: e.target.value,
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ margin: 0 }}>
            <Col xs="12" style={{ height: "100%" }}>
              <div
                style={{
                  ...props.layout.generic_card,
                  marginTop: 20,
                }}
              >
                <TitleCard
                  title="Description"
                  customBorderHeight={2}
                  customTitleSize={{ large: 19, small: 17 }}
                ></TitleCard>
                <div style={{ padding: "15px 15px 25px 15px" }}>
                  <TextField
                    variant="outlined"
                    placeholder="..."
                    multiline
                    rows={height < 850 ? 3 : 6}
                    value={props.data.description}
                    inputProps={{
                      style: {
                        fontWeight: 500,
                        padding: 0,
                        fontFamily: "Montserrat",
                      },
                    }}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      dispatch({
                        type: props.type,
                        payload: {
                          key: "description",
                          value: e.target.value,
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ margin: 0 }}>
            <Col
              lg="4"
              md="6"
              sm="12"
              style={{ height: "100%", marginTop: 10 }}
            >
              <div
                style={{
                  ...props.layout.generic_card,
                  marginTop: 10,
                }}
              >
                <TitleCard
                  title="Other details"
                  customBorderHeight={2}
                  customTitleSize={{ large: 19, small: 17 }}
                ></TitleCard>
                <div
                  style={{
                    height: "50px",
                    marginLeft: "20px",
                    marginTop: "20px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      onChange={(e) => {
                        dispatch({
                          type: props.type,
                          payload: {
                            key: "default",
                            value: e.target.checked,
                          },
                        });
                      }}
                      checked={props.data.default}
                      style={{ marginLeft: "-3px" }}
                    />
                    <span
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                        color: "#6D6D6D",
                        marginLeft: 3,
                      }}
                    >
                      Set as default workspace
                    </span>
                  </div>
                  {/* <Checkbox
                    variant="standard"
                    value={props.data.default}
                    // inputProps={{
                    //   style: {
                    //     fontWeight: 500,
                    //     fontFamily: "Montserrat",
                    //   },
                    // }}
                    onChange={(e) => {
                      dispatch({
                        type: props.type,
                        payload: {
                          key: "default",
                          value: e.target.checked,
                        },
                      });
                    }}
                  /> */}
                </div>
              </div>
            </Col>
          </Row>
          <div style={{ display: "flex", paddingBottom: 120 }}>
            <div
              onClick={async () => {
                makeCall("Draft");
              }}
              style={{
                marginLeft: 10,
                marginTop: 20,
                width: "fit-content",
                backgroundColor: props.layout.color_sidebar,
                color: "white",
                fontFamily: "Montserrat",
                fontWeight: 500,
                fontSize: 16,
                padding: width < 770 ? "5px 8px 3px 8px" : "6px 20px 4px 20px",
                borderRadius: 10,
                cursor: "pointer",
              }}
            >
              {props.api_call === "post" ? "Save" : "Update"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Content);
