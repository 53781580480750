import React, { useState, useCallback } from "react"
import { useDropzone } from "react-dropzone";
import { FaCloudDownloadAlt } from "react-icons/fa"
import InfoAlert from "../../../../../components/Modals/InfoAlert"
import { FaCheckCircle, FaTrash } from "react-icons/fa";


const UploadJSON = (props) => {

    const onDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                const reader = new FileReader();
                reader.onload = async (evt) => {
                    const fileContent = evt.target.result;
                    try {
                        const jsonObject = JSON.parse(fileContent);
                        if (!Object.keys(jsonObject).includes("id")) {
                            InfoAlert("Json file has no key 'id'")
                        } else if (jsonObject.id.includes(" ")) {
                            InfoAlert("Key 'id' cannot have empty spaces")
                        } else if (props.idJson !== undefined && props.idJson !== jsonObject.id) {
                            InfoAlert("Cannot change 'id' key")
                        } else {
                            props.setJson({ file_name: file.name, file: jsonObject })
                        }
                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                    }
                };
                reader.readAsBinaryString(file);
            });
        },
        []
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: ".json",
    });
    return (
        <div>
            <div
                {...getRootProps({ className: "dropzone" })}
                style={
                    isDragActive
                        ? {
                            borderColor: "#4eacfe",
                            background: "rgba(76, 192, 243, 0.1)",
                        }
                        : null
                }
            >
                <div>
                    <FaCloudDownloadAlt
                        style={{ fontSize: 50, color: "#9C9EA4" }}
                    ></FaCloudDownloadAlt>
                    <h6 style={{ marginTop: 15, fontWeight: "bold" }}>
                        Upload json file
                    </h6>
                    <p style={{ color: "gray" }}>
                        Press here to <span className="text-primary">browse</span> for
                        a file to upload.
                    </p>
                </div>
                <input {...getInputProps()} />
            </div>
            {props.json !== null ?
                <div style={{ marginTop: 10 }}>
                    <FaCheckCircle style={{ color: "#198754", marginTop: "-2px", marginRight: 5 }}></FaCheckCircle>
                    {props.json.file_name !== undefined ? props.json.file_name : "File uploaded"}
                    <FaTrash onClick={() => props.setJson(null)} style={{ fontSize: 14, marginLeft: 10, marginTop: "-2px", color: "rgb(252, 39, 39)", cursor: "pointer" }}></FaTrash>
                </div>
                : null}

        </div>
    )
};

export default UploadJSON;
