import React, { useState, useEffect } from "react";
import { useWindowDimensions } from "../../../../../utils/useWindowDimensions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getHeightWithFooterWONavbar } from "../../../../../utils/getHeightWithFooterWONavbar";
import { Row, Col } from "reactstrap";
// import ImportActions from "./ImportActions";
import ActionDetails from "./ActionDetails";
import Pathway from "./Pathway";
import { FaSpinner } from "react-icons/fa";
import PeriodWS from "./PeriodWS";

function Content(props) {
  const [actionDetails, setActionDetails] = useState([]);
  const [actionSelected, setActionSelected] = useState([]);
  const { width, height } = useWindowDimensions();
  const history = useHistory();

  // monitor refresh in actions from other tabs
  useEffect(() => {
    if (props.myActions !== null) {
      setActionDetails(props.myActions)
    }
  }, [props.myActions])

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#E4E6E8",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        paddingLeft: 0,
        paddingBottom: props.layout.footer_height,
        overflow: "auto",
      }}
    >
      {/* workspace without padding */}
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 10,
        }}
      >
        {/* workspace */}
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          {props.myActions === null ? (
            <FaSpinner className="spinner"></FaSpinner>
          ) : (
            <Row style={{ margin: 0 }}>
              <Col
                xxl={width > 1800 ? "2" : "3"}
                xl="3"
                lg="3"
                md="4"
                sm="12"
                style={{ padding: 0 }}
              >
                <Row style={{ margin: 0 }}>
                  {/* <ImportActions
                    setActionDetails={setActionDetails}
                    myActions={props.myActions}
                    setActionSelected={setActionSelected}
                  ></ImportActions> */}
                  <PeriodWS
                    type={props.type}
                    data={props.data}>
                  </PeriodWS>
                  <ActionDetails
                    actionDetails={actionDetails}
                    actionSelected={actionSelected}
                    setActionSelected={setActionSelected}
                    myActions={props.myActions}
                    type={props.type}
                    data={props.data}
                  ></ActionDetails>
                </Row>
              </Col>
              <Pathway
                data={props.data}
                type={props.type}
                myActions={props.myActions}
              ></Pathway>
            </Row>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Content);
