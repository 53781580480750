import React, { useEffect, useState, useRef } from "react";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { connect, useDispatch } from "react-redux";
import { getHeightWithFooterWONavbar } from "../../../../utils/getHeightWithFooterWONavbar";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import { FaSpinner } from "react-icons/fa";
import axios from "axios";
import { API_URL } from "../../../../actions/types";
import { tokenConfig } from "../../../../utils/tokenConfig";
import ActionContract from "./ActionContract";
import moment from "moment";
import { applyTransactionFn } from "../../../../utils/applyTransactionFn";
import { checkActionColumnWidth } from "../../../../utils/checkActionColumnWidth";

function Content(props) {
  const [rowData, setRowData] = useState(null);
  const [reset, setReset] = useState(0);
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();
  // grid ref
  const myGrid = useRef();

  // define function to get data
  const getData = async () => {
    const res = await axios.get(`${API_URL}/clicc/workspace/`, tokenConfig());
    return res.data;
  };

  // on did mount, get data
  useEffect(async () => {
    setRowData(await getData());
  }, []);

  // monitor refresh
  useEffect(() => {
    const channel = new BroadcastChannel("clicc-channel");
    channel.addEventListener("message", async (e) => {
      if (e.data === "refresh") {
        // get data and apply changes
        const newRows = await getData();
        const arr_checks = ["id"];
        if (newRows.length >= 0) {
          applyTransactionFn(myGrid, newRows, arr_checks, true);
        }
      }
    });
    return () => channel.close();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#E4E6E8",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        paddingLeft: props.layout.width_sidebar,
        paddingBottom: props.layout.footer_height,
        overflow: "auto",
      }}
    >
      {/* workspace without padding */}
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 20,
        }}
      >
        {/* workspace */}
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          {rowData === null ? (
            <>
              <FaSpinner
                className="spinner"
                style={{ marginTop: "-2px", marginRight: 5 }}
              ></FaSpinner>
              Loading...
            </>
          ) : (
            <div
              className="excel-style ag-theme-balham"
              style={{
                height: height - 190,
              }}
            >
              <AgGridReact
                ref={myGrid}
                rowData={rowData}
                frameworkComponents={{
                  actionContract: ActionContract,
                }}
                defaultColDef={{
                  flex: 1,
                  resizable: true,
                  sortable: true,
                  filter: true,
                  filterParams: { buttons: ["reset"], newRowsAction: "keep", caseSensitive: true },
                  cellStyle: (params) => {
                    var style = {
                      cursor: "default",
                      display: "flex",
                      alignItems: "center",
                    };
                    if (params.column.colId === "status") {
                      style["justifyContent"] = "center";
                    }
                    return style;
                  },
                }}
              >
                <AgGridColumn
                  field=""
                  maxWidth={rowData.map(o => o.default).some(item => item) ? checkActionColumnWidth(props.auth.user_permissions, "workspace") + 21 : checkActionColumnWidth(props.auth.user_permissions, "workspace")}
                  minWidth={rowData.map(o => o.default).some(item => item) ? checkActionColumnWidth(props.auth.user_permissions, "workspace") + 21 : checkActionColumnWidth(props.auth.user_permissions, "workspace")}
                  suppressMenu={true}
                  cellRenderer="actionContract"
                  cellRendererParams={(params) => {
                    return {
                      opened_workspace: props.opened_workspace,
                      default: params.data.default,
                      auth: props.auth,
                    };
                  }}
                ></AgGridColumn>
                <AgGridColumn
                  field="id"
                  headerName="ID"
                  hide={true}
                  maxWidth={80}
                ></AgGridColumn>
                <AgGridColumn field="name"></AgGridColumn>
                <AgGridColumn field="description"></AgGridColumn>
                <AgGridColumn
                  field="start_date"
                  headerName="Start date"
                  valueFormatter={(params) => {
                    return moment(params.value).format("DD-MM-YYYY");
                  }}
                ></AgGridColumn>
                <AgGridColumn
                  field="end_date"
                  headerName="End date"
                  valueFormatter={(params) => {
                    return moment(params.value).format("DD-MM-YYYY");
                  }}
                ></AgGridColumn>
                <AgGridColumn
                  field="creation_date"
                  headerName="Creation date"
                  sort="desc"
                  hide={true}
                  valueFormatter={(params) => {
                    return moment(params.value).format("DD-MM-YYYY HH:mm:ss");
                  }}
                ></AgGridColumn>
              </AgGridReact>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layout: state.layout,
  opened_workspace: state.opened_workspace,
});

export default connect(mapStateToProps)(Content);
