import React, { useState, useEffect } from "react";
import { TextField, Checkbox } from "@mui/material";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { IS_LOADING, API_URL, GET_ERRORS } from "../../../../actions/types";
import axios from "axios";
import InfoAlert from "../../../../components/Modals/InfoAlert";
import SuccessAlert from "../../../../components/Modals/SuccessAlert";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { triggerRefresh } from "../../../../utils/triggerRefresh";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";

function ModalGroupActions(props) {
  const [groupedActionName, setGroupedActionName] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [rowData, setRowData] = useState([]);
  const { width, height } = useWindowDimensions()

  const dispatch = useDispatch();
  const history = useHistory();
  const toggle = () => props.setIsOpen(!props.isOpen);

  useEffect(() => {
    if (props.rowData !== null) {
      setRowData(props.rowData)
    }
  }, [props.rowData])

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={toggle}
      size="lg"
      unmountOnClose={true}
      style={{ fontFamily: "Montserrat" }}
    >
      <ModalHeader toggle={toggle}>Group accountable actions</ModalHeader>
      <ModalBody style={{ fontFamily: "Montserrat", fontWeight: 500 }}>
        {rowData.length === 0 ? (
          <div>No actions available</div>
        ) : (
          <>
            <TextField
              label={
                <div style={{ fontFamily: "Montserrat" }}>
                  Assign a name*&nbsp;
                </div>
              }
              value={groupedActionName}
              onChange={(e) => setGroupedActionName(e.target.value)}
              InputProps={{
                style: {
                  fontFamily: "Montserrat",
                },
              }}
              style={{ width: "100%" }}
            ></TextField>
            <div style={{ maxHeight: height * 0.6, overflow: "auto", marginTop: 20 }}>
              {rowData
                .filter((obj) => obj.actions === undefined)
                .map((obj, index) => {
                  return (
                    <Card body style={{ marginTop: index > 0 ? 20 : 0 }}>
                      <div>
                        <div style={{ display: "flex" }}>
                          <Checkbox
                            checked={selectedIds.includes(obj.id)}
                            onChange={(e) => {
                              if (selectedIds.includes(obj.id)) {
                                setSelectedIds(
                                  selectedIds.filter((id) => id !== obj.id)
                                );
                              } else {
                                setSelectedIds([...selectedIds, obj.id]);
                              }
                            }}
                            style={{
                              marginLeft: "-3px",
                              marginRight: 5,
                              marginTop: "-2px",
                            }}
                          />
                          <div style={{ fontWeight: "bold" }}>{obj.name}</div>
                        </div>
                        <div style={{ marginTop: 5 }}>
                          Description:{" "}
                          {obj.description === "" ? "--" : obj.description}
                        </div>
                      </div>
                    </Card>
                  );
                })}
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          style={{ fontWeight: 500 }}
          disabled={selectedIds.length === 0 ? true : false}
          onClick={async () => {
            if (groupedActionName === "") {
              InfoAlert("Name cannot be empty");
            } else if (selectedIds.length === 0) {
              InfoAlert("Select at least one action");
            } else {
              dispatch({
                type: IS_LOADING,
              });
              try {
                await axios.post(
                  `${API_URL}/clicc/grouped_actions/`,
                  {
                    name: groupedActionName,
                    actions: selectedIds,
                  },
                  tokenConfig()
                );

                dispatch({
                  type: IS_LOADING,
                });
                toggle();
                setSelectedIds([]);
                setGroupedActionName("");
                history.push("/clicc/dashboard/actions_my_actions");
                SuccessAlert("Grouped action created successfully");
                triggerRefresh()
              } catch (err) {
                dispatch({
                  type: IS_LOADING,
                });
                const errors = {
                  msg: err.response.data,
                  status: err.response.status,
                };
                dispatch({
                  type: GET_ERRORS,
                  payload: errors,
                });
              }
            }
          }}
        >
          Create ({selectedIds.length} actions)
        </Button>
        <Button onClick={toggle} style={{ fontWeight: 500 }}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalGroupActions;
