import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { connect } from "react-redux";
import { API_URL } from "../../../actions/types";
import axios from "axios";
import { tokenConfig } from "../../../utils/tokenConfig";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap";
import { TextField } from "@mui/material";
import SelectScreen from "./drag/SelectScreen";
import ImportScreen from "./drag/ImportScreen";

function ModalSplitScreen(props) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const [numWindows, setNumWindows] = useState(2);

  const toggle = () => props.setIsOpen(!props.isOpen);

  const minWindow = 0;
  const maxWindow = 19;

  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        size="xl"
        style={{ fontFamily: "Montserrat", fontWeight: 500 }}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>Split screen</ModalHeader>
        <ModalBody>
          <Container>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={activeTab === "1" ? "active" : ""}
                  onClick={() => setActiveTab("1")}
                  href="#"
                >
                  Select Screens
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "2" ? "active" : ""}
                  onClick={() => setActiveTab("2")}
                  href="#"
                >
                  Import
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {activeTab === "1" ? (
                  <SelectScreen
                    minWindow={minWindow}
                    maxWindow={maxWindow}
                    numWindows={numWindows}
                    setNumWindows={setNumWindows}
                    fields={props.fields}
                    setFields={props.setFields}
                  ></SelectScreen>
                ) : (
                  ""
                )}
              </TabPane>
              <TabPane tabId="2">
                {activeTab === "2" ? (
                  <ImportScreen
                    minWindow={minWindow}
                    maxWindow={maxWindow}
                    numWindows={numWindows}
                    setNumWindows={setNumWindows}
                    fields={props.fields}
                    setFields={props.setFields}
                  ></ImportScreen>
                ) : (
                  ""
                )}
              </TabPane>
            </TabContent>
          </Container>
        </ModalBody>
        <ModalFooter style={{ marginTop: 30 }}>
          <Button
            color="success"
            style={{ fontWeight: 500 }}
            disabled={
              numWindows < minWindow || numWindows > maxWindow ? true : false
            }
            onClick={() => {
              var wrapper = document
                .getElementById("wrapper")
                .getBoundingClientRect();
              var subWrapper = document.getElementById("sub_wrapper");
              var children = subWrapper.children;
              for (var i = 0; i < children.length; i++) {
                var child = children[i].getBoundingClientRect();
                var heightW =
                  (window.screen.availHeight - 20) *
                  (child.height / (wrapper.height + 10));
                var widthW =
                  window.screen.availWidth * (child.width / wrapper.width);
                var top =
                  (window.screen.availHeight - 20) *
                  ((child.top - wrapper.top - 10) / wrapper.height);
                var left =
                  window.screen.availWidth *
                  ((child.left - wrapper.left) / wrapper.width);
                if (top < 0) {
                  top = 0;
                }
                if (
                  top < window.screen.availHeight / 2 &&
                  heightW < window.screen.availHeight / 1.5
                ) {
                  heightW =
                    heightW -
                    Math.abs(window.screen.height - window.screen.availHeight) *
                    1.4;
                }
                window.open(
                  API_URL.replace(
                    "/api",
                    `/clicc/dashboard/${activeTab === "2"
                      ? children[i].innerText
                      : props.fields[children[i].id] === undefined
                        ? "homepage"
                        : props.fields[children[i].id]
                    }`
                  ),
                  "_blank",
                  "location=yes,height=" +
                  heightW +
                  ",width=" +
                  widthW +
                  ",top=" +
                  top +
                  ",left=" +
                  left +
                  ",scrollbars=yes,status=yes"
                );
              }
            }}
          >
            Apply
          </Button>
          <Button
            hidden={activeTab === "1" ? false : true}
            color="primary"
            style={{ fontWeight: 500 }}
            onClick={() => {
              setOpen(true);
            }}
          >
            Save
          </Button>
          <Button style={{ fontWeight: 500 }} onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={open}
        size="xl"
        style={{ fontFamily: "Montserrat", fontWeight: 500 }}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>Split screen</ModalHeader>
        <ModalBody>
          Select a name for this configuration:{" "}
          <TextField
            variant="standard"
            value={name}
            inputProps={{
              style: {
                fontWeight: 500,
                fontFamily: "Montserrat",
              },
            }}
            onChange={(e) => {
              if (e.target.value !== "") {
                setName(e.target.value);
              }
            }}
            style={{ marginLeft: 5, marginTop: "-5px" }}
          />
        </ModalBody>
        <ModalFooter style={{ marginTop: 30 }}>
          <Button
            hidden={activeTab === "1" ? false : true}
            color="primary"
            style={{ fontWeight: 500 }}
            disabled={name === ""}
            onClick={async () => {
              var wrapper = document
                .getElementById("wrapper")
                .getBoundingClientRect();
              var subWrapper = document.getElementById("sub_wrapper");
              var children = subWrapper.children;
              var htmlDict = {
                wrapper: wrapper,
                subWrapper: subWrapper.getBoundingClientRect(),
                children: {},
              };
              for (var i = 0; i < children.length; i++) {
                var child = children[i].getBoundingClientRect();
                var heightW =
                  (window.screen.availHeight - 20) *
                  (child.height / (wrapper.height + 10));
                var widthW =
                  window.screen.availWidth * (child.width / wrapper.width);
                var top =
                  (window.screen.availHeight - 20) *
                  ((child.top - wrapper.top - 10) / wrapper.height);
                var left =
                  window.screen.availWidth *
                  ((child.left - wrapper.left) / wrapper.width);
                if (top < 0) {
                  top = 0;
                }
                if (
                  top < window.screen.availHeight / 2 &&
                  heightW < window.screen.availHeight / 1.8
                ) {
                  heightW =
                    heightW -
                    Math.abs(window.screen.height - window.screen.availHeight) *
                    1.4;
                }
                htmlDict["children"][children[i].id] = {
                  ...Object.fromEntries(
                    Array.from(
                      Object.keys(DOMRectReadOnly.prototype).filter(
                        (k) => !isNaN(child[k])
                      ),
                      (k) => [k, child[k]]
                    )
                  ),
                  field:
                    props.fields[children[i].id] === undefined
                      ? "homepage"
                      : props.fields[children[i].id],
                };
                window.open(
                  API_URL.replace(
                    "/api",
                    `/clicc/dashboard/${props.fields[children[i].id] === undefined
                      ? "homepage"
                      : props.fields[children[i].id]
                    }`
                  ),
                  "_blank",
                  "location=yes,height=" +
                  heightW +
                  ",width=" +
                  widthW +
                  ",top=" +
                  top +
                  ",left=" +
                  left +
                  ",scrollbars=yes,status=yes"
                );
              }
              await axios.post(
                `${API_URL}/clicc/split_screen/`,
                {
                  name: name,
                  values: htmlDict,
                },
                tokenConfig()
              );
              setOpen(false);
            }}
          >
            Save and Apply
          </Button>
          <Button
            style={{ fontWeight: 500 }}
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  fields: state.selected_values.fields,
});

export default connect(mapStateToProps)(ModalSplitScreen);
