export const applyTransactionFn = (
  grid,
  newRows,
  arr_checks,
  preserve_order = false
) => {
  let checker = (arr) => arr.every((v) => v === true);

  // remove items
  var itemsToRemove = [];
  grid.current.api.forEachNode((rowNode, index) => {
    var found = false;
    if (rowNode.data !== undefined) {
      newRows.forEach((obj, indexInner) => {
        if (preserve_order === true) {
          if (
            // rowNode.data.id !== "total" &&
            index === indexInner &&
            checker(
              arr_checks.map((check) => rowNode.data[check] === obj[check])
            )
          ) {
            found = true;
          }
        } else {
          if (
            checker(
              arr_checks.map((check) => rowNode.data[check] === obj[check])
            )
          ) {
            found = true;
          }
        }
      });
      if (found === false) {
        var data = rowNode.data;
        itemsToRemove.push(data);
      }
    }
  });
  grid.current.api.applyTransaction({
    remove: itemsToRemove,
  });

  // add / update
  var itemsToAdd = [];
  var itemsToUpdate = [];
  newRows.forEach((obj) => {
    var add_obj = true;
    var rowNodeToUpdate;
    grid.current.api.forEachNode((rowNode, index) => {
      if (rowNode.data !== undefined) {
        if (
          checker(arr_checks.map((check) => rowNode.data[check] === obj[check]))
        ) {
          add_obj = false;
          rowNodeToUpdate = rowNode;
        }
      }
    });
    if (add_obj === true) {
      itemsToAdd.push(obj);
    } else {
      var data = rowNodeToUpdate.data;
      for (var key of Object.keys(obj)) {
        data[key] = obj[key];
      }
      // check if there are some keys to remove (when updating a row check if some columns have been removed)
      Object.keys(data).forEach(k => {
        if (!Object.keys(obj).includes(k)) {
          delete data[k]
        }
      })
      itemsToUpdate.push(data);
    }
  });

  grid.current.api.applyTransaction({
    add: itemsToAdd,
  });
  grid.current.api.applyTransaction({
    update: itemsToUpdate,
  });
};
