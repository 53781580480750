import React, { useState } from "react";

export default function CheckboxHeader(props) {
    const [headerChecked, setHeaderChecked] = useState(false);

    const checkedHandler = (e) => {

        var newIDs = { ...props.getDefaultRef() };
        if (e.target.checked === true) {
            props.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
                newIDs[rowNode.data.id] = true // used later to update the ref
                rowNode.data.selected = true; // this updates only the internal value, not the UI
            });
        }
        else {
            props.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
                newIDs[rowNode.data.id] = false // used later to update the ref
                rowNode.data.selected = false; // this updates only the internal value, not the UI
            });
        }

        // select only IDs equal to True
        var trueIDs = Object.fromEntries(
            Object
                .entries(newIDs)
                .filter(([, val]) => val === true)
        );

        // update the ref
        props.setDefaultRef(trueIDs)

        // toggle checkbox in UI based on previous changes
        props.api.refreshCells();

        // set header checkbox as true or false based on the click
        setHeaderChecked(e.target.checked);

        // update redux
        props.updateRedux(trueIDs)
    };


    return (
        <input
            id={`idCheckboxHeader_${props.idParam}`}
            type="checkbox"
            onClick={checkedHandler}
            checked={headerChecked}
            readOnly
            style={{
                accentColor: "seagreen",
                backgroundColor: "white",
                marginLeft: 2,
            }}
        />
    );
}
