export const roundNumberInCard = (num, uom = null, nospace = false) => {
    var toReturn
    if (Math.abs(num) < 100) {
        toReturn = num.toLocaleString("eng", {
            maximumFractionDigits: 2,
        })
    } else if (Math.abs(num) < 1000000) {
        toReturn = num.toLocaleString("eng", {
            maximumFractionDigits: 0,
        })
    } else if (Math.abs(num) < 1000000000) {
        toReturn = `${(num / 1000000).toLocaleString("eng", {
            maximumFractionDigits: 2,
        })} M`
    } else {
        toReturn = `${(num / 1000000000).toLocaleString("eng", {
            maximumFractionDigits: 2,
        })} G`
    }

    if (uom !== null) {
        if (nospace === false) {
            toReturn = `${toReturn} ${uom}`
        } else {
            toReturn = `${toReturn}${uom}`
        }
    }
    return toReturn
}

