import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import axios from "axios";
import { API_URL } from "../../../../actions/types";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { FaTrashAlt } from "react-icons/fa";

function ImportScreen(props) {
  const [selected, setSelected] = useState("");
  const [updated, setUpdated] = useState(0);
  const [templates, setTemplates] = useState(null);
  useEffect(() => {
    async function call() {
      const templatesRequest = await axios.get(
        `${API_URL}/clicc/split_screen/`,
        tokenConfig()
      );
      setTemplates(templatesRequest.data);
    }
    call();
  }, [updated]);

  const localStyle = {
    position: "absolute",
    border: "1px solid gray",
    backgroundColor: "white",
  };
  return (
    <div
      style={{
        border: "1px solid #dee2e6",
        padding: 20,
        backgroundColor: "white",
      }}
    >
      {templates !== null ? (
        <div style={{ display: "flex" }}>
          <FormControl fullWidth>
            <InputLabel id="select-template"
              style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}>Select layout</InputLabel>
            <Select
              label="Select Template"
              labelId="select-template"
              value={selected}
              onChange={(e) => {
                setSelected(e.target.value);
              }}
              style={{ height: "55px" }}
              sx={{ width: "100%" }}
            >
              {templates.map((t) => {
                return (
                  <MenuItem key={t.name} value={t.name} style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                  >
                    {t.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FaTrashAlt
            hidden={selected === ""}
            onClick={async (e) => {
              await axios.delete(
                `${API_URL}/clicc/split_screen/${templates.find((d) => d.name === selected).id
                }`,
                tokenConfig()
              );
              setUpdated(updated + 1);
              setSelected("");
            }}
            color={"red"}
            style={{ marginTop: "17px", marginLeft: "10px", cursor: "pointer" }}
          />
        </div>
      ) : null}

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 30,
        }}
      >
        <div
          style={{
            width: "100%",
            maxHeight:
              window.screen.width > 4000
                ? 1200
                : window.screen.availHeight / 2 + 20,
            maxWidth:
              window.screen.width > 4000 ? 1200 : window.screen.availWidth / 2,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              width: "100%",
              paddingBottom: `${(window.screen.height / window.screen.width) * 100
                }%`,
              height: "100%",
            }}
          >
            <div
              id="wrapper"
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                left: 0,
                border: "5px solid gray",
                borderRadius: 5,
                padding: 2,
                display: "flex",
                gap: "2px",
              }}
            >
              <div
                style={{
                  width: "inherit",
                  height: "inherit",
                  position: "unset",
                }}
                id={"sub_wrapper"}
              >
                {selected !== ""
                  ? Object.entries(
                    templates.find((e) => e.name === selected)["values"]
                      .children
                  ).map(([chiave, valore]) => {
                    const localWrapper = document
                      .getElementById("wrapper")
                      .getBoundingClientRect();
                    const childStyle = Object.fromEntries(
                      Object.entries(valore).map(([k, v]) => {
                        if (k === "left") {
                          return [
                            k,

                            ((v -
                              templates.find((e) => e.name === selected)[
                              "values"
                              ]["wrapper"][k]) /
                              templates.find((e) => e.name === selected)[
                              "values"
                              ]["wrapper"]["width"]) *
                            localWrapper["width"],
                          ];
                        } else if (k === "top") {
                          return [
                            k,

                            ((v -
                              templates.find((e) => e.name === selected)[
                              "values"
                              ]["wrapper"][k]) /
                              templates.find((e) => e.name === selected)[
                              "values"
                              ]["wrapper"]["height"]) *
                            localWrapper["height"],
                          ];
                        } else if (k === "width" || k === "height") {
                          return [
                            k,
                            (v /
                              templates.find((e) => e.name === selected)[
                              "values"
                              ]["wrapper"][k]) *
                            localWrapper[k],
                          ];
                        } else {
                          return [k, v];
                        }
                      })
                    );
                    return (
                      <div
                        key={chiave}
                        style={{
                          ...childStyle,
                          maxWidth: "98%",
                          maxHeight: "98%",
                          ...localStyle,
                          textAlign: "center",
                          paddingTop: "20px",
                          wordWrap: "break-word",
                        }}
                      >
                        {valore.field}
                      </div>
                    );
                  })
                  : null}
              </div>
            </div>
            {window.screen.width < 4000 ? (
              <div
                style={{
                  width: "40%",
                  height: "15px",
                  position: "absolute",
                  bottom: "-10px",
                  border: "5px solid gray",
                  borderRadius: 5,
                }}
              ></div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ImportScreen);
