import {
  RESET_CREATE_NEW_ACTION,
  UPDATE_CREATE_NEW_ACTION,
} from "../actions/types";
import { initializeNewAction } from "../utils/initializeNewAction";

const initialState = {
  ...initializeNewAction(),
};

export default function toolbar(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CREATE_NEW_ACTION:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case RESET_CREATE_NEW_ACTION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
