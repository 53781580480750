import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Card, ModalFooter } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import { Row, Col, Button } from "reactstrap";
import { MenuItem, Select, TextField } from "@mui/material";
import { FaSpinner } from "react-icons/fa";
import InfoAlert from "../../../../components/Modals/InfoAlert";

function ModalAddStakeholder(props) {
  const dispatch = useDispatch();
  const [selectedStakeholder, setSelectedStakeholder] = useState("");
  const [description, setDescription] = useState("");
  const toggle = () => props.setIsOpen(!props.isOpen);

  const inputProps = {
    style: {
      fontWeight: 500,
      fontFamily: "Montserrat",
    },
  };

  const InputLabelProps = {
    style: {
      fontWeight: 500,
      fontFamily: "Montserrat",
    },
  };

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={toggle}
      unMountOnClose={true}
      size="lg"
    >
      <ModalHeader toggle={toggle}>Add involved stakeholder</ModalHeader>
      <ModalBody>
        {props.myActors === null ? (
          <FaSpinner className="spinner"></FaSpinner>
        ) : (
          <>
            <Row style={{ margin: 0 }}>
              <Col xs="12" style={{ marginTop: 5 }}>
                <TextField
                  select
                  label="Stakeholder*"
                  value={selectedStakeholder}
                  style={{
                    width: "100%",
                  }}
                  InputLabelProps={InputLabelProps}
                  InputProps={{
                    style: {
                      height: 56,
                      fontWeight: 500,
                      fontFamily: "Montserrat",
                    },
                  }}
                  onChange={(e) => {
                    setSelectedStakeholder(e.target.value);
                  }}
                >
                  <MenuItem
                    value={""}
                    style={{ fontWeight: 500, fontFamily: "Montserrat" }}
                  >
                    ...
                  </MenuItem>
                  {props.myActors.map((a, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={a.id}
                        style={{ fontWeight: 500, fontFamily: "Montserrat" }}
                      >
                        {a.actor_business_name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Col>
            </Row>
            <Row style={{ margin: 0, marginTop: 30, marginBottom: 5 }}>
              <Col xs="12">
                <TextField
                  label={<div>Description&nbsp;&nbsp;&nbsp;</div>}
                  value={description}
                  inputProps={inputProps}
                  InputLabelProps={InputLabelProps}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </Col>
            </Row>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          onClick={() => {
            if (selectedStakeholder === "") {
              InfoAlert("Select a stakeholder");
            } else {
              dispatch({
                type: props.type,
                payload: {
                  key: "stakeholders",
                  value: [
                    ...props.data.stakeholders,
                    {
                      id: new Date().getTime(),
                      actor: selectedStakeholder,
                      description: description,
                    },
                  ],
                },
              });
              toggle();
              setSelectedStakeholder("");
              setDescription("");
            }
          }}
        >
          Add
        </Button>
        <Button
          color="secondary"
          style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          onClick={toggle}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(ModalAddStakeholder);
