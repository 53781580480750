import React, { useState, useEffect } from "react"
import { Modal, ModalBody, Row, Col } from "reactstrap";
import { MdClose, MdBarChart } from "react-icons/md";
import { FaSpinner, FaChartPie } from "react-icons/fa";
import { roundNumberInCard } from "../../../../../../utils/roundNumberInCard";
import TitleCard from "../../../../../../components/TitleCard/TitleCard";
import { roundAtMost2Digits } from "../../../../../../utils/roundAtMost2Digits";
import { useWindowDimensions } from "../../../../../../utils/useWindowDimensions";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import { usePlotlyConfig } from "../../../../../../utils/usePlotlyConfig";
import Plotly from "plotly.js-dist";


const ModalDashboard = (props) => {
    const [topCards, setTopCards] = useState(null)
    const [macroArea, setMacroArea] = useState(null)
    const [sector, setSector] = useState(null)
    const [label, setLabel] = useState(null)
    const { width, height } = useWindowDimensions()
    const plotlyConfig = usePlotlyConfig();


    useEffect(() => {
        var label_to_use
        if (props.chiave === "co2") {
            label_to_use = "CO2"
        } else if (props.chiave === "co2_equivalente") {
            label_to_use = "CO2 eq."
        } else {
            label_to_use = props.activeTab.replace("_", "-").toUpperCase()
        }
        setLabel(label_to_use)

        // generate array for top cards
        var top_cards = [
            {
                title: `${label_to_use} baseline emissions`,
                value: props.totalCards.emission_baseline,
                percentage: 1
            },
            {
                title: `${label_to_use} emissions reduction`,
                value: props.totalCards.emissions_avoided,
                percentage: props.totalCards.emissions_avoided / props.totalCards.emission_baseline
            },
            {
                title: `${label_to_use} emissions residual`,
                value: props.totalCards.emission_baseline - props.totalCards.emissions_avoided,
                percentage: (props.totalCards.emission_baseline - props.totalCards.emissions_avoided) / props.totalCards.emission_baseline
            },
        ]
        setTopCards(top_cards)

        // generate macro area sorted array for cards
        var rows_total_sector = []
        var rows_total_macro_area = []
        props.tableModal.forEach(row => {
            if (row.action === "TOTAL SECTOR") {
                rows_total_sector.push(row)
            } else if (row.id.includes("TOTAL")) {
                rows_total_macro_area.push(row)
            }
        })

        var macro_area_cards = []
        rows_total_macro_area.forEach(obj => {
            macro_area_cards.push({
                description: obj.macro_area === "" ? "Generic" : obj.macro_area,
                emissions: obj.emissions_avoided,
                percentage: obj.percentage_reduction_total_emissions,
            })
        })
        macro_area_cards.sort((a, b) => b.emissions - a.emissions);

        // generate sector sorted array for cards
        var sector_cards = []
        rows_total_sector.forEach(obj => {
            sector_cards.push({
                description: obj.sector === "" ? "Generic" : obj.sector,
                emissions: obj.emissions_avoided,
                percentage: obj.percentage_reduction_total_emissions,
            })
        })
        sector_cards.sort((a, b) => b.emissions - a.emissions);

        setMacroArea(macro_area_cards)
        setSector(sector_cards)

    }, [props.totalCards, props.tableModal])

    useEffect(() => {
        if (sector !== null) {

            const fullLabels = sector.map(obj => obj.description)
            const shortLabels = fullLabels.map(label => label.length > 9 ? label.substring(0, 9) + '.' : label);
            var dictLabels = {}
            shortLabels.forEach((lab, index) => {
                dictLabels[lab] = `${label} ${fullLabels[index]} emissions: ${roundAtMost2Digits(sector[index].emissions)} [t/y]`
            })


            var data = [
                {
                    x: Object.keys(dictLabels),
                    y: sector.map(obj => obj.emissions),
                    type: "bar",
                    customdata: shortLabels.map(label => dictLabels[label]),
                    hovertemplate: '%{customdata}<extra></extra>'
                },
            ];

            var layout = {
                margin: {
                    t: 20,
                    b: 40,
                    l: 60,
                    r: 60,
                },
                showlegend: false,
            };

            Plotly.newPlot("sectorChart", data, layout, { ...plotlyConfig });
        }
    }, [sector])


    return (
        <Modal
            isOpen={props.isOpen}
            style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
            }}
            size="xl"
            toggle={() => { props.toggle() }}
        >
            <ModalBody>
                <div style={{ position: "absolute", right: 10, top: 10 }}>
                    <MdClose onClick={() => { props.toggle() }}
                        style={{ fontSize: 24, color: "gray", cursor: "pointer" }}></MdClose>
                </div>
                {topCards === null || label === null || macroArea === null || sector === null ?
                    <>
                        <FaSpinner
                            className="spinner"
                            style={{ marginTop: "-3px", marginRight: 5 }}
                        ></FaSpinner>
                        Loading...</>
                    : <div>
                        <Row style={{ marginTop: "-10px" }}>
                            {topCards.map(card => {
                                return <Col lg="4" md="12" style={{ marginTop: 10 }}>
                                    <div
                                        style={{
                                            ...props.layout.generic_card,
                                            display: "flex",
                                            flexFlow: "column",
                                            height: "100%"
                                        }}
                                    >
                                        <TitleCard
                                            title={
                                                <div>
                                                    {card.title}
                                                </div>
                                            }

                                        ></TitleCard>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "end",
                                            alignItems: "end",
                                            fontSize: 22,
                                            fontWeight: "bold",
                                            marginRight: 20,
                                            marginTop: 10,
                                            marginBottom: 10,

                                        }}>
                                            <div>
                                                {roundNumberInCard(card.value, "")} [t/y]
                                            </div>
                                            <div style={{ fontWeight: 500, marginLeft: 5, fontSize: 16, marginBottom: 2 }}>
                                                ({Math.round((card.percentage * 100 + Number.EPSILON) * 10) / 10}%)
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            })}
                        </Row>
                        <div style={{
                            fontFamily: "Montserrat", fontSize: 18, marginTop: 30,
                            display: "flex", alignItems: "center"
                        }}>
                            <FaChartPie style={{ fontSize: 16, marginRight: 5, color: "#3A3A3A", marginTop: "-2px" }} />Emissions reduction by most relevant macro area [t/y]
                        </div>
                        <Row className={width > 1200 ? "flex-nowrap" : ""}>
                            {macroArea.map((macro_area, index) => {
                                if (index < 5) {
                                    return <Col style={{
                                        flex: width > 1200 ? "0 0 20%" : "",
                                        marginTop: 10,
                                    }} md="12">
                                        <div
                                            style={{
                                                ...props.layout.generic_card,
                                                display: "flex",
                                                flexFlow: "column",
                                                height: "100%",
                                            }}
                                        >
                                            <TitleCard
                                                title={
                                                    <div>{macro_area.description.length > 17 ? macro_area.description.substring(0, 17) + '.' : macro_area.description}
                                                    </div>
                                                }
                                                customTitleSize={
                                                    {
                                                        large: 16,
                                                        small: 16
                                                    }
                                                }
                                            ></TitleCard>
                                            <div style={{
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "end",
                                                alignItems: "end",
                                                fontSize: 20,
                                                fontWeight: "bold",
                                                marginRight: 20,
                                                marginBottom: 10,
                                                marginTop: 10,
                                            }}>
                                                <div>
                                                    {roundNumberInCard(macro_area.emissions, "")}
                                                </div>
                                                <div style={{ fontWeight: 500, marginLeft: 5, fontSize: 16, marginBottom: 2 }}>
                                                    ({Math.round((macro_area.percentage * 100 + Number.EPSILON) * 10) / 10}%)
                                                </div>
                                            </div>
                                        </div>

                                    </Col>
                                }
                            })}
                        </Row>
                        <div style={{
                            fontFamily: "Montserrat", fontSize: 18, marginTop: 30,
                            display: "flex", alignItems: "center"
                        }}>
                            <MdBarChart style={{ fontSize: 24, marginRight: 5, color: "#3A3A3A" }} />Emissions reduction by most relevant sectors [t/y]
                        </div>
                        <Row className={width > 1200 ? "flex-nowrap" : ""}>
                            {sector.map((sector_inner, index) => {
                                if (index < 5) {
                                    return <Col style={{
                                        flex: width > 1200 ? "0 0 20%" : "",
                                        marginTop: 10,
                                    }} md="12">
                                        <div
                                            style={{
                                                ...props.layout.generic_card,
                                                display: "flex",
                                                flexFlow: "column",
                                                height: "100%",
                                            }}
                                        >
                                            <TitleCard
                                                title={
                                                    <div>{sector_inner.description.length > 17 ? sector_inner.description.substring(0, 17) + '.' : sector_inner.description}
                                                    </div>
                                                }
                                                customTitleSize={
                                                    {
                                                        large: 16,
                                                        small: 16
                                                    }
                                                }
                                            ></TitleCard>
                                            <div style={{
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "end",
                                                alignItems: "end",
                                                fontSize: 20,
                                                fontWeight: "bold",
                                                marginRight: 20,
                                                marginBottom: 10,
                                                marginTop: 10,
                                            }}>
                                                <div>
                                                    {roundNumberInCard(sector_inner.emissions, "")}
                                                </div>
                                                <div style={{ fontWeight: 500, marginLeft: 5, fontSize: 16, marginBottom: 2 }}>
                                                    ({Math.round((sector_inner.percentage * 100 + Number.EPSILON) * 10) / 10}%)
                                                </div>
                                            </div>
                                        </div>

                                    </Col>
                                }

                            })}
                        </Row>

                        <div
                            style={{
                                ...props.layout.generic_card,
                                display: "flex",
                                flexFlow: "column",
                                height: "100%",
                                marginTop: 30
                            }}
                        >
                            <TitleCard
                                title={
                                    <div> Emissions reduction by sector [t/y]
                                    </div>
                                }
                                customTitleSize={
                                    {
                                        large: 18,
                                        small: 18
                                    }
                                }
                            ></TitleCard>
                            <div
                                style={{
                                    flex: "0.92 1 auto",
                                    borderBottomLeftRadius:
                                        props.layout.generic_card.borderRadius,
                                    borderBottomRightRadius:
                                        props.layout.generic_card.borderRadius,
                                }}
                            >
                                <div
                                    style={{
                                        width: "100%",
                                        height: "300px",
                                    }}
                                    id="sectorChart"
                                ></div>
                            </div>
                        </div>
                    </div>

                }
            </ModalBody>
        </Modal>
    )
};

export default ModalDashboard;
