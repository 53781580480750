import React, { useState } from "react";
import { connect } from "react-redux";
import { useWindowDimensions } from "../../../utils/useWindowDimensions";
import TitleCard from "../../../components/TitleCard/TitleCard";
import { FaArrowUp, FaArrowDown, FaEquals } from "react-icons/fa";
import { roundNumberInCard } from "../../../utils/roundNumberInCard";

function CardHomepageTopRight(props) {
  const { width, height } = useWindowDimensions();
  const [show, setShow] = useState(false);

  return (
    <div
      style={{
        ...props.layout.generic_card,
        display: "flex",
        flexFlow: "column",
        height: "100%",
      }}
    >
      <TitleCard
        title={props.cardData.title}
      ></TitleCard>
      <div
        style={{
          width: "100%",
          height: "100%",
          padding: "0px 10px",
          borderRadius: props.layout.generic_card.borderRadius,
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            fontFamily: "Montserrat",
            color: props.layout.color_footer,
            padding: 10
          }}
        >
          <div style={{ padding: 5 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
                fontSize: height < 780 ? 24 : 34,
                marginBottom: "-3px",
              }}
            >
              {roundNumberInCard(props.homepage["investment_to_date"], "€", true)}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "60%",
                  borderBottom: `1px solid ${props.layout.color_footer}`,
                }}
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: 500,
                textAlign: "center",
                fontSize: height < 780 ? 13 : width > 1600 ? 15 : 13,
              }}
            >
              <i>CAPEX to date</i>
            </div>
          </div>

          <div style={{ padding: 5 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: 500,
                fontSize: height < 780 ? 16 : 20,
              }}
            >
              <div>
                {roundNumberInCard(props.homepage["total_planned_investment"], "€", true)}
              </div>
              <div style={{ marginLeft: 5 }}>
                ({props.homepage["investment_to_date_perc"]}%)
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "60%",
                  borderBottom: `1px solid ${props.layout.color_footer}`,
                }}
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: 500,
                textAlign: "center",
                fontSize: height < 780 ? 13 : width > 1600 ? 15 : 13,
              }}
            >
              <i>Total CAPEX</i>
            </div>
          </div>

          {/* target --> rimosso */}
          {/* <div style={{ padding: 5 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: 500,
                fontSize: height < 780 ? 16 : 20,
              }}
            >
              {props.homepage["investment_distance_from_target_perc"] !==
                null ? (
                <div style={{ marginLeft: 5 }}>
                  {props.homepage["investment_distance_from_target_perc"]}%
                </div>
              ) : "-"}
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "60%",
                  borderBottom: `1px solid ${props.layout.color_footer}`,
                }}
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: 500,
                textAlign: "center",
                fontSize: height < 780 ? 13 : width > 1600 ? 15 : 13,
              }}
            >
              <i>Current distance from target</i>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(CardHomepageTopRight);
