import React, { useState } from "react";
import { Card, Row, Col } from "reactstrap";
import Map3DRoadNetwork from "./Map3DRoadNetwork.tsx";
import { recurrentUpdate } from "../../../../../../utils/recurrentUpdate";
import { useDispatch } from "react-redux";

function SelectRoads(props) {
    const dispatch = useDispatch();

    const updateRedux = (newValue) => {
        props.data.details.parameters.forEach((obj) =>
            recurrentUpdate(obj, props.param, newValue)
        );
        dispatch({
            type: props.type,
            payload: {
                key: "details",
                value: props.data.details,
            },
        });
    };

    return (
        <Card body style={{ marginTop: 20 }}>
            <Row style={{ margin: 0 }}>
                <Col xs="12" style={{ padding: 0 }}>
                    <div style={{ fontFamily: "Montserrat" }}>
                        {props.param.paramName}
                    </div>
                    <div
                        style={{ fontFamily: "Montserrat", fontWeight: 500, fontSize: 12 }}
                    >
                        {props.param.paramDescription}
                    </div>
                    <div style={{ height: 500, marginBottom: "-10px" }}>
                        <Map3DRoadNetwork
                            selectedFeatures={props.param.default}
                            setSelectedFeatures={updateRedux}
                        />
                    </div>
                </Col>
            </Row>
        </Card>
    );
}

export default SelectRoads;
