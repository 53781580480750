import React from "react";
import { FaTimes } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { FaCalculator } from "react-icons/fa";


function ActionRenderer(props) {
  const dispatch = useDispatch();
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {props.data.id === "global_impact" ?
        <FaCalculator style={{
          marginRight: 5,
          fontSize: 12
        }} /> :
        props.data.id !== "total" ? (
          <FaTimes
            onClick={() => {
              dispatch({
                type: props.type,
                payload: {
                  key: "pathways",
                  value: props.data_redux.pathways.map((p) => {
                    if (p.id !== props.data_redux.displayed_pathway) {
                      return p;
                    } else {
                      return {
                        ...p,
                        actions: p.actions.filter((a) => a.id !== props.data.id),
                      };
                    }
                  }),
                },
              });
            }}
            style={{
              color: "#FC2727",
              marginRight: 4,
              cursor: "pointer",
            }}
          ></FaTimes>
        ) : null}
      {props.data.name}
    </div>
  );
}

export default ActionRenderer;
