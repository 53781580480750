import React, { useState, useEffect } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
    Col,
} from "reactstrap";
import { FaSpinner } from "react-icons/fa";
import { connect, useDispatch } from "react-redux";
import axios from "axios";
import { API_URL, GET_ERRORS } from "../../../../actions/types";
import { tokenConfig } from "../../../../utils/tokenConfig";
import InfoAlert from "../../../../components/Modals/InfoAlert";
import SuccessAlert from "../../../../components/Modals/SuccessAlert";
import { triggerRefresh } from "../../../../utils/triggerRefresh";
import { cloneDeep } from "lodash"
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import moment from "moment"
import { getUserType } from "../../../../utils/getUserType";

function ModalCreateUser(props) {
    const [loading, setLoading] = useState(false);
    const [actorList, setActorList] = useState([]);
    const [myUserType, setMyUserType] = useState("");
    const [typeBelowMine, setTypeBelowMine] = useState("");
    const [availableUserType, setAvailableUserType] = useState([]);
    const [registerForm, setRegisterForm] = useState({
        username: "",
        user_type: "",
        parent: "",
        related_anagrafica: null,
        first_name: "",
        last_name: "",
        organization: "",
        email: "",
        password: "",
        password_confirmation: "",
        expiration_date: null,
        // actor_id: null,
    });

    const dispatch = useDispatch();
    const toggle = () => props.setIsOpen(!props.isOpen);


    const find_type_below = (selected_type) => {
        var found = false
        var first_find = false
        var type_below = ""
        props.hierarchy.forEach(type => {
            if (found === true && first_find === false) {
                type_below = type
                first_find = true
            }
            if (selected_type === type) {
                found = true
            }
        })
        return type_below
    }

    const find_type_top = (selected_type) => {
        var found = false
        var first_find = false
        var type_below = ""
        var hierarchy_copy = cloneDeep(props.hierarchy)
        hierarchy_copy.reverse().forEach(type => {
            if (found === true && first_find === false) {
                type_below = type
                first_find = true
            }
            if (selected_type === type) {
                found = true
            }
        })
        return type_below
    }


    useEffect(async () => {
        if (props.isOpen === true) {
            var my_user_type = getUserType(props.auth)
            setMyUserType(my_user_type)
            var type_below_mine = find_type_below(my_user_type)
            setTypeBelowMine(type_below_mine)

            var available_user_type
            if (props.auth.is_project_admin === true) {
                available_user_type = props.hierarchy
            } else {
                available_user_type = []
                var found = false
                props.hierarchy.forEach(type => {
                    if (found === true) {
                        available_user_type.push(type)
                    }
                    if (type === my_user_type) {
                        found = true
                    }
                })
            }
            setAvailableUserType(available_user_type)

            const res = await axios.get(`${API_URL}/clicc/actors/`, tokenConfig());
            setActorList(res.data);
        }
    }, [props.isOpen, props.auth]);


    const submitForm = (e) => {
        e.preventDefault();
        if (registerForm.password.length < 8) {
            InfoAlert("The length of the password cannot be less than 8 characters");
        } else {
            if (loading === false) {
                if (registerForm.user_type === "") {
                    InfoAlert("Select a user type from the list");
                } else if (
                    registerForm.password !== registerForm.password_confirmation
                ) {
                    InfoAlert("Passwords are different");
                } else if (registerForm.expiration_date !== null && !registerForm.expiration_date.isValid()) {
                    InfoAlert("Expiration date is not valid");
                } else if ((registerForm.user_type === "ACTOR" || registerForm.user_type === "SUB ACTOR") && registerForm.related_anagrafica === null) {
                    InfoAlert("Related anagrafica is mandatory");
                }
                else {
                    setLoading(true);
                    var id_parent = null
                    if (registerForm.parent !== "") {
                        var id_parent = props.users.find(user => user.username === registerForm.parent).id
                    }
                    var expiration_date = null
                    if (registerForm.expiration_date !== null) {
                        expiration_date = registerForm.expiration_date.format("YYYY-MM-DD")
                    }
                    axios
                        .post(`${API_URL}/users/`, {
                            ...registerForm,
                            parent: id_parent,
                            expiration_date: expiration_date,
                        }, tokenConfig())
                        .then((res) => {
                            toggle();
                            setRegisterForm({
                                username: "",
                                user_type: "",
                                parent: "",
                                related_anagrafica: null,
                                first_name: "",
                                last_name: "",
                                organization: "",
                                email: "",
                                password: "",
                                password_confirmation: "",
                                expiration_date: null
                            });
                            setLoading(false);
                            SuccessAlert("Account created");
                            triggerRefresh()
                        })
                        .catch((err) => {
                            setLoading(false);
                            const errors = {
                                msg: err.response.data,
                                status: err.response.status,
                            };
                            dispatch({
                                type: GET_ERRORS,
                                payload: errors,
                            });
                        });
                }
            }
        }
    };
    return (
        <Modal
            isOpen={props.isOpen}
            toggle={toggle}
            unMountOnClose={true}
            size="lg"
            style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
            }}
        >
            <ModalHeader toggle={toggle}>Create new user</ModalHeader>
            <ModalBody style={{ paddingBottom: 0 }}>

                <Form id="registration-form" onSubmit={submitForm}>
                    <FormGroup row className="mb-4">
                        <Label for="form-firstname" sm={4}>
                            First name
                        </Label>
                        <Col sm={8}>
                            <Input
                                value={registerForm.first_name}
                                onChange={(e) =>
                                    setRegisterForm({
                                        ...registerForm,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                                type="text"
                                name="first_name"
                                id="form-firstname"
                                placeholder=""
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row className="mb-4">
                        <Label for="form-lastname" sm={4}>
                            Last name
                        </Label>
                        <Col sm={8}>
                            <Input
                                value={registerForm.last_name}
                                onChange={(e) =>
                                    setRegisterForm({
                                        ...registerForm,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                                type="text"
                                name="last_name"
                                id="form-lastname"
                                placeholder=""
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row className="mb-4">
                        <Label for="form-organization" sm={4}>
                            Organization
                        </Label>
                        <Col sm={8}>
                            <Input
                                value={registerForm.organization}
                                onChange={(e) =>
                                    setRegisterForm({
                                        ...registerForm,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                                type="text"
                                name="organization"
                                id="form-organization"
                                placeholder=""
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row className="mb-4">
                        <Label for="form-username" sm={4}>
                            Username*
                        </Label>
                        <Col sm={8}>
                            <Input
                                value={registerForm.username}
                                onChange={(e) =>
                                    setRegisterForm({
                                        ...registerForm,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                                type="text"
                                name="username"
                                id="form-username"
                                placeholder=""
                                required
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row className="mb-4">
                        <Label for="form-user-type" sm={4}>
                            User type*
                        </Label>
                        <Col sm={8}>
                            <Input
                                value={registerForm.user_type}
                                onChange={(e) =>
                                    setRegisterForm({
                                        ...registerForm,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                                type="select"
                                name="user_type"
                                id="form-user-type"
                                placeholder=""
                                required
                            >
                                <option value="">...</option>
                                {availableUserType.map(type => {
                                    return <option value={type}>{type}</option>
                                })}
                            </Input>
                        </Col>
                    </FormGroup>

                    {registerForm.user_type !== "PROJECT ADMIN" && registerForm.user_type !== "" && find_type_top(registerForm.user_type) !== myUserType ?
                        <FormGroup row className="mb-4">
                            <Label for="form-user-type" sm={4}>
                                Parent*
                            </Label>
                            <Col sm={8}>
                                <Input
                                    value={registerForm.parent}
                                    onChange={(e) =>
                                        setRegisterForm({
                                            ...registerForm,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                    type="select"
                                    name="parent"
                                    id="form-parent"
                                    placeholder=""
                                    required
                                >
                                    <option value="">...</option>
                                    {props.users.filter(user => user.user_type === find_type_top(registerForm.user_type)).map(user => {
                                        return <option value={user.username}>{user.username}</option>
                                    })
                                    }
                                </Input>
                            </Col>
                        </FormGroup>
                        : null}

                    {registerForm.user_type === "ACTOR" || registerForm.user_type === "SUB ACTOR" ?
                        <FormGroup row className="mb-4">
                            <Label for="form-related-anagrafica" sm={4}>
                                Related anagrafica*
                            </Label>
                            <Col sm={8}>
                                <Input
                                    value={registerForm.related_anagrafica}
                                    onChange={(e) => {
                                        setRegisterForm({
                                            ...registerForm,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                    }
                                    type="select"
                                    name="related_anagrafica"
                                    id="form-related-anagrafica"
                                    placeholder=""
                                    required
                                >
                                    <option value="">...</option>
                                    {actorList.map(actor => {
                                        return <option value={actor.id}>{actor.actor_business_name}</option>
                                    })
                                    }
                                </Input>
                            </Col>
                        </FormGroup>
                        : null}
                    <FormGroup row className="mb-4">
                        <Label for="form-email" sm={4}>
                            Email*
                        </Label>
                        <Col sm={8}>
                            <Input
                                value={registerForm.email}
                                onChange={(e) =>
                                    setRegisterForm({
                                        ...registerForm,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                                type="email"
                                name="email"
                                id="form-email"
                                placeholder=""
                                required
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row className="mb-4">
                        <Label for="form-password" sm={4}>
                            Password*
                        </Label>
                        <Col sm={8}>
                            <Input
                                value={registerForm.password}
                                onChange={(e) =>
                                    setRegisterForm({
                                        ...registerForm,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                                type="password"
                                name="password"
                                id="form-password"
                                placeholder=""
                                required
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row className="mb-4">
                        <Label for="form-passowrd-confirmation" sm={4}>
                            Password Confirmation*
                        </Label>
                        <Col sm={8}>
                            <Input
                                value={registerForm.password_confirmation}
                                onChange={(e) =>
                                    setRegisterForm({
                                        ...registerForm,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                                type="password"
                                name="password_confirmation"
                                id="form-passowrd-confirmation"
                                placeholder=""
                                required
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row className="mb-0">
                        <Label for="form-email" sm={4}>
                            Expiration date
                        </Label>
                        <Col sm={8}>
                            <DesktopDatePicker
                                name="expiration_date"
                                inputFormat="DD-MM-YYYY"
                                value={registerForm.expiration_date}
                                onChange={(newDate) => {
                                    setRegisterForm({
                                        ...registerForm,
                                        expiration_date: newDate,
                                    })
                                }}
                                minDate={moment()}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        className="datePickerMontserrat"
                                    />
                                )}
                            />
                        </Col>
                    </FormGroup>
                </Form>


            </ModalBody>
            <ModalFooter>
                {loading === true ? (
                    <Button
                        color="success"
                        type="submit"
                        form="registration-form"
                        style={{ minWidth: 130 }}
                    >
                        <FaSpinner className="spinner"> </FaSpinner>
                    </Button>
                ) : (
                    <Button
                        color="success"
                        type="submit"
                        form="registration-form"
                        style={{ minWidth: 130 }}
                    >
                        Register
                    </Button>
                )}
            </ModalFooter>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    layout: state.layout,
    auth: state.auth,
});

export default connect(mapStateToProps)(ModalCreateUser);
