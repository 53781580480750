import { PAGE_TOOLBAR } from "../actions/types";

const initialState = {
  page: "Homepage",
};

export default function toolbar(state = initialState, action) {
  switch (action.type) {
    case PAGE_TOOLBAR:
      return {
        ...state,
        page: action.payload,
      };
    default:
      return state;
  }
}
