import { SET_OPENED, UPDATE_OPENED, RESET_OPENED } from "../actions/types";

const initialState = {};

export default function toolbar(state = initialState, action) {
  switch (action.type) {
    case SET_OPENED:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_OPENED:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case RESET_OPENED:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
