export const HANDLE_MENU_OC = "HANDLE_MENU_OC";
export const OPEN_MENU_OC = "OPEN_MENU_OC";
export const CLOSE_MENU_OC = "CLOSE_MENU_OC";
export const HANDLE_INFOBOTTOM_OC = "HANDLE_INFOBOTTOM_OC";
export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const IS_LOADING = "IS_LOADING";
export const PAGE_TOOLBAR = "PAGE_TOOLBAR";
export const GET_HOMEPAGE = "GET_HOMEPAGE";
export const UPDATE_CREATE = "UPDATE_CREATE";
export const RESET_CREATE = "RESET_CREATE";
export const UPDATE_OPENED = "UPDATE_OPENED";
export const SET_OPENED = "SET_OPENED";
export const RESET_OPENED = "RESET_OPENED";
export const UPDATE_CREATE_NEW_ACTION = "UPDATE_CREATE_NEW_ACTION";
export const RESET_CREATE_NEW_ACTION = "RESET_CREATE_NEW_ACTION";
export const SET_OPENED_ACTION = "SET_OPENED_ACTION";
export const UPDATE_OPENED_ACTION = "UPDATE_OPENED_ACTION";
export const RESET_OPENED_ACTION = "RESET_OPENED_ACTION";
export const UPDATE_CREATE_NEW_CONTRACT = "UPDATE_CREATE_NEW_CONTRACT";
export const RESET_CREATE_NEW_CONTRACT = "RESET_CREATE_NEW_CONTRACT";
export const UPDATE_CREATE_NEW_ACTOR = "UPDATE_CREATE_NEW_ACTOR";
export const RESET_CREATE_NEW_ACTOR = "RESET_CREATE_NEW_ACTOR";
export const SET_OPENED_ACTOR = "SET_OPENED_ACTOR";
export const UPDATE_OPENED_ACTOR = "UPDATE_OPENED_ACTOR";
export const RESET_OPENED_ACTOR = "RESET_OPENED_ACTOR";
export const SET_OPENED_CONTRACT = "SET_OPENED_CONTRACT";
export const UPDATE_OPENED_CONTRACT = "UPDATE_OPENED_CONTRACT";
export const RESET_OPENED_CONTRACT = "RESET_OPENED_CONTRACT";
export const SET_FIELDS = "SET_FIELDS";

// // development
// export const PRODUCTION_API_URL = "http://polito-cittatorino/api"; // (city explorer)
// export const API_URL = `http://polito-cittatorino/api`;

// production --> always uncomment PRODUCTION_API_URL, while for API_URL choose the proper one
// export const PRODUCTION_API_URL = "https://clicc.est.polito.it/api"; // (city explorer) non più usato perchè quello in prod non è aggiornato
// export const PRODUCTION_API_URL = "https://openclicc.est.polito.it/api"; // (city explorer)
// export const API_URL = `http://192.168.167.51/api`;

// // HETZNER
export const PRODUCTION_API_URL = "https://ctp-polito.gdpanalytics.com/api"; // (city explorer)
export const API_URL = `https://clicc-polito.gdpanalytics.com/api`;

// // POLI PUBBLICO
// export const PRODUCTION_API_URL = "https://openclicc.est.polito.it/api"; // (city explorer)
// export const API_URL = `https://openclicc.est.polito.it/api`;
