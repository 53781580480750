import React, { useState, useEffect } from "react";
import {
    Button as ButtonReactstrap,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import { connect, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import UploadJSON from "./UploadJSON";
import UploadPython from "./UploadPython";
import Summary from "./Summary";
import axios from "axios";
import { API_URL, GET_ERRORS, IS_LOADING } from "../../../../../actions/types";
import SuccessAlert from "../../../../../components/Modals/SuccessAlert";
import { triggerRefresh } from "../../../../../utils/triggerRefresh";
import { tokenConfig } from "../../../../../utils/tokenConfig";

function ModalUploadActionType(props) {
    const [activeStep, setActiveStep] = useState(0);
    const [json, setJson] = useState(null)
    const [python, setPython] = useState(null)
    const steps = ['Upload json', 'Upload python', "Summary"];
    const dispatch = useDispatch()

    const toggle = () => props.setIsOpen(!props.isOpen);

    const handleNext = async () => {
        if (activeStep !== steps.length - 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            try {
                let formData = new FormData();
                Object.keys(json.file).forEach(key => {
                    formData.append(key, JSON.stringify(json.file[key]));
                })
                formData.append("python_file", python);
                dispatch({
                    type: IS_LOADING,
                });
                if (props.idJson !== undefined) {
                    await axios.put(
                        `${API_URL}/clicc/actions_inventory/${props.idJson}/`,
                        formData,
                        tokenConfig()
                    );
                } else {
                    await axios.post(
                        `${API_URL}/clicc/actions_inventory/`,
                        formData,
                        tokenConfig()
                    );
                }
                dispatch({
                    type: IS_LOADING,
                });
                toggle()
                SuccessAlert("Files uploaded successfully");
                triggerRefresh()
            } catch (err) {
                dispatch({
                    type: IS_LOADING,
                });
                const errors = {
                    msg: err.response.data,
                    status: err.response.status,
                };
                dispatch({
                    type: GET_ERRORS,
                    payload: errors,
                });
            }

        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getStepContent = (step) => {
        if (step === 0) {
            return <UploadJSON json={json} setJson={setJson} idJson={props.idJson}></UploadJSON>
        } else if (step === 1) {
            return <UploadPython python={python} setPython={setPython}></UploadPython>
        } else if (step === 2) {
            return <Summary json={json} python={python}></Summary>
        }
    }


    return (
        <Modal
            isOpen={props.isOpen}
            toggle={toggle}
            unMountOnClose={true}
            size="lg"
            style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
            }}
        >
            <ModalHeader toggle={toggle}>
                {props.idJson !== undefined ? "Update action type" : "Create action type"}
            </ModalHeader>
            <ModalBody>
                <Box sx={{ width: '100%', marginTop: "10px" }}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            return (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <React.Fragment>
                        <div style={{ padding: "40px 12px 15px 12px" }}>
                            {getStepContent(activeStep)}
                        </div>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                                style={{ fontFamily: "Montserrat" }}
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />

                            <ButtonReactstrap
                                outline={activeStep === steps.length - 1 ? false : true}
                                color={activeStep === steps.length - 1 ? "success" : "primary"}
                                onClick={handleNext}
                                disabled={
                                    activeStep === 0 && json === null ? true :
                                        activeStep === 1 && python === null ? true :
                                            false
                                }

                            >
                                {activeStep === steps.length - 1 ? 'Upload' : 'Next'}
                            </ButtonReactstrap>
                        </Box>
                    </React.Fragment>
                </Box>

            </ModalBody>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    layout: state.layout,
    auth: state.auth,
});

export default connect(mapStateToProps)(ModalUploadActionType);
