import React, { useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import DropDownMenu from "./DropDownMenu";
import { checkPermission } from "../../../utils/checkPermission";

function Sidebar(props) {
  const [showWorkspace, setShowWorkspace] = useState(false);
  const [showActors, setShowActors] = useState(false);
  const [showContract, setShowContract] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [showPrint, setShowPrint] = useState(false);
  const [showCTP, setShowCTP] = useState(false);
  const location = useLocation();

  const styleDivButton = {
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "white",
    cursor: "pointer",
    userSelect: "none",
  };

  // change width here because it is passed to the dropdown
  const widthButton = 140;

  const styleButton = {
    display: "flex",
    justifyContent: "center",
    fontSize: 17,
    fontWeight: "semi-bold",
    fontFamily: "Montserrat",
    width: widthButton,
    borderRadius: 10,
    backgroundColor: props.layout.color_background_button_sidebar,
  };

  // actor menus
  var childrenActorsMenu = []
  if (checkPermission(props.auth.user_permissions, "Can add actor") === true) {
    childrenActorsMenu.push(
      {
        name: "New actor",
        url: "/clicc/dashboard/actors_new_actor",
      },
    )
  }
  if (checkPermission(props.auth.user_permissions, "Can change actor") === true) {
    childrenActorsMenu.push(
      {
        name: "Modify actor",
        url: "/clicc/dashboard/actors_modify_actor",
      },
    )
  }
  if (checkPermission(props.auth.user_permissions, "Can view actor") === true) {
    childrenActorsMenu.push(
      {
        name: "My actors",
        url: "/clicc/dashboard/actors_my_actors",
      },
    )
  }
  if (childrenActorsMenu.length === 0) {
    childrenActorsMenu.push(
      {
        name: "Permission denied",
        url: undefined,
      },
    )
  }

  // action menus
  var childrenActionsMenu = []
  if (props.auth.is_project_admin === true) {
    childrenActionsMenu.push(
      {
        name: "Action type",
        url: "/clicc/dashboard/actions_action_type",
      },
    )
  }
  if (checkPermission(props.auth.user_permissions, "Can add actions") === true) {
    childrenActionsMenu.push(
      {
        name: "New action",
        url: "/clicc/dashboard/actions_new_action",
      },
    )
  }
  if (checkPermission(props.auth.user_permissions, "Can change actions") === true) {
    childrenActionsMenu.push(
      {
        name: "Modify action",
        url: "/clicc/dashboard/actions_modify_action",
      },
    )
  }
  if (checkPermission(props.auth.user_permissions, "Can view actions") === true) {
    childrenActionsMenu.push(
      {
        name: "My actions",
        url: "/clicc/dashboard/actions_my_actions",
      },
    )
  }
  if (childrenActionsMenu.length === 0) {
    childrenActionsMenu.push(
      {
        name: "Permission denied",
        url: undefined,
      },
    )
  }

  // workspace and contract menus (contract inherits from workspace)
  var childrenWorkspaceMenu = []
  var childrenContractMenu = []
  if (checkPermission(props.auth.user_permissions, "Can add workspace") === true) {
    childrenWorkspaceMenu.push(
      {
        name: "New workspace",
        url: "/clicc/dashboard/workspace_new_workspace",
      },
    )
    childrenContractMenu.push(
      {
        name: "New contract",
        url: "/clicc/dashboard/contract_new_contract",
      },
    )
  }
  if (checkPermission(props.auth.user_permissions, "Can change workspace") === true) {
    childrenWorkspaceMenu.push(
      {
        name: "Modify workspace",
        url: "/clicc/dashboard/workspace_modify_workspace",
      },
    )
    childrenContractMenu.push(
      {
        name: "Modify contract",
        url: "/clicc/dashboard/contract_modify_contract",
      },
    )
  }
  if (checkPermission(props.auth.user_permissions, "Can view workspace") === true) {
    childrenWorkspaceMenu.push(
      {
        name: "My workspaces",
        url: "/clicc/dashboard/workspace_my_workspaces",
      },
    )
    childrenContractMenu.push(
      {
        name: "My contracts",
        url: "/clicc/dashboard/contract_my_contracts",
      },
    )
  }
  if (childrenWorkspaceMenu.length === 0) {
    childrenWorkspaceMenu.push(
      {
        name: "Permission denied",
        url: undefined,
      },
    )
    childrenContractMenu.push(
      {
        name: "Permission denied",
        url: undefined,
      },
    )
  }




  var menuStructure = [
    {
      name: "ACTORS",
      idParentLeft: "divActors",
      show: showActors,
      setShow: setShowActors,
      children: childrenActorsMenu

    },
    {
      name: "ACTIONS",
      idParentLeft: "divActions",
      show: showActions,
      setShow: setShowActions,
      children: childrenActionsMenu
    },
    {
      name: "WORKSPACE",
      idParentLeft: "divWorkspace",
      show: showWorkspace,
      setShow: setShowWorkspace,
      children: childrenWorkspaceMenu
    },
    {
      name: "CONTRACT",
      idParentLeft: "divVisualize",
      show: showContract,
      setShow: setShowContract,
      children: childrenContractMenu
    },
  ];

  return (
    <div
      style={{
        position: "absolute",
        height: "100vh",
        zIndex: 2,
        width: props.layout.width_sidebar,
        borderTopRightRadius: 25,
        borderBottomRightRadius: 25,
        backgroundColor: props.layout.color_sidebar,
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          alignContent: "space-around",
          justifyContent: "center",
        }}
      >
        {menuStructure.map((menu, index) => {
          return (
            <div
              onMouseEnter={() => menu.setShow(true)}
              onMouseLeave={() => menu.setShow(false)}
              key={index}
              style={{ transform: "rotate(-90deg)" }}
            >
              <div
                style={{ ...styleDivButton, ...styleButton }}
                id={menu.idParentLeft}
              >
                <div
                  style={{
                    ...styleButton,
                    border: menu.children
                      .map((obj) => obj.url)
                      .includes(
                        location.pathname.endsWith("/")
                          ? location.pathname.slice(0, -1)
                          : location.pathname
                      )
                      ? `2px solid ${props.layout.yellow_detail}`
                      : `2px solid ${props.layout.color_border_button_sidebar}`,
                  }}
                >
                  {menu.name}
                </div>
              </div>
              {menu.show === true ? (
                <DropDownMenu
                  idParentLeft={menu.idParentLeft}
                  pages={menu.children}
                  widthButton={widthButton}
                ></DropDownMenu>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
  auth: state.auth,
});

export default connect(mapStateToProps)(Sidebar);
