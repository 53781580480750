export const checkActionColumnWidth = (user_permissions, category) => {
    // check delete
    const check_detele = user_permissions.find(obj => obj.name === `Can delete ${category}`)
    // check delete
    const check_edit = user_permissions.find(obj => obj.name === `Can change ${category}`)
    // return width
    var width_col = 20
    if (check_detele !== undefined && check_edit !== undefined) {
        width_col = 50
    } else if (check_detele !== undefined || check_edit !== undefined) {
        width_col = 27
    }
    return width_col
}