import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Card } from "reactstrap";
import { useDispatch } from "react-redux";
import { recurrentUpdate } from "../../../../../../utils/recurrentUpdate";
import { FaSpinner } from "react-icons/fa";
import axios from "axios";
import { API_URL } from "../../../../../../actions/types";
import { tokenConfig } from "../../../../../../utils/tokenConfig";
import { AgGridReact } from "ag-grid-react";
import { useWindowDimensions } from "../../../../../../utils/useWindowDimensions";
import CheckboxHeader from "./CheckboxHeader"


function TableWithAPI(props) {
    const [allRows, setAllRows] = useState(null)
    const [rowData, setRowData] = useState(null)
    const [columnDefs, setColumnDefs] = useState(null)
    const { width, height } = useWindowDimensions()

    const updateRedux = (newValue) => {
        props.data.details.parameters.forEach((obj) =>
            recurrentUpdate(obj, props.param, newValue)
        );
        dispatch({
            type: props.type,
            payload: {
                key: "details",
                value: props.data.details,
            },
        });
    };

    const dispatch = useDispatch();
    const grid = useRef();

    // use a ref to keep track of changes
    // redux is updated onclick but the change doesn't rerender
    // why the change in redux doesn't rerender the component? See SingleParam, there is a useState which runs only on didMount
    const defaultRef = useRef()

    // make get request only once 
    useEffect(async () => {
        const getRows = await axios.get(
            `${API_URL}${props.param.api}`,
            tokenConfig()
        );
        setAllRows(getRows.data)
        defaultRef.current = props.param.default
    }, [])

    // define function to get data for aggrid
    const getData = async () => {
        return allRows.map(obj => {
            return {
                ...obj,
                selected: props.param.default[obj.id] === true ? true : false,
            }
        })
    };

    // define function to create columns automatically
    const getColumns = () => {
        var cols = Object.keys(allRows[0])
        var colsIDFirst = ["id", ...cols.filter(c => c !== "id")]
        var colsToReturn = [
            {
                field: "selected",
                headerName: "",
                maxWidth: 37,
                minWidth: 37,
                headerComponent: "checkboxHeader",
                headerComponentParams: {
                    idParam: props.param.id,
                    updateRedux: updateRedux,
                    getDefaultRef: () => defaultRef.current, // use arrow function
                    setDefaultRef: (newObj) => defaultRef.current = newObj, // use arrow function
                },
                valueFormatter: (params) => {
                    if (params.value === true) {
                        return "✅"
                    } else {
                        return "⬜"
                    }
                },
                onCellClicked: (params) => {
                    // check new value
                    var newValue = defaultRef.current[params.data.id] === undefined || defaultRef.current[params.data.id] === false ? true : false
                    // update ref
                    defaultRef.current[params.data.id] = newValue
                    // update the value in the row node
                    params.node.setDataValue('selected', newValue);
                    // check if the checkbox header needs to be toggled
                    check_checkboxHeader(params)

                    // update redux (select only IDs equal to True)
                    var trueIDs = Object.fromEntries(
                        Object
                            .entries(defaultRef.current)
                            .filter(([, val]) => val === true)
                    );
                    updateRedux(trueIDs)
                },
            }
        ]
        colsIDFirst.forEach(c => {
            colsToReturn.push({
                field: c,
                headerName: (c[0].toUpperCase() + c.slice(1)).replace("_", " ").replace("[", " [")
            })
        })
        return colsToReturn
    }

    // set row data and columns for aggrid
    useEffect(async () => {
        if (allRows !== null) {
            setRowData(await getData());
            setColumnDefs(getColumns())
        }
    }, [allRows]);

    // this function checks if the checkbox header has to be true or false
    const check_checkboxHeader = (params) => {
        const checkboxHeaderElement = document.getElementById(`idCheckboxHeader_${props.param.id}`)
        if (checkboxHeaderElement !== null) {
            let checked = true;
            params.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
                if (defaultRef.current[rowNode.data.id] === undefined || defaultRef.current[rowNode.data.id] === false) {
                    checked = false;
                    return;
                }
            });
            checkboxHeaderElement.checked = checked
        }
    }

    return (
        <Card body style={{ margin: 0, marginTop: 20 }}>
            <Row style={{ margin: 0 }}>
                <Col xs="12" style={{ padding: 0 }}>
                    <div style={{ fontFamily: "Montserrat" }}>
                        {props.param.paramName}
                    </div>
                    <div
                        style={{ fontFamily: "Montserrat", fontWeight: 500, fontSize: 12 }}
                    >
                        {props.param.paramDescription}
                    </div>
                    {rowData === null && columnDefs === null ?
                        <FaSpinner
                            icon="spinner"
                            className="spinner"
                            style={{ fontSize: 25, color: "#5B5B5B", marginTop: 15 }}
                        /> :
                        <div className="ag-theme-balham" style={{
                            height: height - 400,
                            marginTop: 15
                        }}>
                            <AgGridReact
                                ref={grid}
                                getRowId={(params) => params.data.id}
                                onFirstDataRendered={params => {
                                    setTimeout(() => {
                                        check_checkboxHeader(params)
                                    }, 100);
                                }}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                defaultColDef={{
                                    flex: 1,
                                    sortable: true,
                                    resizable: true,
                                    filter: true,
                                    filterParams: {
                                        buttons: ["reset"],
                                        newRowsAction: "keep",
                                        caseSensitive: true
                                    },
                                }}
                                frameworkComponents={{
                                    checkboxHeader: CheckboxHeader,
                                }}
                                onFilterChanged={(params) => {
                                    check_checkboxHeader(params)
                                }}
                            >
                            </AgGridReact>
                        </div>

                    }
                </Col>
            </Row>

        </Card>
    );
}

export default React.memo(TableWithAPI);
