import React, { useState } from "react";
import { useWindowDimensions } from "../../../../../utils/useWindowDimensions";
import { connect } from "react-redux";
import { getHeightWithFooterWONavbar } from "../../../../../utils/getHeightWithFooterWONavbar";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap";
import Data from "./Data/Data";
import BaselineDimensions from "./BaselineDimensions/BaselineDimensions";
import { FaPencilAlt, FaChartPie } from "react-icons/fa";
import BaselineTitleCard from "../../../../../components/BaselineTitleCard/BaselineTitleCard";
import Gwp from "./BaselineDimensions/components/Gwp/Gwp";
import ModalDashboard from "./ModalDashboard/ModalDashboard";

function Content(props) {
  const [activeTab, setActiveTab] = useState("co2");
  const [chiave, setChiave] = useState("co2");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDashboard, setIsOpenDashboard] = useState(false);
  const [isOpenGwp, setIsOpenGwp] = useState(false);
  const { width, height } = useWindowDimensions();
  const gases = props.data.gases;
  const toggle = () => setIsOpen(!isOpen);
  const toggleDashboard = () => setIsOpenDashboard(!isOpenDashboard);

  const styleTabPane = {
    height: height < 760 ? "88%" : "95%",
    overflowY: "hidden",
    border: "1px solid #C6C6C6",
    marginTop: "-1px",
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
    // paddingBottom: 80,
    background: "white",
  };

  return (
    <div
      style={{
        ...props.layout.generic_card,
        width: "100%",
        backgroundColor: "#E4E6E8",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        paddingLeft: 0,
        paddingBottom: props.layout.footer_height,
        overflow: "auto",
      }}
    >
      {/* workspace without padding */}
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 10,
        }}
      >
        {/* workspace */}
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <Row style={{ margin: 0 }}>
            <Col xs="12" style={{ padding: 0 }}>
              <div
                style={{
                  width: "100%",
                  padding: 10,
                  height: height < 850 ? height * 0.69 : height * 0.74,
                }}
              >
                <div
                  style={{
                    ...props.layout.generic_card,
                    width: "100%",
                    position: "relative",
                    display: "flex",
                    flexFlow: "column",
                    height: "100%",
                  }}
                >
                  <BaselineTitleCard
                    chiave={chiave}
                    setChiave={setChiave}
                    setIsOpenGwp={setIsOpenGwp}
                  ></BaselineTitleCard>
                  <div
                    onClick={toggleDashboard}
                    style={{
                      width: "fit-content",
                      color: "#1976d2",
                      border: "1px solid #1976d2",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      fontSize: 16,
                      padding: width < 770 ? "5px 8px 3px 8px" : "6px 20px 4px 20px",
                      borderRadius: 10,
                      cursor: "pointer",
                      position: "absolute",
                      top: 15,
                      right: width < 770 ? 65 : 130,
                    }}
                  >
                    <FaChartPie
                      style={{ marginTop: "-3px", marginRight: width < 770 ? 0 : 10 }}
                    ></FaChartPie >
                    {width < 770 ? null : "Dashboard"}
                  </div>
                  <div
                    onClick={() => setIsOpen(true)}
                    style={{
                      width: "fit-content",
                      backgroundColor: props.layout.color_sidebar,
                      color: "white",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      fontSize: 16,
                      padding:
                        width < 770 ? "5px 8px 3px 8px" : "6px 20px 4px 20px",
                      borderRadius: 10,
                      cursor: "pointer",
                      position: "absolute",
                      top: 15,
                      right: 20,
                    }}
                  >
                    <FaPencilAlt
                      style={{
                        marginTop: "-3px",
                        marginRight: width < 770 ? 0 : 10,
                      }}
                    ></FaPencilAlt>
                    {width < 770 ? null : "Edit"}
                  </div>
                  {chiave === "co2" ? (
                    <Data
                      data={props.data}
                      type={props.type}
                      chiave={"ghg_" + chiave}
                    ></Data>
                  ) : chiave === "co2_equivalente" ? (
                    <Data
                      data={props.data}
                      type={props.type}
                      chiave={chiave}
                    ></Data>
                  ) : chiave === "ghg" ? (
                    <Container
                      style={{ height: "85%", marginTop: 15, maxWidth: "99%" }}
                    >
                      <Nav tabs style={{ width: "97%" }}>
                        {gases.map((gas) => {
                          return (
                            <NavItem>
                              <NavLink
                                className={activeTab === gas ? "active" : ""}
                                onClick={() => setActiveTab(gas)}
                                href="#"
                              >
                                {gas.toUpperCase().replace("_", "-")}
                              </NavLink>
                            </NavItem>
                          );
                        })}
                      </Nav>
                      <TabContent
                        activeTab={activeTab}
                        style={{ height: "100%" }}
                      >
                        {gases.map((gas) => {
                          if (activeTab === gas) {
                            return (
                              <TabPane
                                tabId={gas}
                                style={{ height: "100%", ...styleTabPane }}
                              >
                                <Data
                                  data={props.data}
                                  type={props.type}
                                  chiave={"ghg_" + gas}
                                ></Data>
                              </TabPane>
                            );
                          }
                        })}
                      </TabContent>
                    </Container>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
          <Modal
            isOpen={isOpen}
            style={{
              fontFamily: "Montserrat",
              fontWeight: 500,
            }}
            size="xl"
            toggle={toggle}
          >
            <ModalHeader toggle={toggle}>Edit dimensions</ModalHeader>
            <ModalBody>
              <BaselineDimensions
                data={props.data}
                type={props.type}
              ></BaselineDimensions>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={isOpenGwp}
            style={{
              fontFamily: "Montserrat",
              fontWeight: 500,
            }}
            size="xl"
            toggle={() => {
              setIsOpenGwp(false);
            }}
          >
            <ModalHeader
              toggle={() => {
                setIsOpenGwp(false);
              }}
            >
              GWP
            </ModalHeader>
            <ModalBody>
              <Gwp data={props.data} type={props.type} chiave={chiave}></Gwp>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => {
                  setIsOpenGwp(false);
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>

          {/* modal dashboard */}
          {isOpenDashboard === true ?
            <ModalDashboard
              isOpen={isOpenDashboard}
              toggle={toggleDashboard}
              activeTab={activeTab}
              chiave={chiave}
              {...props}
            >
            </ModalDashboard> : null}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Content);
