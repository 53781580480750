import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { getHeightWithFooterWONavbar } from "../../../utils/getHeightWithFooterWONavbar";
import { useWindowDimensions } from "../../../utils/useWindowDimensions";
import { FaSpinner, FaPlus, FaHistory } from "react-icons/fa"
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import { applyTransactionFn } from "../../../utils/applyTransactionFn";
import { API_URL } from "../../../actions/types";
import axios from "axios";
import { tokenConfig } from "../../../utils/tokenConfig";
import ModalCreateUser from "./ModalCreateUser/ModalCreateUser";
import ActionUsers from "./ActionUsers";
import LogUsers from "./LogUsers";
import ModalAllLogs from "./ModalAllLogs/ModalAllLogs";
import { getUserType } from "../../../utils/getUserType";
import moment from "moment"
import { cloneDeep } from "lodash"
import { checkActionColumnWidth } from "../../../utils/checkActionColumnWidth";
import { checkPermission } from "../../../utils/checkPermission";


function Content(props) {
  const [rowData, setRowData] = useState(null)
  const [users, setUsers] = useState(null)
  const [isOpenCreate, setIsOpenCreate] = useState(false)
  const [isOpenAllLogs, setIsOpenAllLogs] = useState(false)
  const { width, height } = useWindowDimensions()

  const hierarchy = ["PROJECT ADMIN", "PROJECT USER", "ACTOR", "SUB ACTOR"]


  // grid ref
  const grid = useRef()

  // define function to get data
  const getData = async () => {
    const res = await axios.get(
      `${API_URL}/users/`,
      tokenConfig()
    );
    return res.data
      .sort((a, b) => a.id - b.id)
      .map((obj, index) => {
        var user_type = getUserType(obj)
        return {
          ...obj,
          user_type: user_type,
          count: index + 1
        }
      })
  }

  // on did mount, get data
  useEffect(async () => {
    const rows = await getData()
    setRowData(rows)
    setUsers(rows)
  }, [])

  // monitor refresh
  useEffect(() => {
    const channel = new BroadcastChannel("clicc-channel");
    channel.addEventListener("message", async e => {
      if (e.data === "refresh") {
        // get data and apply changes
        const newRows = await getData();
        setUsers(newRows)
        const arr_checks = ["id"];
        if (newRows.length >= 1) {
          applyTransactionFn(grid, newRows, arr_checks, true);
        }
      }
    });
    return () => channel.close()
  }, [])


  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#E4E6E8",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        paddingLeft: props.layout.width_sidebar,
        paddingBottom: props.layout.footer_height,
        overflow: "auto",
      }}
    >
      {/* workspace without padding */}
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 20,
        }}
      >
        {/* workspace */}
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          {rowData === null ? <>
            <FaSpinner
              className="spinner"
              style={{ marginTop: "-2px", marginRight: 5 }}
            ></FaSpinner>
            Loading...
          </> : <div
            style={{ fontFamily: "Montserrat", fontSize: 20, marginBottom: 10, }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                List users
              </div>
              <div style={{ display: "flex" }}>

                {props.auth.is_project_admin === true ?
                  <div
                    onClick={() => setIsOpenAllLogs(true)}
                    style={{
                      width: "fit-content",
                      backgroundColor: props.layout.color_sidebar,
                      color: "white",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      fontSize: 16,
                      padding:
                        width < 770 ? "5px 6px 3px 6px" : "6px 15px 4px 15px",
                      borderRadius: 10,
                      cursor: "pointer",
                      marginRight: 15
                    }}
                  >
                    <FaHistory
                      style={{
                        marginTop: "-3px",
                        marginRight: 5
                      }}
                    ></FaHistory> All logs
                  </div>
                  : null}

                {checkPermission(props.auth.user_permissions, "Can add user") === true ?
                  <div
                    onClick={() => setIsOpenCreate(true)}
                    style={{
                      width: "fit-content",
                      backgroundColor: props.layout.color_sidebar,
                      color: "white",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      fontSize: 16,
                      padding:
                        width < 770 ? "5px 6px 3px 6px" : "6px 15px 4px 15px",
                      borderRadius: 10,
                      cursor: "pointer",

                    }}
                  >
                    <FaPlus
                      style={{
                        marginTop: "-3px",
                        marginRight: 5
                      }}
                    ></FaPlus> Create
                  </div> : null
                }



              </div>

            </div>
            <div
              className="excel-style ag-theme-balham"
              style={{
                height: height - 215,
                marginTop: 5
              }}
            >
              <AgGridReact
                ref={grid}
                rowData={rowData}
                defaultColDef={{
                  flex: 1,
                  minWidth: 100,
                  resizable: true,
                  filter: true,
                  filterParams: {
                    buttons: ["reset"],
                    newRowsAction: "keep",
                    caseSensitive: true,
                  },
                  cellStyle: params => {
                    var style = {}
                    if (params.colDef.field === "expiration_date") {
                      style["display"] = "flex"
                      style["justify-content"] = "center"
                    }
                    return style
                  }
                }}
                frameworkComponents={{
                  actionUsers: ActionUsers,
                  logUsers: LogUsers,
                }}
              >
                <AgGridColumn
                  field=""
                  maxWidth={checkActionColumnWidth(props.auth.user_permissions, "user")}
                  minWidth={checkActionColumnWidth(props.auth.user_permissions, "user")}
                  suppressMenu={true}
                  cellRenderer="actionUsers"
                  cellRendererParams={{ rowData: rowData, auth: props.auth }}
                ></AgGridColumn>
                <AgGridColumn
                  field={"id"}
                  headerName={"ID"}
                  maxWidth={40}
                  suppressMenu={true}
                  hide={true}
                ></AgGridColumn>
                <AgGridColumn
                  field={"username"}
                ></AgGridColumn>
                <AgGridColumn
                  field={"first_name"}
                  headerName={"First name"}
                ></AgGridColumn>
                <AgGridColumn
                  field={"last_name"}
                  headerName={"Last name"}
                ></AgGridColumn>
                <AgGridColumn
                  field={"email"}
                  headerName={"Email"}
                ></AgGridColumn>
                <AgGridColumn
                  field={"organization"}
                  headerName={"Organization"}
                ></AgGridColumn>
                <AgGridColumn
                  field={"user_type"}
                  headerName={"Type"}
                ></AgGridColumn>
                <AgGridColumn
                  field={"last_login"}
                  headerName={"Last login"}
                  valueFormatter={params => {
                    if (params.value !== null) {
                      return params.value.replace("T", " ").replace("Z", "")
                    } else {
                      return null
                    }
                  }}
                ></AgGridColumn>
                <AgGridColumn
                  field={"expiration_date"}
                  headerName={"Expire date"}
                  valueFormatter={params => {
                    if (params.value === null || params.value === "") {
                      return "--"
                    } else {
                      var date_moment = moment(params.value)
                      var date_moment_tomorrow = cloneDeep(date_moment).add(1, "d")
                      if (date_moment_tomorrow < moment()) {
                        return `❌ ${date_moment.format("DD-MM-YYYY")}`
                      } else {
                        return date_moment.format("DD-MM-YYYY")
                      }

                    }
                  }}
                ></AgGridColumn>
                <AgGridColumn
                  field=""
                  maxWidth={30}
                  minWidth={30}
                  suppressMenu={true}
                  cellRenderer="logUsers"
                  cellRendererParams={{ rowData: rowData }}
                ></AgGridColumn>
              </AgGridReact>
            </div>
          </div>}

        </div>
      </div>

      {isOpenCreate === true ?
        <ModalCreateUser
          isOpen={isOpenCreate}
          setIsOpen={setIsOpenCreate}
          users={users}
          hierarchy={hierarchy}
        ></ModalCreateUser>
        : null}

      {isOpenAllLogs === true ?
        <ModalAllLogs
          isOpen={isOpenAllLogs}
          setIsOpen={setIsOpenAllLogs}>
        </ModalAllLogs>
        : null}

    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layout: state.layout,
});

export default connect(mapStateToProps)(Content);
