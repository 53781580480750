export const checkObjectsEqual = (a, b) => {
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // check if the number of properties is different
    if (aProps.length !== bProps.length) {
        return false;
    }

    // check if the values of each property are equal
    for (let i = 0; i < aProps.length; i++) {
        const propName = aProps[i];
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    return true;
}