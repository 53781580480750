import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TextField } from "@mui/material";
import { DragContainer } from "./DragContainer";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

function SelectScreen(props) {
  return (
    <div
      style={{
        border: "1px solid #dee2e6",
        padding: 20,
        backgroundColor: "white",
      }}
    >
      Select number of windows to open:{" "}
      <TextField
        variant="standard"
        type="number"
        value={props.numWindows}
        onBlur={(e) => {
          var num = e.target.value;
          if (e.target.value < props.minWindow) {
            props.setNumWindows(props.minWindow);
          } else if (e.target.value > props.maxWindow) {
            props.setNumWindows(props.maxWindow);
          } else {
            props.setNumWindows(Math.round(num));
          }
        }}
        onChange={(e) => {
          if (e.target.value < 20) {
            props.setNumWindows(Math.round(e.target.value));
          }
        }}
        style={{ width: 50, marginLeft: 5, marginTop: "-5px" }}
      />
      {props.numWindows < props.minWindow ||
      props.numWindows > props.maxWindow ? (
        <div style={{ marginTop: 5, color: "red", fontSize: 12 }}>
          *number of windows has to be between {props.minWindow} and{" "}
          {props.maxWindow}
        </div>
      ) : null}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 30,
        }}
      >
        <div
          style={{
            width: "100%",
            maxHeight: window.screen.availHeight / 2 + 20,
            maxWidth: window.screen.availWidth / 2,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              width: "100%",
              paddingBottom: `${
                (window.screen.height / window.screen.width) * 100
              }%`,
              height: "100%",
            }}
          >
            <div
              id="wrapper"
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                left: 0,
                border: "5px solid gray",
                borderRadius: 5,
                padding: 2,
                display: "flex",
                gap: "2px",
              }}
            >
              <DndProvider backend={HTML5Backend}>
                <DragContainer
                  numWindows={props.numWindows}
                  fields={props.fields}
                  setFields={props.setFields}
                ></DragContainer>
              </DndProvider>
            </div>
            {window.screen.width < 4000 ? (
              <div
                style={{
                  width: "40%",
                  height: "15px",
                  position: "absolute",
                  bottom: "-10px",
                  border: "5px solid gray",
                  borderRadius: 5,
                }}
              ></div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(SelectScreen);
