import {
  HANDLE_MENU_OC,
  HANDLE_INFOBOTTOM_OC,
  OPEN_MENU_OC,
  CLOSE_MENU_OC,
} from "../actions/types";

const initialState = {
  open: true,
  info_bottom: false,
};

export default function openClose(state = initialState, action) {
  switch (action.type) {
    case HANDLE_MENU_OC:
      return {
        ...state,
        open: !state.open,
      };
    case OPEN_MENU_OC:
      return {
        ...state,
        open: true,
      };
    case CLOSE_MENU_OC:
      return {
        ...state,
        open: false,
      };
    case HANDLE_INFOBOTTOM_OC:
      return {
        ...state,
        info_bottom: !state.info_bottom,
      };
    default:
      return state;
  }
}
