import React, { useState, useEffect } from "react"
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { API_URL, GET_ERRORS, IS_LOADING } from "../../../../actions/types";
import axios from "axios";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { useDispatch, connect } from "react-redux";
import { FaSpinner, FaRegClock } from "react-icons/fa"
import moment from "moment"
import CardModalLog from "./CardModalLog";
import { roundAtMost2Digits } from "../../../../utils/roundAtMost2Digits";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { usePlotlyConfig } from "../../../../utils/usePlotlyConfig";
import Plotly from "plotly.js-dist";
import TableLogs from "./TableLogs";


const ModalLogUser = (props) => {
    const [logUser, setLogUser] = useState(null)
    const [lastLogin, setLastLogin] = useState(null)
    const [totalTimeSpent, setTotalTimeSpent] = useState(null)
    const [averageDailyUse, setAverageDailyUse] = useState(null)
    const { width, height } = useWindowDimensions()
    const plotlyConfig = usePlotlyConfig();


    const toggle = () => props.setIsOpen(!props.isOpen);
    const dispatch = useDispatch();

    useEffect(async () => {
        const log_user = await axios.get(
            `${API_URL}/users/${props.data.id}/logs/`,
            tokenConfig()
        );
        setLogUser(log_user.data)



        // logic: create daily sessions per day. in each day there can be multiple session
        // what is a session? a sequence of calls where the call t_1 happend within 1 hour than t_0
        function groupByDayAndSumTime(list) {
            const groupedByDay = {};

            // group by day
            list.forEach(item => {
                const date = new Date(item.timestamp);
                const day = date.toISOString().split('T')[0];
                if (!groupedByDay[day]) {
                    groupedByDay[day] = [];
                }
                groupedByDay[day].push(date);
            });

            const results = {};

            // process each day
            Object.keys(groupedByDay).forEach(day => {
                const times = groupedByDay[day].sort((a, b) => a - b);
                let totalDuration = 0;
                let sessionStart = times[0];

                // process each timestamp
                for (let i = 1; i < times.length; i++) {
                    if ((times[i] - times[i - 1]) > 3600000) { // More than 1 hour apart
                        // end current session and add its duration to total
                        if (i - 1 > 0) { // Ensure session has more than one timestamp
                            totalDuration += (times[i - 1] - sessionStart);
                        }
                        sessionStart = times[i]; // start new session
                    } else if (i === times.length - 1) { // last timestamp, close current session
                        totalDuration += (times[i] - sessionStart);
                    }
                }

                // convert total duration from milliseconds to hours
                results[day] = totalDuration / 1000 / 60 / 60;
            });

            return results;
        }
        // total hours per day
        const timePerDay = groupByDayAndSumTime(log_user.data)
        // total hourly time spent in the application
        const totalTime = Object.values(timePerDay).reduce((sum, currentValue) => sum + currentValue, 0);
        // average hourly time per day (considered only days with time > 0)
        var positiveTimePerDay = {}
        Object.keys(timePerDay).forEach(k => {
            if (timePerDay[k] > 0) {
                positiveTimePerDay[k] = timePerDay[k]
            }
        })
        const values = Object.values(positiveTimePerDay);
        const sum = values.reduce((acc, curr) => acc + curr, 0);
        const average = values.length > 0 ? sum / values.length : 0;

        if (props.data.last_login !== null) {
            setLastLogin(moment(props.data.last_login).format("DD-MM-YYYY HH:mm"))
        } else {
            setLastLogin("--")
        }
        setTotalTimeSpent(totalTime)
        setAverageDailyUse(average)

        // generate chart with daily activities (sort timePerDay because it's a dictionary)
        const sortedEntries = Object.entries(timePerDay).sort((a, b) => {
            return new Date(a[0]) - new Date(b[0]);
        });
        const sortedKeys = sortedEntries.map(entry => entry[0]);
        const sortedValues = sortedEntries.map(entry => entry[1]);

        // daily chart
        var data = [
            {
                x: sortedKeys,
                y: sortedValues,
                type: "scatter",
            },
        ];


        var layout = {
            hovermode: "x",
            margin: {
                t: 20,
                b: 30,
                l: 40,
                r: 40,
            },
            showlegend: false,
        };

        Plotly.newPlot("activityChart", data, layout, { ...plotlyConfig });
    }, [])

    return (
        <Modal
            isOpen={props.isOpen}
            toggle={toggle}
            size="xl"
            style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
            }}
        >
            <ModalHeader toggle={toggle}>User activity</ModalHeader>

            <ModalBody>
                {
                    logUser === null || lastLogin === null || totalTimeSpent === null || averageDailyUse === null ?
                        <div>
                            <FaSpinner
                                className="spinner"
                                style={{ marginTop: "-2px", marginRight: 5 }}
                            ></FaSpinner>
                            Loading...
                        </div>
                        : <div>
                            <Row style={{ marginTop: "-20px" }}>
                                <Col lg="4" sm="12" style={{ marginTop: 20 }} >
                                    <CardModalLog title="Last login" text={lastLogin}></CardModalLog>
                                </Col>
                                <Col lg="4" sm="12" style={{ marginTop: 20 }}>
                                    <CardModalLog title="Total time spent" text={`${Math.round(totalTimeSpent)} hours`}></CardModalLog>
                                </Col>
                                <Col lg="4" sm="12" style={{ marginTop: 20 }}>
                                    <CardModalLog title="Average daily use" text={`${roundAtMost2Digits(averageDailyUse)} hours`}></CardModalLog>
                                </Col>
                            </Row>
                            <div style={{ marginTop: 20 }}>
                                <FaRegClock style={{ fontSize: 18, marginTop: "-2px", marginRight: 5 }} />Daily activity (in hours)
                            </div>
                            <div
                                style={{
                                    width: "100%",
                                    height: height * 0.25,
                                }}
                                id="activityChart"
                            ></div>
                            <div style={{ marginTop: 20 }}>
                                <TableLogs logs={logUser} height={height * 0.35}></TableLogs>
                            </div>
                        </div>
                }</ModalBody>
        </Modal>
    )
};

const mapStateToProps = (state) => ({
    layout: state.layout,
});

export default connect(mapStateToProps)(ModalLogUser);



