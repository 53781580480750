import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useDispatch } from "react-redux";
import { API_URL, IS_LOADING } from "../../../../../actions/types";
import SuccessAlert from "../../../../../components/Modals/SuccessAlert";
import axios from "axios";
import { tokenConfig } from "../../../../../utils/tokenConfig";
import { triggerRefresh } from "../../../../../utils/triggerRefresh";

const ModalDeleteActionType = (props) => {
    const dispatch = useDispatch();

    const toggle = () => props.setIsOpen(!props.isOpen);
    return (
        <Modal
            isOpen={props.isOpen}
            toggle={toggle}
            size="lg"
            style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
            }}
        >
            <ModalHeader toggle={toggle}>Attention</ModalHeader>
            <ModalBody>Do you really want to delete the selected item?</ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    onClick={async () => {
                        dispatch({
                            type: IS_LOADING,
                        });
                        await axios.delete(
                            `${API_URL}/clicc/actions_inventory/${props.data.id}/`,
                            tokenConfig()
                        );
                        dispatch({
                            type: IS_LOADING,
                        });
                        triggerRefresh()
                        SuccessAlert("Done!");
                    }}
                >
                    Delete
                </Button>
                <Button color="secondary" onClick={toggle}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    )
};

export default ModalDeleteActionType;
