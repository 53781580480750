import {
  RESET_CREATE_NEW_CONTRACT,
  UPDATE_CREATE_NEW_CONTRACT,
} from "../actions/types";

const initialState = {
  name: "",
  description: "",
  workspace: null,
  commitments: null,
  action_plan: null,
  investment_plan: null,
};

export default function toolbar(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CREATE_NEW_CONTRACT:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case RESET_CREATE_NEW_CONTRACT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
