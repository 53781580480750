import React, { useState } from "react";
import { connect } from "react-redux";
import { useWindowDimensions } from "../../../utils/useWindowDimensions";
import TitleCard from "../../../components/TitleCard/TitleCard";
import { FaArrowUp, FaArrowDown, FaEquals } from "react-icons/fa";
import { BsGearFill } from "react-icons/bs";
import { Switch, FormControlLabel } from "@mui/material";
import { roundNumberInCard } from "../../../utils/roundNumberInCard";

function CardHomepageTopLeft(props) {
  const { width, height } = useWindowDimensions();
  const [show, setShow] = useState(false);

  const toggle = () => setShow(!show);
  return (
    <div
      style={{
        ...props.layout.generic_card,
        display: "flex",
        flexFlow: "column",
        height: "100%",
      }}
    >
      <TitleCard
        title={props.cardData.title}
        icon={
          props.cardData.title.props.children.includes("CO") ? (
            <div
              style={{ display: "flex" }}
              onClick={toggle}
            // onMouseEnter={() => setShow(true)}
            // onMouseLeave={toggle}
            >
              <BsGearFill style={{ cursor: "pointer" }}></BsGearFill>
              {show === true ? (
                <div
                  style={{
                    ...props.layout.generic_card,
                    left: 100,
                    position: "absolute",
                    left:
                      props.selectedTab === "ghg" ? width / 2.96 : width / 2,
                    zIndex: 999,
                    width: "240px",
                    height: "160px",
                    padding: 10,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={props.selectedTab === "co2"}
                        onChange={async (e) => {
                          props.setSelectedTab("co2");
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        style={{ fontFamily: "Montserrat" }}
                      />
                    }
                    label={
                      <div
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                        }}
                      >
                        CO₂
                      </div>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={props.selectedTab === "co2_equivalent"}
                        onChange={async (e) => {
                          props.setSelectedTab("co2_equivalent");
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        style={{ fontFamily: "Montserrat" }}
                      />
                    }
                    label={
                      <div
                        style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                      >
                        CO₂ Equivalent
                      </div>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={props.selectedTab === "ghg"}
                        onChange={async (e) => {
                          props.setSelectedTab("ghg");
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        style={{ fontFamily: "Montserrat" }}
                      />
                    }
                    label={
                      <div
                        style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                      >
                        GHG
                      </div>
                    }
                  />
                </div>
              ) : null}
            </div>
          ) : null
        }
      ></TitleCard>
      <div
        style={{
          width: "100%",
          height: "100%",
          padding: "0px 10px",
          borderRadius: props.layout.generic_card.borderRadius,
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            fontFamily: "Montserrat",
            color: props.layout.color_footer,
            padding: 10
          }}
        >
          <div style={{ padding: 5 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
                fontSize: height < 780 ? 24 : 34,
                marginBottom: "-3px",
              }}

            >

              {props.homepage["emissions_current_cumulative_value_since_baseline"].toLocaleString("eng", {
                maximumFractionDigits: 0,
              })} t
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "60%",
                  borderBottom: `1px solid ${props.layout.color_footer}`,
                }}
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: 500,
                textAlign: "center",
                fontSize: height < 780 ? 13 : width > 1600 ? 15 : 13,
              }}
            >
              <i>Current avoided emissions</i>
            </div>
          </div>

          <div style={{ padding: 5 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: 500,
                fontSize: height < 780 ? 16 : 20,
              }}
            >
              <div>


                {props.homepage["emissions_reduction_from_baseline"].toLocaleString("eng", {
                  maximumFractionDigits: 0,
                })} t/y
              </div>
              <div style={{ marginLeft: 5 }}>
                (
                {props.homepage["emissions_reduction_from_baseline_perc"] >
                  0 ? (
                  <FaArrowUp
                    color="red"
                    style={{
                      marginTop: "-3px",
                      fontSize: height < 780 ? 14 : width > 1500 ? 21 : 18,
                    }}
                  ></FaArrowUp>
                ) : props.homepage["emissions_reduction_from_baseline_perc"] ===
                  0 ? (
                  <FaEquals
                    color="orange"
                    style={{
                      marginTop: "-3px",
                      fontSize: height < 780 ? 14 : width > 1500 ? 21 : 18,
                    }}
                  ></FaEquals>
                ) : (
                  <FaArrowDown
                    color="green"
                    style={{
                      marginTop: "-3px",
                      fontSize: height < 780 ? 16 : width > 1500 ? 21 : 18,
                    }}
                  ></FaArrowDown>
                )}
                {props.homepage["emissions_reduction_from_baseline_perc"]}%)
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "60%",
                  borderBottom: `1px solid ${props.layout.color_footer}`,
                }}
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: 500,
                textAlign: "center",
                fontSize: height < 780 ? 13 : width > 1600 ? 15 : 13,
              }}
            >
              <i>Current updated baseline</i>
            </div>
          </div>

          {/* target --> rimosso */}
          {/* <div style={{ padding: 5 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: 500,
                fontSize: height < 780 ? 16 : 20,
              }}
            >
              {props.homepage["emissions_distance_from_target_perc"] !==
              null ? (
                <div style={{ marginLeft: 5 }}>
                  {props.homepage["emissions_distance_from_target_perc"]}%
                </div>
              ) : (
                "-"
              )}
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "60%",
                  borderBottom: `1px solid ${props.layout.color_footer}`,
                }}
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: 500,
                textAlign: "center",
                fontSize: height < 780 ? 13 : width > 1600 ? 15 : 13,
              }}
            >
              <i>Current distance from target</i>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(CardHomepageTopLeft);
