import React from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { API_URL } from "../../../actions/types";

function DropDownMenu(props) {
  const history = useHistory();
  const location = useLocation();

  const styleSubPage = {
    width: 180,
    height: props.state.layout.height_button_submenu,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 16,
    fontWeight: 500,
    fontFamily: "Montserrat",
    borderRadius: 7,
    marginTop: 10,
    cursor: "pointer",
  };

  const calculatedHeightDropdown =
    props.pages.length * (props.state.layout.height_button_submenu + 10) + 10 + 20;
  const halfHeightDropdown = calculatedHeightDropdown / 2;

  return (
    <div
      style={{
        position: "absolute",
        transform: "rotate(90deg)",
      }}
    >
      <div
        style={{
          //  transform: "rotate(90deg)",
          backgroundColor: props.state.layout.color_sidebar,
          position: "absolute",
          top: -(props.widthButton / 2) - halfHeightDropdown,
          left: 0,
          padding: 20,
          paddingLeft: 30,
          paddingTop: 10,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
          color: "white",
        }}
      >
        {props.pages.map((page, index) => {
          return (
            <div
              key={index}
              style={{
                ...styleSubPage,
                backgroundColor:
                  page.name === "Action type"
                    ? null
                    : props.state.layout.color_background_button_sidebar,
                fontStyle: page.name === "Action type" ? "italic" : "",
                border:
                  location.pathname.includes(page.url)
                    ? `2px solid ${props.state.layout.yellow_detail}`
                    : `2px solid ${props.state.layout.color_border_button_sidebar}`,
              }}
              onClick={() => {
                if (page.external_url === true) {
                  window.location.replace(API_URL.replace("/api", "/ctp"));
                } else {
                  var link = page.url
                  // if click on "Modify ...." then try to see if there is something opened
                  if (page.name.includes("Modify ")) {
                    var split = page.name.split("Modify ")
                    var key = `opened_${split[split.length - 1]}`
                    if (props.state[key].id !== undefined) {
                      link = `${page.url}/?id=${props.state[key].id}`
                    }
                  }
                  history.push(link)
                }
              }}
            >
              {page.name}
              {page.name === "Action type" ? (
                <div
                  style={{
                    fontSize: 8,
                    backgroundColor:
                      props.state.layout.color_background_button_sidebar,
                    padding: "0px 4px 0px 2px",
                    borderRadius: 5,
                    marginLeft: 4,
                    marginTop: "-7px",
                  }}
                >
                  Admin
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  state: state,
});

export default connect(mapStateToProps)(DropDownMenu);
