import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { FaSpinner } from "react-icons/fa";
import { API_URL, GET_ERRORS } from "../../../../actions/types";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { applyTransactionFn } from "../../../../utils/applyTransactionFn";
import axios from "axios";
import { connect, useDispatch } from "react-redux";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import moment from "moment";

function TableLogs(props) {
    const [users, setUsers] = useState(null);
    const [rowData, setRowData] = useState(null);
    const { width, height } = useWindowDimensions();

    // grid ref
    const myGrid = useRef()

    // define function to get data
    const getData = async () => {
        const resUsers = await axios.get(`${API_URL}/users/`, tokenConfig());
        setUsers(resUsers.data);

        // do logs
        const gdpAcc = resUsers.data.find(
            (obj) => obj.username === "gdpanalytics"
        );
        if (gdpAcc !== undefined && window.location.hostname !== "localhost") {
            return props.logs.filter((obj) => obj.user !== gdpAcc.id)
        } else {
            return props.logs
        }
    }

    // on did mount, get data
    useEffect(async () => {
        const rows = await getData()
        setRowData(rows)
    }, [])

    // monitor refresh
    useEffect(() => {
        const channel = new BroadcastChannel("clicc-channel");
        channel.addEventListener("message", async e => {
            if (e.data === "refresh") {
                // get data and apply changes
                const newRows = await getData();
                const arr_checks = ["id"];
                if (newRows.length >= 1) {
                    applyTransactionFn(myGrid, newRows, arr_checks, true);
                }
            }
        });
        return () => channel.close()
    }, [])


    return (
        <div
            className="excel-style ag-theme-balham"
            style={{
                height: props.height,
            }}
        >
            <AgGridReact
                ref={myGrid}
                rowData={rowData}
                defaultColDef={{
                    flex: 1,
                    minWidth: 120,
                    resizable: true,
                    sortable: true,
                    filter: true,
                    filterParams: { buttons: ["reset"], newRowsAction: "keep", caseSensitive: true },
                }}
            >
                <AgGridColumn
                    field="timestamp"
                    sort="desc"
                    valueGetter={(params) => {
                        return moment(params.data[params.colDef.field]).format(
                            "YYYY-MM-DD HH:mm:ss"
                        );
                    }}
                ></AgGridColumn>
                <AgGridColumn
                    field="user"
                    valueGetter={(params) => {
                        var user_detail = users.find(
                            (us) => us.id === params.data[params.colDef.field]
                        );
                        if (user_detail !== undefined) {
                            return user_detail.username;
                        } else {
                            return "utente cancellato";
                        }
                    }}
                ></AgGridColumn>
                <AgGridColumn
                    field="method"
                    valueGetter={(params) => {
                        var value = params.data[params.colDef.field];
                        if (value === "GET") {
                            return "APERTO";
                        } else if (value === "POST") {
                            if (params.data.api === "/api/autenticazione/login/") {
                                return "EFFETTUATO";
                            } else {
                                return "CREATO";
                            }
                        } else if (value === "PUT") {
                            return "MODIFICATO";
                        } else if (value === "DELETE") {
                            return "CANCELLATO";
                        } else {
                            return "";
                        }
                    }}
                ></AgGridColumn>
                <AgGridColumn
                    field=""
                    headerName="Object"
                    valueGetter={(params) => {
                        if (params.data.api.includes("/api/clicc/workspace/")) {
                            return "Workspace";
                        } else if (
                            params.data.api.includes(
                                "/api/clicc/contract_documents/download/"
                            )
                        ) {
                            return "Download contract";
                        } else if (
                            params.data.api.includes("/api/clicc/contract_documents/")
                        ) {
                            return "Contract";
                        } else if (params.data.api.includes("/api/clicc/actors/")) {
                            return "Actor";
                        } else if (params.data.api.includes("/api/clicc/actions/")) {
                            return "Action";
                        } else if (
                            params.data.api.includes("/api/autenticazione/login/")
                        ) {
                            return "Login";
                        } else if (params.data.api.includes("/api/autenticazione/")) {
                            return "Utente";
                        } else if (
                            params.data.api.includes("/api/clicc/download_excel/")
                        ) {
                            return "Download excel";
                        } else if (
                            params.data.api.includes("/api/users/")
                        ) {
                            return "Utente"
                        }
                    }}
                ></AgGridColumn>
                <AgGridColumn
                    field="api"
                    headerName="API"
                ></AgGridColumn>
                {/* <AgGridColumn
                    field=""
                    headerName="Name"
                    valueGetter={(params) => {
                        if (
                            Object.keys(params.data.params).length > 0 &&
                            Object.keys(params.data.params).includes("name")
                        ) {
                            return params.data.params.name;
                        } else if (Object.keys(params.data.payload).length > 0) {
                            if (Object.keys(params.data.payload).includes("name")) {
                                return params.data.payload.name;
                            } else if (
                                Object.keys(params.data.payload).includes(
                                    "actor_business_name"
                                )
                            ) {
                                return params.data.payload.actor_business_name;
                            } else if (
                                Object.keys(params.data.payload).includes("username")
                            ) {
                                return params.data.payload.username;
                            } else if (
                                Object.keys(params.data.payload).includes("data") &&
                                Object.keys(params.data.payload.data).includes("name")
                            ) {
                                if (params.data.payload.data.name === "") {
                                    return "during creation...";
                                } else {
                                    return params.data.payload.data.name;
                                }
                            }
                        }
                    }}
                ></AgGridColumn> */}
                <AgGridColumn
                    field="params"
                    hide={true}
                    valueGetter={(params) => {
                        return JSON.stringify(params.data[params.colDef.field]);
                    }}
                ></AgGridColumn>
                <AgGridColumn
                    field="payload"
                    valueGetter={(params) => {
                        return JSON.stringify(params.data[params.colDef.field]);
                    }}
                ></AgGridColumn>
                <AgGridColumn field="status"></AgGridColumn>
            </AgGridReact>
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(TableLogs);
