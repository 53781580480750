import React from "react";
import { Progress } from "reactstrap";
import { getProgressBar } from "../../../../utils/getProgressBar";
import Tooltip from "@mui/material/Tooltip";

function ProgressColumn(props) {
  const value = getProgressBar(props.data, props.colDef.field);

  return (
    <>
      {props.data.actions !== undefined ? (
        <div>
          <Tooltip title="Completion not available for grouped actions">
            <div>--</div>
          </Tooltip>
        </div>
      ) : value !== null ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "default",
          }}
        >
          <div style={{ width: "50px" }}>
            <Progress
              color={value < 33 ? "danger" : value < 66 ? "warning" : "success"}
              value={value}
              style={{ width: "100%", height: 5 }}
            />
          </div>
          <div
            style={{
              marginLeft: 5,
              textAlign: "center",
              fontWeight: value === 0 ? "" : "bold",
              color:
                value === 0
                  ? "black"
                  : value < 33
                  ? "#DC3546"
                  : value < 66
                  ? "#FFC105"
                  : "#198754",
            }}
          >
            {value}%
          </div>
        </div>
      ) : null}
    </>
  );
}

export default ProgressColumn;
