import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import InfoAlert from "../../../../../../components/Modals/InfoAlert";
import { applyTransactionFn } from "../../../../../../utils/applyTransactionFn";
import { useWindowDimensions } from "../../../../../../utils/useWindowDimensions";
import axios from "axios";
import { API_URL } from "../../../../../../actions/types";
import { tokenConfig } from "../../../../../../utils/tokenConfig";
import { roundAtMost2Digits } from "../../../../../../utils/roundAtMost2Digits";

function Data(props) {
  const [rowData, setRowData] = useState(null);
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();
  const getData = async () => {
    // sortare per macro_area e settore

    return props.data["baseline_" + props.chiave].sort(
      (a, b) =>
        a.macro_area.localeCompare(b.macro_area) ||
        a.settore.localeCompare(b.settore)
    );
  };

  const grid = useRef();
  const mounted = useRef();
  useEffect(async () => {
    mounted.current = false;
    setRowData(null);
  }, [props.chiave]);

  useEffect(async () => {
    if (!mounted.current) {
      setRowData(await getData());
      mounted.current = true;
    } else {
      const newRows = await getData();
      const arr_checks = ["macro_area", "settore", "sottosettore"];
      if (newRows.length >= 1) {
        applyTransactionFn(grid, newRows, arr_checks, true);
      }
    }
  }, [props.data["baseline_" + props.chiave]]);

  var isDragging = false;
  var listChanges = [];
  const fnCellChanged = async (myList) => {
    var raise_error = false;
    // var regExp = /^\d*(?:[.,]\d+)?$/;
    var regExp = /^-?\d*(?:[.,]\d+)?$/;
    var listFiltered = [];
    myList.forEach((params) => {
      // check if there are no letters or commas --> if yes show error and set newValue = oldValue
      if (
        params.newValue === "" ||
        params.newValue === undefined ||
        params.newValue === null
      ) {
        params.data[params.colDef.field] = parseFloat(params.oldValue);
        params.newValue = parseFloat(params.oldValue);
      }
      // this check was changed because in this table negative values are allowed, also in their excel file they have , as thousand separator (there is a replace afterwards)
      else if (
        String(params.newValue).match(regExp) === null
      ) {
        if (
          String(params.newValue).includes(" ")
        ) {
          params.data[params.colDef.field] = parseFloat(params.newValue.replace(/ /g, '').replace(/,/g, ''));
          params.newValue = parseFloat(params.newValue.replace(/ /g, '').replace(/,/g, ''));
        }
        else {
          raise_error = true;
          params.data[params.colDef.field] = params.oldValue;
          params.newValue = params.oldValue;
        }
      } else {
        if (params.colDef.field !== "Gas emissions [t/y]") {
          params.data["Gas emissions [t/y]"] = 0;
        } else {
          Object.keys(params.data).forEach((key) => {
            if (
              key !== "Gas emissions [t/y]" &&
              key !== "macro_area" &&
              key !== "settore" &&
              key !== "sottosettore"
            ) {
              params.data[key] = 0;
            }
          });
        }
      }
      listFiltered.push(params);
    });

    var final = [];
    var updateRows = {};
    listFiltered.forEach((changes) => {
      changes.data[changes.column.colId] = parseFloat(
        String(changes.data[changes.column.colId]).replace(",", ".")
      );

      updateRows[
        changes.data.macro_area +
        "_" +
        changes.data.settore +
        "_" +
        changes.data.sottosettore
      ] = changes.data;
    });
    //dispatch
    props.data["baseline_" + props.chiave].forEach((s) => {
      if (s.macro_area + "_" + s.settore + "_" + s.sottosettore in updateRows) {
        final.push(
          updateRows[s.macro_area + "_" + s.settore + "_" + s.sottosettore]
        );
      } else {
        final.push(s);
      }
    });
    dispatch({
      type: props.type,
      payload: {
        key: "baseline_" + props.chiave,
        value: final,
      },
    });
    // check insert_manual_values: parte la chiamata che aggiorna i valori di co2_equiv
    if (props.data.insert_manual_values === false && raise_error === false) {
      const res = await axios.post(
        `${API_URL}/clicc/workspace/co2_equivalent/`,
        {
          co2: props.data.baseline_ghg_co2,
          f_gas: props.data.baseline_ghg_f_gas,
          nf3: props.data.baseline_ghg_nf3,
          n2o: props.data.baseline_ghg_n2o,
          sf6: props.data.baseline_ghg_sf6,
          ch4: props.data.baseline_ghg_ch4,
          gwps: props.data.gwps,
          [props.chiave.replace("ghg_", "")]: final,
        },
        tokenConfig()
      );
      dispatch({
        type: props.type,
        payload: {
          key: "baseline_co2_equivalente",
          value: res.data.co2_equivalent,
        },
      });
    }
    // if there are errors (i.e. letters) show error --> in any case continue (there is no an ELSE)
    if (raise_error === true) {
      InfoAlert("Invalid format! Only numbers are allowed");
    }

    // reset list
    listChanges = [];
  };
  function rowTotal() {
    const total = {
      macro_area: "",
      settore: "",
      sottosettore: "Total",
    };
    props.data["baseline_" + props.chiave].forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (
          key !== "macro_area" &&
          key !== "settore" &&
          key !== "sottosettore"
        ) {
          if (key in total) {
            total[key] = Number(total[key]) + Number(row[key]);
          } else {
            total[key] = Number(row[key]);
          }
        }
      });
    });
    return total;
  }
  return (
    <div style={{ height: "100%" }}>
      {rowData !== null ? (
        <div
          className="excel-style ag-theme-balham"
          style={{
            height: "95%",
            padding: "20px 20px 0px 20px",
          }}
        >
          <AgGridReact
            ref={grid}
            rowGroupPanelShow={"always"}
            groupDisplayType={"multipleColumns"}
            isGroupOpenByDefault={(params) => {
              return true;
            }}
            groupHideOpenParents={true}
            groupMaintainOrder={true}
            pinnedBottomRowData={[rowTotal()]}
            // enableCellChangeFlash={true}
            enableFillHandle={true}
            enableRangeSelection={true}
            stopEditingWhenCellsLoseFocus={true}
            processDataFromClipboard={(params) => {
              var data = [];
              for (var i = 0; i < params.data.length; i++) {
                if (
                  params.data[i].length !== 1 ||
                  (params.data[i][0] !== "" && params.data[i][0] !== " ")
                ) {
                  data.push(params.data[i]);
                }
              }
              return data;
            }}
            rowData={rowData}
            suppressFieldDotNotation={true}
            getRowStyle={(params) => {
              var style = {};
              style["border"] = "0px";
              return style;
            }}
            // autoGroupColumnDef={{ cellRendererParams: { suppressCount: true } }}
            defaultColDef={{
              minWidth: 100,
              resizable: true,
              filter: true,
              filterParams: {
                buttons: ["reset"],
                newRowsAction: "keep",
                caseSensitive: true,
              },
              editable: (params) => {
                if (
                  params.colDef.field === "macro_area" ||
                  params.colDef.field === "settore" ||
                  params.colDef.field === "sottosettore" ||
                  (params.data.macro_area === "" &&
                    params.data.settore === "" &&
                    params.data.sottosettore === "Total")
                ) {
                  return false;
                } else return true;
              },
              cellStyle: (params) => {
                var style = {
                  cursor: "default",
                };
                const grayColor = "rgba(180, 180, 180, 0.5)";
                // manage Total at the bottom (final row)
                if (
                  params.data !== undefined &&
                  params.data.macro_area === "" &&
                  params.data.settore === "" &&
                  params.data.sottosettore === "Total"
                ) {
                  style["fontWeight"] = "bold";
                  style["background"] = "#d9d9d9";
                }
                try {
                  const prevRow = params.api.getDisplayedRowAtIndex(
                    params.node.rowIndex - 1
                  );
                  // manage border top on change sector
                  if (
                    prevRow !== undefined &&
                    params.colDef.field !== "macro_area" &&
                    prevRow.data.settore !== params.data.settore
                  ) {
                    style["border-top"] = "1px solid #DBDBDB";
                  }
                  if (
                    prevRow !== undefined &&
                    prevRow.data.macro_area !== params.data.macro_area
                  ) {
                    style["border-top"] = "1px solid #DBDBDB";
                  }
                } catch { }
                return style;
              },
            }}
            onCellValueChanged={(params) => {
              listChanges.push(params);
              if (params.source !== "paste" && isDragging === false) {
                fnCellChanged(listChanges);
              }
            }}
            onPasteEnd={(params) => {
              fnCellChanged(listChanges);
            }}
            onDragStarted={(params) => {
              isDragging = true;
            }}
            onDragStopped={(params) => {
              isDragging = false;
              // check if there are no changes because isDragging becomes true even if you simply drag without using the fill operations
              if (listChanges.length > 0) {
                fnCellChanged(listChanges);
              }
            }}
          >
            <AgGridColumn
              field={"macro_area"}
              headerName={"Macro Area"}
              width={130}
              pinned="left"
              valueFormatter={(params) => {
                try {
                  const prevRow = params.api.getDisplayedRowAtIndex(
                    params.node.rowIndex - 1
                  );
                  if (
                    prevRow !== undefined &&
                    prevRow.data.macro_area === params.data.macro_area
                  ) {
                    return "";
                  }
                  return params.data["macro_area"];
                } catch { }
              }}
            ></AgGridColumn>
            <AgGridColumn
              field={"settore"}
              headerName={"Sector"}
              width={150}
              pinned="left"
              valueFormatter={(params) => {
                try {
                  const prevRow = params.api.getDisplayedRowAtIndex(
                    params.node.rowIndex - 1
                  );
                  if (
                    prevRow !== undefined &&
                    prevRow.data.settore === params.data.settore
                  ) {
                    return "";
                  }
                  return params.data["settore"];
                } catch { }
              }}
            ></AgGridColumn>
            <AgGridColumn
              field={"sottosettore"}
              headerName={"Subsector"}
              width={230}
              pinned="left"
            ></AgGridColumn>
            <AgGridColumn
              headerName={
                props.chiave
                  .replace("ghg_", "")
                  .replace("_g", "-G")
                  .toUpperCase()
                  .replace("_EQUIVALENTE", " Equivalent") +
                " Emissions by commodity [t/y]"
              }
            >
              {props.data.baseline_commodities
                .sort((a, b) => a.commodity.localeCompare(b.commodity))
                .map((field) => {
                  const commodity = field.commodity;
                  return (
                    <AgGridColumn
                      field={commodity}
                      headerName={commodity}
                      width={commodity.length * 11}
                      editable={() => {
                        if (
                          props.data.insert_manual_values === false &&
                          props.chiave === "co2_equivalente"
                        ) {
                          return false;
                        } else return true;
                      }}
                      cellClass={(params) => {
                        if (
                          props.data.insert_manual_values === false &&
                          props.chiave === "co2_equivalente"
                        ) {
                          return "non-editable";
                        } else return "";
                      }}
                      valueFormatter={params => {
                        return params.value.toLocaleString("eng", {
                          maximumFractionDigits: 2,
                        })
                      }}
                    ></AgGridColumn>
                  );
                })}
            </AgGridColumn>
            <AgGridColumn
              field={"Gas emissions [t/y]"}
              headerName={
                props.chiave
                  .replace("ghg", "")
                  .replace("_", " ")
                  .replace("equivalente", "eq.")
                  .toUpperCase() + " emissions [t/y]"
              }
              width={220}
              editable={() => {
                if (
                  props.data.insert_manual_values === false &&
                  props.chiave === "co2_equivalente"
                ) {
                  return false;
                } else return true;
              }}
              cellClass={(params) => {
                if (
                  props.data.insert_manual_values === false &&
                  props.chiave === "co2_equivalente"
                ) {
                  return "non-editable";
                } else return "";
              }}
              valueFormatter={params => {
                return params.value.toLocaleString("eng", {
                  maximumFractionDigits: 2,
                })
              }}
            ></AgGridColumn>
            <AgGridColumn
              field={"Emissions incidence"}
              width={200}
              editable={false}
              valueGetter={(params) => {
                var gridTotal = 0;
                var localTotal = 0;
                const columnsTotal = rowTotal();
                Object.keys(columnsTotal).map((key) => {
                  if (
                    props.data.baseline_commodities.some(
                      (e) => e.commodity === key
                    ) ||
                    key === "Gas emissions [t/y]"
                  ) {
                    gridTotal += Number(columnsTotal[key]);
                  }
                });
                Object.keys(params.data).map((key) => {
                  if (
                    props.data.baseline_commodities.some(
                      (e) => e.commodity === key
                    ) ||
                    key === "Gas emissions [t/y]"
                  ) {
                    localTotal += Number(params.data[key]);
                  }
                });

                var perc = Number(localTotal / gridTotal).toLocaleString(
                  "eng",
                  {
                    style: "percent",
                    minimumFractionDigits: 2,
                  }
                );
                var defaultTotal = "0.00%";
                if (
                  params.data.macro_area === "" &&
                  params.data.settore === "" &&
                  params.data.sottosettore === "Total"
                ) {
                  var total = 0;
                  Object.keys(params.data).forEach((key) => {
                    if (
                      key !== "macro_area" &&
                      key !== "settore" &&
                      key !== "sottosettore"
                    )
                      total += parseFloat(params.data[key]);
                  });
                  perc = `${roundAtMost2Digits(total)} (100%)`;
                  defaultTotal = `${roundAtMost2Digits(total)} (100%)`;
                }
                return gridTotal !== 0 ? perc : defaultTotal;
              }}
              cellStyle={{ fontWeight: "bold", background: "#d9d9d9" }}
            ></AgGridColumn>
          </AgGridReact>
        </div>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Data);
