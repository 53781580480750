import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import { connect } from "react-redux";
import { login, loadUser } from "../Dashboard/actions/auth";
import logoSplashScreen from "../images/logo_est_polito_EC_noBackgroud.png";
import logoTorino from "../images/torino_logo_full.png";
import logoFondazione from "../images/logo_compagnia_san_paolo.png";
import { checkAndMakeRedirection } from "../Dashboard/actions/auth";
import { useHistory, Link } from "react-router-dom";
import "./Login.css";

function Copyright() {
  return (
    <div style={{ color: "#F0F0F0", fontSize: 13, textAlign: "center" }}>
      <div>
        {"Copyright EST © energycenter"} - {new Date().getFullYear()}
        {"."}
      </div>
      <div style={{ marginTop: "10px" }}>
        <div>
          In cooperation with{" "}
          <a
            href="https://www.gdpanalytics.com"
            style={{ color: "white", textDecoration: "underline" }}
          >
            GDP Analytics
          </a>
        </div>
      </div>
    </div>
  );
}

export const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  let history = useHistory();


  const onSubmit = (e) => {
    e.preventDefault();
    props.login(username, password);
  };

  const onChangeUsername = (e) => setUsername(e.target.value);
  const onChangePassword = (e) => setPassword(e.target.value);

  useEffect(() => {
    if (props.isAuthenticated) {
      checkAndMakeRedirection(history, props.urlInitial);
    }
  }, [props.isAuthenticated]);

  return (
    <form onSubmit={onSubmit}>
      <div className="context"></div>
      {/* create moving circles and squares (each <li> tag) */}
      <div className="area">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <div className="divLogin login">
        <div className="formFadeIn">
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "70px",
              }}
            >
              <img
                className="content"
                src={logoTorino}
                alt="LogoTorino"
                style={{
                  height: "75px",
                  width: "230px",
                  imageRendering: "-webkit-optimize-contrast",
                  marginRight: 40,
                }}
              />
              <img
                className="content"
                src={logoSplashScreen}
                alt="Logo"
                style={{
                  height: "auto",
                  width: "200px",
                  imageRendering: "-webkit-optimize-contrast",
                  marginRight: 40,
                }}
              />
              <img
                className="content"
                src={logoFondazione}
                alt="Logo"
                style={{
                  height: "auto",
                  width: "200px",
                  imageRendering: "-webkit-optimize-contrast",
                }}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // height: "50vh",
            }}
          >
            <div>
              <div
                style={{
                  textAlign: "center",
                  color: "white",
                  fontWeight: "bold",
                  fontFamily: "Garamond",
                  fontSize: 35,
                }}
              >
                Climate City Contract
              </div>
              <Container
                component="main"
                maxWidth="xs"
                style={{
                  marginTop: "15px",
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                <div>
                  <input
                    type="text"
                    name="username"
                    placeholder="Username"
                    onChange={onChangeUsername}
                    value={username}
                    className="inputLogin"
                  />
                </div>
                <div>
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    onChange={onChangePassword}
                    value={password}
                    className="inputLogin"
                  />
                </div>

                <div>
                  <button className="btnLogin" type="submit">
                    Let me in
                  </button>
                </div>
                <div style={{ color: "#F0F0F0", fontSize: 13, textAlign: "center", marginTop: 10 }}>
                  <div>
                    <Link to="/clicc/send-email-reset-password" style={{ color: "white" }}>
                      Forgot password?
                    </Link>

                  </div>
                </div>
                <div style={{ marginTop: "50px" }}>
                  <Copyright />
                </div>
                <div
                  style={{
                    width: "100%",
                    position: "absolute",
                    bottom: 15,
                    left: 0,
                  }}
                >
                  <div
                    style={{
                      color: "#F0F0F0",
                      fontSize: 15,
                      textDecoration: "underline",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    Credits
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  login,
  loadUser,
})(Login);
