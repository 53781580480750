import update from "immutability-helper";
import React, { useCallback, useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import { Box as DraggableBox } from "./Box.js";
import { SelectComponent } from "./SelectComponent.js";

export const DragContainer = (props) => {
  const styles = {
    width: "inherit",
    height: "inherit",
    position: "unset",
  };
  const [boxes, setBoxes] = useState({});
  useEffect(() => {
    setBoxes({});
    const wrapper = document
      .getElementById("sub_wrapper")
      .getBoundingClientRect();
    if (props.numWindows > 0) {
      var newBoxes = {};
      Array(props.numWindows)
        .fill(1)
        .map((n, i) => {
          newBoxes[String(i) + "_" + String(props.numWindows)] = {
            top: 2,
            left: i === 0 ? 2 : (wrapper.width / props.numWindows) * i,
            // width: wrapper.width / props.numWindows,
          };
        });
      setBoxes(newBoxes);
    }
  }, [props.numWindows]);
  const moveBox = useCallback(
    (id, left, top, width) => {
      setBoxes(
        update(boxes, {
          [id]: {
            $merge: { left, top, width },
          },
        })
      );
    },
    [boxes, setBoxes]
  );
  const [, drop] = useDrop(
    () => ({
      accept: "draggable-box",
      drop(item, monitor) {
        var draggableBox = document
          .getElementById(item.id)
          .getBoundingClientRect();

        const delta = monitor.getDifferenceFromInitialOffset();
        var left = Math.round(item.left + delta.x);
        var top = Math.round(item.top + delta.y);

        // if (height === 0) {
        //   height = wrapper.height * 0.96;
        //   width = width * (1.7 / props.numWindows);
        // }
        // if (left < wrapper.left) {
        //   left = item.left;
        // }
        // if (
        //   top + height > wrapper.height ||
        //   top < 0 ||
        //   left < 0 ||
        //   left + width > wrapper.width
        // ) {
        //   top = item.top;
        //   left = item.left;
        // }
        moveBox(item.id, left, top);
        return undefined;
      },
    }),
    [moveBox]
  );

  return (
    <div ref={drop} style={styles} id={"sub_wrapper"}>
      {Object.keys(boxes).map((key) => {
        const { left, top, width } = boxes[key];
        return (
          <DraggableBox
            key={key}
            id={key}
            left={left}
            top={top}
            numWindows={props.numWindows}
          >
            <SelectComponent
              numWindows={props.numWindows}
              width={width}
              id={key}
              fields={props.fields}
              setFields={props.setFields}
            ></SelectComponent>
          </DraggableBox>
        );
      })}
    </div>
  );
};
