import axios from "axios";
import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  API_URL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  GET_ERRORS,
  PRODUCTION_API_URL,
} from "./types";
import { tokenConfig } from "../utils/tokenConfig";

// CHECK TOKEN & LOAD USER
export const loadUser = () => async (dispatch) => {
  try {
    // check always if the token is valid
    const res = await axios.get(
      `${API_URL}/autenticazione/user/`,
      tokenConfig()
    );
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// LOGIN USER
export const login = (username, password) => async (dispatch) => {
  // Login Loading
  dispatch({ type: USER_LOADING });
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // Request Body
  const body = JSON.stringify({ username, password });
  try {
    const res = await axios.post(
      `${API_URL}/autenticazione/login/`,
      body,
      config
    );
    localStorage.setItem("token", res.data.token);

    // do login also in production (this was done as a workaround to access city explorer in the develpment version inside polito)
    const resCitta = await axios.post(
      `${PRODUCTION_API_URL}/autenticazione/login/`,
      body,
      config
    );
    localStorage.setItem("city_explorer_tk", resCitta.data.token);
    const UserRequest = await axios.get(
      `${API_URL}/autenticazione/user/`,
      tokenConfig()
    );
    dispatch({
      type: LOGIN_SUCCESS,
      payload: UserRequest.data,
    });
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
    });
    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors,
    });
  }
};

// LOGOUT
export const logout = () => async (dispatch) => {
  try {
    await axios.post(`${API_URL}/autenticazione/logout/`, null, tokenConfig());
    dispatch({
      type: LOGOUT_SUCCESS,
    });
    window.location.reload();
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors,
    });
  }
};

// when doing login, check if the initial link comes from the app, if yes go there instead of homepage (and if there is a link to a simulation, open it)
export const checkAndMakeRedirection =
  (history, urlInitial) => {
    // remember that at this point you know that he/she is already authenticated
    if (!urlInitial.includes("/clicc/") || urlInitial.split("/clicc/")[1] === "" || urlInitial.includes("/login")) {
      history.push("/clicc/dashboard/homepage");
    } else {
      history.push(`/clicc/${urlInitial.split("/clicc/")[1]}`);
    }
  };