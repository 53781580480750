export const createEmptyTemplate = () => {
  const commitments = [
    {
      id: "c_h1",
      type: "header",
      data: {
        text: "1 Introduction",
        level: 2,
      },
    },
    {
      id: "c_p1_1",
      type: "paragraph",
      data: {
        text: "Explain your city’s motivation to join the EU Mission “100 climate-neutral and smart cities by 2030” and highlight your city’s present commitments to climate action. You may also want to include the aims of this document.",
        level: 2,
      },
    },
    {
      id: "c_p1_2",
      type: "paragraph",
      data: {
        text: "<b>Your Text Here</b>",
        level: 2,
      },
    },
    {
      id: "c_p2_2_1",
      type: "paragraph",
      data: {
        text: "…",
        level: 2,
      },
    },
    {
      id: "c_h2",
      type: "header",
      data: {
        text: "2 Goal: Climate neutrality by 2030",
        level: 2,
      },
    },
    {
      id: "c_p2_1",
      type: "paragraph",
      data: {
        text: "Articulate your 2030 climate neutrality ambition, as expressed and defined in your Cities Mission Expression of Interest (EoI). This should include your ambition and commitment to a 2030 horizon as a whole city, as well as describe any exclusion areas and summarise how these areas would be addressed beyond 2030. (A more detailed plan for exclusion areas should be included in the 2030 Climate Neutrality Action Plan.) Your 2030 ambition should be supported at a minimum by a Council decision, and it is recommended that it is also supported by a wider stakeholder group. We also recommend you to list other co-benefits you aim to achieve when working towards the climate neutrality goal, like well-being, health, equity, justice, financial savings.",
        level: 2,
      },
    },
    {
      id: "c_p2_2_2",
      type: "paragraph",
      data: {
        text: "<b>Your Text Here</b>",
        level: 2,
      },
    },
    {
      id: "c_p2_2_3",
      type: "paragraph",
      data: {
        text: "…",
        level: 2,
      },
    },
    {
      id: "c_h3",
      type: "header",
      data: {
        text: "3 Key priorities and strategic interventions",
        level: 2,
      },
    },
    {
      id: "c_p3_1",
      type: "paragraph",
      data: {
        text: "This is the core section of the Commitments document that should summarise <b>at least 3 or 4 systemic strategic priorities</b> that need to be implemented for your city to become climate neutral by 2030. These should be meaningful changes that will have a profound impact on reducing GHG emissions in your city, like decarbonising the heating system in the city or generating 100% energy from renewables.  The individual commitments between your city and other stakeholders should address these key priorities and contribute to reaching them. The annexed 2030 Climate Neutrality Action Plan should describe the all interventions, including those to reach your priorities as well as all further actions, in detail and describe how your city plans to implement them.",
        level: 2,
      },
    },
    {
      id: "c_p3_2",
      type: "paragraph",
      data: {
        text: "<b>Your Text Here</b>",
        level: 2,
      },
    },
    {
      id: "c_p2_2_4",
      type: "paragraph",
      data: {
        text: "…",
        level: 2,
      },
    },
    {
      id: "c_h4",
      type: "header",
      data: {
        text: "4 Principles and process",
        level: 2,
      },
    },
    {
      id: "c_p4_1",
      type: "paragraph",
      data: {
        text: "Highlight the key principles that will guide your city as it implements its Climate City Contract, like accountability, transparency, or an open attitude to new approaches. The process should encompass principles like <b>co-creation, innovation, multi-actor and citizen engagement</b>, and should be <b>systemic and demand-driven in nature</b>. It should also be based on <b>monitoring</b> and <b>joint learning</b>. The Commitments Guidance document provides more specific guidance on how integrate these principles into your own process.",
        level: 2,
      },
    },
    {
      id: "c_p4_2",
      type: "paragraph",
      data: {
        text: "<b>Your Text Here</b>",
        level: 2,
      },
    },
    {
      id: "c_p2_2_5",
      type: "paragraph",
      data: {
        text: "…",
        level: 2,
      },
    },
    {
      id: "c_h5",
      type: "header",
      data: {
        text: "5 Signatories",
        level: 2,
      },
    },
    {
      id: "c_p5_1",
      type: "paragraph",
      data: {
        text: "Include a list of stakeholders who have committed to help your city achieve its goal to reach climate neutrality by 2030. Detailed commitments and agreements between individuals or groups of stakeholders should be appended to this Commitments document. This list will likely increase over time.",
        level: 2,
      },
    },
    {
      id: "c_p5_2",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          [
            "Name of the institution",
            "Sector/Area",
            "Legal form",
            "Name of the responsible person",
            "Position of the responsible person",
          ],
          [" ", " ", " ", " ", " "],
          [" ", " ", " ", " ", " "],
          [" ", " ", " ", " ", " "],
        ],
      },
    },
  ];
  const action_plan = [
    {
      id: "a_h1",
      type: "header",
      data: {
        text: "Summary",
        level: 2,
      },
    },
    {
      id: "a_p1_1",
      type: "paragraph",
      data: {
        text: "An abstract <b>summarizes the content</b> of the 2030 Climate Neutrality Action Plan (Action Plan) that is developed jointly by local authorities, local businesses and other stakeholders.",
        level: 2,
      },
    },
    {
      id: "a_p1_2",
      type: "paragraph",
      data: {
        text: "Textual element",
        level: 2,
      },
    },
    {
      id: "a_h2",
      type: "header",
      data: {
        text: "List of figures",
        level: 2,
      },
    },
    {
      id: "a_p2_1",
      type: "paragraph",
      data: {
        text: "The list of figures <b>identifies the titles and locations</b> (page numbers) of <b>all visual elements</b>: figures, drawings, photos, maps, etc. used in the Action Plan.",
        level: 2,
      },
    },
    {
      id: "a_p2_2_1",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          ["Figure №", "Figure title", "Page №"],
          ["Figure 1", "", ""],
          ["", "", ""],
          ["", "", ""],
        ],
      },
    },
    {
      id: "a_h3",
      type: "header",
      data: {
        text: "List of tables",
        level: 2,
      },
    },
    {
      id: "a_p3_1",
      type: "paragraph",
      data: {
        text: "The list of tables <b>identifies the titles and locations</b> (page numbers) of <b>all tables</b> used in the Action Plan.",
        level: 2,
      },
    },
    {
      id: "a_p3_2",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          ["Table №", "Table title", "Page №"],
          ["Table 1", "", ""],
          ["", "", ""],
          ["", "", ""],
        ],
      },
    },
    {
      id: "a_h4",
      type: "header",
      data: {
        text: "Abbreviations and acronyms",
        level: 2,
      },
    },
    {
      id: "a_p4_1",
      type: "paragraph",
      data: {
        text: "The list of abbreviations and acronyms <b>identifies the abbreviations</b> (a shortened form of a word used in place of the full word) <b>and acronyms</b> (a word formed from the first letters of each of the words in a phrase of name) used in the Action Plan. ",
        level: 2,
      },
    },
    {
      id: "a_p4_2",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          ["Abbreviations and acronyms", "Definition"],
          ["", ""],
          ["", ""],
          ["", ""],
        ],
      },
    },
    {
      id: "a_h5",
      type: "header",
      data: {
        text: "1 Introduction",
        level: 2,
      },
    },
    {
      id: "a_p5_1",
      type: "paragraph",
      data: {
        text: "The introduction should outline the local policy context in which the Action Plan is being developed and describe the gap it is addressing in broad terms.",
        level: 2,
      },
    },
    {
      id: "a_p5_2",
      type: "paragraph",
      data: {
        text: "<b>Introduction - textual element</b>",
        level: 2,
      },
    },
    {
      id: "a_p5_3",
      type: "paragraph",
      data: {
        text: "(Please fill out according to the Action Plan Guidance)",
        level: 2,
      },
    },
    {
      id: "a_h6",
      type: "header",
      data: {
        text: "2 Work Process",
        level: 2,
      },
    },
    {
      id: "a_p6_1",
      type: "paragraph",
      data: {
        text: "This section should list the working steps carried out, for example along the NZC Climate Transition Map, or related steps planned as well as outline timeline and milestones for future iterations for the continuous development of the Action Plan.",
        level: 2,
      },
    },
    {
      id: "a_p6_2",
      type: "paragraph",
      data: {
        text: "<b>Work Process - combination of textual and visual elements</b>",
        level: 2,
      },
    },
    {
      id: "a_p6_3",
      type: "paragraph",
      data: {
        text: "(Please fill out according to the Action Plan Guidance)",
        level: 2,
      },
    },
    {
      id: "a_h7",
      type: "header",
      data: {
        text: "3 Part A – Current State of Climate Action",
        level: 2,
      },
    },
    {
      id: "a_p7_1",
      type: "paragraph",
      data: {
        text: "Part A “Current State of Climate Action” describes the point of departure of the city towards climate neutrality, including commitments and strategies of key local businesses, and informs the subsequent modules and the outlined pathways to accelerated climate action.",
        level: 2,
      },
    },
    {
      id: "a_h7_1",
      type: "header",
      data: {
        text: "3.1 Module A-1 Greenhouse Gas Emissions Baseline Inventory",
        level: 3,
      },
    },
    {
      id: "a_p7_1_1",
      type: "paragraph",
      data: {
        text: "Module A-1 “Greenhouse Gas Emissions Baseline Inventory” should detail and describe the city’s latest GHG inventory to establish the emission baseline and to establish the emissions gap to 2030 climate neutrality according to the inventory specifications defined in the Cities Mission’s Info Kit for Cities and the process outlined in the Action Plan Guidance.",
        level: 2,
      },
    },
    {
      id: "a_p7_2_1",
      type: "paragraph",
      data: {
        text: "<b>A-1.1: Final energy use by source sectors</b>",
        level: 2,
      },
    },
    {
      id: "a_p7_3_1",
      type: "paragraph",
      data: {
        text: "Base year",
        level: 2,
      },
    },
    {
      id: "a_p7_4_1",
      type: "paragraph",
      data: {
        text: "Unit MWh/year",
        level: 2,
      },
    },
    {
      id: "a_t7_1_1",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          [" ", "Scope 1", "Scope 2", "Scope 3", "Total"],
          ["Buildings", "", "", "", ""],
          ["(Fuel type/ energy used)", "", "", "", ""],
          ["Transport", "", "", "", ""],
          ["(Fuel type/ energy used)", "", "", "", ""],
          ["Waste", "", "", "", ""],
          ["(Fuel type/ energy used)", "", "", "", ""],
          ["Industrial Process and Product Use (IPPU)", "", "", "", ""],
          ["(Fuel type/ energy used)", "", "", "", ""],
          ["Agricultural, Forestry and Land Use (AFOLU)", "", "", "", ""],
          ["(Fuel type/ energy used)", "", "", "", ""],
        ],
      },
    },
    {
      id: "a_p7_2_2",
      type: "paragraph",
      data: {
        text: "<b>A-1.2: Emission factors applied</b>",
        level: 2,
      },
    },
    {
      id: "a_p7_3_2",
      type: "paragraph",
      data: {
        text: "(please specify for primary energy type and GHG emission factor according to methodology used)",
        level: 2,
      },
    },
    {
      id: "a_p7_4_2",
      type: "paragraph",
      data: {
        text: "For calculation in t or MWh of primary energy",
        level: 2,
      },
    },
    {
      id: "a_p7_5",
      type: "paragraph",
      data: {
        text: "(Please indicate method used, e.g. GPC, IPCC, CRF, national etc.)",
        level: 2,
      },
    },
    {
      id: "a_t7_2",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          [
            "Primary energy/ energy source",
            "Carbon Dioxide (CO2)",
            "Methane (CH4)",
            "Nitrous Oxide (N2O)",
            "F-gases (hydrofluorocarbons and perfluorocarbons)",
            "Sulphur hexafluoride (SF6)",
            "Nitrogen trifluoride (NF3)",
          ],
          ["", "", "", "", "", "", ""],
        ],
      },
    },
    {
      id: "a_p7_2_3",
      type: "paragraph",
      data: {
        text: "<b>A-1.3: Activity by source sectors</b>",
        level: 2,
      },
    },
    {
      id: "a_p7_3_3",
      type: "paragraph",
      data: {
        text: "Base year",
        level: 2,
      },
    },
    {
      id: "a_t7_1_2",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          [" ", "Scope 1", "Scope 2", "Scope 3"],
          ["Buildings", "", "", ""],
          ["(Activity)", "", "", ""],
          ["Transport", "", "", ""],
          ["(Activity)", "", "", ""],
          ["Waste", "", "", ""],
          ["(Activity)", "", "", ""],
          ["Industrial Process and Product Use (IPPU)", "", "", ""],
          ["(Activity)", "", "", ""],
          ["Agricultural, Forestry and Land Use (AFOLU)", "", "", ""],
          ["(Activity)", "", "", ""],
        ],
      },
    },
    {
      id: "a_p7_2_4",
      type: "paragraph",
      data: {
        text: "<b>A-1.4: GHG emissions by source sectors</b>",
        level: 2,
      },
    },
    {
      id: "a_p7_3_4",
      type: "paragraph",
      data: {
        text: "Base year",
        level: 2,
      },
    },
    {
      id: "a_p7_4_3",
      type: "paragraph",
      data: {
        text: "Unit CO2equivalent/year",
        level: 2,
      },
    },
    {
      id: "a_t7_1_3",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          [" ", "Scope 1", "Scope 2", "Scope 3", "Total"],
          ["Buildings", "", "", "", ""],
          ["Transport", "", "", "", ""],
          ["Waste", "", "", "", ""],
          ["Industrial Process and Product Use (IPPU)", "", "", "", ""],
          ["Agricultural, Forestry and Land Use (AFOLU)", "", "", "", ""],
          ["Total", "", "", "", ""],
        ],
      },
    },
    {
      id: "a_p7_2_5",
      type: "paragraph",
      data: {
        text: "<b>A-1.5: Graphics and charts</b>",
        level: 2,
      },
    },
    {
      id: "a_p7_3_5",
      type: "paragraph",
      data: {
        text: "(Please include visualisations of GHG emissions baseline)",
        level: 2,
      },
    },
    {
      id: "a_p7_2_6",
      type: "paragraph",
      data: {
        text: "<b>A-1.6: Description and assessment of GHG baseline inventory</b>",
        level: 2,
      },
    },
    {
      id: "a_p7_3_6",
      type: "paragraph",
      data: {
        text: "(Describe, assess, contextualise tables and charts above)",
        level: 2,
      },
    },
    {
      id: "a_h7_2",
      type: "header",
      data: {
        text: "3.2 Module A-2 Current Policies and Strategies Assessment",
        level: 3,
      },
    },
    {
      id: "a_p7_1_2",
      type: "paragraph",
      data: {
        text: "Module A-2 “Current Policies and Strategies” should list relevant policies, strategies, initiatives or regulation from local, regional and national level, relevant to the city’s climate neutrality transition.",
        level: 2,
      },
    },
    {
      id: "a_p7_2_7",
      type: "paragraph",
      data: {
        text: "<b>A-2.1: List of relevant policies, strategies & regulations</b>",
        level: 2,
      },
    },
    {
      id: "a_t7_1_4",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          [
            "Type",
            "Level",
            "Name & Title",
            "Description",
            "Relevance",
            "Need for action",
          ],
          [
            "(regulation/ policy/ strategy/ action plan)",
            "(local, regional, national, EU)",
            "(Name of policy/ strategy/ plans)",
            "(Description of policy/ strategy/ plans)",
            "(Describe relevance/ impact on climate neutrality ambition)",
            "(list any suggested action in relation – to be further picked in Module C-1)",
          ],
          ["", "", "", "", "", ""],
        ],
      },
    },
    {
      id: "a_p7_2_8",
      type: "paragraph",
      data: {
        text: "<b>A-2.2: Description & assessment of policies</b>",
        level: 2,
      },
    },
    {
      id: "a_p7_2_9",
      type: "paragraph",
      data: {
        text: "(describe and assess listed policies, strategies, regulations etc. to add detail)",
        level: 2,
      },
    },
    {
      id: "a_p7_2_10",
      type: "paragraph",
      data: {
        text: "<b>A-2.3: Emissions gap</b>",
        level: 2,
      },
    },
    {
      id: "a_t7_1_5",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          [
            "Baseline emissions (percentage)",
            "Residual emissions / offestting¹",
            "Baseline emissions reduction target²",
            "Emissions reductions in existing strategies³",
            "Emissions gap (to be addressed by action plan)⁴",
          ],
          [
            "absolute (%)",
            "absolute (%)",
            "absolute (%)",
            "absolute (%)",
            "absolute (%)",
          ],
          ["Buildings", "", "", "", ""],
          ["Transport", "", "", "", ""],
          ["Waste", "", "", "", ""],
          ["Industrial Process and Product Use (IPPU)", "", "", "", ""],
          ["Agricultural, Forestry and Land Use (AFOLU)", "", "", "", ""],
          ["Total", "", "", "", ""],
        ],
      },
    },
    {
      id: "a_p7_2_11",
      type: "paragraph",
      data: {
        text: `¹ Residual emissions consist of those emissions which can’t be reduced through climate action and are being offset. Residual emission may amount to a maximum of 20 % as stated by the Mission Info Kit.<br>
        ² Baseline reduction target = Baseline emissions – residual emissions.<br>
        ³ Emission reductions planned for in existing action planning and strategies should be quantified per sector.<br>
        ⁴ Emissions gap = Baseline emission reduction target – Emissions reduction in existing strategies.<br>
        `,
        level: 2,
      },
    },
    {
      id: "a_h7_3",
      type: "header",
      data: {
        text: "3.3 Module A-3 Systemic Barriers and Opportunities to 2030 Climate Neutrality",
        level: 3,
      },
    },
    {
      id: "a_p7_1_3",
      type: "paragraph",
      data: {
        text: "Module A-3 “Systemic Barriers to 2030 Climate Neutrality” should document the results of the stakeholder, systems and ecosystem mapping and identification of systemic barriers and opportunities. ",
        level: 2,
      },
    },
    {
      id: "a_p7_2_12",
      type: "paragraph",
      data: {
        text: "<b>A-3.1: Systems & stakeholder mapping</b>",
        level: 2,
      },
    },
    // {
    //   id: "a_p7_2_13",
    //   type: "paragraph",
    //   data: {
    //     text: "(Fill out according to AP Guidance) – e.g.",
    //     level: 2,
    //   },
    // },
    {
      id: "a_t7_1_6",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          [
            "System description",
            "Stakeholders involved",
            "Network",
            "Influence",
            "Interest",
          ],
          ["", "", "", "", ""],
        ],
      },
    },
    {
      id: "a_p7_2_14",
      type: "paragraph",
      data: {
        text: "<b>A-3.2: Description of systemic barriers and opportunities – textual elements</b>",
        level: 2,
      },
    },
    {
      id: "a_p7_2_15",
      type: "paragraph",
      data: {
        text: "(Please fill out according to the Action Plan Guidance)",
        level: 2,
      },
    },
    {
      id: "a_p7_2_16",
      type: "paragraph",
      data: {
        text: "<b>A-3.3: Description or visualisation of participatory model for the city climate neutrality – textual and visual elements</b>",
        level: 2,
      },
    },
    {
      id: "a_p7_2_17",
      type: "paragraph",
      data: {
        text: "(Please fill out according to the Action Plan Guidance)",
        level: 2,
      },
    },
    {
      id: "a_h7_4",
      type: "header",
      data: {
        text: "4 Part B – Pathways towards Climate Neutrality by 2030",
        level: 2,
      },
    },
    {
      id: "a_p7_4_4",
      type: "paragraph",
      data: {
        text: "Part B represents the core of the Action Plan, shaped by local authorities, local businesses and stakeholders, comprising of the most essential elements: scenarios, strategic objectives, impacts, action portfolios and indicators for monitoring, evaluation and learning.",
        level: 2,
      },
    },
    {
      id: "a_h7_5",
      type: "header",
      data: {
        text: "4.1 Module B-1 Climate Neutrality Scenarios and Impact Pathways",
        level: 3,
      },
    },
    {
      id: "a_p7_1_5",
      type: "paragraph",
      data: {
        text: "Module B-1 “Climate Neutrality Scenarios and Impact Pathways” should list impact pathways, early and late outcomes and direct and indirect impacts (co-benefits) according to and adapted from the NZC Theory of Change and the AP Guidance – clustered by fields of action.",
        level: 2,
      },
    },
    {
      id: "a_p7_2_18",
      type: "paragraph",
      data: {
        text: "<b>B-1.1: Impact Pathways</b>",
        level: 2,
      },
    },
    {
      id: "a_t7_1_7",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          [
            "Fields of action",
            "Systemic levers",
            "Early changes (1-2 years)",
            "Late outcomes (3-4 years)",
            "Direct impacts (Emission reductions)",
            "Indirect impacts (co-benefits)",
          ],
          [
            "Energy systems",
            "(Lever #1)",
            "(Early change #1)",
            "(Late outcome #1)",
            "(direct impact #1)",
            "(indirect impact #1)",
          ],
          [
            "Mobility & transport",
            "(Lever #1)",
            "(Early change #1)",
            "(Late outcome #1)",
            "(direct impact #1)",
            "(indirect impact #1)",
          ],
          [
            "Waste & circular economy",
            "(Lever #1)",
            "(Early change #1)",
            "(Late outcome #1)",
            "(direct impact #1)",
            "(indirect impact #1)",
          ],
          [
            "Green infrastructure & nature based solutions",
            "(Lever #1)",
            "(Early change #1)",
            "(Late outcome #1)",
            "(direct impact #1)",
            "(indirect impact #1)",
          ],
          [
            "Built environment",
            "(Lever #1)",
            "(Early change #1)",
            "(Late outcome #1)",
            "(direct impact #1)",
            "(indirect impact #1)",
          ],
        ],
      },
    },
    {
      id: "a_p7_2_19",
      type: "paragraph",
      data: {
        text: "<b>B-1.2: Description of impact pathways– textual and visual elements</b>",
        level: 2,
      },
    },
    {
      id: "a_p7_2_20",
      type: "paragraph",
      data: {
        text: "(Describe, visualise and contextualise pathways listed above)",
        level: 2,
      },
    },
    {
      id: "a_h7_6",
      type: "header",
      data: {
        text: "4.2 Module B-2 Climate Neutrality Portfolio Design",
        level: 3,
      },
    },
    {
      id: "a_p7_1_6",
      type: "paragraph",
      data: {
        text: "Module B-2 “Climate Neutrality Portfolio Design” should contain a project description for <b>each intervention planned</b>, including interventions by local businesses and industry, according to the template B-2.1, including actions those interventions targeted at enhancing carbon sinks to address residual emissions. Narrative analysis and comments can be provided in B-2.2. A summary of how residual emissions are addressed, should be provided in B-2.3.",
        level: 2,
      },
    },
    {
      id: "a_p7_2_21",
      type: "paragraph",
      data: {
        text: "<b>B-2.1: Description of action portfolios - textual or visual</b>",
        level: 2,
      },
    },
    {
      id: "a_t7_1_8",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          ["Fields of action", "List of actions", "Descriptions"],
          ["Energy systems", "", ""],
          ["Mobility & transport", "", ""],
          ["Waste & circular economy", "", ""],
          ["Green infrastructure & nature based solutions", "", ""],
          ["Built environment", "", ""],
        ],
      },
    },
    {
      id: "a_p7_2_22",
      type: "paragraph",
      data: {
        text: "<b>B-2.2: Individual action outlines</b>",
        level: 2,
      },
    },
    {
      id: "a_p7_2_23",
      type: "paragraph",
      data: {
        text: "(fill out one sheet per intervention/project)",
        level: 2,
      },
    },
    {
      id: "a_t7_1_9",
      type: "table",
      data: {
        withHeadings: false,
        content: [
          ["Action outline", "Action name ", ""],
          ["", "Action type", ""],
          ["", "Action description", ""],
          ["Reference to impact pathway", "Field of action", ""],
          ["", "Systemic lever", ""],
          ["", "Outcome (according to module B-1.1)", ""],
          [
            "Implementation",
            "Responsible bodies/person for implementation",
            "",
          ],
          ["", "Action scale & addressed entities", ""],
          ["", "Involved stakeholders", ""],
          ["", "Comments on implementation", ""],
          ["Impact & cost", "Generated renewable energy (if applicable)", ""],
          ["", "Removed/substituted energy, volume or fuel type", ""],
          [
            "",
            "GHG emissions reduction estimate (total) per emission source sector",
            "",
          ],
          ["", "Total costs and costs by CO2e unit", ""],
        ],
      },
    },
    {
      id: "a_p7_2_24",
      type: "paragraph",
      data: {
        text: "<b>B-2.3: Summary strategy for residual emissions</b>",
        level: 2,
      },
    },
    {
      id: "a_p7_2_25",
      type: "paragraph",
      data: {
        text: "(Detail how residual emission will be offset, if applicable)",
        level: 2,
      },
    },
    {
      id: "a_h7_7",
      type: "header",
      data: {
        text: "4.3 Module B-3 Indicators for Monitoring, Evaluation and Learning",
        level: 3,
      },
    },
    {
      id: "a_p7_1_7",
      type: "paragraph",
      data: {
        text: "Module B-3 “Indicators for Monitoring, Evaluation and Learning” should contain a selection of indicators taken from the Comprehensive Indicator Sets developed by NZC. The following should be provided: An overview table listing the indicators selected per outcome and impact including targets and evaluation points (B-3.1); and a metadata table for each indicator selected, as specified in the Comprehensive Indicator Sets (B-3.2).",
        level: 2,
      },
    },
    {
      id: "a_p7_1_8",
      type: "paragraph",
      data: {
        text: "<b>B-3.1: Impact Pathways</b>",
        level: 2,
      },
    },
    {
      id: "a_t7_1_10",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          [
            "Outcomes/ impacts addressed",
            "Action/ project",
            "<b>Indicator No.</b> (unique identified)",
            "Indicator name",
            "Target values 2025",
            "Target values 2027",
            "Target values 2030",
          ],
          [
            "(list early changes/ late outcomes and impacts to be evaluated by indicator)",
            "(list action/ pilot project if applicable)",
            "(indicate unique identifier)",
            "(Insert indicator name)",
            "(list one value per indicator)",
            "(list one value per indicator)",
            "(list one value per indicator)",
          ],
          ["", "", "", "", "", "", ""],
        ],
      },
    },
    {
      id: "a_p7_1_9",
      type: "paragraph",
      data: {
        text: "<b>B-3.2: Indicator Metadata</b>",
        level: 2,
      },
    },
    {
      id: "a_p7_1_10",
      type: "paragraph",
      data: {
        text: "(for each indicator selected – take from Comprehensive Indicator Sets)",
        level: 2,
      },
    },
    {
      id: "a_t7_1_11",
      type: "table",
      data: {
        withHeadings: false,
        content: [
          ["Indicator Name", ""],
          ["Indicator Unit", ""],
          ["Definition", ""],
          ["Calculation", ""],
        ],
      },
    },
    {
      id: "a_t7_1_12",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          ["Indicator Context", " "],
          [
            "Does the indicator measure direct impacts (i.e. reduction in greenhouse gas emissions?)",
            "[yes/no]",
          ],
          [
            "If yes, which emission source sectors does it impact?",
            "Fields of action according to GHG inventory format – Module A-1",
          ],
          [
            "Does the indicator measure indirect impacts (i.e. co- benefits)?",
            "[yes/no]",
          ],
          ["If yes, which co-benefit does it measure?", "Co-Benefits"],
          [
            "Can the indicator be used for monitoring impact pathways?",
            "[yes/no]",
          ],
          [
            "If yes, which NZC impact pathway is it relevant for?",
            "Impact Pathways according to  - according to Module B-1",
          ],
          [
            "Is the indicator captured by the existing CDP/ SCIS/ Covenant of Mayors platforms?",
            "[yes/no]",
          ],
        ],
      },
    },
    {
      id: "a_t7_1_13",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          ["Data requirements", " "],
          ["Expected data source", ""],
          ["Expected availability", ""],
          ["Suggested collection interval", ""],
        ],
      },
    },
    {
      id: "a_t7_1_14",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          ["References", " "],
          ["References", ""],
          ["Other indicator systems using this indicator", ""],
        ],
      },
    },
    {
      id: "a_h7_8",
      type: "header",
      data: {
        text: "5 Part C – Enabling Climate Neutrality by 2030",
        level: 2,
      },
    },
    {
      id: "a_p7_1_11",
      type: "paragraph",
      data: {
        text: "Part C “Enabling Climate Neutrality by 2030” aims to outline any enabling interventions, i.e. with regard to organizational setting or collaborative governance models, or related to social innovations – designed to support and enable the climate action portfolios described in Module B-2 as well as aiming to achieve co-benefits outlined in the impact pathway (Module B-1).",
        level: 2,
      },
    },
    {
      id: "a_h7_9",
      type: "header",
      data: {
        text: "5.1 Module C-1 Organisational and Governance Innovation Interventions",
        level: 3,
      },
    },
    {
      id: "a_p7_1_12",
      type: "paragraph",
      data: {
        text: "Module C-1 “Organisational and Governance Innovation Interventions” consists of a summary table, listing organizational and governance interventions and describing their impact (C-1.1) and a section for more detailed descriptions and comments (C-1.2).",
        level: 2,
      },
    },
    {
      id: "a_p7_1_13",
      type: "paragraph",
      data: {
        text: "<b>C.1.1: Enabling organisational and governance interventions</b>",
        level: 2,
      },
    },
    {
      id: "a_t7_1_15",
      type: "table",
      data: {
        withHeadings: false,
        content: [
          [
            "Intervention name",
            "Description",
            "Responsible entity/ dept./ person",
            "Involved stakeholder",
            "Enabling impact",
            "Co-benefits",
          ],
          [
            "(indicate name of intervention)",
            "(describe the substance of the intervention)",
            "(indicate responsible)",
            "(list all stakeholder involved and affected)",
            "(describe how intervention enables climate neutrality)",
            "(indicate how intervention helps achieve impact listed in Module B-1)",
          ],
          ["", "", "", "", "", ""],
        ],
      },
    },
    {
      id: "a_p7_1_14",
      type: "paragraph",
      data: {
        text: "<b>C-1.2: Description of organisation and governance interventions – textual and visual elements</b>",
        level: 2,
      },
    },
    {
      id: "a_p7_1_15",
      type: "paragraph",
      data: {
        text: "(Please provide here any further detail on listed interventions)",
        level: 2,
      },
    },
    {
      id: "a_h7_10",
      type: "header",
      data: {
        text: "5.2 Module C-2 Social and Other Innovation Interventions",
        level: 3,
      },
    },
    {
      id: "a_p7_1_16",
      type: "paragraph",
      data: {
        text: "Module C-2 “Social and Other Innovation Interventions” consists of a summary table, listing organizational and collaborative governance interventions and describing their impact (C-2.1) and a section for more detailed descriptions and comments (C-2.2).",
        level: 2,
      },
    },
    {
      id: "a_p7_1_17",
      type: "paragraph",
      data: {
        text: "<b>C.2.1: Enabling social innovation interventions</b>",
        level: 2,
      },
    },
    {
      id: "a_t7_1_16",
      type: "table",
      data: {
        withHeadings: false,
        content: [
          [
            "Intervention name",
            "Description",
            "Responsible entity/ dept./ person",
            "Involved stakeholder",
            "Enabling impact",
            "Co-benefits",
          ],
          [
            "(indicate name of intervention)",
            "(describe the substance of the intervention)",
            "(indicate responsible)",
            "(list all stakeholder involved and affected)",
            "(describe how intervention enables climate neutrality)",
            "(indicate how intervention helps achieve impact listed in Module B-1)",
          ],
          ["", "", "", "", "", ""],
        ],
      },
    },
    {
      id: "a_p7_1_18",
      type: "paragraph",
      data: {
        text: "<b>C-2.2: Description of social innovation interventions – textual and visual elements</b>",
        level: 2,
      },
    },
    {
      id: "a_p7_1_19",
      type: "paragraph",
      data: {
        text: "(Please provide here any further detail on listed interventions)",
        level: 2,
      },
    },
    {
      id: "a_h7_11",
      type: "header",
      data: {
        text: "5.3 Module C-3 Financing of Action Portfolio",
        level: 3,
      },
    },
    {
      id: "a_p7_1_20",
      type: "paragraph",
      data: {
        text: "Module C-3 “Financing of Action Portfolio” should contain the list of action portfolios and interventions outlined in Modules B-2, and those from C-1 and C-2 with cost implication to provide a summary list of interventions that need to be unpacked in the Investment Plan.",
        level: 2,
      },
    },
    {
      id: "a_p7_1_21",
      type: "paragraph",
      data: {
        text: "<b>C-3.1: Summary of interventions with cost implication (to be unpacked in Investment Plan)</b>",
        level: 2,
      },
    },
    {
      id: "a_t7_1_17",
      type: "table",
      data: {
        withHeadings: false,
        content: [
          [
            "Action/ intervention name",
            "Responsible entity and person",
            "Start/end date",
            "Field of action",
            "Impact",
            "Total cost estimated",
          ],
          [
            "(list action portfolios and interventions from Modules B-2, C-1 and C-2, which have a cost implication)",
            "(indicate responsible entity and person)",
            "(indicate start and end date of the activity)",
            "(indicate the field of action the interventions belongs to)",
            "(indicate impact - i.e. the GHG reduction/ co-benefit)",
            "(indicate the total costs in €, estimated for the intervention)",
          ],
          ["", "", "", "", "", ""],
        ],
      },
    },
    {
      id: "a_h7_12",
      type: "header",
      data: {
        text: "6 Outlook and next steps",
        level: 3,
      },
    },
    {
      id: "a_p7_1_22",
      type: "paragraph",
      data: {
        text: "This section should draw any necessary conclusions on the Action Plan above and highlight next steps and plans for further refining the Action Plan as part of the Climate City Contract.",
        level: 2,
      },
    },
    {
      id: "a_p7_1_23",
      type: "paragraph",
      data: {
        text: "<b>Plans for next CCC and Action Plan iteration – textual elements</b>",
        level: 2,
      },
    },
    {
      id: "a_p7_1_24",
      type: "paragraph",
      data: {
        text: "(please add your text here)",
        level: 2,
      },
    },
    {
      id: "a_h7_13",
      type: "header",
      data: {
        text: "7 Annexes",
        level: 2,
      },
    },
    {
      id: "a_p7_1_25",
      type: "paragraph",
      data: {
        text: "<b>Add any textual</b> or <b>visual material</b> to the 2030 Climate Neutrality Action Plan in the ANNEX as necessary.",
        level: 2,
      },
    },
  ];
  const investment_plan = [
    {
      id: "i_h1",
      type: "header",
      data: {
        text: "Glossary of Terms",
        level: 2,
      },
    },
    {
      id: "i_p5_2_1",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          ["Acronym", "Description"],
          ["AP", "Action Plan"],
          ["IP", "Investment Plan"],
          ["KPI", "Key Performance Indicator"],
          ["MEL", "Monitoring Evaluation & Learning"],
          ["MRV", "Monitoring Reporting Verification"],
          ["WP", "Work Package"],
        ],
      },
    },
    {
      id: "i_h2_1",
      type: "header",
      data: {
        text: "1 Part C – Current State of Climate Investment",
        level: 2,
      },
    },
    {
      id: "i_p1_1",
      type: "paragraph",
      data: {
        text: "Part C “Current State of Climate Investment” is the structural element of the climate neutrality investment plan, putting the basis for the development of the plan through a detailed-oriented evaluation and assessment of your city’s existing financial policies and funding/financing activities.",
        level: 2,
      },
    },
    {
      id: "i_h2_2",
      type: "header",
      data: {
        text: "1.1 Module C1: Existing Climate Action Funding and Financing",
        level: 3,
      },
    },
    {
      id: "i_p2_1_1",
      type: "paragraph",
      data: {
        text: "This section represents the initial step of the 2030 Climate Neutrality Investment Plan (Investment Plan) and will require you to evaluate and assess previous and existing funding and financing for climate activities by field of action.",
        level: 2,
      },
    },
    {
      id: "i_p2_2_1",
      type: "paragraph",
      data: {
        text: "<b>C-1.1: Textual element</b>",
        level: 2,
      },
    },
    {
      id: "i_p2_2_2",
      type: "paragraph",
      data: {
        text: "…",
        level: 2,
      },
    },
    {
      id: "i_p5_2_2",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          [
            "Fields of Action",
            "Sector Subsection",
            "% Current Budget Allocation",
          ],
          ["Transportation", "(e.g. Walking)", ""],
          ["", "(e.g. Cycling)", ""],
          ["", "(e.g. Car)", ""],
          ["", "(e.g. Light Rail /  Metro)", ""],
          ["Built Environment", "(e.g. Residential)", ""],
          ["", "(e.g. Commercial)", ""],
          ["", "(e.g. Public Buildings)", ""],
          ["Energy Systems", "(e.g. Solar Thermal / Solar PV)", ""],
          ["", "(e.g. Oil)", ""],
          ["", "(e.g. Natural Gas)", ""],
          ["", "(e.g. Coal / Lignite)", ""],
          ["", "(e.g. Wind)", ""],
          ["", "(e.g. Hydro)", ""],
          [
            "Green Infrastructure and Nature Based Solutions",
            "(e.g. Parks, Green Roofs, Community Gardens, Rivers, Protected Areas, Forestry)",
            "",
          ],
          [
            "Waste and Circular Economy",
            "(e.g. Household Waste, Industrial Waste, Other Commercial Waste, Sectoral By-products )",
            "",
          ],
        ],
      },
    },
    {
      id: "i_p2_2_3",
      type: "paragraph",
      data: {
        text: "The above representation for capital flow should be recreated to take into account capital stock as well.",
        level: 2,
      },
    },
    {
      id: "i_h2_3",
      type: "header",
      data: {
        text: "1.2 Module C2: Strategic Funding and Financing Evaluation",
        level: 3,
      },
    },
    {
      id: "i_p2_1_2",
      type: "paragraph",
      data: {
        text: "You will evaluate your city’s existing financial policies to understand how they are currently managing the capital allocation towards net zero. This will include strategies in place and what your city has at its disposal to facilitate the transition. Your team should identify the forms of capital it has access to and which are specific to their climate neutrality targets.",
        level: 2,
      },
    },
    {
      id: "i_p2_2_4",
      type: "paragraph",
      data: {
        text: "<b>C-2.1: Textual element</b>",
        level: 2,
      },
    },
    {
      id: "i_p2_2_5",
      type: "paragraph",
      data: {
        text: "…",
        level: 2,
      },
    },
    {
      id: "i_p5_2_3",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          ["Income Category", "City income", "% of city budget"],
          ["Source of City Income", "…", "…"],
          ["…", "…", "…"],
        ],
      },
    },
    {
      id: "i_p2_2_6",
      type: "paragraph",
      data: {
        text: " ",
        level: 2,
      },
    },
    {
      id: "i_p5_2_4",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          ["Type", "Size Range", "Level", "Description"],
          [
            "Source of Capital",
            "Quantum of Capital Accessible to the city through this source",
            "Private or Public",
            "(Description of capital source e.g. cost & provider)",
          ],
          ["…", "…", "…", "…"],
        ],
      },
    },
    {
      id: "i_h2_4",
      type: "header",
      data: {
        text: "1.3 Module C3: Barriers to Climate Investment",
        level: 3,
      },
    },
    {
      id: "i_p2_1_3",
      type: "paragraph",
      data: {
        text: "You will need to evaluate and identify the range of structural, policy, economic, and financial barriers for capital deployment in support of climate action.",
        level: 2,
      },
    },
    {
      id: "i_p2_2_7",
      type: "paragraph",
      data: {
        text: "<b>C-3.1: Textual element</b>",
        level: 2,
      },
    },
    {
      id: "i_p2_2_8",
      type: "paragraph",
      data: {
        text: "…",
        level: 2,
      },
    },
    {
      id: "i_p5_2_5",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          [
            "Financial Barriers to achieving Climate Neutrality",
            "Typology of Barrier",
            "Description",
            "Sector and stakeholders involved",
          ],
          ["…", "…", "…", "…"],
        ],
      },
    },
    {
      id: "i_h2_5",
      type: "header",
      data: {
        text: "2 Part D – Investment Pathways towards Climate Neutrality by 2030",
        level: 3,
      },
    },
    {
      id: "i_p2_1_4",
      type: "paragraph",
      data: {
        text: "Part D “Investment Pathways towards Climate Neutrality by 2030” is in place to capture the actions and needs for mobilising and delivering the funding and financing needed for climate neutrality. This Part of the Investment Plan will be aligned with and build upon the Action Plan. In addition, each of these Plans are likely to entail multiple iterations over the course of the path to climate neutrality.",
        level: 2,
      },
    },
    {
      id: "i_p2_2_9",
      type: "paragraph",
      data: {
        text: "<b>2.1 Module D1: Cost Scenarios for Climate Neutrality</b>",
        level: 2,
      },
    },
    {
      id: "i_p2_2_10",
      type: "paragraph",
      data: {
        text: "These are the actions and measures which make up the 2030 Climate Neutrality Action Plan that need to be costed. Given the Investment Plan needs to be practical, the measures defined within the Action Plan need to be tagged by how much they will cost for the city, considering implementation and operational costs, so the city budget can be adapted to include them. Cities have the option to provide cost estimates at their own discretion on the measures disclosed in the Action Plan template as per table B-2.2 and in the Investment Plan template as per table B1.2. Given these cost estimates for the actions, cities can then include non-sectorial costs (the cost of the levers to implement these actions) these should be considered alongside the concrete actions.",
        level: 2,
      },
    },
    {
      id: "i_p2_2_11",
      type: "paragraph",
      data: {
        text: "<b>D-1.1: Textual element</b>",
        level: 2,
      },
    },
    {
      id: "i_p2_2_12",
      type: "paragraph",
      data: {
        text: "…",
        level: 2,
      },
    },
    {
      id: "i_p5_2_6",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          [
            "Fields of Action",
            "Action / Indicator",
            "Implementation Costs/Capex",
            "Operational Costs",
            "Direct impacts (Emission reductions)*",
            "Indirect impacts (co-benefits)*",
          ],
          [
            "Transportation",
            "e.g. Pedestrian and Cycling Infrastructure",
            "…",
            "…",
            "Impacts in terms of monetary savings**",
            "Job Creation**",
          ],
          [
            "",
            "e.g. Metro /  Light Rail",
            "…",
            "…",
            "(list more direct impacts as needed)",
            "(list more indirect impacts as needed)",
          ],
          ["", "e.g. Fleet Electrification", "…", "…", "…", "…"],
          [
            "",
            "e.g. Shift to public & non-motorized transport",
            "…",
            "…",
            "…",
            "…",
          ],
          [
            "Built Environment",
            "e.g. energy saving building retrofit",
            "…",
            "…",
            "…",
            "…",
          ],
          ["", "e.g. district heating and cooling", "…", "…", "…", "…"],
          ["", "e.g.  indoor and public lighting", "…", "…", "…", "…"],
          ["", "e.g. infrastructure", "…", "…", "…", "…"],
          ["", "re-use and urban re-design", "…", "…", "…", "…"],
          [
            "Energy Systems",
            "e.g. community energy and PED",
            "…",
            "…",
            "…",
            "…",
          ],
          [
            "Green Infrastructure & Nature Based Solutions",
            "…",
            "…",
            "…",
            "…",
            "…",
          ],
          ["Waste and Circular Economy", "…", "…", "…", "…", "…"],
        ],
      },
    },
    {
      id: "i_p5_2_7",
      type: "table",
      data: {
        withHeadings: false,
        content: [
          [
            "Cross Cutting Costs",
            "These can include any supporting activity needed across different sectors, such as citizen engagement, communication with relevant stakeholders, governance and planning exercises, capacity building, the setting-up of SPVs for project management, etc.",
          ],
        ],
      },
    },
    {
      id: "i_p2_2_14",
      type: "paragraph",
      data: {
        text: "*Referring to the Action Plan",
        level: 2,
      },
    },
    {
      id: "i_p2_2_15",
      type: "paragraph",
      data: {
        text: "**Indicative indicators",
        level: 2,
      },
    },
    {
      id: "i_p2_2_16",
      type: "paragraph",
      data: {
        text: "<b>2.2 Module D2: Capital Planning for Climate Neutrality</b>",
        level: 2,
      },
    },
    {
      id: "i_p2_2_17",
      type: "paragraph",
      data: {
        text: "You will need to definite your city’s capital goals and how to achieve them. As you implement its programme the below sources of capital can be laid out as a starting point. These should be aligned with your city's goals and relevant to the actions selected. Ideally this will be a target and the you will optimise towards.",
        level: 2,
      },
    },
    {
      id: "i_p2_2_18",
      type: "paragraph",
      data: {
        text: "<b>D-2.1: Textual element</b>",
        level: 2,
      },
    },
    {
      id: "i_p2_2_19",
      type: "paragraph",
      data: {
        text: "…",
        level: 2,
      },
    },
    {
      id: "i_p5_2_8",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          ["Capital need", "Possible Sources of Capital", "Sector allocation"],
          ["…", "…", "…"],
          ["…", "…", "…"],
        ],
      },
    },
    {
      id: "i_p2_2_20",
      type: "header",
      data: {
        text: "2.3 Module D3: Economic and Financial Indicators for Monitoring, Evaluation and Learning",
        level: 2,
      },
    },
    {
      id: "i_p2_2_21",
      type: "paragraph",
      data: {
        text: "A range of financial policies need to be considered to execute the actions laid out in the 2030 Climate Neutrality Action Plan. You should align your city’s financial policies with their current process and capital allocation. This will depend on the actions selected and be drawn from possible financial tools to assist the transition",
        level: 2,
      },
    },
    {
      id: "i_p2_2_22",
      type: "paragraph",
      data: {
        text: "<b>D-3.1: Textual element</b>",
        level: 2,
      },
    },
    {
      id: "i_p2_2_23",
      type: "paragraph",
      data: {
        text: "…",
        level: 2,
      },
    },
    {
      id: "i_p5_2_9",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          [
            "Fields of Action",
            "Indicator",
            "Indicator Unit",
            "Indicator Baseline*",
            "Indicator Target 2030*",
          ],
          [
            "Transportation",
            "e.g. Pedestrian and Cycling Infrastructure",
            "…",
            "1%*",
            "30%*",
          ],
          ["", "e.g. Metro / Light Rail", "…", "0%*", "25%*"],
          ["", "e.g. Fleet Electrification", "…", "5%*", "50%*"],
          [
            "",
            "e.g. Shift to public & non-motorized transport",
            "…",
            "5%*",
            "50%*",
          ],
          [
            "Built Environment",
            "e.g. energy saving building retrofit",
            "…",
            "…",
            "…",
          ],
          ["", "e.g. district heating and cooling", "…", "…", "…"],
          ["", "e.g. indoor and public lighting", "…", "…", "…"],
          ["", "e.g. infrastructure re-use and urban re-design", "…", "…", "…"],
          ["Energy Systems", "e.g. community energy and PED", "…", "…", "…"],
          [
            "Green Infrastructure and Nature Based Solutions",
            "…",
            "…",
            "…",
            "…",
          ],
          ["Waste and Circular Economy", "…", "…", "…", "…"],
        ],
      },
    },
    {
      id: "i_p2_2_24",
      type: "paragraph",
      data: {
        text: "*Indicative indicators",
        level: 2,
      },
    },
    {
      id: "i_p2_2_25",
      type: "header",
      data: {
        text: "3 Part E – Enabling Financial Conditions for Climate Neutrality by 2030",
        level: 2,
      },
    },
    {
      id: "i_p2_2_26",
      type: "paragraph",
      data: {
        text: "Part E <b>“Enabling Conditions for Climate Neutrality by 2030”</b> is the third section of the Investment Plan and is intended to identify other enabling factors the city needs to consider in the implementation of the Investment Plan.",
        level: 2,
      },
    },
    {
      id: "i_h2_6",
      type: "header",
      data: {
        text: "3.1 Module E1: Climate Policies for Capital Formation and Deployment",
        level: 3,
      },
    },
    {
      id: "i_p2_2_27",
      type: "paragraph",
      data: {
        text: "You will need to optimise the allocation of capital between both public and private sources across the portfolio outlined in the Action Plan to meet the cost of the actions identified for reaching climate neutrality over time.",
        level: 2,
      },
    },
    {
      id: "i_p2_2_28",
      type: "paragraph",
      data: {
        text: "<b>E-1.1: Textual element</b>",
        level: 2,
      },
    },
    {
      id: "i_p2_2_29",
      type: "paragraph",
      data: {
        text: "…",
        level: 2,
      },
    },
    {
      id: "i_p5_2_10",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          [
            "Climate Policy",
            "Description of the policy (sector, targeted audience, etc.)",
            "Intended Outcome for Capital Formation",
          ],
          ["…", "…", "…"],
        ],
      },
    },
    {
      id: "i_h2_7",
      type: "header",
      data: {
        text: "3.2 Module E2: Identification and Mitigation of Riskst",
        level: 3,
      },
    },
    {
      id: "i_p2_2_30",
      type: "paragraph",
      data: {
        text: "Your team should consider the risks relevant to the implementation of an Investment Plan, which may impact their ambition to achieve climate neutrality, mitigation techniques should be identified where necessary and where possible, these should align with the financial policies selected.",
        level: 2,
      },
    },
    {
      id: "i_p2_2_31",
      type: "paragraph",
      data: {
        text: "<b>E-2.1: Textual element</b>",
        level: 2,
      },
    },
    {
      id: "i_p2_2_32",
      type: "paragraph",
      data: {
        text: "…",
        level: 2,
      },
    },
    {
      id: "i_p5_2_11",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          [
            "Fields of Action",
            "Sectoral Project",
            "Risks Identified",
            "Description of Risk",
            "Mitigation of Risk",
          ],
          ["Transportation", "…", "…", "…", "…"],
          ["", "…", "…", "…", "…"],
          ["Built Environment", "…", "…", "…", "…"],
          ["", "…", "…", "…", "…"],
          ["Energy Generation", "…", "…", "…", "…"],
          ["", "…", "…", "…", "…"],
          [
            "Green infrastructure and Nature Based Solutions",
            "…",
            "…",
            "…",
            "…",
          ],
          ["", "…", "…", "…", "…"],
          ["Waste and Circular Economy", "…", "…", "…", "…"],
          ["", "…", "…", "…", "…"],
          ["City Wide Risks (Cross Cutting)", "…", "…", "…", "…"],
        ],
      },
    },
    {
      id: "i_h2_8",
      type: "header",
      data: {
        text: "3.3 Module E3: Capacity Building and Stakeholder Engagement for Capital and Investment Planning",
        level: 3,
      },
    },
    {
      id: "i_p2_2_33",
      type: "paragraph",
      data: {
        text: "You should assess and finally work to develop internal capacity and capabilities, working with both internal and external stakeholders to accelerate the transition to climate neutrality by 2030.",
        level: 2,
      },
    },
    {
      id: "i_p2_2_34",
      type: "paragraph",
      data: {
        text: "<b>E-3.1: Textual element</b>",
        level: 2,
      },
    },
    {
      id: "i_p2_2_35",
      type: "paragraph",
      data: {
        text: "…",
        level: 2,
      },
    },
    {
      id: "i_p5_2_12",
      type: "table",
      data: {
        withHeadings: true,
        content: [
          [
            "Stakeholders involved",
            "Network",
            "Influence",
            "Interest",
            "Level and type of Engagement",
          ],
          ["…", "…", "…", "…", "…"],
        ],
      },
    },
  ];

  return [commitments, action_plan, investment_plan];
};
