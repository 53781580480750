import moment from "moment"
import { cloneDeep } from "lodash"

export const intersectDatesWithWorkspace = (start_date_wp, end_date_wp, start_date_action, end_date_action,) => {

    var start_date_moment
    if (moment(start_date_wp).isValid() && moment(start_date_action) < moment(start_date_wp)) {
        start_date_moment = moment(start_date_wp)
    } else {
        start_date_moment = moment(start_date_action)
    }

    var end_date_reference
    if (end_date_action === null) {
        end_date_reference = moment("2100-12-31")
    } else {
        end_date_reference = moment(end_date_action)
    }
    var end_date_moment
    if (moment(end_date_wp).isValid() && end_date_reference > moment(end_date_wp)) {
        end_date_moment = moment(end_date_wp)
    } else if (start_date_moment > end_date_reference) {
        end_date_moment = cloneDeep(start_date_moment)
    } else {
        end_date_moment = cloneDeep(end_date_reference)
    }

    return [start_date_moment, end_date_moment]
}