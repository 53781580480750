import React, { useState, useEffect } from "react";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { connect, useDispatch } from "react-redux";
import { getHeightWithFooterWONavbar } from "../../../../utils/getHeightWithFooterWONavbar";
import classnames from "classnames";
import AccountableTab from "./AccountableTab";
import GroupedActionsTab from "./GroupedActionsTab";
import NonAccountableTab from "./NonAccountableTab";
import ModalGroupActions from "./ModalGroupActions";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import axios from "axios";
import { API_URL } from "../../../../actions/types";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { extendGroupedAction } from "../../../../utils/extendGroupedAction";

function Content(props) {
  const [activeTab, setActiveTab] = useState(1);
  const [isOpenGroupActions, setIsOpenGroupActions] = useState(false);
  const [showCompletion, setShowCompletion] = useState(false);
  const [actions, setActions] = useState([null, null, null]);
  const { width, height } = useWindowDimensions();


  // define function to get data
  const getData = async () => {
    const resActors = await axios.get(
      `${API_URL}/clicc/actors/`,
      tokenConfig()
    );
    const resActions = await axios.get(
      `${API_URL}/clicc/actions/`,
      tokenConfig()
    );
    const resGroupedActions = await axios.get(
      `${API_URL}/clicc/grouped_actions/`,
      tokenConfig()
    );

    var groupedActionsExtended = [];
    for await (const grouped_action of resGroupedActions.data) {
      groupedActionsExtended.push(
        await extendGroupedAction(grouped_action, resActors.data)
      );
    }

    return [
      resActions.data.filter(obj => obj.is_accountable === true)
        .map((obj, index) => {
          var actor_name = "";
          var currentActor = resActors.data.find((act) => act.id === obj.actor);
          if (currentActor !== undefined) {
            actor_name = currentActor.actor_business_name;
          }
          return {
            ...obj,
            count: index + 1,
            actor_business_name: actor_name,
          };
        }),
      resActions.data.filter(obj => obj.is_accountable === false)
        .map((obj, index) => {
          var actor_name = "";
          var currentActor = resActors.data.find((act) => act.id === obj.actor);
          if (currentActor !== undefined) {
            actor_name = currentActor.actor_business_name;
          }
          return {
            ...obj,
            count: index + 1,
            actor_business_name: actor_name,
          };
        }),
      groupedActionsExtended.map((obj, index) => {
        return {
          ...obj,
          count: index + 1,
        };
      })]
  };

  // on did mount, get data
  useEffect(async () => {
    setActions(await getData())
  }, []);

  // monitor refresh
  useEffect(() => {
    const channel = new BroadcastChannel("clicc-channel");
    channel.addEventListener("message", async (e) => {
      if (e.data === "refresh") {
        // get data and apply changes
        setActions(await getData())
      }
    });
    return () => channel.close();
  }, []);


  const styleTabPane = {
    ...props.layout.generic_card,
    height: "95%",
    overflowY: "hidden",
    backgroundColor: "white",
    marginTop: "-1px",
    borderTopRightRadius: 15,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
    padding: 10,
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#E4E6E8",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        paddingLeft: props.layout.width_sidebar,
        paddingBottom: props.layout.footer_height,
        overflow: "auto",
      }}
    >
      {/* workspace without padding */}
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 10,
        }}
      >
        {/* workspace */}
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <div style={{ padding: 10, height: "100%", position: "relative" }}>
            <div
              style={{
                position: "absolute",
                right: 15,
                top: 20,
                display: "flex",
              }}
            >
              <div
                onClick={() => setIsOpenGroupActions(true)}
                style={{
                  backgroundColor: props.layout.color_sidebar,
                  color: "white",
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  fontSize: 16,
                  padding: "4px 20px 4px 20px",
                  height: "30px",
                  borderRadius: 10,
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  marginTop: "-10px",
                  marginRight: 15,
                }}
              >
                Group actions
              </div>
              <div
                onClick={() => {
                  setShowCompletion(!showCompletion);
                }}
                style={{
                  backgroundColor: props.layout.color_sidebar,
                  color: "white",
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  fontSize: 16,
                  padding: "4px 20px 4px 20px",
                  height: "30px",
                  borderRadius: 10,
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  marginTop: "-10px",
                }}
              >
                Show completion
              </div>
            </div>
            <Nav tabs style={{ width: "90%" }}>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === 1,
                    activeWhite: activeTab === 1,
                  })}
                  onClick={() => {
                    setActiveTab(1);
                  }}
                  style={{
                    fontSize: 14,
                    backgroundColor: activeTab === 1 ? "white" : "transparent",
                  }}
                >
                  ACCOUNTABLE ACTIONS
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === 2,
                    activeWhite: activeTab === 2,
                  })}
                  onClick={() => {
                    setActiveTab(2);
                  }}
                  style={{
                    fontSize: 14,
                    backgroundColor: activeTab === 2 ? "white" : "transparent",
                  }}
                >
                  GROUPED ACTIONS
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === 3,
                    activeWhite: activeTab === 3,
                    nonAccountable: true
                  })}
                  onClick={() => {
                    setActiveTab(3);
                  }}
                  style={{
                    fontSize: 14,
                    backgroundColor: activeTab === 3 ? "white" : "transparent",
                  }}
                >
                  NON-ACCOUNTABLE
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab} style={{ height: "100%" }}>
              <TabPane tabId={1} style={styleTabPane}>
                <AccountableTab
                  showCompletion={showCompletion}
                  rowData={actions[0]}
                  getData={getData}
                ></AccountableTab>
              </TabPane>
              <TabPane tabId={2} style={styleTabPane}>
                <GroupedActionsTab
                  showCompletion={showCompletion}
                  rowData={actions[2]}
                  getData={getData}
                ></GroupedActionsTab>
              </TabPane>
              <TabPane tabId={3} style={styleTabPane}>
                <NonAccountableTab
                  showCompletion={showCompletion}
                  rowData={actions[1]}
                  getData={getData}
                ></NonAccountableTab>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
      <ModalGroupActions
        isOpen={isOpenGroupActions}
        setIsOpen={setIsOpenGroupActions}
        rowData={actions[0]}
        getData={getData}
      ></ModalGroupActions>

    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Content);
