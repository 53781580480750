import React from "react";
import { Route } from "react-router-dom";
import { makeStyles } from "@mui/styles";

// CLICC import
import Homepage from "../routes/Homepage/Homepage";
import NewActor from "../routes/Actors/NewActor/NewActor";
import MyActors from "../routes/Actors/MyActors/MyActors";
import ModifyActor from "../routes/Actors/ModifyActor/ModifyActor";
import NewWorkspace from "../routes/Workspace/NewWorkspace/NewWorkspace";
import MyWorkspaces from "../routes/Workspace/MyWorkspaces/MyWorkspaces";
import ModifyWorkspace from "../routes/Workspace/ModifyWorkspace/ModifyWorkspace";
import NewAction from "../routes/Actions/NewAction/NewAction";
import MyActions from "../routes/Actions/MyActions/MyActions";
import ModifyAction from "../routes/Actions/ModifyAction/ModifyAction";
import ActionType from "../routes/Actions/ActionType/ActionType";
import NewContract from "../routes/Contract/NewContract/NewContract";
import MyContracts from "../routes/Contract/MyContracts/MyContracts";
import ModifyContract from "../routes/Contract/ModifyContract/ModifyContract";
import Users from "../routes/Users/Users";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24,
  },
  appBarSpacer: {},
  content: {
    flexGrow: 1,
    height: "100vh",
    paddingLeft: `${drawerWidth}px`,
    transition: "1s",
  },
  contentShift: {
    paddingLeft: "70px",
  },
  container: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  paper: {
    padding: 10,
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export const ContentWrapper = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Route
        path="/clicc/dashboard/homepage"
        render={(props) => <Homepage classes={classes} {...props}></Homepage>}
      ></Route>

      <Route
        path="/clicc/dashboard/actors_new_actor"
        render={(props) => <NewActor classes={classes} {...props}></NewActor>}
      ></Route>
      <Route
        path="/clicc/dashboard/actors_modify_actor"
        render={(props) => (
          <ModifyActor classes={classes} {...props}></ModifyActor>
        )}
      ></Route>
      <Route
        path="/clicc/dashboard/actors_my_actors"
        render={(props) => <MyActors classes={classes} {...props}></MyActors>}
      ></Route>
      <Route
        path="/clicc/dashboard/workspace_new_workspace"
        render={(props) => (
          <NewWorkspace classes={classes} {...props}></NewWorkspace>
        )}
      ></Route>

      <Route
        path="/clicc/dashboard/workspace_my_workspaces"
        render={(props) => (
          <MyWorkspaces classes={classes} {...props}></MyWorkspaces>
        )}
      ></Route>
      <Route
        path="/clicc/dashboard/workspace_modify_workspace"
        render={(props) => (
          <ModifyWorkspace classes={classes} {...props}></ModifyWorkspace>
        )}
      ></Route>
      <Route
        path="/clicc/dashboard/actions_new_action"
        render={(props) => <NewAction classes={classes} {...props}></NewAction>}
      ></Route>
      <Route
        path="/clicc/dashboard/actions_my_actions"
        render={(props) => <MyActions classes={classes} {...props}></MyActions>}
      ></Route>
      <Route
        path="/clicc/dashboard/actions_modify_action"
        render={(props) => (
          <ModifyAction classes={classes} {...props}></ModifyAction>
        )}
      ></Route>
      <Route
        path="/clicc/dashboard/actions_action_type"
        render={(props) => (
          <ActionType classes={classes} {...props}></ActionType>
        )}
      ></Route>
      <Route
        path="/clicc/dashboard/contract_new_contract"
        render={(props) => (
          <NewContract classes={classes} {...props}></NewContract>
        )}
      ></Route>
      <Route
        path="/clicc/dashboard/contract_my_contracts"
        render={(props) => (
          <MyContracts classes={classes} {...props}></MyContracts>
        )}
      ></Route>
      <Route
        path="/clicc/dashboard/contract_modify_contract"
        render={(props) => (
          <ModifyContract classes={classes} {...props}></ModifyContract>
        )}
      ></Route>
      <Route
        path="/clicc/dashboard/users"
        render={(props) => (
          <Users classes={classes} {...props}></Users>
        )}
      ></Route>
    </React.Fragment>
  );
};

export default ContentWrapper;
