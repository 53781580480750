import React, { useEffect, useState } from "react";
import { useWindowDimensions } from "../../../utils/useWindowDimensions";
import { connect } from "react-redux";
import { Row, Col, Card } from "reactstrap";
import TitleCard from "../../../components/TitleCard/TitleCard";
import Plotly from "plotly.js-dist";
import { usePlotlyConfig } from "../../../utils/usePlotlyConfig";
import { getHeightWithFooterWONavbar } from "../../../utils/getHeightWithFooterWONavbar";
import CardHomepageTopLeft from "./CardHomepageTopLeft";
import moment from "moment";
import axios from "axios";
import { API_URL } from "../../../actions/types";
import { tokenConfig } from "../../../utils/tokenConfig";
import CardHomepageTopRight from "./CardHomepageTopRight";
import { FaSpinner } from "react-icons/fa";
import LogoCLICC from "../../../../images/logo_clicc_senza_scritta.png"
import { FaLeaf, FaListUl, FaRegCopy, FaCalculator } from "react-icons/fa";
import { useHistory } from "react-router-dom";



function Content(props) {
  const { width, height } = useWindowDimensions();
  const plotlyConfig = usePlotlyConfig();
  const [homepage, setHomepage] = useState(null);
  const [selectedTab, setSelectedTab] = useState("co2");
  const [forbidden, setForbidden] = useState(false)
  const history = useHistory();

  // define function to get data
  const getData = async () => {
    try {
      const request = await axios.get(
        `${API_URL}/clicc/workspace/homepage/`,
        tokenConfig()
      );
      setHomepage(request.data);
    } catch (err) {
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };
      if (errors.status === 403) {
        setForbidden(true)
      } else {
        setForbidden(true)
      }

    }
  };

  // on did mount, get data
  useEffect(async () => {
    await getData();
  }, []);

  // monitor refresh
  useEffect(() => {
    const channel = new BroadcastChannel("clicc-channel");
    channel.addEventListener("message", async (e) => {
      if (e.data === "refresh") {
        await getData();
      }
    });
    return () => channel.close();
  }, []);

  const cardCO2eq = {
    title: (
      <div>
        CO<sub>2</sub>
        {selectedTab === "co2" ? null : " equivalent"}
      </div>
    ),
  };
  const cardInvestment = {
    title: <div>CAPEX</div>,
  };
  const cardsContent = {
    co2: (
      <div>
        CO<sub>2</sub>
      </div>
    ),
    n2o: (
      <div>
        N<sub>2</sub>O
      </div>
    ),
    f_gas: <div>F-gas</div>,
    sf6: (
      <div>
        SF<sub>6</sub>
      </div>
    ),
    nf3: (
      <div>
        NF<sub>3</sub>
      </div>
    ),
    ch4: (
      <div>
        CH<sub>4</sub>
      </div>
    ),
  };

  useEffect(async () => {
    // get homepage
    if (homepage !== null) {
      var x = [];
      var y = [];
      var colors = [];
      var y0_emissions = 0;
      var y1_emissions = 0;
      homepage[selectedTab].normalized_emissions.forEach((emission) => {
        if (emission.y > y1_emissions) {
          y1_emissions = emission.y;
        }
        if (emission.y < y0_emissions) {
          y0_emissions = emission.y;
        }
        x.push(emission.x);
        y.push(emission.y);
        if (moment() >= moment(emission.x)) {
          colors.push("#219ebc");
        } else {
          colors.push("white");
        }
      });

      var data = [
        {
          x: x,
          y: y,
          type: "scatter",
          // marker: {
          //   size: 10,
          //   color: colors,
          //   line: { color: "#219ebc", width: 2 },
          // },
          hovertemplate: "Normalized baseline residual: %{y:.2f}",
          name: "Normalized baseline residual",
          line: {
            color: "#219ebc",
          },
        },
      ];


      var layout = {
        hovermode: "x",
        margin: {
          t: 40,
          b: 30,
          l: 60,
          r: 60,
        },
        legend: {
          orientation: "h",
          xanchor: "center",
          x: 0.5,
        },
        showlegend: false,
        shapes: [
          {
            type: "line",
            x0: moment().format("YYYY-MM-DD"),
            y0: y0_emissions,
            x1: moment().format("YYYY-MM-DD"),
            y1: y1_emissions,
            line: {
              color: "grey",
              width: 1.5,
              dash: "dot",
            },
          },
        ],
      };

      Plotly.newPlot("emissionChart", data, layout, { ...plotlyConfig });
      var y2 = [];
      var x2 = [];
      var colors2 = [];
      var y0_investment = 0;
      var y1_investment = 0;

      homepage[selectedTab].cumulative_investment.forEach((investment) => {
        y2.push(investment.y / 1000000000);
        x2.push(investment.x);
      });

      var data2 = [
        {
          x: x2,
          y: y2,
          type: "scatter",
          marker: {
            size: 10,
            color: colors2,
            line: { color: "red", width: 2 },
          },
          hovertemplate: "Investment: %{y:.0f} [G€]",
          name: "Investment",
          line: {
            color: "red",
          },
        },
      ];

      var layout2 = {
        hovermode: "x",
        margin: {
          t: 40,
          b: 30,
          l: 60,
          r: 60,
          pad: 5
        },
        legend: {
          orientation: "h",
          xanchor: "center",
          x: 0.5,
        },
        showlegend: false,
        shapes: [
          {
            type: "line",
            x0: moment().format("YYYY-MM-DD"),
            y0: 0,
            x1: moment().format("YYYY-MM-DD"),
            y1: Math.max(...y2),
            line: {
              color: "grey",
              width: 1.5,
              dash: "dot",
            },
          },
        ],
      };

      Plotly.newPlot("investmentChart", data2, layout2, { ...plotlyConfig });
    }
  }, [homepage, selectedTab]);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#E4E6E8",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        paddingLeft: props.layout.width_sidebar,
        paddingBottom: props.layout.footer_height,
        overflow: "auto",
      }}
    >
      {/* workspace without padding */}
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 10,
        }}
      >
        {/* workspace */}
        {homepage !== null ? (
          <div
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            {/* cards */}
            <Row style={{ margin: 0 }}>
              <Col
                md={selectedTab !== "ghg" ? "6" : "4"}
                sm="12"
                style={{ padding: 10, height: height * 0.31 }}
              >
                <CardHomepageTopLeft
                  homepage={homepage[selectedTab]}
                  cardData={cardCO2eq}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                ></CardHomepageTopLeft>
              </Col>
              <Col
                md="4"
                sm="12"
                style={{ padding: 0, height: height * 0.31 }}
                hidden={selectedTab !== "ghg"}
              >
                <Row style={{ margin: 0, height: "100%" }}>
                  {props.gases.map((gas, index) => {
                    return (
                      <Col xs="6" style={{ padding: 10 }} key={index}>
                        <div
                          style={{
                            ...props.layout.generic_card,
                            display: "flex",
                            flexFlow: "column",
                            height: "100%",
                            position: "relative",
                          }}
                        >
                          <TitleCard
                            title={cardsContent[gas]}
                            customPadding={"10px 10px 0px 10px"}
                            customTitleSize={{ small: 18, large: 18 }}
                          ></TitleCard>
                          <div
                            style={{
                              position: "absolute",
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "end",
                              fontWeight: 500,
                              fontFamily: "Montserrat",
                              fontSize: 18,
                              padding: 10,
                            }}
                          >
                            {homepage[selectedTab][gas] > 1000000
                              ? Math.round(
                                (homepage[selectedTab][gas] / 1000000 +
                                  Number.EPSILON) *
                                100
                              ) / 100
                              : homepage[selectedTab][gas]}
                            {homepage[selectedTab][gas] > 1000000 ? "M " : " "}t
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
              <Col
                md={selectedTab !== "ghg" ? "6" : "4"}
                sm="12"
                style={{ padding: 10, height: height * 0.31 }}
              >
                <CardHomepageTopRight
                  cardData={cardInvestment}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  homepage={homepage[selectedTab]}
                ></CardHomepageTopRight>
              </Col>
            </Row>

            <Row style={{ margin: 0, height: height * 0.58 }}>
              <Col
                md="6"
                sm="12"
                style={{ padding: 10, marginBottom: width < 770 ? 0 : 35 }}
              >
                <div
                  style={{
                    ...props.layout.generic_card,
                    display: "flex",
                    flexFlow: "column",
                    height: "100%",
                  }}
                >
                  <TitleCard
                    title={
                      <div>
                        Normalized CO<sub>2</sub>{" "}
                        {selectedTab !== "co2" ? "equivalent" : null} baseline residual
                      </div>
                    }
                  ></TitleCard>
                  <div
                    style={{
                      flex: "0.92 1 auto",
                      borderBottomLeftRadius:
                        props.layout.generic_card.borderRadius,
                      borderBottomRightRadius:
                        props.layout.generic_card.borderRadius,
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      id="emissionChart"
                    ></div>
                  </div>
                </div>
              </Col>

              <Col md="6" sm="12" style={{ padding: 10, marginBottom: 35 }}>
                <div
                  style={{
                    ...props.layout.generic_card,
                    display: "flex",
                    flexFlow: "column",
                    height: "100%",
                  }}
                >
                  <TitleCard
                    title={<div>Total CAPEX [G€]</div>}
                  ></TitleCard>
                  <div
                    style={{
                      flex: "0.92 1 auto",
                      borderBottomLeftRadius:
                        props.layout.generic_card.borderRadius,
                      borderBottomRightRadius:
                        props.layout.generic_card.borderRadius,
                    }}
                  >
                    <div
                      style={{ width: "100%", height: "100%" }}
                      id="investmentChart"
                    ></div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        ) :
          forbidden === true ?
            (
              <div style={{ padding: "30px 20px" }}>
                <div style={{ fontSize: 24, fontFamily: "Montserrat", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                  <img src={LogoCLICC} style={{ width: 40 }}></img>
                  <div style={{ marginLeft: 20 }}>
                    Welcome to CLICC!
                  </div>
                </div>
                <div style={{ marginTop: 50, fontFamily: "Montserrat", fontWeight: 500 }}>
                  What can you do?
                  <ul>
                    <li>Define policy strategies for climate neutrality</li>
                    <li>What if analyses to assess different policies</li>
                    <li>Simulate actions from a list of mathematical models</li>
                  </ul>
                </div>
                <div style={{ marginTop: 50, fontFamily: "Montserrat" }}>
                  <FaLeaf style={{ fontSize: 18, marginRight: 10 }} />Start now
                </div>
                <Row style={{ marginTop: 10 }}>
                  <Col xs="4">
                    <Card
                      onClick={() => history.push(`/clicc/dashboard/actions_new_action`)}
                      style={{
                        height: 200, boxShadow: "3px 3px 8px 3px #CCCCCC",
                        display: "flex", justifyContent: "center", alignItems: "center",
                        cursor: "pointer"
                      }}>
                      <FaCalculator style={{ fontSize: 26 }} />
                      <div style={{ fontFamily: "Montserrat", marginTop: 10, fontSize: 16 }}>
                        New action
                      </div>
                    </Card>
                  </Col>

                  <Col xs="4">
                    <Card
                      onClick={() => history.push(`/clicc/dashboard/actions_my_actions`)}
                      style={{
                        height: 200, boxShadow: "3px 3px 8px 3px #CCCCCC",
                        display: "flex", justifyContent: "center", alignItems: "center",
                        cursor: "pointer"
                      }}>
                      <FaListUl style={{ fontSize: 26 }} />
                      <div style={{ fontFamily: "Montserrat", marginTop: 10, fontSize: 16 }}>
                        List of actions
                      </div>
                    </Card>
                  </Col>

                  <Col xs="4">
                    <Card
                      onClick={() => history.push(`/clicc/dashboard/actors_my_actors`)}
                      style={{
                        height: 200, boxShadow: "3px 3px 8px 3px #CCCCCC",
                        display: "flex", justifyContent: "center", alignItems: "center",
                        cursor: "pointer"
                      }}>
                      <FaRegCopy style={{ fontSize: 26 }} />
                      <div style={{ fontFamily: "Montserrat", marginTop: 10, fontSize: 16 }}>
                        List of actors
                      </div>
                    </Card>
                  </Col>


                </Row>
              </div>
            ) : (
              <div style={{ textAlign: "center", marginTop: height / 2.8 }}>
                <FaSpinner
                  icon="spinner"
                  className="spinner"
                  style={{ fontSize: 24, marginBottom: 5 }}
                />
                <p style={{ fontFamily: "Montserrat", fontWeight: 500, fontSize: 18, marginTop: 5 }}>Loading...</p>
              </div>
            )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
  gases: state.create_workspace.gases,
});

export default connect(mapStateToProps)(Content);
