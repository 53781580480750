import React, { useState } from "react";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { connect, useDispatch } from "react-redux";
import { getHeightWithFooterWONavbar } from "../../../../utils/getHeightWithFooterWONavbar";
import classnames from "classnames";
import Index from "./Index";
import NetZeroClassification from "./NetZeroClassification";
import Actor from "./Actor";
import Financials from "./Financials";
import Specify from "./Specify";
import CO2EQAvoided from "./CO2EQAvoided";
import Notes from "./Notes";
import CreateOrUpdate from "./CreateOrUpdate";
import {
  Modal,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

function Content(props) {
  const [activeTab, setActiveTab] = useState(1);
  const { width, height } = useWindowDimensions();

  const styleTabPane = {
    ...props.layout.generic_card,
    height: "95%",
    overflowY: "hidden",
    backgroundColor: "white",
    marginTop: "-1px",
    borderTopRightRadius: 15,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
    padding: 10,
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#E4E6E8",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        paddingLeft: props.layout.width_sidebar,
        paddingBottom: props.layout.footer_height,
        overflow: "auto",
      }}
    >
      {/* workspace without padding */}
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 10,
        }}
      >
        {/* workspace */}
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <div style={{ padding: 10, height: "100%", position: "relative" }}>
            <div style={{ position: "absolute", right: 15, top: 15 }}></div>
            <Nav tabs style={{ width: "90%" }}>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === 1,
                    activeWhite: activeTab === 1,
                  })}
                  onClick={() => {
                    setActiveTab(1);
                  }}
                  style={{
                    fontSize: 14,
                    backgroundColor: activeTab === 1 ? "white" : "transparent",
                  }}
                >
                  INDEX
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === 2,
                    activeWhite: activeTab === 2,
                  })}
                  onClick={() => {
                    setActiveTab(2);
                  }}
                  style={{
                    fontSize: 14,
                    backgroundColor: activeTab === 2 ? "white" : "transparent",
                  }}
                >
                  NET-ZERO
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === 3,
                    activeWhite: activeTab === 3,
                  })}
                  onClick={() => {
                    setActiveTab(3);
                  }}
                  style={{
                    fontSize: 14,
                    backgroundColor: activeTab === 3 ? "white" : "transparent",
                  }}
                >
                  ACTOR
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === 5,
                    activeWhite: activeTab === 5,
                  })}
                  onClick={() => {
                    setActiveTab(5);
                  }}
                  style={{
                    fontSize: 14,
                    backgroundColor: activeTab === 5 ? "white" : "transparent",
                  }}
                >
                  SPECIFY
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === 6,
                    activeWhite: activeTab === 6,
                  })}
                  onClick={() => {
                    setActiveTab(6);
                  }}
                  style={{
                    fontSize: 14,
                    backgroundColor: activeTab === 6 ? "white" : "transparent",
                  }}
                >
                  EMISSIONS AVOIDED
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === 4,
                    activeWhite: activeTab === 4,
                  })}
                  onClick={() => {
                    setActiveTab(4);
                  }}
                  style={{
                    fontSize: 14,
                    backgroundColor: activeTab === 4 ? "white" : "transparent",
                  }}
                >
                  FUNDING
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === 7,
                    activeWhite: activeTab === 7,
                  })}
                  onClick={() => {
                    setActiveTab(7);
                  }}
                  style={{
                    fontSize: 14,
                    backgroundColor: activeTab === 7 ? "white" : "transparent",
                  }}
                >
                  NOTES
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === 8,
                    activeWhite: activeTab === 8,
                  })}
                  onClick={() => {
                    setActiveTab(8);
                  }}
                  style={{
                    fontSize: 14,
                    backgroundColor: activeTab === 8 ? "white" : "transparent",
                  }}
                >
                  {props.type === "UPDATE_CREATE_NEW_ACTION"
                    ? "CREATE"
                    : "UPDATE"}
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab} style={{ height: "100%" }}>
              <TabPane tabId={1} style={styleTabPane}>
                <Index data={props.data} type={props.type}></Index>
              </TabPane>
              <TabPane tabId={2} style={styleTabPane}>
                <NetZeroClassification
                  data={props.data}
                  type={props.type}
                ></NetZeroClassification>
              </TabPane>
              <TabPane tabId={3} style={styleTabPane}>
                <Actor data={props.data} type={props.type}></Actor>
              </TabPane>
              <TabPane tabId={4} style={styleTabPane}>
                <Financials data={props.data} type={props.type}></Financials>
              </TabPane>
              {activeTab === 5 ? (
                <TabPane tabId={5} style={styleTabPane}>
                  <Specify data={props.data} type={props.type}></Specify>
                </TabPane>
              ) : null}

              {activeTab === 6 ? (
                <TabPane tabId={6} style={styleTabPane}>
                  <CO2EQAvoided
                    data={props.data}
                    type={props.type}
                  ></CO2EQAvoided>
                </TabPane>
              ) : null}
              <TabPane tabId={7} style={styleTabPane}>
                <Notes data={props.data} type={props.type}></Notes>
              </TabPane>

              <TabPane tabId={8} style={styleTabPane}>
                <CreateOrUpdate
                  data={props.data}
                  type={props.type}
                  setActiveTab={setActiveTab}
                ></CreateOrUpdate>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Content);
