import React, { useEffect } from "react"
import Plotly from "plotly.js-dist";
import { usePlotlyConfig } from "../../../../../utils/usePlotlyConfig";



const Chart = (props) => {
    const plotlyConfig = usePlotlyConfig();

    useEffect(() => {

        var data = props.output.value.data
        var layout = props.output.value.layout

        Plotly.newPlot(`chart-${props.output.id}`, data, layout, plotlyConfig);

    }, [])
    return (
        <div>
            <div id={`chart-${props.output.id}`} style={{ width: "98%", height: "300px" }}>
            </div>
        </div>
    )
};

export default Chart;
