import React, { useState } from "react";
import { useWindowDimensions } from "../../../../../utils/useWindowDimensions";
import { connect, useDispatch } from "react-redux";
import { FaUpload, FaArrowDown, FaArrowUp, FaPencilAlt } from "react-icons/fa";
import TitleCard from "../../../../../components/TitleCard/TitleCard";
import { getHeightWithFooterWONavbar } from "../../../../../utils/getHeightWithFooterWONavbar";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField } from "@mui/material";
import moment from "moment";
import { Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import CO2Equivalent from "./CO2Equivalent";

function Content(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [ghgSelected, setGhgSelected] = useState("");
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#E4E6E8",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        paddingLeft: 0,
        paddingBottom: props.layout.footer_height,
        overflow: "auto",
      }}
    >
      {/* workspace without padding */}
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 10,
        }}
      >
        {/* workspace */}
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          {/* first row */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: height * 0.09,
            }}
          >
            <div style={{ display: "flex", height: "100%" }}>
              <div
                style={{
                  padding: 10,
                  height: "100%",
                }}
              >
                <div
                  style={{
                    ...props.layout.generic_card,
                    width: width < 800 ? 130 : 180,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: width < 800 ? "0px 5px" : "0px 15px",
                  }}
                >
                  <DesktopDatePicker
                    inputFormat="DD-MM-YYYY"
                    value={props.data.start_date}
                    maxDate={props.data.end_date}
                    onChange={(newDate) => {
                      dispatch({
                        type: props.type,
                        payload: {
                          key: "start_date",
                          value:
                            newDate === null
                              ? null
                              : newDate.format("YYYY-MM-DD"),
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        className="datePickerMontserrat"
                      />
                    )}
                  />
                </div>
              </div>
              <div
                style={{
                  padding: 10,
                  height: "100%",
                }}
              >
                <div
                  style={{
                    ...props.layout.generic_card,
                    width: width < 800 ? 130 : 180,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: width < 800 ? "0px 5px" : "0px 15px",
                  }}
                >
                  <DesktopDatePicker
                    inputFormat="DD-MM-YYYY"
                    value={props.data.end_date}
                    minDate={props.data.start_date}
                    onChange={(newDate) => {
                      dispatch({
                        type: props.type,
                        payload: {
                          key: "end_date",
                          value:
                            newDate === null
                              ? null
                              : newDate.format("YYYY-MM-DD"),
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        className="datePickerMontserrat"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundColor: props.layout.color_sidebar,
                color: "white",
                fontFamily: "Montserrat",
                fontWeight: 500,
                fontSize: 16,
                padding:
                  width < 800 ? "10px 5px 10px 15px" : "4px 20px 2px 20px",
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                cursor: "not-allowed",
                marginRight: 15,
              }}
            >
              <FaUpload
                style={{ marginRight: 10, marginTop: "-3px" }}
              ></FaUpload>
              {width < 800 ? null : "Upload from policy"}
            </div>
          </div>

          {/* co2 equivalent */}
          <div
            style={{
              width: "100%",
              padding: 10,
              // marginBottom: 120,
              // height: "100%",
            }}
          >
            <div
              style={{
                ...props.layout.generic_card,
                display: "flex",
                flexFlow: "column",
                // height: "100%",
              }}
            >
              <TitleCard title={"CO2 - equivalent"}></TitleCard>
              <div
                style={{
                  width: "100%",
                  // height: "100%",
                  padding: "15px 10px 25px 10px",
                  borderRadius: props.layout.generic_card.borderRadius,
                }}
              >
                <Row style={{ margin: 0 }}>
                  <Col md="3" sm="12">
                    <div
                      style={{
                        height: width > 770 ? "100%" : height * 0.3,
                        padding: "7px 0px",
                      }}
                    >
                      <div
                        style={{
                          border: "1px solid #E2E2E2",
                          borderRadius: 10,
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          flexFlow: "column",
                        }}
                      >
                        <div
                          style={{
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                            backgroundColor: props.layout.color_sidebar,
                            color: "white",
                            padding: "2px 10px",
                            fontSize: 17,
                            fontFamily: "Montserrat",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          TOTAL
                        </div>
                        <div
                          style={{
                            padding: 10,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            backgroundColor: "#E5EFE7",
                          }}
                        >
                          <div>
                            <div
                              style={{
                                width: "100%",
                                padding: "10px 0px",
                              }}
                            >
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontWeight: "bold",
                                    fontSize: 36,
                                    marginBottom: "-3px",
                                  }}
                                >
                                  <TextField
                                    variant="standard"
                                    value={props.data.total_objective}
                                    onChange={(e) => {
                                      dispatch({
                                        type: props.type,
                                        payload: {
                                          key: "total_objective",
                                          value:
                                            e.target.value === ""
                                              ? null
                                              : e.target.value,
                                        },
                                      });
                                    }}
                                    InputProps={{
                                      disableUnderline: true, // <== added this
                                      style: {
                                        fontFamily: "Montserrat",
                                        fontWeight: "bold",
                                        fontSize: 36,
                                        color: props.layout.color_footer,
                                      },
                                    }}
                                    style={{
                                      width:
                                        props.data.total_objective === null
                                          ? 40
                                          : String(props.data.total_objective)
                                              .length *
                                            0.7 *
                                            36,
                                    }}
                                  />
                                  <span style={{ marginTop: "-1px" }}>
                                    {props.data.total_objective_uom}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "60%",
                                      borderBottom: `1px solid ${props.layout.color_footer}`,
                                    }}
                                  ></div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontWeight: 500,
                                    fontSize: width > 1600 ? 13 : 11,
                                  }}
                                >
                                  <i>
                                    {moment(props.data.end_date).isValid()
                                      ? moment(props.data.end_date).format(
                                          "YYYY"
                                        )
                                      : "final"}{" "}
                                    emission
                                  </i>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                padding: "10px 0px",
                              }}
                            >
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    fontWeight: 500,
                                    fontSize: 31,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {String(
                                      parseFloat(props.data.total_delta)
                                    ) !== "NaN" &&
                                    String(
                                      parseFloat(props.data.total_delta)
                                    ) !== "Infinity" ? (
                                      parseFloat(props.data.total_delta) >=
                                      0 ? (
                                        <FaArrowDown
                                          style={{
                                            color: "#E44747",
                                            marginRight: 5,
                                          }}
                                        ></FaArrowDown>
                                      ) : (
                                        <FaArrowUp
                                          style={{
                                            color: "#41A14B",
                                            marginRight: 5,
                                          }}
                                        ></FaArrowUp>
                                      )
                                    ) : null}
                                    <TextField
                                      variant="standard"
                                      value={props.data.total_delta}
                                      onChange={(e) => {
                                        dispatch({
                                          type: props.type,
                                          payload: {
                                            key: "total_delta",
                                            value:
                                              e.target.value === ""
                                                ? null
                                                : e.target.value,
                                          },
                                        });
                                      }}
                                      InputProps={{
                                        disableUnderline: true,
                                        style: {
                                          fontFamily: "Montserrat",
                                          fontWeight: 500,
                                          fontSize: 30,
                                          color: props.layout.color_footer,
                                        },
                                      }}
                                      style={{
                                        width:
                                          props.data.total_delta === null
                                            ? 40
                                            : String(props.data.total_delta)
                                                .length *
                                              0.7 *
                                              30,
                                      }}
                                    />{" "}
                                    %
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "60%",
                                      borderBottom: `1px solid ${props.layout.color_footer}`,
                                    }}
                                  ></div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontWeight: 500,
                                    fontSize: width > 1600 ? 13 : 11,
                                  }}
                                >
                                  <i>
                                    delta w.r.t.{" "}
                                    {moment(props.data.start_date).isValid()
                                      ? moment(props.data.start_date).format(
                                          "YYYY"
                                        )
                                      : "--"}
                                  </i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md="9" sm="12" style={{ padding: 0 }}>
                    <CO2Equivalent
                      co2_equivalent={props.data.co2_equivalent}
                      type={props.type}
                      key_redux={"co2_equivalent"}
                      end_date={props.data.end_date}
                      start_date={props.data.start_date}
                    ></CO2Equivalent>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          {/* cards */}
          <div
            style={{ display: "flex", flexWrap: "wrap", paddingBottom: 120 }}
          >
            {props.data.ghg.map((card, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width:
                      width > 1400
                        ? "16.6%"
                        : width > 900
                        ? "33.3%"
                        : width > 600
                        ? "50%"
                        : "100%",
                    padding: 10,
                    height: height * 0.26,
                  }}
                >
                  <div
                    style={{
                      ...props.layout.generic_card,
                      display: "flex",
                      flexFlow: "column",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <div style={{ position: "absolute", right: 15, top: 10 }}>
                      <FaPencilAlt
                        style={{ fontSize: 13, cursor: "pointer" }}
                        onClick={() => {
                          setGhgSelected(card.name);
                          toggle();
                        }}
                      ></FaPencilAlt>
                    </div>
                    <TitleCard
                      title={card.name}
                      length_border_bottom={"80%"}
                    ></TitleCard>
                    <div
                      style={{
                        display: "relative",
                        width: "100%",
                        height: "100%",
                        padding: `${
                          height < 780 ? "0px" : "10px"
                        } 10px 25px 10px`,
                        borderRadius: props.layout.generic_card.borderRadius,
                      }}
                    >
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontFamily: "Montserrat",
                          color: props.layout.color_footer,
                        }}
                      >
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontWeight: "bold",
                              fontSize: height < 780 ? 28 : 36,
                              marginBottom: "-3px",
                            }}
                          >
                            <TextField
                              variant="standard"
                              value={card.objective}
                              onChange={(e) => {
                                dispatch({
                                  type: props.type,
                                  payload: {
                                    key: "ghg",
                                    value: props.data.ghg.map((gas) => {
                                      if (gas.name !== card.name) {
                                        return gas;
                                      } else {
                                        return {
                                          ...gas,
                                          objective:
                                            e.target.value === ""
                                              ? null
                                              : e.target.value,
                                        };
                                      }
                                    }),
                                  },
                                });
                              }}
                              InputProps={{
                                disableUnderline: true, // <== added this
                                style: {
                                  fontFamily: "Montserrat",
                                  fontWeight: "bold",
                                  fontSize: height < 780 ? 28 : 36,
                                  color: props.layout.color_footer,
                                },
                              }}
                              style={{
                                width:
                                  card.objective === null
                                    ? 40
                                    : String(card.objective).length * 0.7 * 36,
                              }}
                            />
                            <span style={{ marginTop: 2 }}>{card.uom}</span>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "60%",
                                borderBottom: `1px solid ${props.layout.color_footer}`,
                              }}
                            ></div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontWeight: 500,
                              fontSize: width > 1600 ? 13 : 11,
                            }}
                          >
                            <i>
                              {moment(props.data.end_date).isValid()
                                ? moment(props.data.end_date).format("YYYY")
                                : "final"}{" "}
                              emission
                            </i>
                          </div>
                        </div>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              fontWeight: 500,
                              fontSize: height < 780 ? 18 : 24,
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {String(parseFloat(card.delta)) !== "NaN" &&
                              String(parseFloat(card.delta)) !== "Infinity" ? (
                                parseFloat(card.delta) >= 0 ? (
                                  <FaArrowDown
                                    style={{ color: "#E44747", marginRight: 5 }}
                                  ></FaArrowDown>
                                ) : (
                                  <FaArrowUp
                                    style={{ color: "#41A14B", marginRight: 5 }}
                                  ></FaArrowUp>
                                )
                              ) : null}
                              <TextField
                                variant="standard"
                                value={card.delta}
                                onChange={(e) => {
                                  dispatch({
                                    type: props.type,
                                    payload: {
                                      key: "ghg",
                                      value: props.data.ghg.map((gas) => {
                                        if (gas.name !== card.name) {
                                          return gas;
                                        } else {
                                          return {
                                            ...gas,
                                            delta:
                                              e.target.value === ""
                                                ? null
                                                : e.target.value,
                                          };
                                        }
                                      }),
                                    },
                                  });
                                }}
                                InputProps={{
                                  disableUnderline: true, // <== added this
                                  style: {
                                    fontFamily: "Montserrat",
                                    fontWeight: 500,
                                    fontSize: height < 780 ? 18 : 24,
                                    color: props.layout.color_footer,
                                  },
                                }}
                                style={{
                                  width:
                                    card.delta === null
                                      ? 40
                                      : String(card.delta).length * 0.7 * 24,
                                }}
                              />{" "}
                              %
                            </div>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "60%",
                                borderBottom: `1px solid ${props.layout.color_footer}`,
                              }}
                            ></div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontWeight: 500,
                              fontSize: width > 1600 ? 13 : 11,
                            }}
                          >
                            <i>
                              delta with respect to{" "}
                              {moment(props.data.start_date).isValid()
                                ? moment(props.data.start_date).format("YYYY")
                                : "--"}
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* domain detail of single gas in co2 equivalent */}
      {isOpen === true && ghgSelected !== "" ? (
        <Modal isOpen={isOpen} toggle={toggle} size="xl">
          {/* <ModalHeader toggle={toggle}></ModalHeader> */}
          <ModalBody style={{ padding: 0 }}>
            <TitleCard title={`${ghgSelected} domain details`}></TitleCard>
            <div style={{ padding: 20 }}>
              <CO2Equivalent
                co2_equivalent={
                  props.data.ghg.find((obj) => obj.name === ghgSelected).domains
                }
                type={props.type}
                key_redux={"ghg"}
                ghgSelected={ghgSelected}
                end_date={props.data.end_date}
                start_date={props.data.start_date}
                ghg={props.data.ghg}
              ></CO2Equivalent>
            </div>
          </ModalBody>
        </Modal>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Content);
