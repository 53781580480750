import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Card } from "reactstrap";
import { useDispatch } from "react-redux";
import { recurrentUpdate } from "../../../../../../utils/recurrentUpdate";
import { FaSpinner } from "react-icons/fa";
import axios from "axios";
import { API_URL } from "../../../../../../actions/types";
import { tokenConfig } from "../../../../../../utils/tokenConfig";
import { AgGridReact } from "ag-grid-react";
import { useWindowDimensions } from "../../../../../../utils/useWindowDimensions";
import CheckboxHeader from "./CheckboxHeader"
import Map3D from "./Map3D.tsx";


function SelectBuilding(props) {
    const [allRows, setAllRows] = useState(null)
    const [rowData, setRowData] = useState(null)
    const [columnDefs, setColumnDefs] = useState(null)
    // const [render, setRender] = useState(0)
    const { width, height } = useWindowDimensions()


    const value = useRef();
    const setValue = useRef();


    const updateRedux = (newValue) => {

        // in redux, save an array of true ids, not the object (that's what computations expect)
        var trueIDs = Object.fromEntries(
            Object
                .entries(newValue)
                .filter(([, val]) => val === true)
        );
        var arrayOnlyKeys = Object.keys(trueIDs)
        props.data.details.parameters.forEach((obj) =>
            recurrentUpdate(obj, props.param, arrayOnlyKeys)
        );
        dispatch({
            type: props.type,
            payload: {
                key: "details",
                value: props.data.details,
            },
        });
    };

    const dispatch = useDispatch();
    const grid = useRef();

    // use a ref to keep track of changes
    // redux is updated onclick but the change doesn't rerender
    // why the change in redux doesn't rerender the component? See SingleParam, there is a useState which runs only on didMount
    const defaultRef = useRef()

    // make get request only once 
    useEffect(async () => {
        axios
            .get(
                "https://geoserver.est.polito.it/geoserver/citta/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=citta%3Abuildings&outputFormat=application%2Fjson&propertyName=identifier,type,description,elevation,area,epv,riscaldamento,age,enthermgr,elt_sec_subst,line_type"
            )
            .then((response) => response.data)
            .then((data) => {
                const getRows = data.features.map((f) => f.properties).map(o => { return { ...o, id: o.identifier } })
                setAllRows(getRows)
                // convert from array to object -> this is done only inside this component because it's much faster (still in redux it's saved the array, not the object)
                var fromArrayToObj = {}
                props.param.default.forEach(id => fromArrayToObj[id] = true)
                defaultRef.current = fromArrayToObj
            });
    }, [])

    // define function to get data for aggrid
    const getData = async () => {
        return allRows.map(obj => {
            return {
                ...obj,
                selected: props.param.default[obj.id] === true ? true : false,
            }
        })
    };

    // define function to create columns automatically
    const getColumns = () => {
        var cols = Object.keys(allRows[0])
        var colsIDFirst = ["id", ...cols.filter(c => c !== "id" && c !== "identifier")]
        var colsToReturn = [
            {
                field: "selected",
                headerName: "",
                maxWidth: 37,
                minWidth: 37,
                headerComponent: "checkboxHeader",
                headerComponentParams: {
                    idParam: props.param.id,
                    updateRedux: updateRedux,
                    getDefaultRef: () => defaultRef.current, // use arrow function
                    setDefaultRef: (newObj) => defaultRef.current = newObj, // use arrow function
                    value: () => value.current, // use arrow function
                    setValue: (v) => setValue.current(v), // use arrow function
                },
                valueFormatter: (params) => {
                    if (params.value === true) {
                        return "✅"
                    } else {
                        return "⬜"
                    }
                },
                onCellClicked: (params) => {
                    // check new value
                    var newValue = defaultRef.current[params.data.id] === undefined || defaultRef.current[params.data.id] === false ? true : false
                    // update ref
                    defaultRef.current[params.data.id] = newValue
                    // update the value in the row node
                    params.node.setDataValue('selected', newValue);
                    // check if the checkbox header needs to be toggled
                    check_checkboxHeader(params)

                    // update redux (select only IDs equal to True)
                    var trueIDs = Object.fromEntries(
                        Object
                            .entries(defaultRef.current)
                            .filter(([, val]) => val === true)
                    );
                    defaultRef.current = trueIDs
                    updateRedux(trueIDs)
                    setValue.current(value.current + 1)
                },
            }
        ]
        colsIDFirst.forEach(c => {
            colsToReturn.push({
                field: c,
                headerName: (c[0].toUpperCase() + c.slice(1)).replace("_", " ").replace("[", " ["),
            })
        })
        return colsToReturn
    }

    // set row data and columns for aggrid
    useEffect(async () => {
        if (allRows !== null) {
            setRowData(await getData());
            setColumnDefs(getColumns())
        }
    }, [allRows]);

    // this function checks if the checkbox header has to be true or false
    const check_checkboxHeader = (params) => {
        const checkboxHeaderElement = document.getElementById(`idCheckboxHeader_${props.param.id}`)
        if (checkboxHeaderElement !== null) {
            let checked = true;
            params.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
                if (defaultRef.current[rowNode.data.id] === undefined || defaultRef.current[rowNode.data.id] === false) {
                    checked = false;
                    return;
                }
            });
            checkboxHeaderElement.checked = checked
        }
    }


    const onChildMount = (dataFromChild) => {
        value.current = dataFromChild[0]
        setValue.current = dataFromChild[1]
    };

    return (
        <Card body style={{ padding: 8, margin: 0, marginTop: 20 }}>
            <div style={{ marginLeft: 8, marginTop: 5 }}>
                <div style={{ fontFamily: "Montserrat" }}>
                    {props.param.paramName}
                </div>
                <div
                    style={{ fontFamily: "Montserrat", fontWeight: 500, fontSize: 12 }}
                >
                    {props.param.paramDescription}
                </div>
            </div>

            {rowData === null && columnDefs === null ?
                <FaSpinner
                    icon="spinner"
                    className="spinner"
                    style={{ fontSize: 25, color: "#5B5B5B", marginTop: 8, marginLeft: 8, marginBottom: 8 }}
                /> : <Row style={{ margin: 0 }}>
                    <Col lg="6" md="12" sm="12" xs="12" style={{ padding: 8, height: height - 400 }}>
                        {defaultRef.current !== undefined ? <Map3D
                            onMount={onChildMount}
                            selectedFeaturesOBJ={() => defaultRef.current}
                            setSelectedFeaturesOBJ={(newObj) => {
                                defaultRef.current = newObj
                                updateRedux(newObj)
                                setValue.current(value.current + 1)
                                grid.current.api.forEachNode((rowNode, index) => {
                                    if (newObj[rowNode.data.id] === undefined || newObj[rowNode.data.id] === false) {
                                        rowNode.data.selected = false
                                    } else {
                                        rowNode.data.selected = true
                                    }
                                });
                                grid.current.api.refreshCells();
                            }}
                        /> : null}

                    </Col>
                    <Col lg="6" md="12" sm="12" xs="12" style={{ padding: 8, height: height - 400 }}>
                        <div className="ag-theme-balham" style={{
                            height: "100%",
                        }}>
                            <AgGridReact
                                ref={grid}
                                getRowId={(params) => params.data.id}
                                onFirstDataRendered={params => {
                                    setTimeout(() => {
                                        check_checkboxHeader(params)
                                        params.columnApi.autoSizeAllColumns()
                                    }, 100);
                                }}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                defaultColDef={{
                                    sortable: true,
                                    resizable: true,
                                    filter: true,
                                    filterParams: {
                                        buttons: ["reset"],
                                        newRowsAction: "keep",
                                        caseSensitive: true
                                    },
                                }}
                                frameworkComponents={{
                                    checkboxHeader: CheckboxHeader,
                                }}
                                onFilterChanged={(params) => {
                                    check_checkboxHeader(params)
                                }}
                            >
                            </AgGridReact>
                        </div>
                    </Col>
                </Row>}


        </Card>
    );
}

export default React.memo(SelectBuilding);
