import React from "react";
import { Row, Col } from "reactstrap";
import { useDispatch } from "react-redux";
import { getHeightWithFooterWONavbar } from "../../../../utils/getHeightWithFooterWONavbar";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { connect } from "react-redux";
import {
  API_URL,
  IS_LOADING,
  RESET_CREATE_NEW_ACTION,
  GET_ERRORS,
} from "../../../../actions/types";
import axios from "axios";
import { tokenConfig } from "../../../../utils/tokenConfig";
import SuccessAlert from "../../../../components/Modals/SuccessAlert";
import InfoAlert from "../../../../components/Modals/InfoAlert";
import { useHistory } from "react-router-dom";
import { generateExcelAction } from "../../../../utils/generateExcelAction";
import { triggerRefresh } from "../../../../utils/triggerRefresh";

function CreateOrUpdate(props) {
  const { width, height } = useWindowDimensions();
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <div
      style={{
        width: "100%",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        overflow: "auto",
      }}
    >
      <Row style={{ margin: 0, marginTop: 15 }}>
        <Col
          lg="6"
          md="12"
          style={{ fontFamily: "Montserrat", fontWeight: 500 }}
        >
          <div style={{ display: "flex" }}>
            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
              Name:
            </span>{" "}
            <span>&nbsp;{props.data.name === "" ? "--" : props.data.name}</span>
          </div>

          <div style={{ display: "flex", marginTop: 10 }}>
            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
              Description:
            </span>{" "}
            <span>
              &nbsp;
              {props.data.description === "" ? "--" : props.data.description}
            </span>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "fit-content",
                backgroundColor: props.layout.color_sidebar,
                color: "white",
                fontFamily: "Montserrat",
                fontWeight: 500,
                fontSize: 16,
                padding: "6px 15px 4px 15px",
                borderRadius: 10,
                cursor: "pointer",
                marginTop: 20,
              }}
              onClick={async () => {
                if (props.data.name === "") {
                  InfoAlert("Action name cannot be empty");
                } else {
                  dispatch({
                    type: IS_LOADING,
                  });
                  // run action, create or update
                  if (props.type === "UPDATE_CREATE_NEW_ACTION") {
                    try {
                      const resCreate = await axios.post(
                        `${API_URL}/clicc/actions/`,
                        props.data,
                        tokenConfig()
                      );
                      dispatch({
                        type: RESET_CREATE_NEW_ACTION,
                      });

                      // once the action is created, make one per financial detail
                      const list_promises =
                        props.data.financials_investment_detail.map(
                          (detail) => {
                            return axios.post(
                              `${API_URL}/clicc/actions/${resCreate.data.id}/financials_investment/`,
                              detail,
                              tokenConfig()
                            );
                          }
                        );
                      Promise.all(list_promises).then(function (values) {
                        SuccessAlert("Action created successfully");
                        props.setActiveTab(1)
                        triggerRefresh()
                      });
                    } catch (err) {
                      const errors = {
                        msg: err.response.data,
                        status: err.response.status,
                      };
                      dispatch({
                        type: GET_ERRORS,
                        payload: errors,
                      });
                    }
                  } else {
                    try {
                      const resPut = await axios.put(
                        `${API_URL}/clicc/actions/${props.data.id}/`,
                        props.data,
                        tokenConfig()
                      );
                      // once the action is updated, check financials details to delete and to create
                      // list before updates
                      const listInitialIDs =
                        resPut.data.financials_investment_detail.map(
                          (obj) => obj.id
                        );
                      // new IDs (in redux)
                      const listNewIDs =
                        props.data.financials_investment_detail.map(
                          (obj) => obj.id
                        );
                      var promisesPut = [];
                      // append list to delete
                      listInitialIDs.forEach((id) => {
                        if (!listNewIDs.includes(id)) {
                          promisesPut.push(
                            axios.delete(
                              `${API_URL}/clicc/actions/${resPut.data.id}/financials_investment/?id=${id}`,
                              tokenConfig()
                            )
                          );
                        }
                      });
                      // append list to create
                      listNewIDs.forEach((id) => {
                        if (!listInitialIDs.includes(id)) {
                          axios.post(
                            `${API_URL}/clicc/actions/${resPut.data.id}/financials_investment/`,
                            props.data.financials_investment_detail.find(
                              (detail) => detail.id === id
                            ),
                            tokenConfig()
                          );
                        }
                      });
                      // make all calls
                      Promise.all(promisesPut).then(function (values) {
                        SuccessAlert("Changes have been saved");
                        triggerRefresh()
                      });
                    } catch (err) {
                      const errors = {
                        msg: err.response.data,
                        status: err.response.status,
                      };
                      dispatch({
                        type: GET_ERRORS,
                        payload: errors,
                      });
                    }
                  }
                  dispatch({
                    type: IS_LOADING,
                  });
                }
              }}
            >
              {props.type === "UPDATE_CREATE_NEW_ACTION"
                ? "CREATE ACTION"
                : "UPDATE ACTION"}
            </div>
            <div
              style={{
                width: "fit-content",
                backgroundColor: props.layout.color_sidebar,
                color: "white",
                fontFamily: "Montserrat",
                fontWeight: 500,
                fontSize: 16,
                padding: "6px 15px 4px 15px",
                borderRadius: 10,
                cursor: "pointer",
                marginTop: 20,
                marginLeft: 15,
              }}
              onClick={() => {
                generateExcelAction(props);
              }}
            >
              DOWNLOAD XLSX
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(CreateOrUpdate);
