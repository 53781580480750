import React, { useState } from "react";
import { FaHistory } from "react-icons/fa";
import ModalLogUser from "./ModalLogUser/ModalLogUser"

function LogUsers(props) {
    const [isOpenLog, setIsOpenLog] = useState(false);

    return (
        <div>
            <FaHistory
                onClick={() => setIsOpenLog(true)}
                style={{
                    color: "blue",
                    cursor: "pointer",
                    marginLeft: 2
                }}
            ></FaHistory>
            {isOpenLog === true ?
                <ModalLogUser isOpen={isOpenLog} setIsOpen={setIsOpenLog} {...props}></ModalLogUser>
                : null}


        </div>
    );
}

export default LogUsers;
