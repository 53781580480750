import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import EditorJS from "@editorjs/editorjs";
import Header from "editorjs-header-with-alignment";
import Paragraph from "editorjs-paragraph-with-alignment";
import Table from "@editorjs/table";
import List from "@editorjs/list";
import ImageTool from "@editorjs/image";
import AttachesTool from "@editorjs/attaches";
import createGenericInlineTool, {
  ItalicInlineTool,
  UnderlineInlineTool,
} from "editorjs-inline-tool";
import ColorPlugin from "editorjs-text-color-plugin";
import DragDrop from "editorjs-drag-drop";
import Undo from "editorjs-undo";
import { useDispatch } from "react-redux";
import { tokenConfig } from "../../utils/tokenConfig";
import { API_URL } from "../../actions/types";

const Editor = (props) => {
  const dispatch = useDispatch();
  const [editor, setEditor] = useState(null);
  const EDITTOR_HOLDER_ID = props.name + "editorjs";
  const ejInstance = useRef(null);
  const initEditor = () => {
    const editorInit = new EditorJS({
      holder: EDITTOR_HOLDER_ID,
      logLevel: "ERROR",
      onReady: () => {
        // new Undo({ editorInit });
        new DragDrop(editorInit);
        ejInstance.current = editorInit;
      },
      //   autofocus: true,
      data: { blocks: props.DEFAULT_INITIAL_DATA },
      onChange: async () => {
        let content = await editorInit.saver?.save()
          .then((data) => {
            const outputData = JSON.stringify(data, (key, val) => {
              if (typeof val === "string") {
                return val.replace("&amp;", "&");
              }
              return val;
            });
            // console.log(JSON.parse(outputData).blocks);
            dispatch({
              type: props.type,
              payload: {
                key: props.name,
                value: JSON.parse(outputData).blocks,
              },
            });
            // console.log("Article data: ", JSON.parse(outputData).blocks);
          })
          .catch((error) => {
            console.log("Saving failed: ", error);
          });
      },
      tools: {
        header: {
          class: Header,
          inlineToolbar: true,
        },
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
        },
        table: {
          class: Table,
          inlineToolbar: true,
          config: {
            rows: 3,
            cols: 5,
          },
        },
        list: {
          class: List,
          inlineToolbar: true,
          config: {
            defaultStyle: "unordered",
          },
        },
        image: {
          class: ImageTool,
          config: {
            endpoints: {
              byFile: `${API_URL}/clicc/contract_documents_files/`, // Your backend file uploader endpoint
              byUrl: `${API_URL}/clicc/contract_documents_files/`, // Your endpoint that provides uploading by Url
            },
            additionalRequestHeaders: {
              Authorization: tokenConfig().headers.Authorization,
            },
          },
        },
        attaches: {
          class: AttachesTool,
          config: {
            endpoint: `${API_URL}/clicc/contract_documents_files/`,
            additionalRequestHeaders: {
              Authorization: tokenConfig().headers.Authorization,
            },
          },
        },
        italic: ItalicInlineTool,
        underline: UnderlineInlineTool,
        // Color: {
        //   class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
        //   config: {
        //     colorCollections: [
        //       "#EC7878",
        //       "#9C27B0",
        //       "#673AB7",
        //       "#3F51B5",
        //       "#0070FF",
        //       "#03A9F4",
        //       "#00BCD4",
        //       "#4CAF50",
        //       "#8BC34A",
        //       "#CDDC39",
        //       "#FFF",
        //     ],
        //     defaultColor: "#FF1300",
        //     type: "text",
        //     customPicker: true, // add a button to allow selecting any colour
        //   },
        // },
        Marker: {
          class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
          config: {
            defaultColor: "#FFBF00",
            type: "marker",
            icon: `<svg fill="#000000" height="200px" width="200px" version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M17.6,6L6.9,16.7c-0.2,0.2-0.3,0.4-0.3,0.6L6,23.9c0,0.3,0.1,0.6,0.3,0.8C6.5,24.9,6.7,25,7,25c0,0,0.1,0,0.1,0l6.6-0.6 c0.2,0,0.5-0.1,0.6-0.3L25,13.4L17.6,6z"></path> <path d="M26.4,12l1.4-1.4c1.2-1.2,1.1-3.1-0.1-4.3l-3-3c-0.6-0.6-1.3-0.9-2.2-0.9c-0.8,0-1.6,0.3-2.2,0.9L19,4.6L26.4,12z"></path> </g> <g> <path d="M28,29H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h24c0.6,0,1,0.4,1,1S28.6,29,28,29z"></path> </g> </g></svg>`,
          },
        },
      },
    });
    setEditor(editorInit);
  };
  useEffect(() => {
    if (props.DEFAULT_INITIAL_DATA !== null) {
      if (ejInstance.current === null) {
        initEditor();
      }
    }
    return () => {
      ejInstance?.current?.destroy();
      ejInstance.current = null;
    };
  }, [props.DEFAULT_INITIAL_DATA]);



  return (
    <div
      style={{
        margin: "auto",
        maxWidth: "850px",
        height: "85vh",
      }}
    >
      {/* <button
        onClick={() => {
          editor
            .save()
            .then((data) => {
              const outputData = JSON.stringify(data, (key, val) => {
                if (typeof val === "string") {
                  return val.replace("&amp;", "&");
                }
                return val;
              });
              console.log("Article data: ", JSON.parse(outputData));
            })
            .catch((error) => {
              console.log("Saving failed: ", error);
            });
        }}
      ></button> */}
      <div id={EDITTOR_HOLDER_ID} spellcheck="false"></div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Editor);
