import React, { useState, useEffect, useRef } from "react"
import { Modal, ModalBody, Row, Col } from "reactstrap";
import { MdClose } from "react-icons/md";
import TitleCard from "../../../../../components/TitleCard/TitleCard";
import { roundNumberInCard } from "../../../../../utils/roundNumberInCard";
import { FaSpinner } from "react-icons/fa";
import { usePlotlyConfig } from "../../../../../utils/usePlotlyConfig";
import Plotly from "plotly.js-dist";
import { useWindowDimensions } from "../../../../../utils/useWindowDimensions";
import MappaDashboard from "./MappaDashboard";


const ModalDashboard = (props) => {
    const [pathwayActions, setPathwayActions] = useState(null)
    const [filteredActions, setFilteredActions] = useState(null)
    const { width, height } = useWindowDimensions()
    const plotlyConfig = usePlotlyConfig()

    useEffect(() => {
        setPathwayActions(props.allActions())
        setFilteredActions(props.allActions())
    }, [])



    useEffect(() => {
        if (filteredActions !== null) {
            const activities = filteredActions.map(action => {
                return {
                    task: action.name,
                    start_date: action.start_date,
                    end_date: action.end_date,
                }
            })

            function parseDate(dateString) {
                const date = new Date(dateString);
                if (isNaN(date.getTime())) {
                    console.error(`Invalid date: ${dateString}`);
                    return null;
                }
                return date;
            }

            function sortActivitiesByDate(activities) {
                return activities.sort((a, b) => {
                    const dateA = parseDate(a.start_date);
                    const dateB = parseDate(b.start_date);
                    if (!dateA || !dateB) return 0;  // Handle invalid dates
                    return dateA - dateB;
                });
            }

            // Sort the activities
            const sortedActivities = sortActivitiesByDate(activities);

            // Find the overall start and end dates
            const allDates = sortedActivities.flatMap(a => [parseDate(a.start_date), parseDate(a.end_date)]).filter(Boolean);
            const minDate = new Date(Math.min(...allDates));
            const maxDate = new Date(Math.max(...allDates));

            // Calculate the total duration in days
            const totalDays = Math.ceil((maxDate - minDate) / (1000 * 60 * 60 * 24));

            // Prepare data for Plotly
            const data = [{
                type: 'bar',
                x: sortedActivities.map(a => {
                    const startDate = parseDate(a.start_date);
                    const endDate = parseDate(a.end_date);
                    if (!startDate || !endDate) return 0;
                    return (endDate - startDate) / (1000 * 60 * 60 * 24); // Duration in days
                }),
                y: sortedActivities.map(a => a.task),
                base: sortedActivities.map(a => {
                    const startDate = parseDate(a.start_date);
                    if (!startDate) return 0;
                    return (startDate - minDate) / (1000 * 60 * 60 * 24); // Start position in days from project start
                }),
                orientation: 'h',
                marker: {
                    color: 'rgb(0, 123, 255)',
                    line: {
                        color: 'rgb(0, 90, 200)',
                        width: 1
                    }
                },
                hoverinfo: 'text',
                text: sortedActivities.map(a => `${a.task}`)
            }];

            // Generate tick values and text
            const tickCount = 8;
            const tickStep = Math.ceil(totalDays / (tickCount - 1));
            const tickVals = Array.from({ length: tickCount }, (_, i) => i * tickStep);
            let tickText
            if (filteredActions.length > 0) {
                tickText = tickVals.map(days =>
                    new Date(minDate.getTime() + days * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
                );
            } else {
                tickText = tickVals.map(days =>
                    new Date(1900, 1, 1).toISOString().split('T')[0]
                );
            }
            const layout = {
                font: {
                    family: 'Montserrat'
                },
                xaxis: {
                    title: 'Timeline',
                    type: 'linear',
                    range: [0, totalDays],
                    tickmode: 'array',
                    tickvals: tickVals,
                    ticktext: tickText
                },
                yaxis: {
                    autorange: 'reversed',
                    tickfont: { size: 10 },
                    automargin: true
                },
                margin: {
                    l: 60,
                    r: 40,
                    t: 20,
                    b: 80,
                    pad: 5
                },
                bargap: 0.1
            };

            // Render the chart with vertical scrolling
            try {

                Plotly.newPlot('gantt-chart', data, layout, { scrollZoom: true, ...plotlyConfig });
            } catch { }
        }
    }, [filteredActions])


    return (
        <Modal
            isOpen={props.isOpen}
            style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
            }}
            size="xl"
            toggle={() => { props.toggle() }}
        >
            <ModalBody>
                <div style={{ position: "absolute", right: 10, top: 10 }}>
                    <MdClose onClick={() => { props.toggle() }}
                        style={{ fontSize: 24, color: "gray", cursor: "pointer" }}></MdClose>
                </div>
                {filteredActions === null || pathwayActions === null ?
                    <FaSpinner className="spinner"></FaSpinner>
                    :
                    pathwayActions.length === 0 ?
                        <div>Insert actions into pathway to see the dashboard</div>
                        :
                        <>
                            <Row>
                                <Col lg="5" md="12">
                                    <div
                                        style={{
                                            ...props.layout.generic_card,
                                            display: "flex",
                                            flexFlow: "column",
                                            height: "40%",
                                        }}
                                    >
                                        <TitleCard
                                            title={
                                                <div>
                                                    CO<sub>2</sub> avoided
                                                </div>
                                            }
                                        ></TitleCard>
                                        <div style={{
                                            height: "100%",
                                            display: "flex", justifyContent: "end",
                                            alignItems: "center",
                                            fontSize: 28,
                                            fontWeight: "bold",
                                            marginTop: "-25px",
                                            marginRight: 20,
                                            marginBottom: 10
                                        }}>

                                            {roundNumberInCard(
                                                filteredActions.map(action => action.emissions.find(obj => obj.gas === "co2").emissions_avoided).reduce((a, b) => a + b, 0)
                                                , "[t/y]")}
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            ...props.layout.generic_card,
                                            display: "flex",
                                            flexFlow: "column",
                                            height: "40%",
                                            marginTop: "20px"
                                        }}
                                    >
                                        <TitleCard
                                            title={
                                                <div>
                                                    Total cost
                                                </div>
                                            }
                                        ></TitleCard>
                                        <div style={{
                                            height: "100%",
                                            display: "flex", justifyContent: "end",
                                            alignItems: "center",
                                            fontSize: 28,
                                            fontWeight: "bold",
                                            marginTop: "-25px",
                                            marginRight: 20,
                                            marginBottom: 10
                                        }}>
                                            {roundNumberInCard(
                                                filteredActions.map(action => action.financials_total_investment).reduce((a, b) => a + b, 0)
                                                , "€")}
                                        </div>
                                    </div>
                                    {width > 1000 ?
                                        <div style={{
                                            fontFamily: "Montserrat",
                                            fontWeight: "bold",
                                            fontSize: 20, marginTop: 40, marginLeft: 10
                                        }}>
                                            Gantt actions
                                        </div> : null}

                                </Col>
                                <Col lg="7" md="12"
                                    style={{ paddingRight: width > 1000 ? "40px" : "10px" }}
                                >
                                    <div style={{
                                        width: "100%",
                                        height: "350px",
                                        // border: "1px solid black"
                                        boxShadow: "3px 3px 10px rgb(133,164,204,0.7)"
                                    }}>
                                        <MappaDashboard actions={pathwayActions} filteredActions={filteredActions} setFilteredActions={setFilteredActions} />
                                    </div>
                                </Col>
                            </Row>
                            <div
                                id="gantt-chart"
                                style={{ width: "100%", height: "400px", marginTop: "20px" }}>

                            </div>
                        </>

                }


            </ModalBody>
        </Modal>
    )
};

export default ModalDashboard;
