import { combineReducers } from "redux";
import handleOpenClose from "./handleOpenClose";
import errors from "./errors";
import auth from "./auth";
import toolbar from "./toolbar";
import isLoading from "./isLoading";
import layout from "./layout";
import create_workspace from "./create_workspace";
import opened_workspace from "./opened_workspace";
import create_new_action from "./create_new_action";
import opened_action from "./opened_action";
import create_new_contract from "./create_new_contract";
import opened_contract from "./opened_contract";
import create_new_actor from "./create_new_actor";
import opened_actor from "./opened_actor";
import { selected_values } from "./selected_values";

export default combineReducers({
  handleOpenClose,
  errors,
  auth,
  toolbar,
  isLoading,
  layout,
  create_workspace,
  opened_workspace,
  create_new_action,
  opened_action,
  create_new_contract,
  create_new_actor,
  opened_actor,
  opened_contract,
  selected_values,
});
