import React, { useState } from "react";
import { FaTimes, FaPencilAlt } from "react-icons/fa";
import { BsFillPinFill } from "react-icons/bs";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { API_URL, IS_LOADING, RESET_OPENED } from "../../../../actions/types";
import { useDispatch } from "react-redux";
import SuccessAlert from "../../../../components/Modals/SuccessAlert";
import axios from "axios";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { useHistory } from "react-router-dom";
import { triggerRefresh } from "../../../../utils/triggerRefresh";
import Tooltip from "@mui/material/Tooltip";
import { checkPermission } from "../../../../utils/checkPermission";


function ActionContract(props) {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const toggle = () => setIsOpen(!isOpen);
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {checkPermission(props.auth.user_permissions, "Can delete workspace") === true ?
        <FaTimes
          onClick={toggle}
          style={{
            color: "#FC2727",
            cursor: "pointer",
          }}
        ></FaTimes> : null
      }
      {checkPermission(props.auth.user_permissions, "Can change workspace") === true ?
        <FaPencilAlt
          onClick={async (e) => {
            if (e.ctrlKey) {
              window.open(
                window.location.hostname === "localhost"
                  ? `http://localhost:3009/clicc/dashboard/workspace_modify_workspace/?id=${props.data.id}`
                  : API_URL.replace(
                    "/api",
                    `/clicc/dashboard/workspace_modify_workspace/?id=${props.data.id}`
                  ),
                "_blank"
              );
              SuccessAlert("Workspace opened in another tab");
            } else if (e.shiftKey) {
              localStorage.setItem("opened_workspace", props.data.id);
              triggerRefresh();
              SuccessAlert("Workspace available in tab already opened");
            } else {
              dispatch({
                type: RESET_OPENED,
              });
              history.push(
                `/clicc/dashboard/workspace_modify_workspace/?id=${props.data.id}`
              );
            }
          }}
          style={{
            color: "#EE9B1B",
            cursor: "pointer",
            marginLeft: checkPermission(props.auth.user_permissions, "Can delete workspace") === true ? 10 : 0,
          }}
        ></FaPencilAlt> : null
      }

      <Tooltip title="Default workspace">
        <div>
          <BsFillPinFill
            hidden={props.default !== true}
            style={{
              color: "#969696",
              marginLeft: checkPermission(props.auth.user_permissions, "Can delete workspace") === true || checkPermission(props.auth.user_permissions, "Can change workspace") === true ? 8 : 6,
              fontSize: 14,
              color: "green"

            }}
          ></BsFillPinFill>
        </div>
      </Tooltip>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        style={{
          fontFamily: "Montserrat",
          fontWeight: 500,
        }}
      >
        <ModalHeader toggle={toggle}>Attention</ModalHeader>
        <ModalBody>
          Do you really want to delete the selected workspace?
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={async () => {
              dispatch({
                type: IS_LOADING,
              });
              await axios.delete(
                `${API_URL}/clicc/workspace/${props.data.id}/?name=${props.data.name}`,
                tokenConfig()
              );
              if (
                props.opened_workspace.id !== undefined &&
                props.opened_workspace.id === props.data.id
              ) {
                dispatch({
                  type: RESET_OPENED,
                });
              }
              dispatch({
                type: IS_LOADING,
              });
              SuccessAlert("Workspace deleted successfully");
              triggerRefresh();
            }}
          >
            Delete
          </Button>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ActionContract;
