import { API_URL } from "../actions/types";
import { saveAs } from "file-saver";


export const contextMenuWithJSON = (params, sheetName, table) => {
  return [
    "copy",
    "copyWithHeaders",
    "paste",
    "separator",
    {
      name: "Export",
      icon: '<span class="ag-icon ag-icon-save" unselectable="on" role="presentation"></span>',
      subMenu: [
        "csvExport",
        {
          name: "Excel Export (.xlsx)",
          icon: '<span class="ag-icon ag-icon-excel" unselectable="on" role="presentation"></span>',
          action: () => {
            params.api.exportDataAsExcel({
              sheetName: sheetName,
            });
          },
        },
        {
          name: "Raw export (.json)",
          icon: '<span class="ag-icon ag-icon-menu" unselectable="on" role="presentation"></span>',
          action: async () => {
            // set api and body
            var api
            var body = {}
            if (table === "accountable" || table === "non_accountable") {
              api = `${API_URL}/clicc/actions/download/`
              body = {
                is_accountable: table === "accountable" ? true : false
              };
            } else {
              api = `${API_URL}/clicc/grouped_actions/download/`
            }
            // call
            const xhr = new XMLHttpRequest();
            xhr.responseType = "arraybuffer";
            xhr.open(
              "POST",
              api,
              true
            );
            xhr.setRequestHeader(
              "Authorization",
              `Token ${localStorage.getItem("token")}`
            );
            xhr.setRequestHeader("Content-type", "application/json");
            xhr.onload = function () {
              if (this.status === 200) {
                var type = xhr.getResponseHeader("Content-Type");

                var blob = new Blob([this.response], { type: type });
                var filename = `${table} actions`.replace("_", " ");
                saveAs(blob, filename);
              }
            };
            xhr.send(JSON.stringify(body));
          },
        },
      ],
    },
  ];
};
