import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import { useWindowDimensions } from "../../../../../utils/useWindowDimensions";
import TitleCard from "../../../../../components/TitleCard/TitleCard";
import { FaAngleDoubleRight } from "react-icons/fa";
import { Checkbox, Tooltip } from "@mui/material";
import InfoAlert from "../../../../../components/Modals/InfoAlert";

function ActionDetails(props) {
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();


  return (
    <Col
      sm="12"
      style={{
        position: "relative",
        padding: 10,
        height: height < 850 ? height * 0.44 : height * 0.49,
      }}
    >
      <div
        style={{
          ...props.layout.generic_card,
          display: "flex",
          flexFlow: "column",
          height: "100%",
        }}
      >
        <TitleCard title="Action details"></TitleCard>
        <div
          style={{
            padding: "10px 15px",
            flex: "1 1 auto",
            borderRadius: props.layout.generic_card.borderRadius,
            height: "auto",
            width: "100%",
            overflow: "auto",
          }}
        >
          <div style={{ height: "95%", overflow: "auto" }}>
            <div
              style={{
                fontFamily: "Montserrat",
                fontWeight: "bold",
                color: props.layout.color_footer,
                fontSize: width > 1450 ? 16 : 14,
              }}
            >
              {props.actionDetails.length === 0 ? null : "My actions"}
            </div>
            <div
              style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
                fontSize: 14,
              }}
            >
              {props.actionDetails.length > 0
                ? props.actionDetails.filter(action => action.id !== "global_impact")
                  .map((action) => {
                    return (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: 15,
                          }}
                        >
                          <Checkbox
                            checked={
                              props.actionSelected.includes(action.id) === true
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              props.actionSelected.includes(action.id) === false
                                ? props.setActionSelected([
                                  ...props.actionSelected,
                                  action.id,
                                ])
                                : props.setActionSelected(
                                  props.actionSelected.filter(
                                    (id) => id !== action.id
                                  )
                                )
                            }
                          ></Checkbox>
                          <div
                            style={{
                              marginLeft: 8,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {action.name}
                            {Object.keys(action).includes("actions") ? (
                              <Tooltip title="Grouped action">
                                <span
                                  style={{
                                    backgroundColor: "purple",
                                    borderRadius: "50%",
                                    marginLeft: 4,
                                    color: "white",
                                    fontWeight: "bold",
                                    padding: "0px 4px",
                                    fontSize: 10,
                                    height: 15,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "default",
                                  }}
                                >
                                  G
                                </span>
                              </Tooltip>
                            ) : null}
                          </div>
                        </div>
                      </>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>

      {/* double arrow right */}
      <div
        style={{
          position: "absolute",
          zIndex: 9,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          top: "50%",
          right: 0,
          marginRight: width < 770 ? 0 : "-16px",
          color: "white",
          backgroundColor: props.layout.color_sidebar,
          borderRadius: "50%",
          width: 35,
          height: 35,
          cursor: "pointer",
        }}
        onClick={() => {
          if (props.actionSelected.length === 0) {
            InfoAlert("No action selected");
          } else if (props.data.displayed_pathway === null) {
            InfoAlert("Select a pathway to proceed");
          } else {
            var curr_pathway = props.data.pathways.find(
              (p) => p.id === props.data.displayed_pathway
            );
            var found = false
            curr_pathway.actions.forEach(a => {
              if (props.actionSelected.includes(a.action_id)) {
                found = true
              }
            })
            if (found === true) {
              InfoAlert("Cannot add twice the same action in a pathway");

            } else {
              curr_pathway.actions = [
                ...curr_pathway.actions,
                ...props.actionSelected.map((id) => {
                  return {
                    id: props.myActions.find((obj) => obj.id === id).actions ===
                      undefined
                      ? `action_${id}`
                      : `grouped_action_${id}`,
                    action_id: id,
                    is_grouped_action:
                      props.myActions.find((obj) => obj.id === id).actions ===
                        undefined
                        ? false
                        : true,
                  };
                }),
              ];


              dispatch({
                type: props.type,
                payload: {
                  key: "pathways",
                  value: props.data.pathways.map((p) => {
                    if (p.id !== props.data.displayed_pathway) {
                      return p;
                    } else {
                      return curr_pathway;
                    }
                  }),
                },
              });

              // reset selection to empty. That's important also to prevent bug if deleting a selected action from other tabs
              props.setActionSelected([])
            }

          }
        }}
      >
        <FaAngleDoubleRight style={{ fontSize: 25 }}></FaAngleDoubleRight>
      </div>
    </Col>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(ActionDetails);
