import React from "react";
import { Modal, ModalHeader, ModalBody, Card } from "reactstrap";
import Fields from "../../../Actors/NewActor/components/Fields";
import { connect, useDispatch } from "react-redux";
import {
  UPDATE_CREATE_NEW_ACTOR,
  RESET_CREATE_NEW_ACTOR,
  IS_LOADING,
  GET_ERRORS,
} from "../../../../actions/types";
import axios from "axios";
import { API_URL } from "../../../../actions/types";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { triggerRefresh } from "../../../../utils/triggerRefresh";

function ModalCreateActor(props) {
  const dispatch = useDispatch();
  const toggle = () => props.setIsOpen(!props.isOpen);
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={toggle}
      unMountOnClose={true}
      size="xl"
    >
      <ModalHeader toggle={toggle}>Create new actor</ModalHeader>
      <ModalBody>
        <Fields
          data={props.create_new_actor}
          type={UPDATE_CREATE_NEW_ACTOR}
        ></Fields>
        <div
          style={{
            width: "fit-content",
            backgroundColor: props.layout.color_sidebar,
            color: "white",
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: 16,
            padding: "6px 15px 4px 15px",
            borderRadius: 10,
            cursor: "pointer",
            marginTop: 20,
            marginLeft: 12,
          }}
          onClick={async () => {
            try {
              dispatch({
                type: IS_LOADING,
              });
              await axios.post(
                `${API_URL}/clicc/actors/`,
                props.create_new_actor,
                tokenConfig()
              );
              dispatch({
                type: RESET_CREATE_NEW_ACTOR,
              });
              dispatch({
                type: IS_LOADING,
              });
              toggle();
              triggerRefresh()
            } catch (err) {
              dispatch({
                type: IS_LOADING,
              });
              const errors = {
                msg: err.response.data,
                status: err.response.status,
              };
              dispatch({
                type: GET_ERRORS,
                payload: errors,
              });
            }
          }}
        >
          CREATE
        </div>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
  create_new_actor: state.create_new_actor,
});

export default connect(mapStateToProps)(ModalCreateActor);
