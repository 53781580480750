import React from "react";
import { Row, Col } from "reactstrap";
import { MenuItem, Select, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { getHeightWithFooterWONavbar } from "../../../../utils/getHeightWithFooterWONavbar";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { connect } from "react-redux";

function NetZeroClassification(props) {
  const dispatch = useDispatch();
  const { width, height } = useWindowDimensions();

  const scopes = ["Scope 1", "Scope 2", "Scope 3"];
  const source_sector = [
    "Buildings",
    "Transport",
    "Waste",
    "Industrial Process and Product Use (IPPU)",
    "Agricultural",
    "Forestry and Land Use (AFOLU)",
  ];

  const inputProps = {
    style: {
      fontWeight: 500,
      fontFamily: "Montserrat",
    },
  };

  const InputLabelProps = {
    style: {
      fontWeight: 500,
      fontFamily: "Montserrat",
    },
  };

  return (
    <div
      style={{
        width: "100%",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        overflow: "auto",
      }}
    >
      <Row style={{ margin: 0 }}>
        <Col lg="3" md="12" sm="12" style={{ marginTop: 15 }}>
          <TextField
            select
            label="Scope"
            value={props.data.net_zero_scope ? props.data.net_zero_scope : ""}
            style={{
              width: "100%",
            }}
            InputLabelProps={InputLabelProps}
            InputProps={{
              style: {
                height: 56,
                fontWeight: 500,
                fontFamily: "Montserrat",
              },
            }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: {
                  key: "net_zero_scope",
                  value: e.target.value,
                },
              });
            }}
          >
            <MenuItem
              value=""
              style={{ fontWeight: 500, fontFamily: "Montserrat" }}
            >
              ...
            </MenuItem>
            {scopes.map((s, index) => {
              return (
                <MenuItem
                  key={index}
                  value={s}
                  style={{ fontWeight: 500, fontFamily: "Montserrat" }}
                >
                  {s}
                </MenuItem>
              );
            })}
          </TextField>
        </Col>
        <Col
          lg="6"
          md="12"
          sm="12"
          style={{ marginTop: width < 990 ? 30 : 15 }}
        >
          <TextField
            select
            label="Source sector"
            value={
              props.data.net_zero_source_sector
                ? props.data.net_zero_source_sector
                : ""
            }
            style={{
              width: "100%",
            }}
            InputLabelProps={InputLabelProps}
            InputProps={{
              style: {
                height: 56,
                fontWeight: 500,
                fontFamily: "Montserrat",
              },
            }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: {
                  key: "net_zero_source_sector",
                  value: e.target.value,
                },
              });
            }}
          >
            <MenuItem
              value=""
              style={{ fontWeight: 500, fontFamily: "Montserrat" }}
            >
              ...
            </MenuItem>
            {source_sector.map((s, index) => {
              return (
                <MenuItem
                  key={index}
                  value={s}
                  style={{ fontWeight: 500, fontFamily: "Montserrat" }}
                >
                  {s}
                </MenuItem>
              );
            })}
          </TextField>
        </Col>
      </Row>
      <Row style={{ margin: 0, marginTop: 30 }}>
        <Col xs="12">
          <TextField
            label={
              <div>
                Generated renewable energy (if
                applicable)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
            }
            multiline
            rows={3}
            value={
              props.data.net_zero_generated_renewable
                ? props.data.net_zero_generated_renewable
                : ""
            }
            inputProps={inputProps}
            InputLabelProps={InputLabelProps}
            style={{ width: "100%" }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: {
                  key: "net_zero_generated_renewable",
                  value: e.target.value,
                },
              });
            }}
          />
        </Col>
      </Row>
      <Row style={{ margin: 0, marginTop: 30 }}>
        <Col xs="12">
          <TextField
            label={
              <div>
                Removed/substituted energy, volume or fuel
                type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
            }
            multiline
            rows={3}
            value={
              props.data.net_zero_removed_energy
                ? props.data.net_zero_removed_energy
                : ""
            }
            inputProps={inputProps}
            InputLabelProps={InputLabelProps}
            style={{ width: "100%" }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: {
                  key: "net_zero_removed_energy",
                  value: e.target.value,
                },
              });
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(NetZeroClassification);
