import {
  SET_OPENED_CONTRACT,
  UPDATE_OPENED_CONTRACT,
  RESET_OPENED_CONTRACT,
} from "../actions/types";

const initialState = {};

export default function toolbar(state = initialState, action) {
  switch (action.type) {
    case SET_OPENED_CONTRACT:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_OPENED_CONTRACT:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case RESET_OPENED_CONTRACT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
