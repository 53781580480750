import { getYearlyEmissionAction } from "./getYearlyEmissionAction"

// compute the average of co2 equivalent emission avoided per year per gas
// example: start_date 01-06-2020, end date 31-12-2021, co2 emission = 1000 t/y, then the output will be 750 (always in t/y)

export const computeAvgEmission = (action, start_date_wp, end_date_wp) => {

    const gases = action.emissions.map(obj => obj.gas)

    gases.forEach(gas => {

        var result = getYearlyEmissionAction(action, gas, "emissions_avoided", start_date_wp, end_date_wp)
        var result_in_co2_eq = getYearlyEmissionAction(action, gas, "co2_equivalent_avoided", start_date_wp, end_date_wp)

        var final_average = Object.values(result).reduce((a, b) => a + b, 0) / Object.keys(result).length
        var final_average_in_co2_equivalent = Object.values(result_in_co2_eq).reduce((a, b) => a + b, 0) / Object.keys(result_in_co2_eq).length

        //  final_average can be NaN when len(unique years) = 0 (this happens when the grouped action has more actions inside)
        if (String(final_average) !== "NaN") {
            action[`average_yearly_emission_avoided_${gas}`] = final_average
        } else {
            action[`average_yearly_emission_avoided_${gas}`] = 0
        }
        if (String(final_average_in_co2_equivalent) !== "NaN") {
            action[`average_yearly_emission_avoided_${gas}_in_co2_equivalent`] = final_average_in_co2_equivalent
        } else {
            action[`average_yearly_emission_avoided_${gas}_in_co2_equivalent`] = 0
        }

    })
    return action
}