import React, { useEffect, useState, useRef } from "react";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { connect } from "react-redux";
import { getHeightWithFooterWONavbar } from "../../../../utils/getHeightWithFooterWONavbar";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import { FaSpinner, FaPlus } from "react-icons/fa";
import axios from "axios";
import { API_URL, GET_ERRORS } from "../../../../actions/types";
import { tokenConfig } from "../../../../utils/tokenConfig";
import ActionInventory from "./ActionInventory";
import DownloadJson from "./DownloadJson";
import DownloadPython from "./DownloadPython";
import SuccessAlert from "../../../../components/Modals/SuccessAlert";
import ErrorAlert from "../../../../components/Modals/ErrorAlert";
import { triggerRefresh } from "../../../../utils/triggerRefresh";
import { applyTransactionFn } from "../../../../utils/applyTransactionFn";
import ModalUploadActionType from "./ModalUploadActionType/ModalUploadActionType";

function Content(props) {
  const [rowData, setRowData] = useState(null);
  const [isOpenCreate, setIsOpenCreate] = useState(false)
  const { width, height } = useWindowDimensions();

  // grid ref
  const myGrid = useRef()

  // define function to get data
  const getData = async () => {
    const res = await axios.get(
      `${API_URL}/clicc/actions_inventory/`,
      tokenConfig()
    );
    return res.data.map((obj, index) => {
      return {
        ...obj,
        count: index + 1
      }
    })
  }

  // on did mount, get data
  useEffect(async () => {
    setRowData(await getData())
  }, [])

  // monitor refresh
  useEffect(() => {
    const channel = new BroadcastChannel("clicc-channel");
    channel.addEventListener("message", async e => {
      if (e.data === "refresh") {
        // get data and apply changes
        const newRows = await getData();
        const arr_checks = ["id"];
        if (newRows.length >= 1) {
          applyTransactionFn(myGrid, newRows, arr_checks, true);
        }
      }
    });
    return () => channel.close()
  }, [])

  async function readText(event) {
    let file = event.target.files[0]; // FileList object
    let reader = new FileReader();
    reader.readAsText(file);
    reader.onload = async () => {
      try {
        await axios.post(
          `${API_URL}/clicc/actions_inventory/`,
          JSON.parse(reader.result),
          tokenConfig()
        );
        SuccessAlert("JSON uploaded successfully");
        triggerRefresh()
        event.target.value = "";
      } catch (err) {
        event.target.value = "";
        ErrorAlert(
          "An error occurred, make sure that the ID is not a duplicate"
        );
      }
    };
  }

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#E4E6E8",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        paddingLeft: props.layout.width_sidebar,
        paddingBottom: props.layout.footer_height,
        overflow: "auto",
      }}
    >
      {/* workspace without padding */}
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 20,
        }}
      >
        {/* workspace */}
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          {rowData === null ? (
            <>
              <FaSpinner
                className="spinner"
                style={{ marginTop: "-2px", marginRight: 5 }}
              ></FaSpinner>
              Loading...
            </>
          ) : (
            <>
              {/* <input
                type="file"
                accept=".json"
                onChange={(e) => readText(e)}
              ></input> */}
              <div
                style={{ fontFamily: "Montserrat", fontSize: 20 }}
              >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    Action types
                  </div>
                  <div style={{ display: "flex" }}>
                    <div
                      onClick={() => setIsOpenCreate(true)}
                      style={{
                        width: "fit-content",
                        backgroundColor: props.layout.color_sidebar,
                        color: "white",
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                        fontSize: 16,
                        padding:
                          width < 770 ? "5px 6px 3px 6px" : "6px 15px 4px 15px",
                        borderRadius: 10,
                        cursor: "pointer",
                        marginTop: "-5px",
                        marginBottom: 10
                      }}
                    >
                      <FaPlus
                        style={{
                          marginTop: "-3px",
                          marginRight: 5
                        }}
                      ></FaPlus> Create
                    </div>
                  </div>

                </div>
              </div>


              <div
                className="excel-style ag-theme-balham"
                style={{
                  height: height - 220,
                }}
              >
                <AgGridReact
                  ref={myGrid}
                  rowData={rowData}
                  frameworkComponents={{
                    actionInventory: ActionInventory,
                    downloadJson: DownloadJson,
                    downloadPython: DownloadPython,
                  }}
                  defaultColDef={{
                    flex: 1,
                    resizable: true,
                    filter: true,
                    filterParams: { buttons: ["reset"], newRowsAction: "keep", caseSensitive: true },
                    cellStyle: (params) => {
                      var style = {
                        cursor: "default",
                        display: "flex",
                        alignItems: "center",
                      };
                      if (params.column.colId === "status") {
                        style["justifyContent"] = "center";
                      }
                      return style;
                    },
                  }}
                >
                  <AgGridColumn
                    field=""
                    minWidth={50}
                    maxWidth={50}
                    suppressMenu={true}
                    cellRenderer="actionInventory"
                  ></AgGridColumn>
                  <AgGridColumn
                    field="count"
                    headerName="#"
                    sort="desc"
                    sortable={false}
                    suppressMenu={true}
                    maxWidth={rowData.length > 99 ? 35 : 30}
                  ></AgGridColumn>
                  <AgGridColumn
                    field="id"
                    flex={0.8}
                  ></AgGridColumn>
                  <AgGridColumn
                    field="subActionName"
                    headerName="Name"
                  ></AgGridColumn>
                  <AgGridColumn
                    field="subject_1"
                    headerName="Subject 1"
                  ></AgGridColumn>
                  <AgGridColumn
                    field="subject_2"
                    headerName="Subject 2"
                  ></AgGridColumn>
                  <AgGridColumn
                    field="subActionDescription"
                    headerName="Description"
                    hide={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    field=""
                    headerName="Json"
                    minWidth={80}
                    maxWidth={80}
                    suppressMenu={true}
                    cellRenderer="downloadJson"
                  ></AgGridColumn>
                  <AgGridColumn
                    field=""
                    headerName="Python"
                    minWidth={80}
                    maxWidth={80}
                    suppressMenu={true}
                    cellRenderer="downloadPython"
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </>
          )}
        </div>
      </div>

      {isOpenCreate === true ?
        <ModalUploadActionType
          isOpen={isOpenCreate}
          setIsOpen={setIsOpenCreate}
        ></ModalUploadActionType>
        : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Content);
