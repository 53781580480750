import React from "react";
import Alert from "./Alert";
import IsLoading from "./IsLoading";

export const ModalsListener = () => {
  return (
    <React.Fragment>
      <Alert></Alert>
      <IsLoading></IsLoading>
    </React.Fragment>
  );
};

export default ModalsListener;
