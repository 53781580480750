import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import TitleCard from "../../../../../components/TitleCard/TitleCard";
import { useWindowDimensions } from "../../../../../utils/useWindowDimensions";
import { FaPlus, FaInfoCircle, FaTrash, FaChartPie } from "react-icons/fa";
import ModalAddPathway from "./ModalAddPathway";
import { AgGridReact } from "ag-grid-react";
import { applyTransactionFn } from "../../../../../utils/applyTransactionFn";
import { cloneDeep } from "lodash";
import ActionRenderer from "./ActionRenderer";
import { Row, Col, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { ModalFooter } from "reactstrap";
import SuccessAlert from "../../../../../components/Modals/SuccessAlert";
import { roundAtMost2Digits } from "../../../../../utils/roundAtMost2Digits";
import { getYearlyEmissionGroupedAction } from "../../../../../utils/getYearlyEmissionGroupedAction";
import { getYearlyEmissionAction } from "../../../../../utils/getYearlyEmissionAction";
import DateColumns from "./DateColumns";
import { TextField, Select, MenuItem, Switch, FormControlLabel, } from "@mui/material";
import { NumericFormat } from "react-number-format";
import Tooltip from "@mui/material/Tooltip";
import ModalDashboard from "./ModalDashboard";




function Pathway(props) {
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenDashboard, setIsOpenDashboard] = useState(false);
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();

  const toggleDelete = () => setIsOpenDelete(!isOpenDelete);
  const toggleDashboard = () => setIsOpenDashboard(!isOpenDashboard);


  const inputProps = {
    style: {
      fontWeight: 500,
      fontFamily: "Montserrat",
      cursor: "not-allowed",
      marginTop: "-5px"
    },
  };

  const InputLabelProps = {
    style: {
      fontWeight: 500,
      fontFamily: "Montserrat",
    },
  };

  const ccs_list = [
    {
      key: "CCE",
      name: "Carbon Content of Electricity",
      uom: "kgCO2/kWh"
    },
    {
      key: "CCG",
      name: "Carbon Content of Gas",
      uom: "kgCO2/kWh"
    },
    {
      key: "CCDH",
      name: "Carbon Content of District Heating",
      uom: "kgCO2/kWh"
    },
  ]

  const getData = async () => {
    var current_pathway = props.data.pathways.find(
      (p) => p.id === props.data.displayed_pathway
    );
    var rows;
    var emission_types = ["co2_equivalent_avoided", "emissions_avoided"]
    // initialize yearly emissions (used later for totals)
    var yearly_emissions = {}
    var yearly_emissions_by_id = {}
    const generate_key = (gas, emission_type) => {
      var key_name = `average_yearly_emission_avoided_${gas}`
      if (emission_type === "co2_equivalent_avoided") {
        key_name += "_in_co2_equivalent"
      }
      return key_name
    }
    props.data.gases.forEach(gas => {
      emission_types.forEach(emission_type => {
        yearly_emissions[generate_key(gas, emission_type)] = {}
        yearly_emissions_by_id[generate_key(gas, emission_type)] = {}
      })
    })

    var yearly_values
    if (current_pathway !== undefined) {
      rows = [];
      var rowsToIterate = cloneDeep(current_pathway.actions)

      // check if cc_decarbonization is available in  props.myActions
      var cc_decarbonization_row = props.myActions.find(obj => obj.id === "global_impact")
      if (cc_decarbonization_row !== undefined) {
        rowsToIterate.push(cc_decarbonization_row)
      }

      rowsToIterate.forEach((action_redux, index) => {
        var row
        if (action_redux.id === "global_impact") {
          row = cloneDeep(action_redux);
        } else {
          row = cloneDeep(
            props.myActions.find((obj) => obj.id === action_redux.action_id)
          );
        }
        if (row !== undefined) {
          // compute yearly emissions and add values to yearly_emissions
          props.data.gases.forEach(gas => {
            emission_types.forEach(emission_type => {
              if (row.actions !== undefined) {
                yearly_values = getYearlyEmissionGroupedAction(
                  row["actions_list"],
                  gas,
                  emission_type,
                  props.data.start_date,
                  props.data.end_date
                )
              } else {
                yearly_values = getYearlyEmissionAction(
                  row,
                  gas,
                  emission_type,
                  props.data.start_date,
                  props.data.end_date
                )
              }
              yearly_emissions_by_id[generate_key(gas, emission_type)][action_redux.id] = yearly_values
              Object.keys(yearly_values).forEach(year => {
                if (yearly_emissions[generate_key(gas, emission_type)][year] === undefined) {
                  yearly_emissions[generate_key(gas, emission_type)][year] = yearly_values[year]
                } else {
                  yearly_emissions[generate_key(gas, emission_type)][year] += yearly_values[year]
                }
              })
            })
          })
          // push row
          rows.push({
            ...row,
            action_id: row.id,
            id: action_redux.id,
          });
        }
      });
    }

    if (rows !== undefined) {
      var sum_key
      var overall_sum
      var num_years
      var perc_value
      // compute totals abs
      var totals_abs = {}
      props.data.gases.forEach(gas => {
        emission_types.forEach(emission_type => {
          sum_key = Object.values(yearly_emissions[generate_key(gas, emission_type)]).reduce((a, b) => a + b, 0)
          num_years = Object.keys(yearly_emissions[generate_key(gas, emission_type)]).length
          totals_abs[generate_key(gas, emission_type)] = sum_key / num_years
        })
      })

      // compute percentages for each row
      props.data.gases.forEach(gas => {
        emission_types.forEach(emission_type => {
          rows.forEach(row => {
            sum_key = Object.values(yearly_emissions_by_id[generate_key(gas, emission_type)][row.id]).reduce((a, b) => a + b, 0)
            overall_sum = Object.values(yearly_emissions[generate_key(gas, emission_type)]).reduce((a, b) => a + b, 0)
            // check if NaN, that is when 0/0
            if (String(sum_key / overall_sum) === "NaN") {
              perc_value = 0
            } else {
              perc_value = sum_key / overall_sum
            }
            row[`${generate_key(gas, emission_type)}_perc`] = perc_value
          })
        })
      })

      // add total row
      var total = {
        id: "total",
        name: "Total",
      };
      props.data.gases.forEach(gas => {
        emission_types.forEach(emission_type => {
          total[generate_key(gas, emission_type)] = totals_abs[generate_key(gas, emission_type)]
          total[`${generate_key(gas, emission_type)}_perc`] = 1
        })
      })
      rows.push(total);
    }

    return rows;
  };

  // hide gases with 0 emission (except for co2)
  const checkHideColumns = (rows) => {
    props.data.gases
      .filter(gas => gas !== "co2")
      .forEach(gas => {
        var sum_gas = rows.reduce((acc, currentValue) => {
          return acc + currentValue[`average_yearly_emission_avoided_${gas}`];
        }, 0);
        var list_keys = [
          `average_yearly_emission_avoided_${gas}`,
          `average_yearly_emission_avoided_${gas}_perc`,
          `average_yearly_emission_avoided_${gas}_in_co2_equivalent`,
          `average_yearly_emission_avoided_${gas}_in_co2_equivalent_perc`,
        ]
        if (sum_gas === 0) {
          list_keys.forEach(k => {
            grid.current.columnApi.setColumnsVisible([k], false);
          })
        } else {
          list_keys.forEach(k => {
            grid.current.columnApi.setColumnsVisible([k], true);
          })
        }
      })
  }

  const grid = useRef();
  const mounted = useRef();
  useEffect(async () => {
    if (!mounted.current) {
      const res = await getData()
      setRowData(res);
      checkHideColumns(res)
      mounted.current = true;
    } else {
      const newRows = await getData();
      const arr_checks = ["id"];
      if (newRows !== undefined && newRows.length >= 1) {
        checkHideColumns(newRows)
        applyTransactionFn(grid, newRows, arr_checks, true);
      }
    }
  }, [props.myActions, props.data.displayed_pathway]);

  const absFormatting = (params) => {
    return params.data[params.colDef.field].toLocaleString("eng", {
      maximumFractionDigits: 2,
    })
  }
  const percFormatting = (params) => {
    return `${roundAtMost2Digits(params.data[params.colDef.field] * 100)}%`
  }


  return (
    <Col
      xxl={width > 1800 ? "10" : "9"}
      xl="9"
      lg="9"
      md="8"
      sm="12"
      style={{
        marginBottom: 120,
        padding: 0,
      }}
    >
      <div
        style={{
          width: "100%",
          padding: 10,
          height: height < 850 ? height * 0.69 : height * 0.74,
        }}
      >
        <div
          style={{
            ...props.layout.generic_card,
            width: "100%",
            position: "relative",
            display: "flex",
            flexFlow: "column",
            height: "100%",
          }}
        >
          <TitleCard
            title={
              props.data.displayed_pathway === null ? (
                "Select pathway"
              ) : (
                <div>
                  <Select
                    variant="standard"
                    disableUnderline={true}
                    value={props.data.displayed_pathway}
                    style={{
                      fontFamily: "Montserrat",
                    }}
                    onChange={(e) => {
                      dispatch({
                        type: props.type,
                        payload: {
                          key: "displayed_pathway",
                          value: e.target.value,
                        },
                      });
                    }}
                  >
                    {props.data.pathways.map((p) => {
                      return (
                        <MenuItem
                          value={p.id}
                          style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                        >
                          {p.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              )
            }
          ></TitleCard>
          {props.data.displayed_pathway !== null ?
            <div
              onClick={toggleDashboard}
              style={{
                width: "fit-content",
                color: "#1976d2",
                border: "1px solid #1976d2",
                fontFamily: "Montserrat",
                fontWeight: 500,
                fontSize: 16,
                padding: width < 1000 ? "5px 8px 3px 8px" : "6px 20px 4px 20px",
                borderRadius: 10,
                cursor: "pointer",
                position: "absolute",
                top: 15,
                right: width < 1000 ? 110 : 260,
              }}
            >
              <FaChartPie
                style={{ marginTop: "-3px", marginRight: width < 1000 ? 0 : 10 }}
              ></FaChartPie >
              {width < 1000 ? null : "Dashboard"}

            </div>
            : null}

          {props.data.displayed_pathway !== null ?
            <div
              onClick={toggleDelete}
              style={{
                width: "fit-content",
                // backgroundColor: props.layout.color_sidebar,
                color: "#dc3545",
                border: "1px solid #dc3545",
                fontFamily: "Montserrat",
                fontWeight: 500,
                fontSize: 16,
                padding: width < 1000 ? "5px 8px 3px 8px" : "6px 20px 4px 20px",
                borderRadius: 10,
                cursor: "pointer",
                position: "absolute",
                top: 15,
                right: width < 1000 ? 65 : 130,
              }}
            >
              <FaTrash
                style={{ marginTop: "-3px", marginRight: width < 1000 ? 0 : 10 }}
              ></FaTrash>
              {width < 1000 ? null : "Delete"}
            </div>
            : null}

          <div
            onClick={() => setIsOpenAdd(true)}
            style={{
              width: "fit-content",
              backgroundColor: props.layout.color_sidebar,
              border: `1px solid ${props.layout.color_sidebar}`,
              color: "white",
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: 16,
              padding: width < 1000 ? "5px 8px 3px 8px" : "6px 20px 4px 20px",
              borderRadius: 10,
              cursor: "pointer",
              position: "absolute",
              top: 15,
              right: 20,
            }}
          >
            <FaPlus
              style={{ marginTop: "-3px", marginRight: width < 1000 ? 0 : 10 }}
            ></FaPlus>
            {width < 1000 ? null : "Add"}
          </div>
          {props.data.displayed_pathway ===
            null ? null : props.data.pathways.find(
              (p) => p.id === props.data.displayed_pathway
            ).actions.length === 0 ? (
            <div
              style={{ fontFamily: "Montserrat", fontWeight: 500, padding: 15 }}
            >
              Add actions from the left panel
            </div>
          ) : (
            <>
              <div
                className="excel-style ag-theme-balham"
                style={{
                  height: "100%",
                  padding: "15px 15px 0px 15px",
                }}
              >
                <AgGridReact
                  ref={grid}
                  rowData={rowData}
                  suppressColumnVirtualisation={true}
                  frameworkComponents={{
                    actionRenderer: ActionRenderer,
                    dateColumns: DateColumns
                  }}
                  defaultColDef={{
                    flex: 1,
                    minWidth: 100,
                    resizable: true,
                    filter: true,
                    filterParams: {
                      buttons: ["reset"],
                      newRowsAction: "keep",
                      caseSensitive: true,
                    },
                    suppressMenu: true,

                    cellStyle: (params) => {
                      var style = {
                        cursor: "default",
                        display: "flex",
                        alignItems: "center",
                      };
                      if (params.column.colId !== "name") {
                        style["justifyContent"] = "center";
                      }
                      if (params.data.id === "total") {
                        style["fontWeight"] = "bold";
                        style["backgroundColor"] = "rgba(217, 217, 217, 0.5)";
                      }
                      return style;
                    },
                  }}
                  enableRangeSelection={true}
                  suppressMovableColumns={true}
                  columnDefs={[
                    {
                      headerName: "",
                      children: [
                        {
                          field: "name",
                          headerName: "Actions",
                          suppressMenu: false,
                          cellClass: "darker-border",
                          headerClass: "darker-border",
                          minWidth: 270,
                          cellRenderer: "actionRenderer",
                          cellRendererParams: {
                            data_redux: props.data,
                            type: props.type,
                          },
                          // add sort and comparator to always place Decarbonization due to CCs variations and Total row at the bottom
                          comparator: (
                            valueA,
                            valueB,
                            nodeA,
                            nodeB,
                            isDescending
                          ) => {
                            if (valueA === "Decarbonization due to CCs variations" && valueB === "Total") {
                              return 1; // "Decarbonization due to CCs variations" should come before "Total"
                            } else if (valueA === "Total" && valueB === "Decarbonization due to CCs variations") {
                              return -1; // "Total" should come after "Decarbonization due to CCs variations"
                            } else if (valueA === "Decarbonization due to CCs variations" || valueA === "Total") {
                              return -1; // "Decarbonization due to CCs variations" and "Total" should come after all others
                            } else if (valueB === "Decarbonization due to CCs variations" || valueB === "Total") {
                              return 1; // All others should come before "Decarbonization due to CCs variations" and "Total"
                            }
                            return 0;
                          },
                          sort: "desc",
                        },
                      ],
                    },
                    {
                      headerName: "Date",
                      children: [
                        {
                          field: "start_date",
                          headerName: "start",
                          cellRenderer: 'dateColumns',
                          cellRendererParams: {
                            allProps: props,
                          }
                        },
                        {
                          field: "end_date",
                          headerName: "end",
                          cellClass: "darker-border",
                          headerClass: "darker-border",
                          cellRenderer: 'dateColumns',
                          cellRendererParams: {
                            allProps: props,
                          }
                        },
                      ],
                    },
                    {
                      headerName: "CO2",
                      children: [
                        {
                          field: "average_yearly_emission_avoided_co2",
                          headerName: "t/y",
                          valueFormatter: absFormatting,
                        },
                        {
                          field: "average_yearly_emission_avoided_co2_perc",
                          headerName: "%",
                          cellClass: "darker-border",
                          headerClass: "darker-border",
                          valueFormatter: percFormatting,
                        },
                      ],
                    },
                    {
                      headerName: "N2O",
                      children: [
                        {
                          field: "average_yearly_emission_avoided_n2o",
                          headerName: "t/y",
                          valueFormatter: absFormatting,
                        },
                        {
                          field: "average_yearly_emission_avoided_n2o_in_co2_equivalent",
                          headerName: "CO2 eq. [t/y]",
                          valueFormatter: absFormatting,
                        },
                        {
                          field: "average_yearly_emission_avoided_n2o_in_co2_equivalent_perc",
                          headerName: "%",
                          cellClass: "darker-border",
                          headerClass: "darker-border",
                          valueFormatter: percFormatting,
                        },
                      ],
                    },
                    {
                      headerName: "F-gas",
                      children: [
                        {
                          field: "average_yearly_emission_avoided_f_gas",
                          headerName: "t/y",
                          valueFormatter: absFormatting,
                        },
                        {
                          field: "average_yearly_emission_avoided_f_gas_in_co2_equivalent",
                          headerName: "CO2 eq. [t/y]",
                          valueFormatter: absFormatting,
                        },
                        {
                          field: "average_yearly_emission_avoided_f_gas_in_co2_equivalent_perc",
                          headerName: "%",
                          cellClass: "darker-border",
                          headerClass: "darker-border",
                          valueFormatter: percFormatting,
                        },
                      ],
                    },
                    {
                      headerName: "SF6",
                      children: [
                        {
                          field: "average_yearly_emission_avoided_sf6",
                          headerName: "t/y",
                          valueFormatter: absFormatting,
                        },
                        {
                          field: "average_yearly_emission_avoided_sf6_in_co2_equivalent",
                          headerName: "CO2 eq. [t/y]",
                          valueFormatter: absFormatting,
                        },
                        {
                          field: "average_yearly_emission_avoided_sf6_in_co2_equivalent_perc",
                          headerName: "%",
                          cellClass: "darker-border",
                          headerClass: "darker-border",
                          valueFormatter: percFormatting,
                        },
                      ],
                    },
                    {
                      headerName: "NF3",
                      children: [
                        {
                          field: "average_yearly_emission_avoided_nf3",
                          headerName: "t/y",
                          valueFormatter: absFormatting,
                        },
                        {
                          field: "average_yearly_emission_avoided_nf3_in_co2_equivalent",
                          headerName: "CO2 eq. [t/y]",
                          valueFormatter: absFormatting,
                        },
                        {
                          field: "average_yearly_emission_avoided_nf3_in_co2_equivalent_perc",
                          headerName: "%",
                          cellClass: "darker-border",
                          headerClass: "darker-border",
                          valueFormatter: percFormatting,
                        },
                      ],
                    },
                    {
                      headerName: "CH4",
                      children: [
                        {
                          field: "average_yearly_emission_avoided_ch4",
                          headerName: "t/y",
                          valueFormatter: absFormatting,
                        },
                        {
                          field: "average_yearly_emission_avoided_ch4_in_co2_equivalent",
                          headerName: "CO2 eq. [t/y]",
                          valueFormatter: absFormatting,
                        },
                        {
                          field: "average_yearly_emission_avoided_ch4_in_co2_equivalent_perc",
                          headerName: "%",
                          cellClass: "darker-border",
                          headerClass: "darker-border",
                          valueFormatter: percFormatting,
                        },
                      ],
                    },
                  ]}
                ></AgGridReact>
              </div>
              <Row style={{
                padding: 15,
                height: "12%",
              }}>
                <Col xs="9" style={{ display: "flex" }}>
                  {ccs_list.map((cc, index) => {
                    return <NumericFormat
                      disabled={!props.data.use_motherfunctions_ccs}
                      variant="standard"
                      value={props.data.CCs[cc.key] === undefined ? 0 : Math.round((props.data.CCs[cc.key] + Number.EPSILON) * 100000) / 100000}
                      customInput={TextField}
                      // prevent user from typing when manual_values === false
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      label={<div style={{ display: "flex" }}>{cc.key} [{cc.uom}]{<Tooltip title={props.data.use_motherfunctions_ccs === true ? `${cc.name}. This value is automatically comuputed based on the actions inserted above and it's expressed in ${cc.uom}` : 'When disabled, for each action the CC value is taken as input manually inserted by the user in the "Specify" tab'}><div style={{ marginTop: "-3px" }}><FaInfoCircle style={{
                        marginTop: "-7px", fontSize: 12, color: props.data.use_motherfunctions_ccs === true ? "#2072DE" : "gray",
                        marginLeft: 4
                      }}></FaInfoCircle></div></Tooltip>}</div>}
                      inputProps={inputProps}
                      InputLabelProps={InputLabelProps}
                      style={{
                        width: 150,
                        marginLeft: index > 0 ? 10 : 0
                      }}
                    />
                  })}


                </Col>
                <Col xs="3" style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end"
                }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={props.data.use_motherfunctions_ccs}
                        onChange={async (e) => {
                          dispatch({
                            type: props.type,
                            payload: {
                              key: "use_motherfunctions_ccs",
                              value: e.target.checked,
                            },
                          });
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        style={{ fontFamily: "Montserrat" }}
                      />
                    }
                    label={
                      <div style={{ fontFamily: "Montserrat", fontWeight: 500 }}>
                        Compute CCs
                      </div>
                    }
                  />
                </Col>
              </Row>

            </>
          )}
        </div>
      </div>
      {/* delete pathway modal */}
      <Modal
        isOpen={isOpenDelete}
        style={{
          fontFamily: "Montserrat",
          fontWeight: 500,
        }}
      >
        <ModalHeader>Attention</ModalHeader>
        <ModalBody>
          Do you really want to delete the selected pathway?
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => {
              var idToSet = null;
              var idToDelete = cloneDeep(props.data.displayed_pathway);
              props.data.pathways.forEach((p) => {
                if (p.id !== props.data.displayed_pathway) {
                  idToSet = p.id;
                }
              });
              dispatch({
                type: props.type,
                payload: {
                  key: "pathways",
                  value: props.data.pathways.filter(
                    (p) => p.id !== props.data.displayed_pathway
                  ),
                },
              });
              dispatch({
                type: props.type,
                payload: { key: "displayed_pathway", value: idToSet },
              });
              // remove also the object for the following keys
              ["fields_of_action", "systemic_lever", "co_benefit"].forEach(
                (k) => {
                  dispatch({
                    type: props.type,
                    payload: {
                      key: k,
                      value: props.data[k].filter(
                        (obj) => obj.id !== idToDelete
                      ),
                    },
                  });
                }
              );
              toggleDelete();
              SuccessAlert("Pathway removed successfully");
            }}
          >
            Delete
          </Button>
          <Button color="secondary" onClick={toggleDelete}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      {/* modal dashboard */}

      {isOpenDashboard === true ?
        <ModalDashboard
          isOpen={isOpenDashboard}
          toggle={toggleDashboard}
          layout={props.layout}
          allActions={() => {
            var listActions = []
            var findAction
            props.data.pathways.forEach(pathway => {
              if (pathway.id === props.data.displayed_pathway) {
                pathway.actions.forEach(action_pt => {
                  if (!action_pt.id.includes("grouped")) {
                    findAction = props.myActions
                      .filter(action => action.actions_list === undefined)
                      .find(action => action.id === action_pt.action_id)
                    listActions.push(findAction)
                  } else {
                    findAction = props.myActions
                      .filter(action => action.actions_list !== undefined)
                      .find(action => action.id === action_pt.action_id)
                    if (findAction !== undefined) {
                      listActions.push(...findAction.actions_list)
                    }
                  }
                })
              }
            })
            return listActions
          }}
        >
        </ModalDashboard> : null}

      {/* add pathway modal */}
      <ModalAddPathway
        data={props.data}
        isOpen={isOpenAdd}
        setIsOpen={setIsOpenAdd}
        type={props.type}
      ></ModalAddPathway>
    </Col>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Pathway);
