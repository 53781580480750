import { TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import React from "react";
import { useDispatch } from "react-redux";

function DateRenderer(props) {
  const dispatch = useDispatch();
  return (
    <div>
      {props.data.id !== "total" ? (
        <div
          style={{
            width: "100%",
          }}
        >
          <DesktopDatePicker
            inputFormat="DD-MM-YYYY"
            value={props.data[props.column.colId]}
            onChange={(newDate) => {
              dispatch({
                type: props.type,
                payload: {
                  key: "pathways",
                  value: props.data_redux.pathways.map((p) => {
                    if (p.id !== props.data_redux.displayed_pathway) {
                      return p;
                    } else {
                      return {
                        ...p,
                        sub_actions: p.sub_actions.map((a) => {
                          if (a.id !== props.data.id) {
                            return a;
                          } else {
                            return {
                              ...a,
                              [props.column.colId]:
                                newDate === null || !newDate.isValid()
                                  ? null
                                  : newDate.format("YYYY-MM-DD"),
                            };
                          }
                        }),
                      };
                    }
                  }),
                },
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                className="datePickerMontserrat"
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontSize: 12,
                  },
                }}
              />
            )}
          />
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            cursor: "not-allowed",
            textAlign: "center",
          }}
        >
          --
        </div>
      )}
    </div>
  );
}

export default DateRenderer;
