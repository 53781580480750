import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { LicenseManager } from "ag-grid-enterprise";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "ol/ol.css";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

LicenseManager.setLicenseKey(
  "CompanyName=GDP ANALYTICS SRL,LicensedGroup=GDP Analytics DevTeam,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=6,AssetReference=AG-014593,ExpiryDate=26_March_2022_[v2]_MTY0ODI1MjgwMDAwMA==0d9e6cfe1142113ddca4383abf1d51de"
);

ReactDOM.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <App />
    </LocalizationProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
