import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
} from "reactstrap";
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import SuccessAlert from "../../../../../../../../components/Modals/SuccessAlert";

function AddUpdateModal(props) {
  const dispatch = useDispatch();
  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        size="lg"
        style={{
          fontFamily: "Montserrat",
          fontWeight: 500,
        }}
      >
        <ModalHeader toggle={props.toggle}>Add new row</ModalHeader>
        <ModalBody>
          <Row style={{ margin: 0 }}>
            <Col lg="4" md="12">
              <TextField
                variant="standard"
                inputProps={{
                  style: {
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                  },
                }}
                label={<div style={{
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                }}>Macro Area</div>}
                value={props.macroArea}
                onChange={(e) => {
                  props.setMacroArea(e.target.value);
                }}
              />
            </Col>
            <Col lg="4" md="12">
              <TextField
                variant="standard"
                inputProps={{
                  style: {
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                  },
                }}
                label={<div style={{
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                }}>Sector</div>}
                value={props.settore}
                onChange={(e) => {
                  props.setSettore(e.target.value);
                }}
              />
            </Col>
            <Col lg="4" md="12">
              <TextField
                variant="standard"
                inputProps={{
                  style: {
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                  },
                }}
                label={<div style={{
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                }}>Subsector</div>}
                value={props.sottosettore}
                onChange={(e) => {
                  props.setSottosettore(e.target.value);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={
              props.data_redux.baseline_areas.filter(
                (obj) =>
                  props.macroArea === obj.macro_area &&
                  props.settore === obj.settore &&
                  props.sottosettore === obj.sottosettore
              ).length !== 0 || props.macroArea === ""
            }
            color={props.update ? "primary" : "success"}
            onClick={() => {
              if (props.update === true) {
                dispatch({
                  type: props.type,
                  payload: {
                    key: "baseline_areas",
                    value: props.data_redux.baseline_areas.map((obj) => {
                      if (
                        obj.macro_area === props.data.macro_area &&
                        obj.settore === props.data.settore &&
                        obj.sottosettore === props.data.sottosettore
                      ) {
                        return {
                          macro_area: props.macroArea,
                          settore: props.settore,
                          sottosettore: props.sottosettore,
                        };
                      } else return obj;
                    }),
                  },
                });
                props.data_redux.gases.forEach((gas) => {
                  dispatch({
                    type: props.type,
                    payload: {
                      key: "baseline_ghg_" + gas,
                      value: props.data_redux["baseline_ghg_" + gas].map(
                        (obj) => {
                          if (
                            obj.macro_area === props.data.macro_area &&
                            obj.settore === props.data.settore &&
                            obj.sottosettore === props.data.sottosettore
                          ) {
                            return {
                              ...obj,
                              macro_area: props.macroArea,
                              settore: props.settore,
                              sottosettore: props.sottosettore,
                            };
                          } else return obj;
                        }
                      ),
                    },
                  });
                });
                dispatch({
                  type: props.type,
                  payload: {
                    key: "baseline_co2_equivalente",
                    value: props.data_redux.baseline_co2_equivalente.map(
                      (obj) => {
                        if (
                          obj.macro_area === props.data.macro_area &&
                          obj.settore === props.data.settore &&
                          obj.sottosettore === props.data.sottosettore
                        ) {
                          return {
                            ...obj,
                            macro_area: props.macroArea,
                            settore: props.settore,
                            sottosettore: props.sottosettore,
                          };
                        } else return obj;
                      }
                    ),
                  },
                });
                props.toggle();
                SuccessAlert("Done!");
              } else {
                dispatch({
                  type: props.type,
                  payload: {
                    key: "baseline_areas",
                    value: [
                      ...props.data_redux.baseline_areas,
                      {
                        macro_area: props.macroArea,
                        settore: props.settore,
                        sottosettore: props.sottosettore,
                      },
                    ],
                  },
                });
                var newObj = {};
                Object.keys(
                  props.data_redux.baseline_co2_equivalente[0]
                ).forEach((v) => (newObj[v] = 0));
                props.data_redux.gases.forEach((gas) => {
                  dispatch({
                    type: props.type,
                    payload: {
                      key: "baseline_ghg_" + gas,
                      value: [
                        ...props.data_redux["baseline_ghg_" + gas],
                        {
                          ...newObj,
                          macro_area: props.macroArea,
                          settore: props.settore,
                          sottosettore: props.sottosettore,
                        },
                      ],
                    },
                  });
                });
                dispatch({
                  type: props.type,
                  payload: {
                    key: "baseline_co2_equivalente",
                    value: [
                      ...props.data_redux.baseline_co2_equivalente,
                      {
                        ...newObj,
                        macro_area: props.macroArea,
                        settore: props.settore,
                        sottosettore: props.sottosettore,
                      },
                    ],
                  },
                });
              }
              props.toggle();
              SuccessAlert("Done!");
            }}
          >
            {props.update ? "Update" : "Add"}
          </Button>
          <Button color="secondary" onClick={props.toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AddUpdateModal;
