import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Map, View } from "ol"
import { OSM, Vector as VectorSource } from 'ol/source.js';
import { Vector as VectorLayer, Tile as TileLayer } from 'ol/layer.js';
import { fromLonLat } from "ol/proj";
import { API_URL } from "../../../../../actions/types";
import { InputLabel, MenuItem, FormControl, Select, Tooltip } from "@mui/material"
import GeoJSON from 'ol/format/GeoJSON.js';
import { Fill, Stroke, Style, Icon } from 'ol/style.js';
import Point from 'ol/geom/Point.js';
import Feature from 'ol/Feature.js';
import PinIcon from './pin.png'
import Overlay from 'ol/Overlay.js';
import { renderToString } from "react-dom/server";
import { SlSizeFullscreen } from "react-icons/sl";
import { defaults as defaultInteractions } from 'ol/interaction.js';
import { FaInfoCircle } from "react-icons/fa";

const MappaDashboard = ({ actions, filteredActions, setFilteredActions }) => {
    const [geometries, setGeometries] = useState()
    const [fixedActions, setFixedAction] = useState([])
    const [suddivisione, setSuddivisione] = useState("quartieri")
    const suddivisioneRef = React.useRef(suddivisione);
    const mapRef = useRef();
    const containerMapRef = useRef();
    const popupRef = useRef();

    const [view] = useState(
        new View({
            projection: "EPSG:3857",
            center: fromLonLat([7.646, 45.07]),
            zoom: 11.2,
        })
    );
    const [features] = useState(
        new VectorSource()
    );
    const [selectedFeatures] = useState(
        new VectorSource()
    );
    const [actionsLayers] = useState(
        new VectorSource()
    );
    const [popup] = useState(
        new Overlay({
            positioning: 'bottom-center',
            stopEvent: false,
        })
    );

    const [map] = useState(
        new Map({
            target: null,
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
                new VectorLayer({
                    source: features,
                }),
                new VectorLayer({
                    source: selectedFeatures,
                    style: new Style({
                        stroke: new Stroke({
                            color: 'blue',
                            lineDash: [4],
                            width: 3,
                        }),
                        fill: new Fill({
                            color: 'rgba(0, 0, 255, 0.1)',
                        }),
                    })
                }),
                new VectorLayer({
                    source: actionsLayers,
                }),
            ],
            view: view,
            controls: [],
            interactions: defaultInteractions({
                doubleClickZoom: false,
            }),
        })
    );

    useEffect(() => {
        actionsLayers.clear()

        var fActions = []
        filteredActions.forEach(action => {
            if (action.long !== null) {
                const iconFeature = new Feature({
                    geometry: new Point(fromLonLat([action.long, action.lat])),
                    name: action.name,
                    start_date: action.start_date,
                    end_date: action.end_date,
                    total_invesment: Math.round(action.financials_total_investment),
                    co2_avoided: Math.round(action.emissions.find(em => em.gas === "co2").emissions_avoided)
                });
                iconFeature.setStyle(
                    new Style({
                        image: new Icon({
                            anchor: [0.5, 1],
                            anchorXUnits: 'fraction',
                            anchorYUnits: 'fraction',
                            scale: 0.1,
                            src: PinIcon,
                        }),
                    }),
                );
                actionsLayers.addFeature(iconFeature)
            } else {
                fActions.push(action)
            }
        })
        setFixedAction(fActions)
    }, [filteredActions])

    useEffect(() => {
        if (map.getTarget() !== null) return
        map.setTarget(mapRef.current);
        axios.get(API_URL + "/data/quartieri_torino_geometria/").then(response => {
            setGeometries(response.data)
        })
        popup.setElement(popupRef.current)

        const overlayContainer = document.createElement("div");
        overlayContainer.classList.add("ol-popup");
        const overlayCloser = document.createElement("a");
        overlayCloser.classList.add("ol-popup-closer");
        overlayContainer.appendChild(overlayCloser);
        const overlayContent = document.createElement("div");
        overlayContent.setAttribute("id", "overlayContent");
        overlayContainer.appendChild(overlayContent);



        const overlay = new Overlay({
            element: overlayContainer,
            autoPan: false,
        });
        map.addOverlay(overlay);

        overlayCloser.onclick = () => {
            overlay.setPosition(undefined)
        }

        map.on('click', function (evt) {
            const feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
                return feature;
            });
            const featureProperties = feature.getProperties()
            if (featureProperties.start_date !== undefined) {
                overlayContent.innerHTML = renderToString(
                    <div>
                        <h6 style={{ textAlign: "center", fontWeight: "bold" }}>{featureProperties.name}</h6>
                        <div style={{ display: 'flex', justifyContent: "space-between" }}><span>Start date:</span><span>{featureProperties.start_date}</span></div>
                        <div style={{ display: 'flex', justifyContent: "space-between" }}><span>End date:</span><span>{featureProperties.end_date}</span></div>
                        <div style={{ display: 'flex', justifyContent: "space-between" }}><span>CO2 avoided:</span><span>{featureProperties.co2_avoided}</span></div>
                        <div style={{ display: 'flex', justifyContent: "space-between" }}><span>Total invesment:</span><span>{featureProperties.total_invesment}</span></div>

                    </div>
                );
                overlay.setPosition(view.getCenter())
            } else {
                overlay.setPosition(undefined)
                if (selectedFeatures.getFeatureById(feature.getId()) === null) {
                    features.removeFeature(feature)
                    selectedFeatures.addFeature(feature)
                } else {
                    selectedFeatures.removeFeature(feature)
                    features.addFeature(feature)
                }
            }
        });
        selectedFeatures.on("change", (e) => {
            if (e.target.getFeatures().length === 0) {
                setFilteredActions([])
                return
            }
            let selectedNames
            switch (suddivisioneRef.current) {
                case 'torino':
                    setFilteredActions(actions)
                    break;
                case 'circoscrizioni':
                    selectedNames = e.target.getFeatures().map(feature => String(feature.get("nome")))
                    setFilteredActions(
                        actions.filter(action => {
                            if (action["action_scale_detail"] === null || action.long === null) return true
                            let found = false
                            Object.entries(action["action_scale_detail"]['Whole city']).forEach(([circosrizione, quartieri]) => {
                                if (selectedNames.includes(circosrizione)) {
                                    quartieri.forEach(quartiere => {
                                        if (quartiere.checked === true) {
                                            found = true
                                        }
                                    })
                                }
                            })
                            return found
                        })
                    )
                    break
                case 'quartieri':
                    selectedNames = e.target.getFeatures().reduce((dict, feature) => {
                        const circoscrizione = feature.get("circoscrizione")
                        const nome = feature.get("nome")
                        if (dict.hasOwnProperty(circoscrizione)) {
                            dict[circoscrizione].push(nome)
                        } else {
                            dict[circoscrizione] = [nome]
                        }
                        return dict
                    }, {})
                    setFilteredActions(
                        actions.filter(action => {
                            if (action["action_scale_detail"] === null || action.long === null) return true
                            let found = false
                            Object.entries(action["action_scale_detail"]['Whole city']).forEach(([circosrizione, quartieri]) => {
                                quartieri.forEach(quartiere => {
                                    if (quartiere.checked === true && selectedNames[circosrizione].includes(quartiere.nome)) {
                                        found = true
                                    }
                                })

                            })
                            return found
                        })
                    )
                    break
                default:
                    break;
            }
        })
    }, [])


    useEffect(() => {
        if (geometries === undefined) return
        features.clear()
        selectedFeatures.clear()
        const geoJSONFeatures = new GeoJSON({
            dataProjection: "EPSG:4326",
            featureProjection: "EPSG:3857"
        }).readFeatures(geometries[suddivisione])
        geoJSONFeatures.forEach((f, index) => f.setId(index))
        selectedFeatures.addFeatures(geoJSONFeatures)
    }, [suddivisione, geometries])


    return (
        <div ref={containerMapRef} style={{ width: "100%", height: "100%", position: "relative", background: "white", display: 'flex' }} >
            <div ref={mapRef} style={{ width: "100%", height: "100%", position: "relative", background: "white" }} >
                <div style={{ position: "absolute", left: 10, top: 10, width: 160, background: "white", zIndex: 99, padding: 6, borderRadius: 5 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label"
                            style={{ fontFamily: "Montserrat" }}
                        >Visualization</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={suddivisione}
                            label="Visualization"
                            onChange={(e) => {
                                setSuddivisione(e.target.value)
                                suddivisioneRef.current = e.target.value
                            }}
                            InputLabel={{
                                style: { fontFamily: "Montserrat" }
                            }}

                            style={{
                                fontFamily: "Montserrat",
                                fontWeight: 500,
                            }}
                        >
                            <MenuItem value="torino"
                                style={{
                                    fontFamily: "Montserrat",
                                    fontWeight: 500,
                                }}
                            >Torino</MenuItem>
                            <MenuItem value="circoscrizioni"
                                style={{
                                    fontFamily: "Montserrat",
                                    fontWeight: 500,
                                }}
                            >Circoscrizioni</MenuItem>
                            <MenuItem value="quartieri"
                                style={{
                                    fontFamily: "Montserrat",
                                    fontWeight: 500,
                                }}
                            >Quartieri</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div
                    style={{ position: 'absolute', bottom: 5, right: 5, backgroundColor: 'white', cursor: 'pointer', width: 26, height: 26, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 99 }}
                    onClick={() => {
                        if (containerMapRef.current.style.height === '100%') {
                            containerMapRef.current.style.height = '100vh'
                            containerMapRef.current.style.width = '100vw'
                            containerMapRef.current.style.position = 'fixed'
                            containerMapRef.current.style.top = '0px'
                            containerMapRef.current.style.left = '0px'
                            containerMapRef.current.style.zIndex = '999'
                        } else {
                            containerMapRef.current.style.height = '100%'
                            containerMapRef.current.style.width = '100%'
                            containerMapRef.current.style.position = 'relative'
                        }
                    }}
                >
                    <SlSizeFullscreen />
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', overflow: "auto", overflowX: "clip", alignItems: 'center', }}>
                {fixedActions.length > 0 ?
                    <div style={{ marginBottom: 2 }}>
                        <Tooltip title="List of actions without an address">
                            <div>
                                <FaInfoCircle color="#00AAFF" />
                            </div>
                        </Tooltip>
                    </div> : <></>}


                {fixedActions.map(action => {
                    return <img src={PinIcon} style={{ height: 30 }} alt="action" onClick={() => {
                        const co2_avoided = Math.round(action.emissions.find(em => em.gas === "co2").emissions_avoided)
                        document.getElementById("overlayContent").innerHTML = renderToString(
                            <div>
                                <h6 style={{ textAlign: "center", fontWeight: "bold" }}>{action.name}</h6>
                                <div style={{ display: 'flex', justifyContent: "space-between" }}><span>Start date:</span><span>{action.start_date}</span></div>
                                <div style={{ display: 'flex', justifyContent: "space-between" }}><span>End date:</span><span>{action.end_date}</span></div>
                                <div style={{ display: 'flex', justifyContent: "space-between" }}><span>CO2 avoided:</span><span>{co2_avoided} t</span></div>
                                <div style={{ display: 'flex', justifyContent: "space-between" }}><span>Total invesment:</span><span>{Math.round(action.financials_total_investment)} €</span></div>

                            </div>
                        );
                        map.getOverlays().getArray()[0].setPosition(view.getCenter())
                    }} />
                })}
            </div>
        </div>
    )
}

export default MappaDashboard;