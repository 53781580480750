import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";

function CliccSignatory(props) {
  return (
    <div>
      {props.value === true ? (
        <FaCheck style={{ color: "green" }}></FaCheck>
      ) : (
        <FaTimes style={{ color: "red" }}></FaTimes>
      )}
    </div>
  );
}

export default CliccSignatory;
