export const index = () => {
  return {
    name: "",
    action_type: "",
    field_of_action: "",
    systemic_lever: "",
    cup: "",
    rup: "",
    description: "",
    execution_start_date: null,
    execution_end_date: null,
    start_date: "2019-01-01",
    end_date: "2030-12-31",
    status: "",
    scale: "",
    address: "",
    torino_cambia: false,
    addressed_entities: "",
  };
};

export const netZero = () => {
  return {
    net_zero_generated_renewable: "",
    net_zero_removed_energy: "",
    net_zero_scope: "",
    net_zero_source_sector: "",
  };
};

export const actor = () => {
  return {
    actor: null,
    stakeholders: [],
  };
};

export const financials = () => {
  return {
    financials_funding_programme: "",
    financials_total_investment: 0,
    financials_capex: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    financials_opex: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    financials_investment_detail: [],
  };
};

export const specify = () => {
  return {
    details: {},
  };
};

export const initializeNewAction = () => {
  var toReturn = {
    ...index(),
    ...actor(),
    ...netZero(),
    ...financials(),
    ...specify(),
    total_co2_equivalent_avoided: 0,
    // emissions: [
    //   {
    //     "gas": "co2",
    //     "emissions_avoided": 0,
    //     "gwp": 1,
    //     "co2_equivalent_avoided": 0
    //   },
    //   {
    //     "gas": "f_gas",
    //     "emissions_avoided": 0,
    //     "gwp": 0,
    //     "co2_equivalent_avoided": 0
    //   },
    //   {
    //     "gas": "nf3",
    //     "emissions_avoided": 0,
    //     "gwp": 16100,
    //     "co2_equivalent_avoided": 0
    //   },
    //   {
    //     "gas": "n2o",
    //     "emissions_avoided": 0,
    //     "gwp": 265,
    //     "co2_equivalent_avoided": 0
    //   },
    //   {
    //     "gas": "sf6",
    //     "emissions_avoided": 0,
    //     "gwp": 23500,
    //     "co2_equivalent_avoided": 0
    //   },
    //   {
    //     "gas": "ch4",
    //     "emissions_avoided": 0,
    //     "gwp": 28,
    //     "co2_equivalent_avoided": 0
    //   }
    // ],
    electricity_impact: {
      Green_Certified_elec_Purchase: 0,
      RESs_Generation: 0,
      Variation_Electricity_consumption: 0
    },
    optional_params: {},
    is_accountable: false,
    macro_area: "",
    sector: "",
    parent_action: null,
    emissions: [],
    manual_values: false,
    notes: "",
  };
  return toReturn;
};
