import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "reactstrap";
import { InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { getHeightWithFooterWONavbar } from "../../../../utils/getHeightWithFooterWONavbar";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { connect } from "react-redux";
import { FaEuroSign, FaMoneyBillWave, FaPlus } from "react-icons/fa";
import { AgGridReact } from "ag-grid-react";
import { applyTransactionFn } from "../../../../utils/applyTransactionFn";
import ModalInvestmentDetail from "./ModalInvestmentDetail";
import DeleteDetail from "./DeleteDetail";
import axios from "axios";
import { API_URL } from "../../../../actions/types";
import { tokenConfig } from "../../../../utils/tokenConfig";

function Financials(props) {
  const [rowData, setRowData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [listActors, setListActors] = useState([]);
  const [reset, setReset] = useState([]);
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();

  const getData = async () => {
    return props.data.financials_investment_detail;
  };

  const grid = useRef();
  const mounted = useRef();
  useEffect(async () => {
    if (!mounted.current) {
      setRowData(await getData());
      mounted.current = true;
    } else {
      const newRows = await getData();
      const arr_checks = ["id"];
      if (newRows.length >= 1) {
        applyTransactionFn(grid, newRows, arr_checks, true);
      }
    }
  }, [props.data.financials_investment_detail]);

  useEffect(async () => {
    const res = await axios.get(`${API_URL}/clicc/actors/`, tokenConfig());
    setListActors(res.data);
  }, [reset]);

  const inputProps = {
    style: {
      fontWeight: 500,
      fontFamily: "Montserrat",
    },
  };

  const InputLabelProps = {
    style: {
      fontWeight: 500,
      fontFamily: "Montserrat",
    },
  };

  return (
    <div
      style={{
        width: "100%",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        overflow: "auto",
      }}
    >
      <Row style={{ margin: 0 }}>
        <Col lg="6" md="12" style={{ marginTop: 15 }}>
          <TextField
            label={
              <div>Funding programme&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
            }
            value={
              props.data.financials_funding_programme
                ? props.data.financials_funding_programme
                : ""
            }
            inputProps={inputProps}
            InputLabelProps={InputLabelProps}
            style={{ width: "100%" }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: {
                  key: "financials_funding_programme",
                  value: e.target.value,
                },
              });
            }}
          />
        </Col>
      </Row>

      <Row style={{ margin: 0, paddingBottom: 160 }}>
        <Col xs="12">
          <div
            style={{
              fontFamily: "Montserrat",
              marginTop: 20,
              marginBottom: 5,
              fontWeight: 500,
              color: "#525252",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
            }}
          >
            <div>
              <FaMoneyBillWave style={{ marginTop: "-3px", marginRight: 5 }}></FaMoneyBillWave >
              Funding details
            </div>
            <div
              onClick={() => setIsOpen(true)}
              style={{
                width: "fit-content",
                backgroundColor: props.layout.color_sidebar,
                color: "white",
                fontFamily: "Montserrat",
                fontWeight: 500,
                fontSize: 16,
                padding: width < 770 ? "5px 6px 3px 6px" : "6px 15px 4px 15px",
                borderRadius: 10,
                cursor: "pointer",
              }}
            >
              {width < 770 ? (
                <FaPlus
                  style={{
                    marginTop: "-3px",
                    marginRight: width < 770 ? 0 : 7,
                  }}
                ></FaPlus>
              ) : (
                "Add detail"
              )}
            </div>
          </div>
          <div
            className="excel-style ag-theme-balham"
            style={{ marginTop: 10 }}
          >
            <AgGridReact
              ref={grid}
              rowData={props.data.financials_investment_detail}
              domLayout="autoHeight"
              frameworkComponents={{
                deleteDetail: DeleteDetail,
              }}
              popupParent={document.querySelector("body")}
              columnDefs={[
                {
                  field: "",
                  width: 28,
                  maxWidth: 28,
                  suppressMenu: true,
                  cellRenderer: "deleteDetail",
                  cellRendererParams: {
                    data_redux: props.data,
                    type: props.type,
                  },
                },
                { field: "description", flex: 1 },
                { field: "start_date", headerName: "Start date", flex: 1 },
                { field: "end_date", headerName: "End date", flex: 1 },
                {
                  field: "funder",
                  headerName: "Funder",
                  flex: 1,
                  valueGetter: (params) => {
                    var actor = listActors.find(
                      (obj) => obj.id === params.data[params.colDef.field]
                    );
                    if (actor === undefined) {
                      return "";
                    } else {
                      return actor.actor_business_name;
                    }
                  },
                },
                {
                  field: "amount",
                  headerName: "Amount [€]",
                  flex: 1,
                  valueFormatter: (params) => {
                    return params.value.toLocaleString();
                  },
                },
              ]}
              defaultColDef={{
                minWidth: 100,
                resizable: true,
                filter: true,
                filterParams: { buttons: ["reset"], newRowsAction: "keep", caseSensitive: true },
                cellStyle: (params) => {
                  var style = {
                    cursor: "default",
                  };
                  if (params.colDef.field !== "description") {
                    style["display"] = "flex";
                    style["alignItems"] = "center";
                    style["justifyContent"] = "center";
                  }
                  return style;
                },
              }}
            ></AgGridReact>
          </div>
        </Col>
      </Row>
      <ModalInvestmentDetail
        data={props.data}
        type={props.type}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setListActors={setListActors}
      ></ModalInvestmentDetail>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Financials);
