import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import TitleCard from "../../../../../components/TitleCard/TitleCard";
import { connect, useDispatch } from "react-redux";
import { useWindowDimensions } from "../../../../../utils/useWindowDimensions";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import moment from "moment"

function PeriodWS(props) {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch()

  useEffect(() => {
    setStartDate(props.data.start_date)
    setEndDate(props.data.end_date)
  }, [])

  return (
    <Col xs="12" style={{ padding: 0 }}>
      <div
        style={{
          width: "100%",
          padding: 10,
          height: height * 0.25,
        }}
      >
        <div
          style={{
            ...props.layout.generic_card,
            width: "100%",
            display: "flex",
            flexFlow: "column",
            height: "100%",
          }}
        >
          <TitleCard title={"Period workspace"}></TitleCard>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              padding: height < 780 ? 0 : 10,
              borderRadius: props.layout.generic_card.borderRadius,
            }}
          >
            <div>


              <div
                style={{ width: 150 }}
              >
                <DesktopDatePicker
                  inputFormat="DD-MM-YYYY"
                  value={startDate}
                  maxDate={props.data.end_date}
                  onChange={(newDate) => {
                    setStartDate(newDate)
                    if (moment(newDate).isValid()) {
                      dispatch({
                        type: props.type,
                        payload: {
                          key: "start_date",
                          value: newDate.format("YYYY-MM-DD"),
                        },
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onBlur={e => {
                        if (!moment(e.target.value, "DD-MM-YYYY", true).isValid()) {
                          setStartDate(moment(props.data.start_date))
                        }
                      }}
                      label={<div style={{ fontFamily: "Montserrat", fontWeight: 500 }}>Start date</div>}
                      variant="standard"
                      className="datePickerMontserrat"
                    />
                  )}
                />
              </div>

              <div
                style={{ width: 150, marginTop: height > 850 ? 20 : 10 }}
              >
                <DesktopDatePicker
                  inputFormat="DD-MM-YYYY"
                  value={endDate}
                  minDate={props.data.start_date}
                  onChange={(newDate) => {
                    setEndDate(newDate)
                    if (moment(newDate).isValid()) {
                      dispatch({
                        type: props.type,
                        payload: {
                          key: "end_date",
                          value: newDate.format("YYYY-MM-DD"),
                        },
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onBlur={e => {
                        if (!moment(e.target.value, "DD-MM-YYYY", true).isValid()) {
                          setEndDate(moment(props.data.end_date))
                        }
                      }}
                      label={<div style={{ fontFamily: "Montserrat", fontWeight: 500 }}>End date</div>}
                      variant="standard"
                      className="datePickerMontserrat"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Col >
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(PeriodWS);
