import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import AddUpdateModal from "./AddUpdateModal";
import DeleteDetailStakeholders from "./DeleteDetail";
import UpdateDetail from "./UpdateDetail";
import { applyTransactionFn } from "../../../../../../../../utils/applyTransactionFn";
import { customComparator } from "../../../../../../../../utils/customComparator";
import { FaPlus } from "react-icons/fa";
import { useWindowDimensions } from "../../../../../../../../utils/useWindowDimensions";

function Areas(props) {
  const [rowData, setRowData] = useState(null);
  const [update, setUpdate] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { width, height } = useWindowDimensions();

  const getData = async () => {
    return props.data.baseline_areas;
  };

  const grid = useRef();
  const mounted = useRef();
  useEffect(async () => {
    if (!mounted.current) {
      setRowData(await getData());
      mounted.current = true;
    } else {
      const newRows = await getData();
      const arr_checks = ["macro_area", "settore", "sottosettore"];
      if (newRows.length >= 1) {
        applyTransactionFn(grid, newRows, arr_checks, false);
      }
    }
  }, [props.data.baseline_areas]);

  const toggle = () => {
    setIsOpen(!isOpen);
    setUpdate(false);
    setMacroArea("");
    setSettore("");
    setSottosettore("");
  };

  const [macroArea, setMacroArea] = useState("");
  const [settore, setSettore] = useState("");
  const [sottosettore, setSottosettore] = useState("");
  const [data, setData] = useState({});

  return (
    <div>
      <div>
        <h4 style={{ padding: 10, marginBottom: "-10px" }}>Area</h4>
        <div
          onClick={() => toggle()}
          style={{
            width: "fit-content",
            backgroundColor: props.layout.color_sidebar,
            color: "white",
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: 16,
            padding: width < 770 ? "5px 8px 3px 8px" : "6px 20px 4px 20px",
            borderRadius: 10,
            cursor: "pointer",
            position: "absolute",
            top: 20,
            right: 25,
          }}
        >
          <FaPlus
            style={{ marginTop: "-3px", marginRight: width < 770 ? 0 : 10 }}
          ></FaPlus>
          {width < 770 ? null : "Add"}
        </div>
      </div>
      <div
        className="excel-style ag-theme-balham"
        style={{ height: "13rem", padding: 10 }}
      >
        <AgGridReact
          ref={grid}
          rowData={rowData}
          frameworkComponents={{
            deleteDetail: DeleteDetailStakeholders,
            updateDetail: UpdateDetail,
          }}
          columnDefs={[
            {
              field: "",
              width: 28,
              maxWidth: 28,
              suppressMenu: true,
              cellRenderer: "deleteDetail",
              cellRendererParams: {
                type: props.type,
                update: update,
                setUpdate: setUpdate,
                data_redux: props.data,
              },
            },
            {
              field: "",
              width: 28,
              maxWidth: 28,
              suppressMenu: true,
              cellRenderer: "updateDetail",
              cellRendererParams: {
                setUpdate: setUpdate,
                setIsOpen: setIsOpen,
                data_redux: props.data,
                setData: setData,
                setMacroArea: setMacroArea,
                setSettore: setSettore,
                setSottosettore: setSottosettore,
              },
            },
            {
              field: "macro_area",
              headerName: "Macro Area",
              flex: 1,
              sortable: true,
              sort: "asc",
              comparator: customComparator,
            },
            {
              field: "settore",
              headerName: "Sector",
              flex: 1,
              sortable: true,
              sort: "asc",
              comparator: customComparator,
              sort: "asc",
              sortable: true,
            },
            {
              field: "sottosettore",
              headerName: "Subsector",
              flex: 1,
              sortable: true,
              sort: "asc",
              comparator: customComparator,
            },
          ]}
          defaultColDef={{
            minWidth: 100,
            resizable: true,
            filter: true,
            filterParams: {
              buttons: ["reset"],
              newRowsAction: "keep",
              caseSensitive: true
            },
            cellStyle: (params) => {
              var style = {
                cursor: "default",
              };

              return style;
            },
          }}
        ></AgGridReact>
      </div>
      {isOpen ? (
        <AddUpdateModal
          type={props.type}
          data_redux={props.data}
          data={data}
          isOpen={isOpen}
          toggle={toggle}
          update={update}
          macroArea={macroArea}
          setMacroArea={setMacroArea}
          settore={settore}
          setSettore={setSettore}
          sottosettore={sottosettore}
          setSottosettore={setSottosettore}
        ></AddUpdateModal>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Areas);
