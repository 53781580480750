import moment from "moment";


export const getEmissionsByYear = (startDate, endDate, emission) => {
    const result = {};

    // when startDate and endDate are the same return empty (this happens if the action ends before the beginning of the workspace)
    if (startDate.format("YYYY-MM-DD") === endDate.format("YYYY-MM-DD")) {
        return result
    }

    let currentYear = startDate.year();

    while (currentYear <= endDate.year()) {
        const startOfYear = moment({ year: currentYear, month: 0, date: 1 });
        const endOfYear = moment({ year: currentYear, month: 11, date: 31 });

        const daysInYear = moment.min(endOfYear, endDate).diff(moment.max(startOfYear, startDate), 'days') + 1;

        var emission_year
        if (((currentYear % 4 == 0) && (currentYear % 100 != 0)) || (currentYear % 400 == 0)) {
            emission_year = emission / 366 * daysInYear
        } else {
            emission_year = emission / 365 * daysInYear
        }

        result[currentYear] = emission_year;
        currentYear++;
    }

    return result;
}