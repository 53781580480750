import React, { useState } from "react";
import { useWindowDimensions } from "../../../../../utils/useWindowDimensions";
import { connect, useDispatch } from "react-redux";
import TitleCard from "../../../../../components/TitleCard/TitleCard";
import { getHeightWithFooterWONavbar } from "../../../../../utils/getHeightWithFooterWONavbar";
import { TextField, MenuItem, Collapse } from "@mui/material";
import moment from "moment";
import { Row, Col, Card } from "reactstrap";
import { fieldsOfAction } from "../../../../../utils/fieldsOfAction";
import { systemicLever } from "../../../../../utils/systemicLever";

import { FaPlus } from "react-icons/fa";


function Content(props) {
  const [checked, setChecked] = useState({
    systemic_lever: {
      name: "Systemic lever",
      checked: false,
      items: systemicLever
    },
    fields_of_action: {
      name: "Fields of action",
      checked: false,
      items: fieldsOfAction
    },
  })
  const [checkedCoBenefit, setCheckedCoBenefit] = useState(false)
  const [checkedNotes, setCheckedNotes] = useState(false)
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();

  const inputProps = {
    style: {
      fontWeight: 500,
      fontFamily: "Montserrat",
    },
  };

  const InputLabelProps = {
    style: {
      fontWeight: 500,
      fontFamily: "Montserrat",
    },
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#E4E6E8",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        paddingLeft: 0,
        paddingBottom: props.layout.footer_height,
        overflow: "auto",
      }}
    >
      {/* workspace without padding */}
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 10,
        }}
      >
        {/* workspace */}
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              padding: 10,
              height: height < 850 ? height * 0.69 : height * 0.74,
            }}
          >
            <div
              style={{
                ...props.layout.generic_card,
                display: "flex",
                flexFlow: "column",
              }}
            >
              <TitleCard title={"Others"}></TitleCard>
              <div
                style={{
                  width: "100%",
                  padding: "15px 10px 25px 10px",
                  borderRadius: props.layout.generic_card.borderRadius,
                  height: "100%",
                }}
              >
                <Row style={{ margin: 0, height: "100%" }}>
                  <Col md="12" sm="12" style={{ height: "100%" }}>
                    <div
                      style={{
                        padding: "7px 0px",
                        overflow: "auto",
                        height: "90%",
                        width: "100%",
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                      }}
                    >
                      {Object.keys(checked).map((k, idx) => {
                        return (<Card body style={{ marginTop: idx > 0 ? 20 : 0 }}>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <FaPlus
                              onClick={() => setChecked({
                                ...checked,
                                [k]: {
                                  ...checked[k],
                                  checked: !checked[k].checked
                                }
                              })}
                              style={{
                                color: props.layout.color_sidebar,
                                marginRight: 5,
                                cursor: "pointer"
                              }}></FaPlus> {checked[k].name}
                          </div>
                          <Collapse in={checked[k].checked}>
                            <div style={{ marginTop: 10 }}>
                              {props.data[k].length === 0 ? (
                                <div>
                                  Create at least one pathway to define {checked[k].name}
                                </div>
                              ) : (
                                props.data[k].map((pathway, index) => {
                                  return (
                                    <div key={index}>
                                      <div>
                                        {
                                          props.data.pathways.find(
                                            (obj) => obj.id === pathway.id
                                          ).name
                                        }
                                      </div>
                                      <TextField
                                        select
                                        label={<div>Value&nbsp;</div>}
                                        value={pathway.value}
                                        SelectProps={{
                                          style: {
                                            height: 55,
                                            fontWeight: 500,
                                            fontFamily: "Montserrat",
                                            paddingLeft: 5,
                                          },
                                        }}
                                        InputLabelProps={{
                                          style: {
                                            fontWeight: 500,
                                            fontFamily: "Montserrat",
                                          },
                                        }}
                                        style={{
                                          width: 300,
                                          marginTop: 10,
                                          marginBottom: 20,
                                        }}
                                        onChange={(e) => {
                                          dispatch({
                                            type: props.type,
                                            payload: {
                                              key: k,
                                              value: props.data[k].map(
                                                (p) => {
                                                  if (p.id !== pathway.id) {
                                                    return p;
                                                  } else {
                                                    return {
                                                      ...p,
                                                      value: e.target.value,
                                                    };
                                                  }
                                                }
                                              ),
                                            },
                                          });
                                        }}
                                      >
                                        <MenuItem
                                          value={""}
                                          style={{
                                            fontWeight: 500,
                                            fontFamily: "Montserrat",
                                          }}
                                        >
                                          ...
                                        </MenuItem>
                                        {checked[k].items.map((s) => {
                                          return (
                                            <MenuItem
                                              value={s}
                                              style={{
                                                fontWeight: 500,
                                                fontFamily: "Montserrat",
                                              }}
                                            >
                                              {s}
                                            </MenuItem>
                                          );
                                        })}
                                      </TextField>
                                    </div>
                                  );
                                })
                              )}
                            </div>
                          </Collapse>
                        </Card>)
                      })}

                      {/* co-benefit */}
                      <Card body style={{ marginTop: 20 }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <FaPlus
                            onClick={() => setCheckedCoBenefit(!checkedCoBenefit)}
                            style={{
                              color: props.layout.color_sidebar,
                              marginRight: 5,
                              cursor: "pointer"
                            }}></FaPlus> Co-benefit
                        </div>
                        <Collapse in={checkedCoBenefit}>
                          <div style={{ marginTop: 10 }}>
                            {props.data.co_benefit.length === 0 ? (
                              <div>
                                Create at least one pathway to define co-benefit
                              </div>
                            ) : (
                              props.data.co_benefit.map((pathway) => {
                                return (
                                  <div>
                                    <div>
                                      {
                                        props.data.pathways.find(
                                          (obj) => obj.id === pathway.id
                                        ).name
                                      }
                                    </div>
                                    <TextField
                                      label={<div>Value&nbsp;</div>}
                                      multiline
                                      rows={2}
                                      value={pathway.value}
                                      InputProps={{
                                        style: {
                                          fontWeight: 500,
                                          fontFamily: "Montserrat",
                                        },
                                      }}
                                      InputLabelProps={{
                                        style: {
                                          fontWeight: 500,
                                          fontFamily: "Montserrat",
                                        },
                                      }}
                                      style={{
                                        width: "100%",
                                        marginTop: 10,
                                        marginBottom: 20,
                                      }}
                                      onChange={(e) => {
                                        dispatch({
                                          type: props.type,
                                          payload: {
                                            key: "co_benefit",
                                            value: props.data.co_benefit.map((p) => {
                                              if (p.id !== pathway.id) {
                                                return p;
                                              } else {
                                                return {
                                                  ...p,
                                                  value: e.target.value,
                                                };
                                              }
                                            }),
                                          },
                                        });
                                      }}
                                    >
                                      <MenuItem
                                        value={""}
                                        style={{
                                          fontWeight: 500,
                                          fontFamily: "Montserrat",
                                        }}
                                      >
                                        ...
                                      </MenuItem>
                                      {[
                                        "Technology/Infrastructure",
                                        "Governance & policy",
                                        "Social Innovation",
                                        "Democracy/participation",
                                        "Finance & funding",
                                        "Learning & capabilities",
                                      ].map((s, index) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={s}
                                            style={{
                                              fontWeight: 500,
                                              fontFamily: "Montserrat",
                                            }}
                                          >
                                            {s}
                                          </MenuItem>
                                        );
                                      })}
                                    </TextField>
                                  </div>
                                );
                              })
                            )}
                          </div>
                        </Collapse>
                      </Card>

                      {/* notes */}
                      <Card body style={{ marginTop: 20 }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <FaPlus
                            onClick={() => setCheckedNotes(!checkedNotes)}
                            style={{
                              color: props.layout.color_sidebar,
                              marginRight: 5,
                              cursor: "pointer"
                            }}></FaPlus> Notes
                        </div>
                        <Collapse in={checkedNotes}>
                          <div style={{ marginTop: 10 }}>
                            <TextField
                              label={<div>Text</div>}
                              multiline
                              rows={
                                height > 800
                                  ? Math.round(height / 130)
                                  : Math.round(height / 140)
                              }
                              value={props.data.notes ? props.data.notes : ""}
                              inputProps={inputProps}
                              InputLabelProps={InputLabelProps}
                              style={{ width: "100%" }}
                              onChange={(e) => {
                                dispatch({
                                  type: props.type,
                                  payload: { key: "notes", value: e.target.value },
                                });
                              }}
                            />
                          </div>
                        </Collapse>
                      </Card>

                    </div>
                  </Col>
                </Row>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Content);
