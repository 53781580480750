import {
  RESET_CREATE_NEW_ACTOR,
  UPDATE_CREATE_NEW_ACTOR,
} from "../actions/types";
import { initializeNewActor } from "../utils/initializeNewActor";

const initialState = {
  ...initializeNewActor(),
};

export default function toolbar(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CREATE_NEW_ACTOR:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case RESET_CREATE_NEW_ACTOR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
