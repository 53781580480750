import { RESET_CREATE, UPDATE_CREATE } from "../actions/types";
import { initializeScenario } from "../utils/initializeScenario";

const initialState = {
  ...initializeScenario(),
};

export default function toolbar(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CREATE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case RESET_CREATE:
      return {
        ...initializeScenario(),
      };
    default:
      return state;
  }
}
