import { getEmissionsByYear } from "./getEmissionsByYear"
import { intersectDatesWithWorkspace } from "./intersectDatesWithWorkspace"
import moment from "moment"

export const getYearlyEmissionAction = (action, gas, emission_type, start_date_wp, end_date_wp) => {
    var emissions_gas = action.emissions.find(inner_obj => inner_obj.gas === gas)[emission_type]

    var start_date_moment
    var datesToUse
    if (start_date_wp === null) {
        start_date_moment = moment(action.start_date)
    } else {
        datesToUse = intersectDatesWithWorkspace(start_date_wp, end_date_wp, action.start_date, action.end_date)
        start_date_moment = datesToUse[0]
    }
    var end_date_moment
    if (end_date_wp === null) {
        end_date_moment = action.end_date === null ? moment("2100-12-31") : moment(action.end_date)
    } else {
        datesToUse = intersectDatesWithWorkspace(start_date_wp, end_date_wp, action.start_date, action.end_date)
        end_date_moment = datesToUse[1]
    }

    var result = getEmissionsByYear(start_date_moment, end_date_moment, emissions_gas);

    return result
}