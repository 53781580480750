import React, { useState, useEffect } from "react"
import Checkbox from '@mui/material/Checkbox';
import axios from "axios";
import { API_URL, GET_ERRORS } from "../../../../actions/types";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { useDispatch } from "react-redux";


const ActionSelect = (props) => {
    const [checked, setChecked] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (props.userAnagrafica.actions_inventory_list.includes(props.data.id)) {
            setChecked(true)
        } else {
            setChecked(false)
        }
    }, [props.userAnagrafica])

    const makeCall = async (id, bool) => {
        try {
            if (bool === true) {
                await axios.put(
                    `${API_URL}/users/${props.userAnagrafica.id}/add_action_inventory/?action_inventory_id=${id}`,
                    null,
                    tokenConfig()
                );
                setChecked(true)
            } else {
                await axios.put(
                    `${API_URL}/users/${props.userAnagrafica.id}/remove_action_inventory/?action_inventory_id=${id}`,
                    null,
                    tokenConfig()
                );
                setChecked(false)
            }

            // // update state
            // setUserPermissions(
            //     userPermissions.map(obj => {
            //         return {
            //             ...obj,
            //             children: obj.children.map(inner_obj => {
            //                 if (inner_obj.id !== id) {
            //                     return inner_obj
            //                 } else {
            //                     return {
            //                         ...inner_obj,
            //                         active: bool
            //                     }
            //                 }
            //             })
            //         }
            //     })
            // )
        } catch (err) {
            const errors = {
                msg: err.response.data,
                status: err.response.status,
            };
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        }
    }

    return (
        <Checkbox
            checked={checked}
            className="bluecheckbox"
            size="small"
            color="success"
            style={{ marginTop: "-2px" }}
            onChange={e => {
                makeCall(props.data.id, e.target.checked)
            }}
        />
    )
};

export default ActionSelect;
