import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { FaSpinner } from "react-icons/fa";

export class IsLoading extends Component {
  render() {
    const { is_loading } = this.props;
    return (
      <React.Fragment>
        {is_loading ? (
          <Modal
            isOpen={is_loading}
            className="isLoading"
            style={{ paddingTop: "100px" }}
          >
            <ModalHeader>Loading...</ModalHeader>
            <ModalBody>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px 0px",
                }}
              >
                <FaSpinner
                  icon="spinner"
                  className="spinner"
                  style={{ fontSize: 30, color: "#2e66eb" }}
                />
              </div>
            </ModalBody>
          </Modal>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  is_loading: state.isLoading.is_loading,
});

export default connect(mapStateToProps)(IsLoading);
