import React from "react"
import moment from "moment"
import { FaExclamationCircle } from "react-icons/fa";
import Tooltip from "@mui/material/Tooltip";
import { checkActionPeriod } from "../../../../../utils/checkActionPeriod";



const DateColumns = (props) => {

    var error_level = checkActionPeriod(props, props.allProps)

    return (
        <>
            {
                props.data.id === "total" || props.data[props.colDef.field] === undefined ? null :
                    error_level === 0 ?
                        <div >
                            {props.data[props.colDef.field]}
                        </div> :
                        error_level === 1 ?
                            <div>
                                <Tooltip title={`The "${props.colDef.field.replace("_", " ")}" of this action is outside the period of the workspace. For the computations it's automatically considered ${moment(props.allProps.data[props.colDef.field]).format("DD-MM-YYYY")}`}>
                                    <div style={{ display: "flex", alignItems: "center", color: "#ED9F04" }}>
                                        {<FaExclamationCircle style={{ marginRight: 5 }}></FaExclamationCircle>}{props.data[props.colDef.field]}
                                    </div>
                                </Tooltip>
                            </div> :
                            error_level === 2 ?
                                <div >
                                    <Tooltip title={`This action is not considered in the computations because its "start date" and "end date" are outside the period of the workspace`}>
                                        <div style={{ display: "flex", alignItems: "center", color: "red" }}>
                                            {<FaExclamationCircle style={{ marginRight: 5 }}></FaExclamationCircle>}{props.data[props.colDef.field]}
                                        </div>
                                    </Tooltip>
                                </div> : null
            }
        </>

    )
};

export default DateColumns;
