import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import {
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { getHeightWithFooterWONavbar } from "../../../../utils/getHeightWithFooterWONavbar";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { API_URL } from "../../../../actions/types";
import { connect } from "react-redux";
import { FaUser, FaPlusCircle } from "react-icons/fa";
import axios from "axios";

function Fields(props) {
  const [actorTypes, setActorTypes] = useState([]);
  const [actorSectors, setActorSectors] = useState([]);
  const [actorAreas, setActorAreas] = useState([]);
  const [newActorType, setNewActorType] = useState("");
  const [newActorSector, setNewActorSector] = useState("");
  const [newActorArea, setNewActorArea] = useState("");
  const [resetTypes, setResetTypes] = useState(0);
  const [resetSectors, setResetSectors] = useState(0);
  const [resetAreas, setResetAreas] = useState(0);
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();

  useEffect(async () => {
    const resTypes = await axios.get(
      `${API_URL}/clicc/actor_types/`,
      tokenConfig()
    );
    // sort and place "Altro" at the end
    var sortedActorTypes = resTypes.data
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
      .reduce((acc, element) => {
        if (element.name === "Other") {
          return [element, ...acc];
        }
        return [...acc, element];
      }, []);
    sortedActorTypes = [...sortedActorTypes, sortedActorTypes[0]];
    sortedActorTypes.shift();

    setActorTypes(sortedActorTypes);
  }, [resetTypes]);

  useEffect(async () => {
    const resSectors = await axios.get(
      `${API_URL}/clicc/actor_sectors/`,
      tokenConfig()
    );
    var sortedSectors = resSectors.data.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    setActorSectors(sortedSectors);
  }, [resetSectors]);

  useEffect(async () => {
    const resAreas = await axios.get(
      `${API_URL}/clicc/actor_areas/`,
      tokenConfig()
    );
    var sortedAreas = resAreas.data.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    setActorAreas(sortedAreas);
  }, [resetAreas]);

  const inputProps = {
    style: {
      fontWeight: 500,
      fontFamily: "Montserrat",
    },
  };

  const InputLabelProps = {
    style: {
      fontWeight: 500,
      fontFamily: "Montserrat",
    },
  };

  return (
    <div>
      <Row style={{ margin: 0 }}>
        <Col lg="4" md="12">
          <TextField
            label={<div>Business name&nbsp;&nbsp;&nbsp;</div>}
            value={
              props.data.actor_business_name
                ? props.data.actor_business_name
                : ""
            }
            inputProps={inputProps}
            InputLabelProps={InputLabelProps}
            style={{ width: "100%" }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: {
                  key: "actor_business_name",
                  value: e.target.value,
                },
              });
            }}
          />
        </Col>
        <Col lg="4" md="12" style={{ marginTop: width > 990 ? 0 : 30 }}>
          <TextField
            label={<div>Service category&nbsp;&nbsp;&nbsp;</div>}
            value={
              props.data.actor_service_category
                ? props.data.actor_service_category
                : ""
            }
            inputProps={inputProps}
            InputLabelProps={InputLabelProps}
            style={{ width: "100%" }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: {
                  key: "actor_service_category",
                  value: e.target.value,
                },
              });
            }}
          />
        </Col>
      </Row>
      <Row style={{ margin: 0 }}>
        <Col lg="4" md="12" style={{ marginTop: 30 }}>
          <TextField
            select
            label="Actor type"
            value={props.data.actor_type ? props.data.actor_type : ""}
            style={{
              width: "100%",
            }}
            InputLabelProps={InputLabelProps}
            InputProps={{
              style: {
                height: 56,
                fontWeight: 500,
                fontFamily: "Montserrat",
              },
            }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: {
                  key: "actor_type",
                  value: e.target.value,
                },
              });
            }}
          >
            <MenuItem
              value={null}
              style={{ fontWeight: 500, fontFamily: "Montserrat" }}
            >
              ...
            </MenuItem>

            {actorTypes.map((type, index) => {
              return (
                <MenuItem
                  key={index}
                  value={type.name}
                  style={{
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                    paddingTop: 2,
                    paddingBottom: 2,
                  }}
                >
                  {type.name}
                </MenuItem>
              );
            })}
            <div
              style={{
                padding: 15,
                borderTop: "1px solid #BABABA",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                label="Create"
                variant="outlined"
                value={newActorType}
                onKeyDown={(e) => e.stopPropagation()}
                onChange={(e) => {
                  setNewActorType(e.target.value);
                }}
                InputLabelProps={InputLabelProps}
                InputProps={{
                  style: {
                    height: 56,
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                  },
                }}
              />
              <FaPlusCircle
                style={{
                  color: "green",
                  fontSize: 25,
                  marginLeft: 10,
                  cursor: "pointer",
                }}
                onClick={async () => {
                  if (newActorType !== "") {
                    await axios.post(
                      `${API_URL}/clicc/actor_types/`,
                      { name: newActorType },
                      tokenConfig()
                    );
                    setResetTypes(resetTypes + 1);
                    setNewActorType("");
                  }
                }}
              ></FaPlusCircle>
            </div>
          </TextField>
        </Col>
        <Col lg="4" md="12" style={{ marginTop: 30 }}>
          <TextField
            select
            label="Actor Sector"
            value={props.data.actor_sector ? props.data.actor_sector : ""}
            style={{
              width: "100%",
            }}
            InputLabelProps={InputLabelProps}
            InputProps={{
              style: {
                height: 56,
                fontWeight: 500,
                fontFamily: "Montserrat",
              },
            }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: {
                  key: "actor_sector",
                  value: e.target.value,
                },
              });
            }}
          >
            <MenuItem
              value={null}
              style={{ fontWeight: 500, fontFamily: "Montserrat" }}
            >
              ...
            </MenuItem>
            {actorSectors.map((type, index) => {
              return (
                <MenuItem
                  key={index}
                  value={type.name}
                  style={{
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                    paddingTop: 2,
                    paddingBottom: 2,
                  }}
                >
                  {type.name}
                </MenuItem>
              );
            })}
            <div
              style={{
                padding: 15,
                borderTop: "1px solid #BABABA",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                label="Create"
                variant="outlined"
                value={newActorSector}
                onKeyDown={(e) => e.stopPropagation()}
                onChange={(e) => {
                  setNewActorSector(e.target.value);
                }}
                InputLabelProps={InputLabelProps}
                InputProps={{
                  style: {
                    height: 56,
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                  },
                }}
              />
              <FaPlusCircle
                style={{
                  color: "green",
                  fontSize: 25,
                  marginLeft: 10,
                  cursor: "pointer",
                }}
                onClick={async () => {
                  if (newActorSector !== "") {
                    await axios.post(
                      `${API_URL}/clicc/actor_sectors/`,
                      { name: newActorSector },
                      tokenConfig()
                    );
                    setResetSectors(resetSectors + 1);
                    setNewActorSector("");
                  }
                }}
              ></FaPlusCircle>
            </div>
          </TextField>
        </Col>
        <Col lg="4" md="12" style={{ marginTop: 30 }}>
          <TextField
            select
            label="Actor Area"
            value={props.data.actor_area ? props.data.actor_area : ""}
            style={{
              width: "100%",
            }}
            InputLabelProps={InputLabelProps}
            InputProps={{
              style: {
                height: 56,
                fontWeight: 500,
                fontFamily: "Montserrat",
              },
            }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: {
                  key: "actor_area",
                  value: e.target.value,
                },
              });
            }}
          >
            <MenuItem
              value={null}
              style={{ fontWeight: 500, fontFamily: "Montserrat" }}
            >
              ...
            </MenuItem>
            {actorAreas.map((type, index) => {
              return (
                <MenuItem
                  key={index}
                  value={type.name}
                  style={{
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                    paddingTop: 2,
                    paddingBottom: 2,
                  }}
                >
                  {type.name}
                </MenuItem>
              );
            })}
            <div
              style={{
                padding: 15,
                borderTop: "1px solid #BABABA",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                label="Create"
                variant="outlined"
                value={newActorArea}
                onKeyDown={(e) => e.stopPropagation()}
                onChange={(e) => {
                  setNewActorArea(e.target.value);
                }}
                InputLabelProps={InputLabelProps}
                InputProps={{
                  style: {
                    height: 56,
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                  },
                }}
              />
              <FaPlusCircle
                style={{
                  color: "green",
                  fontSize: 25,
                  marginLeft: 10,
                  cursor: "pointer",
                }}
                onClick={async () => {
                  if (newActorArea !== "") {
                    await axios.post(
                      `${API_URL}/clicc/actor_areas/`,
                      { name: newActorArea },
                      tokenConfig()
                    );
                    setResetAreas(resetAreas + 1);
                    setNewActorSector("");
                  }
                }}
              ></FaPlusCircle>
            </div>
          </TextField>
        </Col>
      </Row>
      <Row style={{ margin: 0 }}>
        <Col lg="4" md="12" style={{ marginTop: 30 }}>
          <TextField
            label="Address"
            value={props.data.actor_address ? props.data.actor_address : ""}
            inputProps={inputProps}
            InputLabelProps={InputLabelProps}
            style={{ width: "100%" }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: { key: "actor_address", value: e.target.value },
              });
            }}
          />
        </Col>
        <Col lg="4" md="12" style={{ marginTop: 30 }}>
          <TextField
            label={<div>Legal representative&nbsp;&nbsp;&nbsp;&nbsp;</div>}
            value={
              props.data.actor_legal_representative
                ? props.data.actor_legal_representative
                : ""
            }
            inputProps={inputProps}
            InputLabelProps={InputLabelProps}
            style={{ width: "100%" }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: {
                  key: "actor_legal_representative",
                  value: e.target.value,
                },
              });
            }}
          />
        </Col>
        <Col lg="4" md="12" style={{ marginTop: 30 }}>
          <TextField
            label={<div>Website&nbsp;</div>}
            value={props.data.actor_website ? props.data.actor_website : ""}
            inputProps={inputProps}
            InputLabelProps={InputLabelProps}
            style={{ width: "100%" }}
            onChange={(e) => {
              dispatch({
                type: props.type,
                payload: {
                  key: "actor_website",
                  value: e.target.value,
                },
              });
            }}
          />
        </Col>
      </Row>

      <Row style={{ margin: 0 }}>
        <Col lg="6" md="12" style={{ marginTop: 30 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              onChange={(e) => {
                dispatch({
                  type: props.type,
                  payload: {
                    key: "actor_clicc_signatory",
                    value: e.target.checked,
                  },
                });
              }}
              checked={props.data.actor_clicc_signatory}
              style={{ marginLeft: "-3px" }}
            />
            <span
              style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
                color: "#6D6D6D",
                marginLeft: 3,
              }}
            >
              CLICC signatory
            </span>
          </div>
        </Col>
      </Row>
      <Row style={{ margin: 0 }}>
        <Col xs="12">
          <div
            style={{
              fontFamily: "Montserrat",
              marginTop: 25,
              marginBottom: 5,
              fontWeight: 500,
              color: "#525252",
            }}
          >
            <FaUser style={{ marginTop: "-3px", marginRight: 5 }}></FaUser>
            Reference person
          </div>
          <div
            style={{
              border: "1px solid rgb(192, 192, 192)",
              borderRadius: 5,
              padding: "0px 10px 25px 10px",
            }}
          >
            <Row style={{ margin: 0 }}>
              <Col md="6" sm="12" style={{ marginTop: 25 }}>
                <TextField
                  label={<div>Name</div>}
                  value={
                    props.data.actor_reference_person_name
                      ? props.data.actor_reference_person_name
                      : ""
                  }
                  inputProps={inputProps}
                  InputLabelProps={InputLabelProps}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    dispatch({
                      type: props.type,
                      payload: {
                        key: "actor_reference_person_name",
                        value: e.target.value,
                      },
                    });
                  }}
                />
              </Col>
              <Col md="6" sm="12" style={{ marginTop: 25 }}>
                <TextField
                  label={<div>Surname&nbsp;</div>}
                  value={
                    props.data.actor_reference_person_surname
                      ? props.data.actor_reference_person_surname
                      : ""
                  }
                  inputProps={inputProps}
                  InputLabelProps={InputLabelProps}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    dispatch({
                      type: props.type,
                      payload: {
                        key: "actor_reference_person_surname",
                        value: e.target.value,
                      },
                    });
                  }}
                />
              </Col>
            </Row>
            <Row style={{ margin: 0 }}>
              <Col md="4" sm="12" style={{ marginTop: 30 }}>
                <TextField
                  label={<div>Job role</div>}
                  value={
                    props.data.actor_reference_person_job_role
                      ? props.data.actor_reference_person_job_role
                      : ""
                  }
                  inputProps={inputProps}
                  InputLabelProps={InputLabelProps}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    dispatch({
                      type: props.type,
                      payload: {
                        key: "actor_reference_person_job_role",
                        value: e.target.value,
                      },
                    });
                  }}
                />
              </Col>
              <Col md="4" sm="12" style={{ marginTop: 30 }}>
                <TextField
                  label={<div>Email</div>}
                  value={
                    props.data.actor_reference_person_email
                      ? props.data.actor_reference_person_email
                      : ""
                  }
                  inputProps={inputProps}
                  InputLabelProps={InputLabelProps}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    dispatch({
                      type: props.type,
                      payload: {
                        key: "actor_reference_person_email",
                        value: e.target.value,
                      },
                    });
                  }}
                />
              </Col>
              <Col md="4" sm="12" style={{ marginTop: 30 }}>
                <TextField
                  label={<div>Phone number&nbsp;&nbsp;&nbsp;&nbsp;</div>}
                  value={
                    props.data.actor_reference_person_phone_number
                      ? props.data.actor_reference_person_phone_number
                      : ""
                  }
                  inputProps={inputProps}
                  InputLabelProps={InputLabelProps}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    dispatch({
                      type: props.type,
                      payload: {
                        key: "actor_reference_person_phone_number",
                        value: e.target.value,
                      },
                    });
                  }}
                />
              </Col>
            </Row>
            <Row style={{ margin: 0 }}>
              <Col md="4" sm="12" style={{ marginTop: 30 }}>
                <TextField
                  label={<div>Address</div>}
                  value={
                    props.data.actor_reference_person_address
                      ? props.data.actor_reference_person_address
                      : ""
                  }
                  inputProps={inputProps}
                  InputLabelProps={InputLabelProps}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    dispatch({
                      type: props.type,
                      payload: {
                        key: "actor_reference_person_address",
                        value: e.target.value,
                      },
                    });
                  }}
                />
              </Col>
              <Col md="4" sm="12" style={{ marginTop: 30 }}>
                <TextField
                  label={<div>Postal code&nbsp;&nbsp;</div>}
                  value={
                    props.data.actor_reference_person_post_code
                      ? props.data.actor_reference_person_post_code
                      : ""
                  }
                  inputProps={inputProps}
                  InputLabelProps={InputLabelProps}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    dispatch({
                      type: props.type,
                      payload: {
                        key: "actor_reference_person_post_code",
                        value: e.target.value,
                      },
                    });
                  }}
                />
              </Col>
              <Col md="4" sm="12" style={{ marginTop: 30 }}>
                <TextField
                  label={<div>Municipality</div>}
                  value={
                    props.data.actor_reference_person_municipality
                      ? props.data.actor_reference_person_municipality
                      : ""
                  }
                  inputProps={inputProps}
                  InputLabelProps={InputLabelProps}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    dispatch({
                      type: props.type,
                      payload: {
                        key: "actor_reference_person_municipality",
                        value: e.target.value,
                      },
                    });
                  }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Fields);
