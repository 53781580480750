import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useWindowDimensions } from "../Dashboard/utils/useWindowDimensions";
import Logo from "../images/logo_clicc_con_scritta.png";
import { API_URL, GET_ERRORS } from "../Dashboard/actions/types";
// import { getErrors } from "../Dashboard/utils/getErrors";
import { tokenConfig } from "../Dashboard/utils/tokenConfig";
import axios from "axios";
import SuccessAlert from "../Dashboard/components/Modals/SuccessAlert";

export const SendEmail = (props) => {
    const { width, height } = useWindowDimensions();
    const [email, setEmail] = useState("");
    const onChangeEmail = (e) => setEmail(e.target.value);
    const dispatch = useDispatch()

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(
                API_URL + "/password_reset/",
                {
                    email: email,
                },
                tokenConfig()
            );
            SuccessAlert("An email has been sent to your account!")
            setEmail("")
        } catch (err) {
            const errors = {
                msg: err.response.data,
                status: err.response.status,
            };
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        }
    };

    return (
        <div>
            <div
                style={{
                    fontFamily: 'Montserrat',
                    fontWeight: "300",
                    height: height,
                    display: "flex",
                    background: "#f9fafc",
                }}
            >
                <div style={{ margin: "auto auto" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            background: "#ffffff",
                            borderRadius: "4px",
                            boxShadow: "0px 2px 6px -1px rgb(0 0 0 / 12%)",
                        }}
                    >
                        <form
                            onSubmit={onSubmit}
                            style={{
                                width: width > 721 ? "400px" : null,
                                padding: "35px",
                                background: "#fefefe",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                    marginBottom: 10,
                                }}
                            >
                                <img
                                    src={Logo}
                                    style={{ width: "auto", height: 50, marginRight: 5 }}
                                ></img>
                            </div>

                            <p
                                style={{
                                    width: "100%",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    textAlign: "center",
                                    marginBottom: 10,
                                }}
                            >
                                Reset password
                            </p>
                            <p style={{ width: "100%", textAlign: "center", marginBottom: 25, opacity: "0.65", }}>Insert below your email address</p>
                            <div
                                data-testid="login_form_email"
                                style={{
                                    position: "relative",
                                    marginBottom: "30px",
                                    width: "100%",
                                }}
                            >
                                <TextField
                                    type="email"
                                    label={"Email"}
                                    placeholder="Insert your address"
                                    onChange={onChangeEmail}
                                    value={email}
                                    InputLabelProps={{
                                        shrink: true,
                                        fontFamily: "Montserrat"
                                    }}
                                    sx={{
                                        width: "100%",
                                        "& input": {
                                            fontFamily: "Montserrat"
                                        },
                                        "& label": {
                                            fontFamily: "Montserrat"
                                        }
                                    }}
                                ></TextField>
                            </div>
                            <Button
                                onClick={onSubmit}
                                data-testid="login_form_button"
                                sx={{
                                    placeSelf: "end",
                                    margin: "20px 0px 20px 0px",
                                    width: "100%",
                                }}
                                type="submit"
                                variant="contained"
                                style={{
                                    backgroundColor: "rgb(0, 43, 73)", marginTop: 0, fontFamily: "Montserrat"
                                }}
                            >
                                Send
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, {})(SendEmail)
