import React, { useState } from "react";
import { FaTimes, FaDownload } from "react-icons/fa";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  API_URL,
  IS_LOADING,
  UPDATE_CREATE_NEW_CONTRACT,
} from "../../../../actions/types";
import { useDispatch } from "react-redux";
import SuccessAlert from "../../../../components/Modals/SuccessAlert";
import axios from "axios";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { createEmptyTemplate } from "../../../../utils/createEmptyTemplate";
import { getFilledBlocks } from "../../../../utils/getFilledBlocks";
import { replaceBlocks } from "../../../../utils/replaceBlocks";
import { useHistory } from "react-router-dom";

function ImportWorkspace(props) {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const toggle = () => setIsOpen(!isOpen);
  return (
    <div>
      <FaDownload
        onClick={async () => {
          dispatch({
            type: IS_LOADING,
          });
          const res = await axios.get(
            `${API_URL}/clicc/workspace/${props.data.id}/`,
            tokenConfig()
          );
          dispatch({
            type: UPDATE_CREATE_NEW_CONTRACT,
            payload: { key: "workspace", value: props.data.id },
          });
          const emptyTemplate = createEmptyTemplate();
          const filledBlocks = await getFilledBlocks(res.data);
          const filledTemplate = replaceBlocks(emptyTemplate, filledBlocks);
          dispatch({
            type: UPDATE_CREATE_NEW_CONTRACT,
            payload: { key: "commitments", value: emptyTemplate[0] },
          });
          dispatch({
            type: UPDATE_CREATE_NEW_CONTRACT,
            payload: { key: "action_plan", value: emptyTemplate[1] },
          });
          dispatch({
            type: UPDATE_CREATE_NEW_CONTRACT,
            payload: { key: "investment_plan", value: emptyTemplate[2] },
          });

          dispatch({
            type: IS_LOADING,
          });
          SuccessAlert("Workspace opened successfully");
          props.setReset(props.reset + 1);
        }}
        style={{
          color: "#EE9B1B",
          cursor: "pointer",
          marginLeft: 7,
        }}
      ></FaDownload>
    </div>
  );
}

export default ImportWorkspace;
