import React, { useState } from "react";
import { FaTimes, FaPencilAlt } from "react-icons/fa";
import ModalDeleteActionType from "./ModalDeleteActionType/ModalDeleteActionType"
import ModalUploadActionType from "./ModalUploadActionType/ModalUploadActionType"

function ActionInventory(props) {
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);



  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <FaTimes
        onClick={() => setIsOpenDelete(true)}
        style={{
          color: "#FC2727",
          cursor: "pointer",
        }}
      ></FaTimes>
      <FaPencilAlt
        onClick={() => setIsOpenEdit(true)}
        style={{
          color: "#EE9B1B",
          cursor: "pointer",
          marginLeft: 10,
        }}
      ></FaPencilAlt>

      {isOpenDelete === true ?
        <ModalDeleteActionType isOpen={isOpenDelete} setIsOpen={setIsOpenDelete} {...props}></ModalDeleteActionType>
        : null}
      {isOpenEdit === true ?
        <ModalUploadActionType
          isOpen={isOpenEdit}
          setIsOpen={setIsOpenEdit}
          idJson={props.data.id}
        ></ModalUploadActionType>

        : null}
    </div>
  );
}

export default ActionInventory;
