import React, { useState, useEffect } from "react";
import {
  InputAdornment,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { FaEuroSign, FaSpinner } from "react-icons/fa";
import InfoAlert from "../../../../components/Modals/InfoAlert";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { useDispatch } from "react-redux";
import axios from "axios";
import { API_URL } from "../../../../actions/types";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { NumericFormat } from "react-number-format";

function ModalInvestmentDetail(props) {
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [funder, setFunder] = useState(null);
  const [amount, setAmount] = useState(0);
  const [listActors, setListActors] = useState(null);

  const dispatch = useDispatch();

  useEffect(async () => {
    if (props.isOpen === true) {
      const res = await axios.get(`${API_URL}/clicc/actors/`, tokenConfig());
      props.setListActors(res.data);
      setListActors(res.data);
    }
  }, [props.isOpen]);

  const toggle = () => props.setIsOpen(!props.isOpen);

  return (
    <Modal isOpen={props.isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} style={{ fontFamily: "Montserrat" }}>
        Add investment detail
      </ModalHeader>
      <ModalBody style={{ fontFamily: "Montserrat", fontWeight: 500 }}>
        {listActors === null ? (
          <FaSpinner className="spinner"></FaSpinner>
        ) : (
          <>
            <Row style={{ margin: 0, marginTop: 10 }}>
              <Col lg="6" md="12">
                <TextField
                  label={<div>Description*&nbsp;&nbsp;&nbsp;&nbsp;</div>}
                  value={description}
                  inputProps={{
                    style: {
                      fontWeight: 500,
                      fontFamily: "Montserrat",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      fontWeight: 500,
                      fontFamily: "Montserrat",
                    },
                  }}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row style={{ margin: 0 }}>
              <Col lg="6" md="12" style={{ marginTop: 30 }}>
                <DesktopDatePicker
                  inputFormat="DD-MM-YYYY"
                  value={startDate}
                  maxDate={endDate}
                  onChange={(newDate) => {
                    if (newDate === null) {
                      setStartDate(null);
                    } else {
                      setStartDate(newDate.format("YYYY-MM-DD"));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={<div>Start date&nbsp;&nbsp;&nbsp;</div>}
                      className="datePickerMontserrat"
                      InputLabelProps={{
                        style: {
                          fontWeight: 500,
                          fontFamily: "Montserrat",
                        },
                      }}
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </Col>
              <Col lg="6" md="12" style={{ marginTop: 30 }}>
                <DesktopDatePicker
                  inputFormat="DD-MM-YYYY"
                  value={endDate}
                  minDate={startDate}
                  onChange={(newDate) => {
                    if (newDate === null) {
                      setEndDate(null);
                    } else {
                      setEndDate(newDate.format("YYYY-MM-DD"));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={<div>End date&nbsp;&nbsp;&nbsp;</div>}
                      className="datePickerMontserrat"
                      InputLabelProps={{
                        style: {
                          fontWeight: 500,
                          fontFamily: "Montserrat",
                        },
                      }}
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row style={{ margin: 0 }}>
              <Col lg="6" md="12" style={{ marginTop: 30 }}>
                {/* <InputLabel id="select-funder">Age</InputLabel> */}

                <TextField
                  select
                  label={<div>Funder&nbsp;&nbsp;</div>}
                  value={funder}
                  SelectProps={{
                    style: {
                      height: 55,
                      fontWeight: 500,
                      fontFamily: "Montserrat",
                      paddingLeft: 5,
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      fontWeight: 500,
                      fontFamily: "Montserrat",
                    },
                  }}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setFunder(e.target.value);
                  }}
                >
                  {listActors.map((actor, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={actor.id}
                        style={{
                          fontWeight: 500,
                          fontFamily: "Montserrat",
                        }}
                      >
                        {actor.actor_business_name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Col>
              <Col lg="6" md="12" style={{ marginTop: 30 }}>
                <NumericFormat
                  value={amount}
                  customInput={TextField}
                  onValueChange={(values) => {
                    const { formattedValue, value, floatValue } = values;
                    setAmount(floatValue);
                  }}
                  thousandSeparator="."
                  decimalSeparator=","
                  label={<div>Amount&nbsp;&nbsp;&nbsp;</div>}
                  inputProps={{
                    style: {
                      fontWeight: 500,
                      fontFamily: "Montserrat",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      fontWeight: 500,
                      fontFamily: "Montserrat",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div>
                          <FaEuroSign
                            style={{ marginTop: "-2px" }}
                          ></FaEuroSign>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          onClick={() => {
            if (description === "") {
              InfoAlert("Description cannot be empty");
            } else {
              dispatch({
                type: props.type,
                payload: {
                  key: "financials_investment_detail",
                  value: [
                    ...props.data.financials_investment_detail,
                    {
                      id: new Date().getTime(),
                      description: description,
                      start_date: startDate,
                      end_date: endDate,
                      funder: funder,
                      amount: amount,
                    },
                  ],
                },
              });
              // reset modal values
              setTimeout(() => {
                setDescription("");
                setStartDate(null);
                setEndDate(null);
                setFunder(null);
                setAmount(0);
              }, 300);
              toggle();
            }
          }}
        >
          Add
        </Button>
        <Button
          color="secondary"
          style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          onClick={toggle}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalInvestmentDetail;
