import React, { useEffect, useLayoutEffect } from "react";
import { getHeightWithFooterWONavbar } from "../../../../utils/getHeightWithFooterWONavbar";
import {
  API_URL,
  GET_ERRORS,
  SET_OPENED_ACTOR,
  RESET_OPENED_ACTOR,
  IS_LOADING,
} from "../../../../actions/types";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { tokenConfig } from "../../../../utils/tokenConfig";
import axios from "axios";
import SuccessAlert from "../../../../components/Modals/SuccessAlert";
import { connect, useDispatch } from "react-redux";
import Fields from "../../NewActor/components/Fields";
import { Card } from "reactstrap";
import { Link } from "react-router-dom";
import { FaSpinner } from "react-icons/fa"
import { triggerRefresh } from "../../../../utils/triggerRefresh";
import { useHistory } from "react-router-dom";

function Content(props) {
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();
  const history = useHistory()

  const key_name = `opened_${window.location.href.split("/?id=")[0].split("_modify_")[1]}`

  // define function to get data
  const getData = async () => {
    // if there is something in local storage remove it at this point
    localStorage.removeItem(key_name)
    // if there is an id in the url, open it
    if (window.location.href.includes("?id=")) {
      // if there is an actor in another tab and you delete him, the call will crash
      try {
        const res = await axios.get(
          `${API_URL}/clicc/actors/${window.location.href.split("?id=")[1]}/`,
          tokenConfig()
        );
        dispatch({
          type: SET_OPENED_ACTOR,
          payload: res.data,
        });
      } catch {
        // see above
        history.push(`/clicc/dashboard/actors_modify_actor`);
        dispatch({
          type: RESET_OPENED_ACTOR,
        });
      }
    }
  }

  // on did mount, get data
  useEffect(async () => {
    await getData()
  }, [])

  // monitor refresh
  useEffect(() => {
    const channel = new BroadcastChannel("clicc-channel");
    channel.addEventListener("message", async e => {
      if (e.data === "refresh") {
        if (localStorage.getItem(key_name) !== null) {
          history.push(`/clicc/dashboard/actors_modify_actor/?id=${localStorage.getItem(key_name)}`);
          await getData();
        } else {
          await getData();
        }
      }
    });
    return () => channel.close()
  }, [])


  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#E4E6E8",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        paddingLeft: props.layout.width_sidebar,
        paddingBottom: props.layout.footer_height,
        overflow: "auto",
      }}
    >
      {/* workspace without padding */}
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 20,
        }}
      >
        {/* workspace */}
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          {!window.location.href.includes("/?id=") ? (
            <div style={{ padding: 10 }}>
              <Card style={{ padding: "10px 20px" }}>
                <div>
                  <Link to="/clicc/dashboard/actors_my_actors">Open</Link> an
                  actor to see the details
                </div>
              </Card>
            </div>
          ) : props.data.id === undefined ? (
            <>
              <FaSpinner
                className="spinner"
                style={{ marginTop: "-2px", marginRight: 5 }}
              ></FaSpinner>
              Loading...
            </>
          ) : (
            <div>
              <div
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 20,
                  marginBottom: 10,
                }}
              >
                Modify an actor
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  padding: "25px 10px",
                  borderRadius: 10,
                }}
              >
                <Fields data={props.data} type={props.type}></Fields>
                <div
                  style={{
                    width: "fit-content",
                    backgroundColor: props.layout.color_sidebar,
                    color: "white",
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                    fontSize: 16,
                    padding: "6px 15px 4px 15px",
                    borderRadius: 10,
                    cursor: "pointer",
                    marginTop: 20,
                    marginLeft: 12,
                  }}
                  onClick={async () => {
                    try {
                      dispatch({
                        type: IS_LOADING,
                      });
                      await axios.put(
                        `${API_URL}/clicc/actors/${props.data.id}/`,
                        props.data,
                        tokenConfig()
                      );
                      dispatch({
                        type: IS_LOADING,
                      });
                      SuccessAlert("Actor updated successfully!");
                      triggerRefresh()
                    } catch (err) {
                      dispatch({
                        type: IS_LOADING,
                      });
                      const errors = {
                        msg: err.response.data,
                        status: err.response.status,
                      };
                      dispatch({
                        type: GET_ERRORS,
                        payload: errors,
                      });
                    }
                  }}
                >
                  {props.type === "UPDATE_CREATE_NEW_ACTOR"
                    ? "CREATE"
                    : "UPDATE"}
                </div>
              </div>
              {/* fake div to prevent overlay with footer */}
              <div style={{ height: 50 }}></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Content);
