import moment from "moment"
import { getRangeNumbers } from "./getRangeNumbers"

export const generateCCDecarbonizationAction = (resMotherFn, start_date_wp, end_date_wp, gases) => {
    var objToReturn = {
        id: "global_impact",
        name: "Decarbonization due to CCs variations",
        start_date: start_date_wp,
        end_date: end_date_wp,
        is_accountable: true,
        macro_area: "Global impact",
        sector: "Global impact",
        optional_params: {}
    }

    // add emisisons
    var emissions = []
    gases.forEach(gas => {
        emissions.push({
            gas: gas,
            gwp: 0,
            emissions_avoided: gas === "co2" ? resMotherFn.cc_decarbonization : 0,
            co2_equivalent_avoided: gas === "co2" ? resMotherFn.cc_decarbonization : 0,
        })
    })

    // add total co2 equivalent avoided
    var total_co2_equivalent_avoided = emissions.find(obj => obj.gas === "co2").co2_equivalent_avoided

    // add financials (all 0)
    var financials_capex = []
    var financials_opex = []
    var financials_total_investment = 0
    var start_date_moment = moment(start_date_wp)
    var end_date_moment = moment(end_date_wp)
    if (start_date_moment.isValid() && end_date_moment.isValid()) {
        var start_year = start_date_moment.year()
        var end_year = end_date_moment.year()
        var list_years = getRangeNumbers(start_year, end_year)

        if (list_years.length > 0) {
            list_years.forEach((y, index) => {
                financials_capex[index] = 0
                financials_opex[index] = 0
            })
        }
    }

    // append all keys
    objToReturn["emissions"] = emissions
    objToReturn["total_co2_equivalent_avoided"] = total_co2_equivalent_avoided
    objToReturn["financials_capex"] = financials_capex
    objToReturn["financials_opex"] = financials_opex
    objToReturn["financials_total_investment"] = financials_total_investment

    return objToReturn
}