import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import {
  API_URL,
  UPDATE_OPENED_CONTRACT,
  IS_LOADING,
} from "../../../../actions/types";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { createEmptyTemplate } from "../../../../utils/createEmptyTemplate";
import { getFilledBlocks } from "../../../../utils/getFilledBlocks";
import { replaceBlocks } from "../../../../utils/replaceBlocks";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import SuccessAlert from "../../../../components/Modals/SuccessAlert";
import { FaSpinner, FaLeaf } from "react-icons/fa";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import { connect, useDispatch } from "react-redux";

function WorkspaceUpdate(props) {
  const { width, height } = useWindowDimensions();
  const [isOpen, setIsOpen] = useState(false);
  const [workspace, setWorkspace] = useState(null);
  const [rowData, setRowData] = useState(null);
  const dispatch = useDispatch();

  const toggle = () => setIsOpen(!isOpen);

  useEffect(async () => {
    const res = await axios.get(
      `${API_URL}/clicc/workspace/${props.data.workspace}/`,
      tokenConfig()
    );
    setWorkspace(res.data);
    const rows = res.data.pathways.map((pathway) => {
      return {
        pathway: pathway.name,
        systemic_lever: res.data.systemic_lever.find(
          (obj) => obj.id === pathway.id
        ).value,
        fields_of_action: res.data.fields_of_action.find(
          (obj) => obj.id === pathway.id
        ).value,
        co_benefit: res.data.co_benefit.find((obj) => obj.id === pathway.id)
          .value,
      };
    });
    setRowData(rows);
  }, [props.data.workspace]);

  return (
    <div>
      {workspace === null ? (
        <Row style={{ margin: 0 }}>
          <Col xs="12">
            <FaSpinner className="spinner"></FaSpinner>
          </Col>
        </Row>
      ) : (
        <div
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            marginTop: 10,
            position: "relative",
          }}
        >
          <div
            onClick={() => setIsOpen(!isOpen)}
            style={{
              width: "fit-content",
              backgroundColor: props.layout.color_sidebar,
              color: "white",
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: 16,
              padding: "6px 15px 4px 15px",
              borderRadius: 10,
              cursor: "pointer",
              position: width > 990 ? "absolute" : "",
              marginLeft: 12,
              marginBottom: 15,
              right: 10,
              top: 0,
            }}
          >
            Refresh blocks from workspace
          </div>
          <Row style={{ margin: 0 }}>
            <Col xs="12">
              <div>
                <span
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  Name:
                </span>{" "}
                {workspace.name}
              </div>
              <div style={{ marginTop: 20 }}>
                <span
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  Description:
                </span>{" "}
                {workspace.description}
              </div>
              <div style={{ marginTop: 20 }}>
                <span
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  Start date:
                </span>{" "}
                {workspace.start_date}
              </div>
              <div style={{ marginTop: 20 }}>
                <span
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  End date:
                </span>{" "}
                {workspace.end_date}
              </div>
              <div
                style={{ marginTop: 20, display: "flex", alignItems: "center" }}
              >
                <FaLeaf style={{ marginRight: 5 }}></FaLeaf>
                Pathways summary
              </div>
              {rowData !== null ? (
                <div
                  className="excel-style ag-theme-balham"
                  style={{ marginTop: 5 }}
                >
                  <AgGridReact
                    rowData={rowData}
                    domLayout="autoHeight"
                    defaultColDef={{
                      flex: 1,
                      resizable: true,
                      sortable: true,
                      filter: true,
                      filterParams: {
                        buttons: ["reset"],
                        newRowsAction: "keep",
                        caseSensitive: true
                      },
                    }}
                  >
                    <AgGridColumn field="pathway"></AgGridColumn>
                    <AgGridColumn
                      field="systemic_lever"
                      headerName="Systemic lever"
                    ></AgGridColumn>
                    <AgGridColumn
                      field="fields_of_action"
                      headerName="Fields of action"
                    ></AgGridColumn>
                    <AgGridColumn
                      field="co_benefit"
                      headerName="Co-benefit"
                    ></AgGridColumn>
                  </AgGridReact>
                </div>
              ) : null}
            </Col>
          </Row>
        </div>
      )}
      <Modal isOpen={isOpen} toggle={toggle} unmountOnClose={true}>
        <ModalHeader toggle={toggle} style={{ fontFamily: "Montserrat" }}>
          Attention
        </ModalHeader>
        <ModalBody
          style={{ fontFamily: "Montserrat", fontWeight: 500, fontSize: 14 }}
        >
          <div style={{ marginBottom: 10 }}>
            By clicking on "Refresh", data from the selected workspace will be
            injected inside the 3 documents. This operation may remove data
            inserted manually. Do you really want to proceed?
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={async () => {
              dispatch({
                type: IS_LOADING,
              });
              const emptyTemplate = createEmptyTemplate();
              const filledBlocks = await getFilledBlocks(workspace);
              const filledTemplate = replaceBlocks(emptyTemplate, filledBlocks);
              dispatch({
                type: UPDATE_OPENED_CONTRACT,
                payload: { key: "commitments", value: emptyTemplate[0] },
              });
              dispatch({
                type: UPDATE_OPENED_CONTRACT,
                payload: { key: "action_plan", value: emptyTemplate[1] },
              });
              dispatch({
                type: UPDATE_OPENED_CONTRACT,
                payload: { key: "investment_plan", value: emptyTemplate[2] },
              });
              dispatch({
                type: IS_LOADING,
              });
              SuccessAlert("Operation computed successfully!");
              toggle();
              props.setReset(props.reset + 1);
            }}
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          >
            Refresh
          </Button>
          <Button
            color="secondary"
            onClick={toggle}
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(WorkspaceUpdate);
