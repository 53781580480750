export const initializeScenario = () => {
  var toReturn = {
    name: "",
    description: "",
    start_date: "2019-01-01",
    end_date: "2030-12-31",
    ghg: [],
    co2_equivalent: [],
  };
  const ghg = ["CO2", "N2O", "F-gas", "SF6", "NF3", "CH4"];
  const domains = [
    "Transport",
    "Energy",
    "Industrial process",
    "Buildings",
    "Waste",
    "Green areas",
  ];
  const iconDomains = [
    "FaCarAlt",
    "FaBolt",
    "FaCogs",
    "FaBuilding",
    "FaTrash",
    "FaLeaf",
  ];
  // initialize ghg
  ghg.forEach((gas) => {
    toReturn["ghg"].push({
      name: gas,
      objective: null,
      uom: "t",
      delta: null,
      domains: domains.map((domain, index) => {
        return {
          name: domain,
          icon: iconDomains[index],
          objective: null,
          uom: "t",
          delta: null,
        };
      }),
    });
  });
  // initialize co2 equivalent per domain
  domains.forEach((domain, index) => {
    toReturn["co2_equivalent"].push({
      name: domain,
      icon: iconDomains[index],
      objective: null,
      uom: "t",
      delta: null,
    });
  });
  // add totals
  toReturn["total_objective"] = null;
  toReturn["total_objective_uom"] = "t";
  toReturn["total_delta"] = null;
  // initialize pathways
  toReturn["pathways"] = [];
  toReturn["displayed_pathway"] = null;
  // initialize other fields
  toReturn["fields_of_action"] = [];
  toReturn["systemic_lever"] = [];
  toReturn["co_benefit"] = [];
  toReturn["barriers_system_and_stakeholder"] = [];
  toReturn["barriers_description_systemic_barriers"] = "";
  toReturn["barriers_description_participatory_model"] = "";
  // baseline
  toReturn["baseline_areas"] = [
    {
      macro_area: "Stationary energy",
      settore: "Municipal",
      sottosettore: "Municipal Buildings",
    },
    {
      macro_area: "Stationary energy",
      settore: "Municipal",
      sottosettore: "Public lighting",
    },
    {
      macro_area: "Stationary energy",
      settore: "Residential",
      sottosettore: "",
    },
    {
      macro_area: "Stationary energy",
      settore: "Tertiary",
      sottosettore: "",
    },
    {
      macro_area: "Stationary energy",
      settore: "Industrial",
      sottosettore: "Non-ETS",
    },
    {
      macro_area: "Stationary energy",
      settore: "Incinerator",
      sottosettore: "",
    },
    {
      macro_area: "Transport",
      settore: "Municipal vehicles",
      sottosettore: "Mopeds",
    },
    {
      macro_area: "Transport",
      settore: "Municipal vehicles",
      sottosettore: "Motorcycles",
    },
    {
      macro_area: "Transport",
      settore: "Municipal vehicles",
      sottosettore: "Personal transport vehicles",
    },
    {
      macro_area: "Transport",
      settore: "Municipal vehicles",
      sottosettore: "Trucks p.t.w. < 3500 kg",
    },
    {
      macro_area: "Transport",
      settore: "Municipal vehicles",
      sottosettore: "Trucks 3500 kg < p.t.w. < 12000 kg",
    },
    {
      macro_area: "Transport",
      settore: "Municipal vehicles",
      sottosettore: "Trucks p.t.w. > 12000 kg",
    },
    {
      macro_area: "Transport",
      settore: "Private transportation",
      sottosettore: "Cars",
    },
    {
      macro_area: "Transport",
      settore: "Private transportation",
      sottosettore: "Motorcycles",
    },
    {
      macro_area: "Transport",
      settore: "Private transportation",
      sottosettore: "Industrial vehicles",
    },
    {
      macro_area: "Transport",
      settore: "Private transportation",
      sottosettore: "Buses (excluding GTT city buses)",
    },
    {
      macro_area: "Transport",
      settore: "Public transportation",
      sottosettore: "Tram network",
    },
    {
      macro_area: "Transport",
      settore: "Public transportation",
      sottosettore: "GTT railway network",
    },
    {
      macro_area: "Transport",
      settore: "Public transportation",
      sottosettore: "Trenitalia rail network",
    },
    {
      macro_area: "Transport",
      settore: "Public transportation",
      sottosettore: "Subway",
    },
    {
      macro_area: "Transport",
      settore: "Public transportation",
      sottosettore: "Urban vehicles",
    },
    {
      macro_area: "Waste",
      settore: "Sorted waste treatment",
      sottosettore: "",
    },
    {
      macro_area: "Waste",
      settore: "Wastewater treatment",
      sottosettore: "",
    },
    {
      macro_area: "Waste",
      settore: "Landfill",
      sottosettore: "",
    },
    {
      macro_area: "IPPU",
      settore: "--",
      sottosettore: "",
    },
    {
      macro_area: "AFOLU",
      settore: "Urban",
      sottosettore: "Absorption from urban greenery",
    },
  ];
  toReturn["baseline_commodities"] = [
    { commodity: "Electricity" },
    { commodity: "District heating" },
    { commodity: "Natural gas" },
    { commodity: "LPG" },
    { commodity: "Fuel oil" },
    { commodity: "Diesel" },
    { commodity: "Gasoline" },
    { commodity: "Biomass" },
    { commodity: "Solar Thermal" },
    { commodity: "Municipal solid waste" },
  ];
  const gases = ["co2", "f_gas", "nf3", "n2o", "sf6", "ch4"];

  toReturn["gases"] = gases;
  toReturn["baseline_co2_equivalente"] = [];
  gases.forEach((gas) => {
    toReturn["baseline_ghg_" + gas] = [];
  });

  const commodityDict = {};
  toReturn["baseline_commodities"].forEach((element) => {
    const key = Object.values(element);
    commodityDict[key] = 0;
  });
  toReturn["baseline_areas"].forEach((row) => {
    [
      ...gases.map((g) => {
        return "baseline_ghg_" + g;
      }),
      "baseline_co2_equivalente",
    ].forEach((gas) => {
      toReturn[gas].push({
        ...row,
        ...commodityDict,
        "Gas emissions [t/y]": 0,
      });
    });
  });

  // insert default manual baseline
  const manual_baseline = [
    {
      "macro_area": "Stationary energy",
      "settore": "Municipal",
      "sottosettore": "Municipal Buildings",
      "Electricity": 13620,
      "District heating": 22550,
      "Natural gas": 18141,
    },
    {
      "macro_area": "Stationary energy",
      "settore": "Municipal",
      "sottosettore": "Public lighting",
      "Electricity": 14504,
    },
    {
      "macro_area": "Stationary energy",
      "settore": "Residential",
      "sottosettore": "",
      "Electricity": 209871,
      "District heating": 205938,
      "Natural gas": 287692,
      "LPG": 3150,
      "Fuel oil": 5357,
    },
    {
      "macro_area": "Stationary energy",
      "settore": "Tertiary",
      "sottosettore": "",
      "Electricity": 276005,
      "District heating": 58241,
      "Natural gas": 190292,
    },
    {
      "macro_area": "Stationary energy",
      "settore": "Industrial",
      "sottosettore": "Non-ETS",
      "Electricity": 58141,
      "Natural gas": 271571,
    },
    {
      "macro_area": "Stationary energy",
      "settore": "Incinerator",
      "sottosettore": "",
      "Municipal solid waste": 99351,
    },
    {
      "macro_area": "Transport",
      "settore": "Municipal vehicles",
      "sottosettore": "Mopeds",
      "Gasoline": 1.74,
    },
    {
      "macro_area": "Transport",
      "settore": "Municipal vehicles",
      "sottosettore": "Motorcycles",
      "Gasoline": 16.43,
    },
    {
      "macro_area": "Transport",
      "settore": "Municipal vehicles",
      "sottosettore": "Personal transport vehicles",
      "Electricity": 2.21,
      "Natural gas": 93.12,
      "LPG": 28.88,
      "Diesel": 181.56,
      "Gasoline": 328.43
    },
    {
      "macro_area": "Transport",
      "settore": "Municipal vehicles",
      "sottosettore": "Trucks p.t.w. < 3500 kg",
      "Electricity": 4.41,
      "Natural gas": 8.69,
      "Diesel": 56.87,
      "Gasoline": 23.16
    },
    {
      "macro_area": "Transport",
      "settore": "Municipal vehicles",
      "sottosettore": "Trucks 3500 kg < p.t.w. < 12000 kg",
      "Diesel": 2.94,
    },
    {
      "macro_area": "Transport",
      "settore": "Municipal vehicles",
      "sottosettore": "Trucks p.t.w. > 12000 kg",
      "Diesel": 1.07,
    },
    {
      "macro_area": "Transport",
      "settore": "Private transportation",
      "sottosettore": "Cars",
      "Electricity": 67.62,
      "Natural gas": 6921.53,
      "LPG": 34869.22,
      "Diesel": 131470.53,
      "Gasoline": 214092.44
    },
    {
      "macro_area": "Transport",
      "settore": "Private transportation",
      "sottosettore": "Motorcycles",
      "Electricity": 0.98,
      "Gasoline": 23050.93
    },
    {
      "macro_area": "Transport",
      "settore": "Private transportation",
      "sottosettore": "Industrial vehicles",
      "Electricity": 138.82,
      "Natural gas": 3794.37,
      "LPG": 2882.19,
      "Diesel": 150645.14,
      "Gasoline": 6032.27
    },
    {
      "macro_area": "Transport",
      "settore": "Private transportation",
      "sottosettore": "Buses (excluding GTT city buses)",
      "Diesel": 8506.35,
      "Gasoline": 144.42
    },
    {
      "macro_area": "Transport",
      "settore": "Public transportation",
      "sottosettore": "Tram network",
      "Electricity": 6396.22,
    },
    {
      "macro_area": "Transport",
      "settore": "Public transportation",
      "sottosettore": "GTT railway network",
      "Electricity": 338.10,
    },
    {
      "macro_area": "Transport",
      "settore": "Public transportation",
      "sottosettore": "Trenitalia rail network",
      "Electricity": 1014.06,
    },
    {
      "macro_area": "Transport",
      "settore": "Public transportation",
      "sottosettore": "Subway",
      "Electricity": 4035.40,
    },
    {
      "macro_area": "Transport",
      "settore": "Public transportation",
      "sottosettore": "Urban vehicles",
      "Natural gas": 12484.61,
      "Diesel": 28641.89,
    },
    {
      "macro_area": "Waste",
      "settore": "Sorted waste treatment",
      "sottosettore": "",
      "Municipal solid waste": 23796,
    },
    {
      "macro_area": "Waste",
      "settore": "Wastewater treatment",
      "sottosettore": "",
      "Municipal solid waste": 15730,
    },
    {
      "macro_area": "Waste",
      "settore": "Landfill",
      "sottosettore": "",
      "Municipal solid waste": 21761,
    },
    {
      "macro_area": "AFOLU",
      "settore": "Urban",
      "sottosettore": "Absorption from urban greenery",
      "Gas emissions [t/y]": -35117,
    },

  ]
  const keys_manual_baseline = ["baseline_ghg_co2", "baseline_co2_equivalente"]
  keys_manual_baseline.forEach(k => {
    toReturn[k].forEach((baseline_row, index) => {
      var find_match = manual_baseline.find(mr => mr.macro_area === baseline_row.macro_area && mr.settore === baseline_row.settore && mr.sottosettore === baseline_row.sottosettore)
      if (find_match !== undefined) {
        toReturn[k][index] = { ...baseline_row, ...find_match }
      }
    })
  })


  toReturn["notes"] = "";
  toReturn["insert_manual_values"] = false;
  toReturn["default"] = false;
  toReturn["gwps"] = [
    {
      gas: "co2",
      gwp: 1,
    },
    {
      gas: "f_gas",
      gwp: 0,
    },
    {
      gas: "nf3",
      gwp: 16100,
    },
    {
      gas: "n2o",
      gwp: 265,
    },
    {
      gas: "sf6",
      gwp: 23500,
    },
    {
      gas: "ch4",
      gwp: 28,
    },
  ];

  // target
  gases.forEach(gas => {
    return toReturn[`target_ghg_${gas}`] = []
  })
  toReturn["target_co2_equivalente"] = []
  // initialize CCs
  toReturn["CCs"] = {}
  toReturn["use_motherfunctions_ccs"] = true

  return toReturn;
};
