import React from "react";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
import { Row, Col, Card } from "reactstrap";
import { FaEuroSign } from "react-icons/fa";
import { useDispatch } from "react-redux";
import SingleParam from "./SingleParam";
import { recurrentUpdate } from "../../../../../utils/recurrentUpdate";

function Dropdown(props) {
  const dispatch = useDispatch();

  const updateRedux = (newValue) => {
    props.data.details.parameters.forEach((obj) =>
      recurrentUpdate(obj, props.param, newValue)
    );
    dispatch({
      type: props.type,
      payload: {
        key: "details",
        value: props.data.details,
      },
    });
  };
  return (
    <Card body style={{ margin: 0, marginTop: 20 }}>
      <Row style={{ margin: 0 }}>
        <Col lg="4" md="12" style={{ padding: 0 }}>
          <div style={{ fontFamily: "Montserrat" }}>
            {props.param.paramName}
          </div>
          <div
            style={{ fontFamily: "Montserrat", fontWeight: 500, fontSize: 12 }}
          >
            {props.param.paramDescription}
          </div>
          <TextField
            select
            label={<div>Value&nbsp;</div>}
            value={props.param.default}
            InputProps={{
              style: {
                height: 56,
                fontWeight: 500,
                fontFamily: "Montserrat",
              },
            }}
            InputLabelProps={{
              style: {
                fontWeight: 500,
                fontFamily: "Montserrat",
              },
            }}
            style={{
              width: "100%",
              marginTop: 15,
            }}
            onChange={(e) => {
              updateRedux(e.target.value);
            }}
          >
            {props.param.options.map((obj) => {
              return (
                <MenuItem
                  value={obj.id}
                  style={{ fontWeight: 500, fontFamily: "Montserrat" }}
                >
                  {obj.label}
                </MenuItem>
              );
            })}
          </TextField>
        </Col>
      </Row>
      {props.param.default !== null ? (
        <Row>
          <Col xs="12">
            {props.param.options.map((obj) => {
              if (
                obj.id === props.param.default &&
                obj.parameters !== undefined
              ) {
                return obj.parameters.map((param) => {
                  return (
                    <SingleParam
                      param={param}
                      data={props.data}
                      type={props.type}
                    ></SingleParam>
                  );
                });
              }
            })}
          </Col>
        </Row>
      ) : null}
    </Card>
  );
}

export default Dropdown;
