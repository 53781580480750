import React, { useState, useEffect } from "react";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { connect, useDispatch } from "react-redux";
import { getHeightWithFooterWONavbar } from "../../../../utils/getHeightWithFooterWONavbar";
import classnames from "classnames";
import {
  Card,
  Modal,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Index from "../../NewAction/components/Index";
import NetZeroClassification from "../../NewAction/components/NetZeroClassification";
import Actor from "../../NewAction/components/Actor";
import Financials from "../../NewAction/components/Financials";
import Specify from "../../NewAction/components/Specify";
import CO2EQAvoided from "../../NewAction/components/CO2EQAvoided";
import Notes from "../../NewAction/components/Notes";
import CreateOrUpdate from "../../NewAction/components/CreateOrUpdate";
import { useHistory, Link } from "react-router-dom";
import { tokenConfig } from "../../../../utils/tokenConfig";
import axios from "axios";
import {
  API_URL,
  RESET_OPENED_ACTION,
  SET_OPENED_ACTION,
  UPDATE_OPENED_ACTION,
} from "../../../../actions/types";
import { FaSpinner } from "react-icons/fa";

function Content(props) {
  const [activeTab, setActiveTab] = useState(1);
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();
  const history = useHistory();

  const key_name = `opened_${window.location.href.split("/?id=")[0].split("_modify_")[1]
    }`;

  // define function to get data
  const getData = async () => {
    // if there is something in local storage remove it at this point
    localStorage.removeItem(key_name);
    // if there is an id in the url, open it
    if (window.location.href.includes("?id=")) {
      // if there is an actor in another tab and you delete him, the call will crash
      try {
        const res = await axios.get(
          `${API_URL}/clicc/actions/${window.location.href.split("?id=")[1]}/`,
          tokenConfig()
        );
        dispatch({
          type: SET_OPENED_ACTION,
          payload: res.data,
        });
        if (res.data.actor !== null) {
          const resActor = await axios.get(
            `${API_URL}/clicc/actors/${res.data.actor}/`,
            tokenConfig()
          );
          Object.keys(resActor.data).forEach((k) => {
            if (k !== "id") {
              dispatch({
                type: UPDATE_OPENED_ACTION,
                payload: { key: k, value: resActor.data[k] },
              });
            }
          });
        }
      } catch {
        // see above
        history.push(`/clicc/dashboard/actions_modify_action`);
        dispatch({
          type: RESET_OPENED_ACTION,
        });
      }
    }
  };

  // on did mount, get data
  useEffect(async () => {
    await getData();
  }, []);

  // monitor refresh
  useEffect(() => {
    const channel = new BroadcastChannel("clicc-channel");
    channel.addEventListener("message", async (e) => {
      if (e.data === "refresh") {
        if (localStorage.getItem(key_name) !== null) {
          history.push(
            `/clicc/dashboard/actions_modify_action/?id=${localStorage.getItem(
              key_name
            )}`
          );
          await getData();
        } else {
          await getData();
        }
      }
    });
    return () => channel.close();
  }, []);

  const styleTabPane = {
    ...props.layout.generic_card,
    height: "95%",
    overflowY: "hidden",
    backgroundColor: "white",
    marginTop: "-1px",
    borderTopRightRadius: 15,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
    padding: 10,
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#E4E6E8",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty,
          props.layout.subnavbar_height_not_empty
        ),
        paddingLeft: props.layout.width_sidebar,
        paddingBottom: props.layout.footer_height,
        overflow: "auto",
      }}
    >
      {/* workspace without padding */}
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 10,
        }}
      >
        {/* workspace */}
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          {!window.location.href.includes("/?id=") ? (
            <div style={{ padding: 10 }}>
              <Card style={{ padding: "10px 20px" }}>
                <div>
                  <Link to="/clicc/dashboard/actions_my_actions">Open</Link> an
                  action to see the details
                </div>
              </Card>
            </div>
          ) : props.data.name === undefined ? (
            <>
              <FaSpinner
                className="spinner"
                style={{ marginTop: "-2px", marginRight: 5 }}
              ></FaSpinner>
              Loading...
            </>
          ) : (
            <div style={{ padding: 10, height: "100%", position: "relative" }}>
              <div style={{ position: "absolute", right: 15, top: 15 }}>
                {props.data.details.length > 1 ? (
                  <div
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: 10,
                      backgroundColor: "purple",
                      color: "white",
                      padding: "2px 8px",
                      borderRadius: 10,
                    }}
                  >
                    GROUPED
                  </div>
                ) : null}
              </div>
              <Nav tabs style={{ width: "90%" }}>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 1,
                      activeWhite: activeTab === 1,
                    })}
                    onClick={() => {
                      setActiveTab(1);
                    }}
                    style={{
                      fontSize: 14,
                      backgroundColor:
                        activeTab === 1 ? "white" : "transparent",
                    }}
                  >
                    INDEX
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 2,
                      activeWhite: activeTab === 2,
                    })}
                    onClick={() => {
                      setActiveTab(2);
                    }}
                    style={{
                      fontSize: 14,
                      backgroundColor:
                        activeTab === 2 ? "white" : "transparent",
                    }}
                  >
                    NET-ZERO
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 3,
                      activeWhite: activeTab === 3,
                    })}
                    onClick={() => {
                      setActiveTab(3);
                    }}
                    style={{
                      fontSize: 14,
                      backgroundColor:
                        activeTab === 3 ? "white" : "transparent",
                    }}
                  >
                    ACTOR
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 5,
                      activeWhite: activeTab === 5,
                    })}
                    onClick={() => {
                      setActiveTab(5);
                    }}
                    style={{
                      fontSize: 14,
                      backgroundColor:
                        activeTab === 5 ? "white" : "transparent",
                    }}
                  >
                    SPECIFY
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 6,
                      activeWhite: activeTab === 6,
                    })}
                    onClick={() => {
                      setActiveTab(6);
                    }}
                    style={{
                      fontSize: 14,
                      backgroundColor:
                        activeTab === 6 ? "white" : "transparent",
                    }}
                  >
                    EMISSIONS AVOIDED
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 4,
                      activeWhite: activeTab === 4,
                    })}
                    onClick={() => {
                      setActiveTab(4);
                    }}
                    style={{
                      fontSize: 14,
                      backgroundColor:
                        activeTab === 4 ? "white" : "transparent",
                    }}
                  >
                    FUNDING
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 7,
                      activeWhite: activeTab === 7,
                    })}
                    onClick={() => {
                      setActiveTab(7);
                    }}
                    style={{
                      fontSize: 14,
                      backgroundColor:
                        activeTab === 7 ? "white" : "transparent",
                    }}
                  >
                    NOTES
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 8,
                      activeWhite: activeTab === 8,
                    })}
                    onClick={() => {
                      setActiveTab(8);
                    }}
                    style={{
                      fontSize: 14,
                      backgroundColor:
                        activeTab === 8 ? "white" : "transparent",
                    }}
                  >
                    {props.type === "UPDATE_CREATE_NEW_ACTION"
                      ? "CREATE"
                      : "UPDATE"}
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab} style={{ height: "100%" }}>
                <TabPane tabId={1} style={styleTabPane}>
                  <Index data={props.data} type={props.type}></Index>
                </TabPane>
                <TabPane tabId={2} style={styleTabPane}>
                  <NetZeroClassification
                    data={props.data}
                    type={props.type}
                  ></NetZeroClassification>
                </TabPane>
                <TabPane tabId={3} style={styleTabPane}>
                  <Actor data={props.data} type={props.type}></Actor>
                </TabPane>
                <TabPane tabId={4} style={styleTabPane}>
                  <Financials data={props.data} type={props.type}></Financials>
                </TabPane>
                {activeTab === 5 ? (
                  <TabPane tabId={5} style={styleTabPane}>
                    <Specify data={props.data} type={props.type}></Specify>
                  </TabPane>
                ) : null}

                {activeTab === 6 ? (
                  <TabPane tabId={6} style={styleTabPane}>
                    <CO2EQAvoided
                      data={props.data}
                      type={props.type}
                    ></CO2EQAvoided>
                  </TabPane>
                ) : null}
                <TabPane tabId={7} style={styleTabPane}>
                  <Notes data={props.data} type={props.type}></Notes>
                </TabPane>
                <TabPane tabId={8} style={styleTabPane}>
                  <CreateOrUpdate
                    data={props.data}
                    type={props.type}
                  ></CreateOrUpdate>
                </TabPane>
              </TabContent>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Content);
