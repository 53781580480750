import React, { useState, useEffect, useRef } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { Row, Col, Card } from "reactstrap";
import { FaEuroSign } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { recurrentUpdate } from "../../../../../../utils/recurrentUpdate";
import { useWindowDimensions } from "../../../../../../utils/useWindowDimensions";
import { applyTransactionFn } from "../../../../../../utils/applyTransactionFn";
import { FaSpinner } from "react-icons/fa"
import { AgGridReact } from "ag-grid-react";


function TableEditable(props) {
    const [rowData, setRowData] = useState(null)
    const [columnDefs, setColumnDefs] = useState(null)
    const { width, height } = useWindowDimensions()

    const dispatch = useDispatch();

    const updateRedux = (newValue) => {
        props.data.details.parameters.forEach((obj) =>
            recurrentUpdate(obj, props.param, newValue)
        );
        dispatch({
            type: props.type,
            payload: {
                key: "details",
                value: props.data.details,
            },
        });
    };

    const getData = async () => {
        var rows = []
        var cols = []
        if (props.param.default.length > 0) {
            rows = props.param.default
            cols = props.param.columns_config
        }

        return [rows, cols]
    };


    const grid = useRef();
    const mounted = useRef();
    useEffect(async () => {
        if (!mounted.current) {
            var res = await getData()
            setRowData(res[0]);
            setColumnDefs(res[1]);
            mounted.current = true;
        } else {
            const newRows_temp = await getData();
            const newRows = newRows_temp[0];
            const arr_checks = ["id"];
            if (newRows.length >= 1) {
                applyTransactionFn(grid, newRows, arr_checks, true);
            }
        }
    }, [props.param.default]);


    var isDragging = false;
    var listChanges = [];
    const fnCellChanged = async (myList) => {
        var raise_error = false;
        var listFiltered = [];
        myList.forEach((params) => {
            // check if there are no letters or commas --> if yes show error and set newValue = oldValue
            if (
                params.newValue === "" ||
                params.newValue === undefined ||
                params.newValue === null
            ) {
                params.data[params.colDef.field] = parseFloat(params.oldValue);
                params.newValue = parseFloat(params.oldValue);
            }
            listFiltered.push(params);
        });

        var newRows = []
        props.param.default.forEach(obj => {
            var find_row = listFiltered.find(params => params.data.id === obj.id)
            if (find_row === undefined) {
                newRows.push(obj)
            } else {
                newRows.push(find_row.data)
            }
        })

        updateRedux(newRows)
        // reset list
        listChanges = [];
    };


    return (
        <Card body style={{ marginTop: 20 }}>
            <Row style={{ margin: 0 }}>
                <Col xs="12" style={{ padding: 0 }}>
                    <div style={{ fontFamily: "Montserrat" }}>
                        {props.param.paramName}
                    </div>
                    <div
                        style={{ fontFamily: "Montserrat", fontWeight: 500, fontSize: 12 }}
                    >
                        {props.param.paramDescription}
                    </div>
                    {rowData === null && columnDefs === null ?
                        <FaSpinner
                            icon="spinner"
                            className="spinner"
                            style={{ fontSize: 25, color: "#5B5B5B", marginTop: 15 }}
                        /> :
                        <div className="ag-theme-balham" style={{
                            height: height - 400,
                            marginTop: 15
                        }}>
                            <AgGridReact
                                ref={grid}
                                // getRowId={(params) => params.data.id}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                defaultColDef={{
                                    flex: 1,
                                    sortable: true,
                                    resizable: true,
                                    filter: true,
                                    filterParams: {
                                        buttons: ["reset"],
                                        newRowsAction: "keep",
                                        caseSensitive: true
                                    },
                                }}
                                enableFillHandle={true}
                                enableRangeSelection={true}
                                stopEditingWhenCellsLoseFocus={true}
                                processDataFromClipboard={(params) => {
                                    var data = [];
                                    for (var i = 0; i < params.data.length; i++) {
                                        if (
                                            params.data[i].length !== 1 ||
                                            (params.data[i][0] !== "" && params.data[i][0] !== " ")
                                        ) {
                                            data.push(params.data[i]);
                                        }
                                    }
                                    return data;
                                }}
                                onCellValueChanged={(params) => {
                                    listChanges.push(params);
                                    if (params.source !== "paste" && isDragging === false) {
                                        fnCellChanged(listChanges);
                                    }
                                }}
                                onPasteEnd={(params) => {
                                    fnCellChanged(listChanges);
                                }}
                                onDragStarted={(params) => {
                                    isDragging = true;
                                }}
                                onDragStopped={(params) => {
                                    isDragging = false;
                                    // check if there are no changes because isDragging becomes true even if you simply drag without using the fill operations
                                    if (listChanges.length > 0) {
                                        fnCellChanged(listChanges);
                                    }
                                }}
                            >
                            </AgGridReact>
                        </div>

                    }
                </Col>
            </Row>
        </Card>
    );
}

export default TableEditable;
