export function checkArraysEqual(array1, array2) {
    // Check if the arrays are the same length
    if (array1.length !== array2.length) {
        return false;
    }

    // Compare each element
    for (let i = 0; i < array1.length; i++) {
        if (array1[i] !== array2[i]) {
            return false;
        }
    }

    // If we reach this point, the arrays are equal
    return true;
}