import React from "react"

const Summary = (props) => {
    return (
        <div>
            Summary files:
            <ul style={{ marginTop: 10 }}>
                <li><span style={{ textDecoration: "underline" }}>Json file uploaded</span>: {props.json.file_name}</li>
                <li><span style={{ textDecoration: "underline" }}>Python file uploaded</span>: {props.python.name}</li>
            </ul>
        </div>
    )
};

export default Summary;
