import {} from "../actions/types";

const initialState = {
  width_sidebar: 50,
  color_sidebar: "#259565",
  color_border_button_sidebar: "#61D6A4",
  color_background_button_sidebar: "#52B98C",
  height_button_submenu: 30,
  color_navbar: "white",
  subnavbar_height_empty: 6,
  subnavbar_height_not_empty: 30,
  color_main_content_background: "#E4E6E8",
  color_footer: "#1A2546",
  footer_height: 35,
  yellow_detail: "#FFCC00",
  generic_card: {
    width: "100%",
    height: "100%",
    borderRadius: 20,
    backgroundColor: "white",
    boxShadow: "1px 1px 5px rgb(133,164,204,0.7)",
  },
};

export default function toolbar(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
