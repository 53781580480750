import React, { useEffect, useState, useRef } from "react";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { connect, useDispatch } from "react-redux";
import { applyTransactionFn } from "../../../../utils/applyTransactionFn";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import { FaSpinner } from "react-icons/fa";
import axios from "axios";
import { API_URL } from "../../../../actions/types";
import { tokenConfig } from "../../../../utils/tokenConfig";
import ImportWorkspace from "./ImportWorkspace";
// import StatusContract from "./StatusContract";
import moment from "moment";

function Workspace(props) {
  const [rowData, setRowData] = useState(null);
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();

  // grid ref
  const myGrid = useRef()

  // define function to get data
  const getData = async () => {
    const res = await axios.get(`${API_URL}/clicc/workspace/`, tokenConfig());
    return res.data
  }

  // on did mount, get data
  useEffect(async () => {
    setRowData(await getData());
  }, []);

  // monitor refresh
  useEffect(() => {
    const channel = new BroadcastChannel("clicc-channel");
    channel.addEventListener("message", async e => {
      if (e.data === "refresh") {
        // get data and apply changes
        const newRows = await getData();
        const arr_checks = ["id"];
        if (newRows.length >= 1) {
          applyTransactionFn(myGrid, newRows, arr_checks, true);
        }
      }
    });
    return () => channel.close()
  }, [])

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        padding: 10,
      }}
    >
      {rowData === null ? (
        <>
          <FaSpinner
            className="spinner"
            style={{ marginTop: "-2px", marginRight: 5 }}
          ></FaSpinner>
          Loading...
        </>
      ) : (
        <div
          className="excel-style ag-theme-balham"
          style={{
            height: height - 280,
          }}
        >
          <AgGridReact
            ref={myGrid}
            rowData={rowData}
            frameworkComponents={{
              importWorkspace: ImportWorkspace,
            }}
            defaultColDef={{
              flex: 1,
              resizable: true,
              sortable: true,
              filter: true,
              filterParams: { buttons: ["reset"], newRowsAction: "keep", caseSensitive: true },
              cellStyle: (params) => {
                var style = {
                  cursor: "default",
                  display: "flex",
                  alignItems: "center",
                };
                if (params.column.colId === "status") {
                  style["justifyContent"] = "center";
                }
                return style;
              },
            }}
          >
            <AgGridColumn
              field=""
              maxWidth={40}
              minWidth={40}
              suppressMenu={true}
              cellRenderer="importWorkspace"
              cellRendererParams={{
                reset: props.reset,
                setReset: props.setReset,
              }}
            ></AgGridColumn>
            {/* <AgGridColumn
              field="status"
              maxWidth={100}
              minWidth={100}
              suppressMenu={true}
              cellRenderer="statusContract"
            ></AgGridColumn> */}
            <AgGridColumn field="name" headerName="Workspace"></AgGridColumn>
            <AgGridColumn field="description"></AgGridColumn>
            <AgGridColumn
              field="start_date"
              headerName="Start date"
              valueFormatter={(params) => {
                return moment(params.value).format("DD-MM-YYYY");
              }}
            ></AgGridColumn>
            <AgGridColumn
              field="end_date"
              headerName="End date"
              valueFormatter={(params) => {
                return moment(params.value).format("DD-MM-YYYY");
              }}
            ></AgGridColumn>
            <AgGridColumn
              field="creation_date"
              headerName="Creation date"
              valueFormatter={(params) => {
                return moment(params.value).format("DD-MM-YYYY");
              }}
            ></AgGridColumn>
          </AgGridReact>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Workspace);
