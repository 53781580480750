import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { FaTimes, FaCheck } from "react-icons/fa";

function IsAccountableColumn(props) {
  return (
    <div>
      <Tooltip title={props.isAccountable ? "Accountable" : "Non-accountable"}>
        <div>
          {props.isAccountable === true ? (
            <FaCheck color={"green"}></FaCheck>
          ) : props.isAccountable === false ? (
            <FaTimes color={"red"}></FaTimes>
          ) : null}
        </div>
      </Tooltip>
    </div>
  );
}

export default IsAccountableColumn;
