import React, { useState } from "react";
import { FaTimes, FaPencilAlt } from "react-icons/fa";
import ModalDeleteUser from "./ModalDeleteUser/ModalDeleteUser"
import ModalEditUser from "./ModalEditUser/ModalEditUser"
import { checkPermission } from "../../../utils/checkPermission";

function ActionUsers(props) {
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [isOpenEdit, setIsOpenEdit] = useState(false);

    const toggleEdit = () => setIsOpenEdit(!isOpenEdit);
    return (
        <div>
            {checkPermission(props.auth.user_permissions, "Can delete user") === true ?
                <FaTimes
                    onClick={() => setIsOpenDelete(true)}
                    style={{
                        color: "#FC2727",
                        cursor: "pointer",
                    }}
                ></FaTimes> : null
            }

            {checkPermission(props.auth.user_permissions, "Can change user") === true ?
                <FaPencilAlt
                    onClick={toggleEdit}
                    style={{
                        color: "#EE9B1B",
                        cursor: "pointer",
                        marginLeft: checkPermission(props.auth.user_permissions, "Can delete user") === true ? 10 : 0,
                    }}
                ></FaPencilAlt> : null
            }

            {isOpenDelete === true ?
                <ModalDeleteUser isOpen={isOpenDelete} setIsOpen={setIsOpenDelete} {...props}></ModalDeleteUser>
                : null}
            {isOpenEdit === true ?
                <ModalEditUser isOpen={isOpenEdit} setIsOpen={setIsOpenEdit} {...props}></ModalEditUser>
                : null}

        </div>
    );
}

export default ActionUsers;
