import React, { useState } from "react";
import PythonIcon from "../../../../../images/pythonIcon.png"
import { saveAs } from "file-saver";
import { API_URL } from "../../../../actions/types";

function DownloadPython(props) {


  return (
    <div style={{ display: "flex", justifyContent: "center", }}>
      <img
        src={PythonIcon}
        onClick={() => {

          const xhr = new XMLHttpRequest();
          xhr.responseType = "arraybuffer";
          xhr.open("GET", `${API_URL}/clicc/actions_inventory/${props.data.id}/download_python_file/`, true);
          xhr.setRequestHeader(
            "Authorization",
            `Token ${localStorage.getItem("token")}`
          );

          xhr.onload = function () {
            var filename = "download.py";
            var type = xhr.getResponseHeader("Content-Type");

            var blob = new Blob([this.response], { type: type });
            saveAs(blob, filename);

          };

          xhr.send();

        }}
        style={{ marginLeft: 25, width: 15, height: 15, cursor: "pointer" }}></img>
    </div>
  );
}

export default DownloadPython;
