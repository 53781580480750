import { TextField } from "@mui/material";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";
import { getIconMapped } from "../../../../../utils/getIconMapped";
import { useWindowDimensions } from "../../../../../utils/useWindowDimensions";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import moment from "moment";

function CO2Equivalent(props) {
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();
  return (
    <Row style={{ margin: 0 }}>
      {props.co2_equivalent.map((card) => {
        return (
          <Col xxl="4" xl="6" lg="6" md="12">
            <div
              style={{
                height: height < 780 ? height * 0.18 : height * 0.14,
                display: "flex",
                alignItems: "center",
                fontFamily: "Montserrat",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: 28,
                  height: 28,
                  marginLeft: "-13px",
                  borderRadius: "50%",
                  backgroundColor: props.layout.color_footer,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {getIconMapped(card.icon, { color: "white" })}
              </div>
              <div
                style={{
                  border: "1px solid #E2E2E2",
                  borderRadius: 10,
                  width: "100%",
                  height: "90%",
                  display: "flex",
                  flexFlow: "column",
                }}
              >
                <div
                  style={{
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    backgroundColor: props.layout.color_sidebar,
                    color: "white",
                    padding: "3px 10px",
                    fontSize: 14,
                  }}
                >
                  {card.name}
                </div>
                <div
                  style={{
                    padding: 10,
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      // padding: "10px 0px",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          fontWeight: "bold",
                          fontSize: 26,
                          marginBottom: "-3px",
                        }}
                      >
                        <TextField
                          variant="standard"
                          value={card.objective}
                          onChange={(e) => {
                            if (props.key_redux === "co2_equivalent") {
                              dispatch({
                                type: props.type,
                                payload: {
                                  key: props.key_redux,
                                  value: props.co2_equivalent.map((domain) => {
                                    if (domain.name !== card.name) {
                                      return domain;
                                    } else {
                                      return {
                                        ...domain,
                                        objective:
                                          e.target.value === ""
                                            ? null
                                            : e.target.value,
                                      };
                                    }
                                  }),
                                },
                              });
                            } else if (props.key_redux === "ghg") {
                              dispatch({
                                type: props.type,
                                payload: {
                                  key: props.key_redux,
                                  value: props.ghg.map((obj) => {
                                    if (obj.name !== props.ghgSelected) {
                                      return obj;
                                    } else {
                                      return {
                                        ...obj,
                                        domains: props.co2_equivalent.map(
                                          (domain) => {
                                            if (domain.name !== card.name) {
                                              return domain;
                                            } else {
                                              return {
                                                ...domain,
                                                objective:
                                                  e.target.value === ""
                                                    ? null
                                                    : e.target.value,
                                              };
                                            }
                                          }
                                        ),
                                      };
                                    }
                                  }),
                                },
                              });
                            }
                          }}
                          InputProps={{
                            disableUnderline: true, // <== added this
                            style: {
                              fontFamily: "Montserrat",
                              fontWeight: "bold",
                              fontSize: 24,
                              color: props.layout.color_footer,
                            },
                          }}
                          style={{
                            width:
                              card.objective === null
                                ? 40
                                : String(card.objective).length * 0.7 * 24,
                          }}
                        />
                        <span style={{ marginTop: 2 }}>{card.uom}</span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "60%",
                            borderBottom: `1px solid ${props.layout.color_footer}`,
                          }}
                        ></div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          fontWeight: 500,
                          fontSize: width > 1600 ? 13 : 11,
                        }}
                      >
                        <i>
                          {moment(props.end_date).isValid()
                            ? moment(props.end_date).format("YYYY")
                            : "final"}{" "}
                          emission
                        </i>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "50%",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          fontWeight: 500,
                          fontSize: 24,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {String(parseFloat(card.delta)) !== "NaN" &&
                          String(parseFloat(card.delta)) !== "Infinity" ? (
                            parseFloat(card.delta) >= 0 ? (
                              <FaArrowDown
                                style={{
                                  color: "#E44747",
                                  marginRight: 5,
                                }}
                              ></FaArrowDown>
                            ) : (
                              <FaArrowUp
                                style={{
                                  color: "#41A14B",
                                  marginRight: 5,
                                }}
                              ></FaArrowUp>
                            )
                          ) : null}
                          <TextField
                            variant="standard"
                            value={card.delta}
                            onChange={(e) => {
                              if (props.key_redux === "co2_equivalent") {
                                dispatch({
                                  type: props.type,
                                  payload: {
                                    key: props.key_redux,
                                    value: props.co2_equivalent.map(
                                      (domain) => {
                                        if (domain.name !== card.name) {
                                          return domain;
                                        } else {
                                          return {
                                            ...domain,
                                            delta:
                                              e.target.value === ""
                                                ? null
                                                : e.target.value,
                                          };
                                        }
                                      }
                                    ),
                                  },
                                });
                              } else if (props.key_redux === "ghg") {
                                dispatch({
                                  type: props.type,
                                  payload: {
                                    key: props.key_redux,
                                    value: props.ghg.map((obj) => {
                                      if (obj.name !== props.ghgSelected) {
                                        return obj;
                                      } else {
                                        return {
                                          ...obj,
                                          domains: props.co2_equivalent.map(
                                            (domain) => {
                                              if (domain.name !== card.name) {
                                                return domain;
                                              } else {
                                                return {
                                                  ...domain,
                                                  delta:
                                                    e.target.value === ""
                                                      ? null
                                                      : e.target.value,
                                                };
                                              }
                                            }
                                          ),
                                        };
                                      }
                                    }),
                                  },
                                });
                              }
                            }}
                            InputProps={{
                              disableUnderline: true, // <== added this
                              style: {
                                fontFamily: "Montserrat",
                                fontWeight: 500,
                                fontSize: 24,
                                color: props.layout.color_footer,
                              },
                            }}
                            style={{
                              width:
                                card.delta === null
                                  ? 40
                                  : String(card.delta).length * 0.7 * 24,
                            }}
                          />{" "}
                          %
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "60%",
                            borderBottom: `1px solid ${props.layout.color_footer}`,
                          }}
                        ></div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          fontWeight: 500,
                          fontSize: width > 1600 ? 13 : 11,
                        }}
                      >
                        <i>
                          delta w.r.t.{" "}
                          {moment(props.start_date).isValid()
                            ? moment(props.start_date).format("YYYY")
                            : "--"}
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        );
      })}
    </Row>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(CO2Equivalent);
