import React from "react";
import { connect } from "react-redux";
import { FaAngleDoubleLeft } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";

function SubNavbar(props) {
  const history = useHistory();
  const location = useLocation();

  return (
    <div
      style={{
        width: "100%",
        zIndex: 1,
        backgroundColor: props.state.layout.color_footer,
        height:
          props.pages === undefined
            ? props.state.layout.subnavbar_height_empty
            : props.state.layout.subnavbar_height_not_empty,
        padding: `0px 20px 0px ${props.state.layout.width_sidebar + 20}px`,
      }}
    >
      {/* workspace */}
      <div
        style={{
          width: "100%",
          height: "100%",
          display: props.pages === undefined ? "none" : "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: "white",
          fontFamily: "Montserrat",
          fontSize: 14,
        }}
      >
        <div style={{ display: "flex" }}>
          {props.pages !== undefined
            ? props.pages.map((obj, index) => {
                return (
                  <div
                    key={index}
                    className="underlineOnHover"
                    onClick={() => {
                      var link = obj.link;
                      // if click on "Modify ...." then try to see if there is something opened
                      if (obj.name.includes("Modify ")) {
                        var split = obj.name.split("Modify ");
                        var key = `opened_${split[split.length - 1]}`;
                        if (props.state[key].id !== undefined) {
                          link = `${obj.link}/?id=${props.state[key].id}`;
                        }
                      }
                      history.push(link);
                    }}
                    style={{
                      paddingLeft: 15,
                      height: 20,
                      display: "flex",
                      alignItems: "center",
                      borderLeft: "3px solid",
                      cursor: "pointer",
                      marginLeft: index === 0 ? 0 : 30,
                      borderLeftColor: location.pathname.includes(obj.link)
                        ? props.state.layout.yellow_detail
                        : "white",
                      color: location.pathname.includes(obj.link)
                        ? props.state.layout.yellow_detail
                        : "white",
                    }}
                  >
                    {obj.name}
                  </div>
                );
              })
            : " "}
        </div>
        <div
          className="underlineOnHover"
          onClick={() => history.push("/clicc/dashboard/homepage")}
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <FaAngleDoubleLeft
            style={{ fontSize: 16, marginRight: 2 }}
          ></FaAngleDoubleLeft>
          Home
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  state: state,
});

export default connect(mapStateToProps)(SubNavbar);
